import { Injectable, Output, EventEmitter, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { AuthenticationService } from "../app.service";
import { Router } from '@angular/router'
import { isToday } from 'date-fns';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class JobseekerService {
  apipath = '';
  baseUrl: string = "";
  token: string = "";
  isLogin: string = 'false';
  constructor(
    private API: AuthenticationService,
	  private AuthenticationService: AuthenticationService,
    private _router: Router,
    private http: HttpClient) {
    this.apipath = `${environment.apipath}`
    this.apipath = `${environment.apipath}`;
    this.baseUrl = this.API.url;
    this.token = localStorage.getItem("token");
  }

  // Service for jobseekerDashboard Start
  jobseekerDashboard = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    // console.log("services start ==============>");
    let localurl = this.baseUrl + "/api/jobseeker/dashboard";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Service for jobseekerDashboard End

  MarksAsFavouriteJobApi(jsId,jobId,isFavourite) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/favouritejob";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    let body = {
      jsId:jsId,
      jobId: jobId,
      isFavouritejob: isFavourite
    }
    // console.log("localurl updatefavourite >>", localurl, body);
    return this.http.post(localurl, body, httpOptions);
  }
  // Service for recentActivity for jobseeker Start
  recentActivity(PageSize,PageNumber) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/activities";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };
    let body = {
      PageNumber: PageNumber,
      PageSize: PageSize
    };
    return this.http.post(localurl, body, httpOptions);
  }
  // Service for recentActivity for jobseeker End

  // Service for announcement for jobseeker Start
  announcement(PageNumber,PageSize) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/announcements";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };
    let body = {
      PageNumber: PageNumber,
      PageSize: PageSize
    };
    return this.http.post(localurl, body, httpOptions);
  }
  // Service for announcement for jobseeker End

  // jobseeker profile  details api Start
  jobseekerdetails = () => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/myprofile";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  };
  // jobseeker profile  details api End
  // jobseeker profile  details api Start
  jobseekerCheckEmail = (isSelectedJSid, obj) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/checkemail/" + isSelectedJSid + '/' + obj;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  };
  // jobseeker profile  details api End

  // jobseeker profile  details api Start
  jobseekerCheckPhoneNo = (isSelectedJSid, obj) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/checkmobile/" + isSelectedJSid + '/' + obj;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  };
  // jobseeker profile  details api End


  //Update jobseeker data
  staffJobseekerupdateVD = (data: any,id) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    var aa = this.AuthenticationService.jwtTokenExpCheck();

    const localurl = this.apipath + 'Recruiter/EditJobseeker';
    const httpOptions = {
        // headers: new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'authenticate': this.token + ''
        // })

        headers :{
          Authorization:"Bearer " + this.token // token
      }

    };   
    const body=
    {
        'userId': id,
        'name': data.firstName,
        'email': data.emailId,
        'phone': data.phone_mobile,
        'skillIds': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        'ModifiedBy':0,
        'subscriberId': 0,
        'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        'roleManagerId': 0 ,
        'AppType':environment.AppType,
        'AppRefType':environment.AppRefTypeJobseeker  
      }
    return this.http.post(localurl, body, httpOptions);
  }
  
  // jobseeker basic details in profile page api Start
  jobseekerBasicDetails(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/updateprofile";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };
    // let body = {
    //   twitterUrl: data.twitterUrl,
    //   facebookUrl: data.facebookUrl,
    //   linkdinUrl: data.linkdinUrl
    // };
    return this.http.post(localurl, body, httpOptions);
  }
  // jobseeker basic details in profile page api End

  // jobseeker update social link api Start
  jobseekerupdatesociallink(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/updatelinkedprofile";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };


    return this.http.post(localurl, body, httpOptions);
  }
  // jobseeker update social link api End

  // jobseeker update current jobdetails api Start
  updatecurrentjobdetails(srNo, body) {

    let localurl = this.baseUrl + "/api/jobseeker/updateexperience"
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };

    // let body = {
    //   role: data.role,
    //   companyName: data.companyName,
    //   relocation: data.relocation,
    //   ssnissueDate: data.ssnissueDate,
    //   linkedinId: data.linkedinId,
    //   skypeId: data.skypeId,
    //   durationFrom: data.durationFrom,
    //   durationTo: data.durationTo,
    // };
    return this.http.post(localurl, body, httpOptions);
  }
  // jobseeker update current jobdetails api End

  // jobseeker update skills api Start
  updateskills(body) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/updateskills";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };


    return this.http.post(localurl, body, httpOptions);
  }
  // jobseeker update skills api End

  // Jobseeker Update Profile Img api Start
  JobseekerUpdateProfileImg(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/uploadprofileimage";
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, data, httpOptions);
  }
  // Jobseeker Update Profile Img api End

  profileImageVD = (Id) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin == 'true') {   
      const url = this.apipath + 'Account/GetUserProfileImage';
      const httpOptions = {     
        headers :{
          Authorization:"Bearer " + this.token // token
      }
      };
      let body = {
        "UserId": Id,
        "AppType":environment.AppType,
        "AppRefType":environment.AppRefTypeJobseeker
        
      }
      return this.http.post(url, body, httpOptions);
    } else {
      this._router.navigate(['/']);
    }
  }

  //  Update client profile image to VD
 seekerProfileImgUpdateVD = (formData) => {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/UpdateUserImage';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
   
    return this.http.post(url, formData, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}

  // Jobseeker Upload Resume api Start
  JobseekerUploadResume(data, date:string) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/uploadresume";
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
   
    return this.http.post(localurl, data, httpOptions);
  }
  // Jobseeker Upload Resume api End

  // Jobseeker Delete Resume api Start
  JobseekerDeleteResume(resumename) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();

    let localurl = this.baseUrl + "/api/jobseeker/deleteresume/" + resumename;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Jobseeker Delete Resume api End

//Jobseeker Download Resume api statrt

JobseekerDownloadResume(){
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  let localurl = this.baseUrl + "/api/jobseeker/downloadresume";
  const httpOptions={
    headers: new HttpHeaders({
      'authenticate':this.token
    })
  };
  return this.http.get(localurl,httpOptions);
}

//Jobseeker Download Resume api end

  // jobseeker Browse jobs List api Start
  BrowsejobsListAPI = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/browsejobs"
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  // jobseeker Browse jobs List api End

  // jobseeker My jobs List api Start
  MyjobsListAPI = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/myjobs"
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  // jobseeker My jobs List api End

  // jobseeker Apply OnJob API api Start
  ApplyOnJobAPI = (jobId,date:string) => {
     
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/applyjob";
    // console.log(localurl, jobId)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };
    let body ={
      jobId:jobId,
      date:date
    }
    return this.http.post(localurl,body, httpOptions);
  }
  countries = (search) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/country/" + search

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  joblocation = (search) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/joblocation/" + search

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };
    return this.http.get(localurl, httpOptions);
  }


  // jobseeker Apply OnJob API api End
  // jobseeker My jobs List api Start
  getinterviewJS = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/getinterview"
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }
  createChatRoom = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/getroom"
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }


  // Guest Browse jobs List api Start
  GuestBrowsejobsListAPI = (body) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/guestuser/searchjobs"
    // console.log(localurl, data);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'authenticate': `${this.token}`
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }
  // Guest Browse jobs List api End




  getfiltersMyjob() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/jobseeker/getfilters/JS/myjobs`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  getfiltersbrowsejobs() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/jobseeker/getfilters/JS/browsejobs`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  AddTimeSheetMaster = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/timesheet/timesheetmaster`;
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'authenticate': this.token
        })
    };
    const body = data[0];
    return this.http.post(localurl, body, httpOptions);
}
      
  AddTimeSheetDetail = (data) => {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/timesheet/timesheetDetail`;
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'authenticate': this.token
        })
    };
    const body = data;
    return this.http.post(localurl, body, httpOptions);
}

AddTimeSheetAuditTB = (data) => {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  const localurl = `${this.baseUrl}/api/timesheet/timesheetAuditTB`;
  const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
      })
  };
  const body = data[0];
  return this.http.post(localurl, body, httpOptions);
}


  StateSearch(search) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/jobseeker/StateSearch/${search}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);
  }

  GetListofJobTitles(enterpriseId,hiringmanager,JobId,jobseekerId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/timesheet/listofjobtitles/${enterpriseId}/${hiringmanager}/${JobId}/${jobseekerId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  // get Timesheet period date in table
GetTimeSheet(data) {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  let localurl = `${this.baseUrl}/api/timesheet/gettimesheet`;
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'authenticate': this.token
    })
  };
  const body=data;
  return this.http.post(localurl,body, httpOptions);
}

  //This service use for accept jobseeker timesheet
  AcceptTimeSheet(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/timesheet/accepttimesheet`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    const body=data;
    return this.http.post(localurl,body, httpOptions);
  }

    //This service use for reject jobseeker timesheet
    RejectTimeSheet(data) {
      this.token = localStorage.getItem("token");
      this.AuthenticationService.jwtTokenExpCheck();
      let localurl = `${this.baseUrl}/api/timesheet/gettimesheet`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
        })
      };
      const body=data;
      return this.http.post(localurl,body, httpOptions);
    }

UpdateTimeSheetDetail = (data) => {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  const localurl = `${this.baseUrl}/api/timesheet/updatetimesheetDetail`;
  const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  };
  const body = data;
  return this.http.post(localurl, body, httpOptions);
}

//Get Job overtime status
GetJobsStatus = (JobId) => {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  let localurl = this.baseUrl + "/api/timesheet/getjobstatus/"+JobId;
  const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authenticate: this.token
    })
  };
  return this.http.get(localurl, httpOptions);
}

// get exist period date in table
Getexistperiod(data) {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  let localurl = `${this.baseUrl}/api/timesheet/listofexistperiod`;
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'authenticate': this.token
    })
  };
  const body=data;
  return this.http.post(localurl,body, httpOptions);
}

getSupplierName(Id){
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  let localurl = this.baseUrl + "/api/jobseeker/getsuppliername/"+Id;
  const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authenticate: this.token
    })
  };
  return this.http.get(localurl, httpOptions);
}

  //get job status by jobseekerId

  getJobStatus(){
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = this.baseUrl + "/api/jobseeker/getjobstatus";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authenticate: this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  GetManager(JobseekerId) {
    
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/timesheet/getManger/${JobseekerId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  country() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    
    let localurl = `${this.baseUrl}/api/jobseeker/countries`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }
  state(countryId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/staffingagency/states/${countryId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  city(stateId) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/staffingagency/cities/${stateId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  nationality() {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/jobseeker/nationality`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }


//20201104:Akhilesh, Search manager API
searchManager(job_id, data: string) {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  const localurl = `${this.baseUrl}/api/timesheet/searchManager/${job_id}/${data}`;
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'authenticate': this.token
    })
  };
  return this.http.get(localurl, httpOptions);
}



//20201104:Akhilesh, Search title API
searchTitle(hiring_manager,job_id,jobseekerId,enterprise_Id, data: string) {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  const localurl = `${this.baseUrl}/api/timesheet/searchTitle/${hiring_manager}/${job_id}/${jobseekerId}/${enterprise_Id}/${data}`;
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'authenticate': this.token
    })
  };
  return this.http.get(localurl, httpOptions);
}

jobseekerdetailsVS = (token:any) => {
  let localurl = this.baseUrl + "/api/jobseeker/myprofile";
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'authenticate': token
    })
  };
  return this.http.get(localurl, httpOptions);
}




}
