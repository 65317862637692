import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';

// import {
//   SocialLoginModule,
//   AuthServiceConfig,
//   GoogleLoginProvider,
//   LinkedinLoginProvider,
//   FacebookLoginProvider,
//   AuthService
// } from 'angular-6-social-login';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterLink, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogOverviewExampleDialog } from './header/header.component';
import { componentServices } from './component.service';
// import { BrowseJobBasicComponent } from './browse-job/browse-job.component'
import { ShareModuleModule } from '../share-module/share-module.module';
import { BrowseJobComponent } from '../share-module/browse-job/browse-job.component';
import { staffingAgencyServices } from '../staffing-agency/staffing-agency.services';
// import { UpdateCandidateComponent } from "./update-candidate/update-candidate.component";
@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        MatMenuModule,
        MatFormFieldModule,
        MatDialogModule,
        RouterModule,
        MatRadioModule,
        MatPaginatorModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        ShareModuleModule,
        MatSelectModule,
        // appRouteModule,
        MatAutocompleteModule,
        ShareModuleModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatTabsModule,
        MatProgressSpinnerModule
    ],
    providers: [
        componentServices,
        // { provide: AuthServiceConfig, useFactory: getAuthServiceConfigs },
        // AuthService,
        staffingAgencyServices
    ],
    exports: [
        HeaderComponent,
        FooterComponent
        //  BrowseJobBasicComponent
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        DialogOverviewExampleDialog
        //  BrowseJobBasicComponent
    ]
})
export class ComponentModule { }

export function getAuthServiceConfigs() {
  // const config = new AuthServiceConfig([
  //   {
  //     id: FacebookLoginProvider.PROVIDER_ID,
  //     provider: new FacebookLoginProvider('315907432417866')
  //   },
  //   {
  //     id: GoogleLoginProvider.PROVIDER_ID,
  //     provider: new GoogleLoginProvider(
  //       '607772684504-gjitkbbdoi97561io0vu0ep8nv2853o8.apps.googleusercontent.com'
  //     )
  //   },
  //   {
  //     id: LinkedinLoginProvider.PROVIDER_ID,
  //     provider: new LinkedinLoginProvider('51778846')
  //   }
  // ]);
  // return config;
}
