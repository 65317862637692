import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableService } from 'src/app/admin/service';
import { LayoutService } from '../layout.service';


@Component({
  selector: 'app-projectapproval',
  templateUrl: './projectapproval.component.html',
  styleUrls: ['./projectapproval.component.css']
})
export class ProjectapprovalComponent implements OnInit {
  id = '';
  baseUrl = '';
  projectData: any;
  IsapprovePopUp: boolean = false;
  IsrejectPopUp: boolean = false;
  IsNotrejectPopUp: boolean = false;
  IsYesrejectPopUp: boolean = false;
  IsExpiredPopUp: boolean = false;
  constructor
    (
      private _activated: ActivatedRoute,
      private tableService: TableService,
      private layoutService:LayoutService
    ) {
    this.id = this._activated.snapshot.params['id'];
    this.baseUrl = "/";
    this.verifyinvitation(this.id);
  }


  ngOnInit() {
    this.layoutService.setShowSidebar(false);
  }
  //
  verifyinvitation(code: any) {
    this.tableService.approvproject(code).subscribe(res => {
      this.projectData = res
      if(this.projectData.data[0].approved_status != 'Approved' && this.projectData.data[0].approved_status != 'Rejected'){
      if (this.projectData.success) {
        if (this.projectData.data[0].approval_url_token.trim() === code) {
          // let body = {
          //   "Id": this.projectData.data.Id,
          //   "ProjectId": this.projectData.data.projectid,
          //   "status": "Approved"
          // }
          this.IsapprovePopUp = true;
          let Id = this.projectData.data[0].Id;
          let ProjectId = this.projectData.data[0].projectid;
          let status = "Approved";
          this.tableService.getProjectApprovalByEmailId(Id, ProjectId, status).subscribe(res => {
            let data = res;
          })

        }
        else {
          this.IsrejectPopUp = true;    
          //this.RejectFunction();     
        }

      }
    }
    else{
this.IsExpiredPopUp=true;
    }
    })

  }

  Yesreject(){
    this.IsrejectPopUp = false;
    this.IsNotrejectPopUp = true;
    let Id = this.projectData.data[0].Id;
    let ProjectId = this.projectData.data[0].projectid;
    let status = "Rejected";
    this.tableService.getProjectApprovalByEmailId(Id, ProjectId, status).subscribe(res => {
      let data = res;
    })
  }   
  Noreject(){
    this.IsrejectPopUp = false;
    this.IsNotrejectPopUp = false;
    this.IsYesrejectPopUp=true;
    }

}
