import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EnterpriseService } from '../enterprise.service';
import { AuthenticationService } from '../../app.service';
import { DatePipe } from '@angular/common';
import { from } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { ShareService } from 'src/app/share-module/share.service';
import { HttpErrorResponse } from '@angular/common/http';



@Component({
  selector: 'app-profile-for-enterprise',
  templateUrl: './profile-for-enterprise.component.html',
  styleUrls: ['./profile-for-enterprise.component.css']
})
export class ProfileForEnterpriseComponent implements OnInit {
  basicupdateVD: any;
  preLoader = false;
  dataLoad = false;
  listOrder = '';
  Profilelist: any;
  ProfileData: any;
  staffingProfillist: any;
  istabope = '';
  isEditform = false;
  isaboutform = false;
  islinkform = false;
  staffEditForm: FormGroup;
  staffEditForm1: FormGroup;
  staffEditForm2: FormGroup;
  submitted2 = false;
  userData1: any;
  userData: any;
  exitsMail = false;
  changePaswordForm: FormGroup;
  existPhone = false;
  notMatch: boolean = false;
  img_path = '/assets/images/user.svg';
  select_img_path = '/assets/images/user.svg';
  serverpth = '';
  basicupdate: any;
  submitted: boolean = false;
  IsAdminCheck: boolean;
  isImgExits: boolean = false;
  imgName: any;
  userid: string | Blob;
  // constructor() { }
  constructor(
    private EnterpriserApi: EnterpriseService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrManager,
    private appAPI: AuthenticationService,
    private datepipe: DatePipe,
    private _titleService: Title,
    private shareservice: ShareService,
    private AuthenticationService: AuthenticationService

  ) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    // this.img_path = localStorage.getItem('profileImagePath');
    this.select_img_path = localStorage.getItem('selectprofileImagePath');
    if(this.select_img_path != null){
    this.img_path = this.select_img_path;
  }else {
    this.img_path = localStorage.getItem('profileImagePath');
    this.select_img_path = this.img_path;
  }
    // for title set 
    var newTitle = 'Verisource | My Profile';
    this._titleService.setTitle(newTitle);

    this.serverpth = `${this.appAPI.url}`;
    this.istabope = 'date';

    this.staffEditForm = this._formBuilder.group({
      'companyName': ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      'website': ['', [Validators.required, Validators.pattern(
        "(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      'firstName': ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      'middleName': [''],
      'lastName': ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      'emailId': ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      'password': [''],
      'contactNo': [''],
      'profileimage': [''],
      'createdDate': ['']
    });

    this.staffEditForm1 = this._formBuilder.group({
      'aboutCompany': ['', Validators.required]
    });
    this.staffEditForm2 = this._formBuilder.group({
      'twitterUrl': [''],
      'facebookUrl': [''],
      'linkdinUrl': ['']
    });

    this.changePaswordForm = this._formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(8)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      updatedDate: ['']
    });
  }

  ngOnInit() {
    this.profilefuction();
  }
  get f() { return this.staffEditForm.controls; }
  get a() { return this.staffEditForm1.controls; }
  profilefuction() { 
    this.AuthenticationService.jwtTokenExpCheck();
    this.preLoader = true;
    const _promisses = new Promise((resolve, reject) => {
      this.EnterpriserApi.Entrpzdetails().subscribe(res => {
        this.Profilelist = res;
                
        this.IsAdminCheck = this.userData.data.credentials.isAdmin;
        resolve(res);
        this.dataLoad = true;
        this.preLoader = false;
      }, err => {
        reject(err);
      });
    });
    return _promisses;
  }

  Popupstaffingform() {
    this.preLoader=true;
    this.AuthenticationService.jwtTokenExpCheck();
    this.staffEditForm.controls['companyName'].setValue(this.Profilelist.data.companyName);
    this.staffEditForm.controls['website'].setValue(this.Profilelist.data.website);
    this.staffEditForm.controls['firstName'].setValue(this.Profilelist.data.firstName);
    this.staffEditForm.controls['middleName'].setValue(this.Profilelist.data.middleName);
    this.staffEditForm.controls['lastName'].setValue(this.Profilelist.data.lastName);
    this.staffEditForm.controls['emailId'].setValue(this.Profilelist.data.emailId);
    this.staffEditForm.controls['password'].setValue(this.Profilelist.data.password);
    this.staffEditForm.controls['contactNo'].setValue(this.Profilelist.data.contactNo);
    this.preLoader=false;
    this.isEditform = !this.isEditform;
  }
  modal_staffinglink_popup() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.staffEditForm2.controls['twitterUrl'].setValue(this.Profilelist.data.twitterUrl);
    this.staffEditForm2.controls['facebookUrl'].setValue(this.Profilelist.data.facebookUrl);
    this.staffEditForm2.controls['linkdinUrl'].setValue(this.Profilelist.data.linkdinUrl);
    this.islinkform = !this.islinkform;
  }
  modal_staffingabout_popup() {
    this.preLoader=true;
    this.AuthenticationService.jwtTokenExpCheck();
    this.staffEditForm1.controls['aboutCompany'].setValue(this.Profilelist.data.aboutCompany);
    this.preLoader=false;
    this.isaboutform = !this.isaboutform;
  }

  submited3 = true;
  isChangePasswordform: boolean = false;
  changePasswordForm() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.submited3 = false;
    this.changePaswordForm = this._formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      updatedDate: ['']
    });
    this.changePaswordForm.controls['oldPassword'].setErrors({ 'invalid': false });
    this.changePaswordForm.controls['password'].setErrors({ 'invalid': false });
    this.changePaswordForm.controls['confirmPassword'].setErrors({ 'invalid': false });
    this.notMatch = false;
    this.isChangePasswordform = !this.isChangePasswordform;
  }

  get cp() { return this.changePaswordForm.controls }

  onSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.submited3 = true;
    if (this.changePaswordForm.controls['password'].value === this.changePaswordForm.controls['confirmPassword'].value) {
      this.notMatch = false;
      if (this.changePaswordForm.valid) {
        this.isChangePasswordform = false;
        this.preLoader = true;
        try {
          this.changePaswordForm.controls['updatedDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

          this.EnterpriserApi.resetPasswordVD(this.changePaswordForm.value, this.Profilelist.data.emailId).subscribe((res: any) => {

            if (res.success) {

              this.toastr.successToastr(res.message, 'Done!');
              this.isChangePasswordform = false;
              this.preLoader = false;
              // this.EnterpriserApi.changePassword(this.changePaswordForm.value, this.Profilelist.data.enterpriseId,'EP',this.Profilelist.data.emailId).subscribe((res: any) => {
              //   if (res.success) {
              //     this.toastr.successToastr('Password changed successfully');
              //   } else {
              //     this.toastr.errorToastr('Old password is not correct');
              //   }
              // });
            }
            else {
              this.preLoader = false;
              this.isChangePasswordform = true;
              this.toastr.errorToastr('Old password is not correct', 'Error!');
            }
          });
        } catch (error) {
          this.isChangePasswordform = true;
          this.preLoader = false;
          throw error;
        }
        
      } else {
        return false;
      }
    } else {
      this.notMatch = true;
      return false;
    }
  }

  dateFormat(date) {
    return this.datepipe.transform(date, 'dd/MM/yy');
  }


  staffingFormdetals() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.submitted = true;
    if (this.staffEditForm.valid) {
      this.isEditform=false;
      this.preLoader = true;
      let id = this.userData.data.credentials.userId;
      this.staffEditForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
       
      // this.EnterpriserApi.enterpriseUpdateFormVD(this.staffEditForm.value, id).subscribe(res => {
      //   this.basicupdateVD = res;
      //   if (this.basicupdateVD.success) {
          this.EnterpriserApi.profileBasicUpdate(this.staffEditForm.value).subscribe(res => {
            this.basicupdate = res;
            if (this.basicupdate.success) {
              this.EnterpriserApi.enterpriseUpdateFormVD(this.staffEditForm.value, id).subscribe(res => {
                this.basicupdateVD = res;
                if (this.basicupdateVD.success) {
                  this.updateLocalStorage(this.basicupdate, 'basic');
                  this.isEditform = false;
                  this.preLoader = false;
                  this.profilefuction();
                  this.toastr.successToastr(this.basicupdate.message, '', { position: 'top-right' });
                }
                else{
                  this.preLoader = false;
                  this.isEditform = true;
                  this.toastr.successToastr(this.basicupdateVD.message, '', { position: 'top-right' });
                }
              });
              
            } else {
              this.preLoader = false;
              this.isEditform = true;
              this.toastr.errorToastr(this.basicupdate.message, '', { position: 'top-right' });
            }
          },(errorResponse: any) => {     
            this.preLoader = false;
            this.islinkform = true;
            if (errorResponse instanceof HttpErrorResponse) {
              if (errorResponse.status === 422) {
                 if(errorResponse['error'].errors){
                   this.toastr.errorToastr(errorResponse['error'].errors[0].msg)
                 }
              }
              else{
                this.toastr.errorToastr(errorResponse['message']);
              }
            }
           });

    } else {
      return false;
    }

  }

  basicupdate1: any
  updateAboutSubmmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.submitted2 = true;
    if (this.staffEditForm1.valid) {
      this.isaboutform=false;
      this.preLoader=true;
      this.EnterpriserApi.profileAboutUpdate(this.staffEditForm1.value).subscribe(res => {
        this.basicupdate1 = res;
        if (this.basicupdate1.success) {
          this.isaboutform = false;
          this.preLoader=false;
          this.profilefuction();
          this.toastr.successToastr(this.basicupdate1.message, '', { position: 'top-right' });
        }
        else {
          this.isaboutform=true;
          this.preLoader=false;
          this.toastr.errorToastr(this.basicupdate1.message, '', { position: 'top-right' });
        }
      },(errorResponse: any) => {     
        this.preLoader = false;
        this.islinkform = true;
        if (errorResponse instanceof HttpErrorResponse) {
          if (errorResponse.status === 422) {
             if(errorResponse['error'].errors){
               this.toastr.errorToastr(errorResponse['error'].errors[0].msg)
             }
          }
          else{
            this.toastr.errorToastr(errorResponse['message']);
          }
        }
       });
    }
    else {
      return false;
    }

  }

  sociallinkSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    if (this.staffEditForm2.valid) {
      this.EnterpriserApi.profileSocialUpdate(this.staffEditForm2.value).subscribe(res => {
        this.basicupdate = res;
        if (this.basicupdate.success) {
          // this.isEditform = false;
          this.islinkform = false;
          this.profilefuction();
          // alert(this.basicupdate.message);
          // window.location.reload();
          this.toastr.successToastr(this.basicupdate.message, '', { position: 'top-right' });
        }
        else {
          this.toastr.errorToastr(this.basicupdate.message, '', { position: 'top-right' });
        }

      },(errorResponse: any) => {     
        this.preLoader = false;
        this.islinkform = true;
        if (errorResponse instanceof HttpErrorResponse) {
          if (errorResponse.status === 422) {
             if(errorResponse['error'].errors){
               this.toastr.errorToastr(errorResponse['error'].errors[0].msg)
             }
          }
          else{
            this.toastr.errorToastr(errorResponse['message']);
          }
        }
       });
    }
    else {
      return false;
    }
  }
 
  profileImage() {  
    this.AuthenticationService.jwtTokenExpCheck();
    this.preLoader = true;
    const _promisses = new Promise((resolve, reject) => {
      if(this.Profilelist.data.isAdmin == true){
        this.userid = this.userData.data.credentials.userId;       
    }else{
      this.userid=  this.userData.data.credentials.UserIdSelf;
    }
      this.EnterpriserApi.profileImageVD(this.userid).subscribe(res => {
         
        this.ProfileData = res; 
        if(this.ProfileData.data.length > 0){   
        var profileImagePath = this.img_path =this.ProfileData.data[0].profileImagePath;        
        localStorage.setItem('selectprofileImagePath', this.ProfileData.data[0].profileImagePath); 
        resolve(res);
        this.dataLoad = true;
        this.preLoader = false;
        }
      }, err => {
        reject(err);
      });
    });
    return _promisses;
  }

  sync = false;
  isImage = false;
  profileimageResponse: any;
  onSelect(event) {  
    this.AuthenticationService.jwtTokenExpCheck();
    this.preLoader = true;
    if( event.target.files.length > 0){
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type == 'image/png') {
      const formData = new FormData();
      if (event.target.files[0].size < (2029410 / 2)) {
        if(this.Profilelist.data.isAdmin == true){
          this.userid = this.userData.data.credentials.userId;       
      }else{
        this.userid=  this.userData.data.credentials.UserIdSelf;
      }
        
        formData.append("ImageUrl", event.target.files[0]);
        formData.append("UserId", this.userid);        
        this.EnterpriserApi.enterpriseProfileImgUpdateVD(formData).subscribe(res => {
          this.basicupdateVD = res;
          if (this.basicupdateVD.success) {
            this.img_path=this.select_img_path = this.basicupdateVD.data['filePath'];
            localStorage.setItem('profileImagePath', this.select_img_path);
            localStorage.setItem('profileUpdateImagePath', this.select_img_path)
            // this.profilefuction();
            // this.profileImage();
            this.isImage = true;
            this.isImgExits = true;
            this.toastr.successToastr(this.basicupdateVD.message, '', { position: 'top-right' });
            this.preLoader = false;
          } else {

            this.toastr.errorToastr(this.profileimageResponse.message, '', { position: 'top-right' });
          }
          this.sync = true;
        });
      } else {
        this.toastr.errorToastr('Profile image should be less than 1 MB.');
        this.preLoader = false;
      }
    } else {
      this.toastr.errorToastr('Profile Img should be in JPEG & PNG format.', 'Error!');
      this.preLoader = false;
    }
  }

  }

  updateLocalStorage(val, type) {
    this.AuthenticationService.jwtTokenExpCheck();
    let udata;
    udata = JSON.parse(window.localStorage.getItem('udata'));
    if (type == 'basic') {
      udata.data.credentials.firstName = val.data.firstName;
      udata.data.credentials.lastName = val.data.lastName;
    } else {
      udata.data.credentials.profileImage = val.data.profileImage;
    }
    window.localStorage.setItem('udata', JSON.stringify(udata));
  }


  OnChangePhoneNo(field_no, e) {
    if (e.target.value != '') {
      let f_val = '';
      var r = /(\D+)/g,
        npa = '',
        nxx = '',
        last4 = '';
      e.value = e.target.value.replace(r, '');
      npa = e.value.substr(0, 3);
      nxx = e.value.substr(3, 3);
      last4 = e.value.substr(6, 4);
      if (e.target.value !== '' && e.keyCode != 8) {
        if (field_no === 1) {
          this.staffEditForm.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
        } else if (field_no === 2) {

        }
      }
    }

  }


  checkmail(id, event) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.EnterpriserApi.mailExist(id, event.target.value).subscribe((res: any) => {
      if (res.success) {
        if (res.data.exists) {
          this.exitsMail = true;
          this.staffEditForm.controls['emailId'].setErrors({ 'errors': true });
        } else {
          this.exitsMail = false;
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
  }

  checkPhone(id, event) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.EnterpriserApi.phoneExist(id, event.target.value).subscribe((res: any) => {
      if (res.success) {
        if (res.data.exists) {
          this.existPhone = true;
          this.staffEditForm.controls['contactNo'].setErrors({ 'error': true });
        } else {
          this.existPhone = false;
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
  }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  ImageFileExits(imgname) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.EnterpriserApi.fileExists("profileimage", imgname).subscribe((res: any) => {
      if (res.data == true) {
        this.isImgExits = true;
      }
    });
  }

  // 20200929: Akhilesh, Special charcter validation, jira no 70
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;
    if (k == 33 || k == 36 || k == 37 || k == 44 || k == 58 || k == 60 || k == 61 || k == 62 || k == 63 || k == 91 || k == 92 || k == 93 || k == 94 || k == 96 || k == 123 || k == 124 || k == 125 || k == 126) {
      this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .', 'Validation!');
      return false
    }
  }
}
