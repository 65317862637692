import { Component, OnInit, ViewChild } from '@angular/core';
import { JobseekerService } from '../../JobSeeker/jobseeker.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications'
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ShareService } from '../share.service';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
  
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { ExcelService } from 'src/app/admin/service/excel.service';
@Component({
  selector: 'app-clienttimesheetdetails',
  templateUrl: './clienttimesheetdetails.component.html',
  styleUrls: ['./clienttimesheetdetails.component.css'],
  providers: [    
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ClienttimesheetdetailsComponent implements OnInit {
   // Datepicker takes `Moment` objects instead of `Date` objects.
   date = new FormControl(moment([2017, 0, 1]));
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  userType:any;
  userData: any;
  id = '';
  period='';
  jobid='';
  invoiceid:number;
  inv:'';
  timeSheetData: any = [];
  paginator = {
    pageSize: 10,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [10, 20, 50, 100]
  };
  //  202001008, Akhilesh : jira no 99(1)
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];
  searchForm:FormGroup;
  constructor(
    private _activated: ActivatedRoute,
    private toastr: ToastrManager,
    private _formBuilder: FormBuilder,
    private JSApi: JobseekerService,
    private shareservice:ShareService,
    private datepipe:DatePipe,
    private excelService:ExcelService) 
    { 
    this.id = this._activated.snapshot.params['id'];
    this.period = this._activated.snapshot.params['period'];
    this.jobid = this._activated.snapshot.params['jobid'];
    this.invoiceid = this._activated.snapshot.params['invoiceid'];
    this.inv = this._activated.snapshot.params['inv'];

    this.searchForm = this._formBuilder.group({
      searchManager: [''],
      searchjobTitle: [''],
      fromdate: [''],
      todate: [''],
      PageSize: 10,
      PageNumber: 1,
      period:'',
      search:''
    });
  }

  ngOnInit() {
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.userType=this.userData.data.credentials.userType;
    this.ShowData();
  }

  csv_timesheetdetails=[];
  ShowData() {
    if(this.searchForm.value.todate !="" && this.searchForm.value.fromdate != null){
      const d = new Date(this.searchForm.value.todate);
      const f = new Date(this.searchForm.value.fromdate);
      if(this.searchForm.value.fromdate <= this.searchForm.value.todate){
      var month = d.getMonth()+1;
       var month1 = f.getMonth()+1;       
      let finalDate = d.getFullYear() + "-"+ (month<10 ? '0' : '') + month + '-' +(d.getDate()<10 ? '0' : '')+ d.getDate();
      let formDate = f.getFullYear() + "-"+ (month1<10 ? '0' : '') + month1 + '-' +(f.getDate()<10 ? '0' : '')+ f.getDate();
      this.searchForm.value.todate = finalDate;
      this.searchForm.value.fromdate =formDate
     }
     else{
      this.toastr.errorToastr("From date should not greater than To date", 'Error!');
       return;
     }
    }
    // else{
    //   this.searchForm.controls['todate'].setValue('');
    //   this.searchForm.controls['fromdate'].setValue('')
    // }
    if(this.searchButton == true){
      this.searchForm.controls['PageNumber'].setValue(1);
    }
    var data = {
      'fromdate': this.searchForm.value.fromdate== null ? '' : this.shareservice.convertDateIntoUtc(this.datepipe.transform(this.searchForm.value.fromdate,'yyyy-MM-dd')),
      'todate': this.searchForm.value.todate == null ? '' : this.shareservice.convertDateIntoUtc(this.datepipe.transform(this.searchForm.value.todate,'yyyy-MM-dd 23:59:59')),
      'ApprovalUser': this.searchForm.value.searchManager,
      'jobId': this.jobid,
      'UserID': this.id,
      'PageSize':this.searchForm.value.PageSize,
      'PageNumber':this.searchForm.value.PageNumber,
      'period':this.period,
      'search':this.searchForm.value.search,
      'invoiceid':this.invoiceid == undefined?0:this.invoiceid,
      'inv':this.inv == undefined?'':this.inv
    }
    this.JSApi.GetTimeSheet(data).subscribe((res: any) => {
      if(res.success){
      this.timeSheetData = res.data 
      if(this.timeSheetData.length>0){
        this.paginator.length = this.timeSheetData[0].totalCount;
      }
      else{
        this.paginator.length=0;
      }
      if(this.searchButton == true)
      {
        this.paginators.pageIndex = 0;
      }
      this.searchButton = false;

      this.timeSheetData.forEach(element => {
        this.csv_timesheetdetails.push({
            'Start Date':this.datepipe.transform(element.Period,'MMM dd,yyyy'),
            'End Date':this.datepipe.transform(element.lastdate,'MMM dd,yyyy'),
            'Job ID':element.JobId,
            'Employee Name': element.fullname,
            'Employee ID': element.JobseekerId,
            'Monday': element.monday,
            'Monday Ot': element.ot_monday,
            'Tuesday': element.tuesday,
            'Tuesday Ot': element.ot_tuesday,
            'Wednesday': element.wednesday,
            'Wednesday Ot': element.ot_wednesday,
            'Thursday': element.thursday,
            'Thursday Ot': element.ot_thursday,
            'Friday': element.friday,
            'Friday Ot': element.ot_friday,
            'Saturday': element.saturday,
            'Saturday Ot': element.ot_saturday,
            'Sunday': element.sunday,
            'Sunday Ot': element.ot_sunday,
            'Total Hrs':element.TotalHours,
            'OT Total Hrs':element.ot_totalhours,
            'Status':(element.status==0)?'submitted':(element.status==1)?'accepted':(element.status==2)?'rejected':'',
            
          });
    
    });
    }
    else {
      this.toastr.errorToastr(res.message, 'Error!')
    }
    })  
  
}

searchButton = false;
LoadData(){
  this.searchButton= true;
  this.ShowData();
}
resetSearchForm(){
  this.today = new Date().toISOString().split('T')[0];
  this.from_Date = null;
  this.searchForm = this._formBuilder.group({
    searchManager: [''],
    searchjobTitle: [''],
    fromdate: [''],
    todate: [''],
    PageSize: 10,
    PageNumber: 1,
    period:'',
    search:''
  });
  this.paginator.pageSize = 10;
  this.ShowData();
  this.paginators.pageSize = 10;
  this.paginators.pageIndex = 0;
}
// getpaginatorData(e) {
//   this.searchForm.controls['PageSize'].setValue(e.pageSize);
//   this.searchForm.controls['PageNumber'].setValue(e.pageIndex + 1);
//   this.ShowData();
// }
setpaggination(e) {
  this.searchForm.controls['PageSize'].setValue(e.pageSize);
  this.searchForm.controls['PageNumber'].setValue(e.pageIndex + 1);
  this.ShowData();
}

from_Date:String;
dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datepipe.transform(this.searchForm.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datepipe.transform(this.searchForm.controls['todate'].value)).toISOString();
  }
}

exportAsXLSX(): void {
  this.excelService.exportAsExcelFile(this.csv_timesheetdetails, 'sample');
}

AcceptTimeSheet(timeSheet){
var data={
  'status':1,
  'TimeSheetID':timeSheet.TimeSheetID
}
this.JSApi.AcceptTimeSheet(data).subscribe((res: any) => {
  if(res.success){
    this.ShowData();
    this.toastr.successToastr('TimeSheet accept successfully');
}
else {
  this.toastr.errorToastr(res.message, 'Error!')
}
}) 
}
RejectTimeSheet(timeSheet){
  var data={
    'status':2,
    'TimeSheetID':timeSheet.TimeSheetID
  }
  this.JSApi.AcceptTimeSheet(data).subscribe((res: any) => {
    if(res.success){
      this.ShowData();
      this.toastr.successToastr('TimeSheet reject successfully');
  }
  else {
    this.toastr.errorToastr(res.message, 'Error!')
  }
  }) 
}

removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
  const startPos = e.currentTarget.selectionStart;
  if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
    e.preventDefault();
  }
}
}
