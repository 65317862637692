import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { staffingAgencyServices } from '../../staffing-agency/staffing-agency.services'
import { ToastrManager } from 'ng6-toastr-notifications'
import { Router } from '@angular/router'
import { AuthenticationService } from '../../app.service';
import * as moment from 'moment';
import { ShareService } from '../share.service';

@Component({
  selector: 'app-update-candidate',
  templateUrl: './update-candidate.component.html',
  styleUrls: ['./update-candidate.component.css']
})
export class UpdateCandidateComponent implements OnInit {

  @Input() jobSeekerId: string = '';
  @Input() userType: string = '';
  @Input() modeType: string = '';
  fileresponse: any;
  imgSrc: any = [];
  docsList: any = [];
  activeTab = 4;
  editUploadProfile: FormGroup;
  htmlCode1 = `<ngx-img (onSelect)="onSelect($event)" (onReset)="onReset()"></ngx-img>`;
  optionsHTML = `<ngx-img (onSelect)="onSelect($event)" (onReset)="onReset()" [config]="options"></ngx-img>`;
  submitMsg: string = "";
  serverpth: string = '';
  constructor(
    private _formBuilder: FormBuilder,
    private appAPI: AuthenticationService,
    private staffAPI: staffingAgencyServices,private shareservice:ShareService, private toastr: ToastrManager, vcr: ViewContainerRef, private _router: Router) {


    this.editUploadProfile = this._formBuilder.group({
      "firstName": ["", Validators.required],
      "lastName": ["", Validators.required],
      "emailId": ["", Validators.required, Validators.email],
      "contactNo": ['', Validators.required],
      "password": ['', Validators.required],
      "ssnNo": ['', Validators.required],
      "resume": ['', Validators.required],
      "dob": ['', Validators.required],
      "country": ['', Validators.required],
      "verified_phoneNo": ['', Validators.required],
      "stateCode": [''],
      "linkdinProfileUrl": [''],
      "city": ['', Validators.required],
      "streetName": [''],
      "experience": ['', Validators.required],
      "skill": [''],
      "voiceSample": [''],
      "profileImage": [''],
      'docIds': [this.docsList],
      'educationFile': [''],
      'createdDate':['']
    })
    this.serverpth = this.appAPI.url;
  }

  ngOnInit() {
  }


  profileLoader: boolean = false;
  resImageData: any;
  imgProfile: String = "";
  onSelect(event) {

    // console.log(event);
    this.profileLoader = true;
    let formData = new FormData();
    formData.append('profileimage', event.target.files[0]);
    this.staffAPI.staffJobseekerProfileImg(formData).subscribe(res => {
      // console.log('IMG============>', res);
      this.resImageData = res;
      if (this.resImageData.success) {
        this.toastr.successToastr(this.resImageData.message, '', { position: 'bottom-right' });
        this.editUploadProfile.controls['profileImage'].setValue(this.resImageData.data.fileName);
        this.profileLoader = false;
        this.imgProfile = `${this.serverpth}/api/staffingagency/image/profileimage/${this.resImageData.data.fileName}`
      } else {
        this.toastr.errorToastr(this.resImageData.message, '', { position: 'bottom-right' });
      }
    });

  }
  sync: boolean = false;
  resumeResponse: any;
  resumeLoader: boolean = false;
  resumeUplaod(event) {
    // console.log(event);
    this.resumeLoader = true;
    let formData = new FormData();
    formData.append('resume', event.target.files[0]);
    this.staffAPI.staffJobseekerProfileImg(formData).subscribe(res => {
     //  console.log('file sdadasd ============>', res);
      this.resumeResponse = res;
      if (this.resumeResponse.success) {
        this.resumeLoader = false;
        // console.log("resume File name =///////////////===>", this.resumeResponse.data.fileName);
        this.editUploadProfile.controls['resume'].setValue(this.resumeResponse.data.fileName);
        this.toastr.successToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
      } else {
        this.toastr.errorToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
      }
      this.sync = true;
    });
  }
  sync1: boolean = false;
  resumeResponse1: any;
  voiceLoader: boolean = false;
  voiceUpload(event) {
    // console.log(event)
    this.voiceLoader = true;
    let formData = new FormData();
    formData.append('voicesample', event.target.files[0]);
    this.staffAPI.staffJobseekerProfileImg(formData).subscribe(res => {
     //  console.log('file sdadasd ============>', res);
      this.resumeResponse1 = res;
      if (this.resumeResponse1.success) {
        this.voiceLoader = false;
        this.toastr.successToastr(this.resumeResponse1.message, '', { position: 'bottom-right' });
        this.editUploadProfile.controls['voiceSample'].setValue(this.resumeResponse1.data.fileName);
        document.getElementById('voiceList').innerHTML = `<b class="badge badge-info">${this.resumeResponse1.data.fileName}</b>`
      } else {
        this.toastr.errorToastr(this.resumeResponse1.message, '', { position: 'bottom-right' });
      }
      this.sync1 = true;
    });
  }
  sync2: boolean = false;
  resumeResponse2: any;
  docsLoader: boolean = false;
  verificationDocs(event) {
    // console.log(event);
    this.docsLoader = true;
    let formData = new FormData();
    formData.append('verification', event.target.files[0]);
    formData.append('verificationIdType', 'voterID');
    this.staffAPI.staffJobseekerProfileImg(formData).subscribe(res => {
     //  console.log('file sdadasd ============>', res);
      this.resumeResponse2 = res;
      if (this.resumeResponse2.success) {
        this.docsLoader = false;
        this.toastr.successToastr(this.resumeResponse2.message, '', { position: 'bottom-right' });
        document.getElementById('docList').innerHTML = `<b class="badge badge-info">${this.resumeResponse2.data.docName}</b>`

        this.docsList.push(this.resumeResponse2.data.docId);

        this.editUploadProfile.controls['docIds'].setValue(this.docsList);
      } else {
        this.toastr.errorToastr(this.resumeResponse2.message, '', { position: 'bottom-right' });
      }
      this.sync2 = true;

    });
  }

  sync3: boolean = false;
  resumeResponse3: any;
  eduLoader: boolean = false;
  qualificationUpload(event) {
    // console.log(event);
    this.eduLoader = true;
    let formData = new FormData();
    formData.append('qualification', event.target.files[0]);
    formData.append('qualificationlevel', 'graduation');
    this.staffAPI.staffJobseekerProfileImg(formData).subscribe(res => {
     //  console.log('file sdadasd ============>', res);
      this.resumeResponse3 = res;
      if (this.resumeResponse3.success) {
        this.eduLoader = false;
        this.toastr.successToastr(this.resumeResponse3.message, '', { position: 'bottom-right' });
        this.docsList.push(this.resumeResponse2.data.docId);
        this.editUploadProfile.controls['docIds'].setValue(this.docsList);
      } else {
        this.toastr.errorToastr(this.resumeResponse3.message, '', { position: 'bottom-right' });
      }
      this.sync3 = true;
    });
  }

  changeTab(i: number) {
    this.imgSrc = [];
    this.activeTab = i;
  }

  reset() {
    this.imgSrc = [];

  }

  get pu() {
    return this.editUploadProfile.controls;
  }
  uploadData: any;
  Epsubmitted: boolean = false;


  onUpdateProfile(data) {
    this.Epsubmitted = true;
    // console.log("adsadsad ------------->", data.value);
    if (data.valid) {
     //  console.log(this.editUploadProfile.value);
     this.editUploadProfile.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
      this.staffAPI.staffJobseekerCreation(this.editUploadProfile.value).subscribe(res => {
       //  console.log("sdsd====>", res);
        this.uploadData = res;
        this.toastr.successToastr("Profile Update successfull !", 'Succcess!', { position: 'bottom-right' });
        setTimeout(() => {
          this._router.navigate(['/st/staff/dashboard']);
        }, 500);

      });



    } else {
      this.submitMsg = "Invalid data please fill required feild."
      this.toastr.errorToastr("Invalid data please fill required feild.", 'Error!', { position: 'bottom-right' });
      document.getElementById('updateMsg').innerHTML = `${document.getElementById('updateMsg').innerHTML}&nbsp;<b class="text-danger">${this.submitMsg}</b>`;

      return false;
    }
    // console.log(this.editUploadProfile);
  }
  onresumeSelect() {

  }

  resetUpdate() {
    this._router.navigate(["/DashboardStaffing"]);
  }
  uploadDoc(val) {
    document.getElementById(val).click();
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }



}
