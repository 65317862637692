import { Component, OnInit, Input } from '@angular/core';
import { ShareService } from '../share.service';

@Component({
  selector: 'app-app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: ['./app-notification.component.css']
})
export class AppNotificationComponent implements OnInit {

  @Input() userType: string = ''
  noticeData: any;
  sync: boolean = false;

  paginator = {
    pageSize: 10,
    length: 0,
    pageSizeOptions: [10, 25, 100]
  }


  constructor(private shareAPI: ShareService) { }

  ngOnInit() {
    this.getNotificationData(10, 1)
  }
  getNotificationData(size, page) {
    this.shareAPI.notification(this.userType, size, page).subscribe((res: any) => {
      if (res.success) {
        this.noticeData = res.data;
        if (this.noticeData.length > 0) {
          this.paginator.length = this.noticeData[0].totalCount;
        } else {
          this.paginator.length
        }
        // console.log(this.noticeData)
        this.sync = true
      } else {

      }
    })

  }

  setpaggination(e) {
    // console.log(e);
    this.getNotificationData(e.pageSize, e.pageIndex + 1);
  }


}
