import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthenticationService {
    // BehaviorSubject use 
    private subject = new BehaviorSubject<any>(false);
    public mysubject = this.subject.asObservable();
    url = '';

    constructor(private http: HttpClient, private router: Router) {
        this.url = `${environment.url}`;
     }

    login(emailId: string, password: string) {

        return this.http.post<any>(`${environment.url}/api/auth/adminlogin`, { emailId, password })
            .pipe(map(admin => {
                // login successful if there's a jwt token in the response  
                if (admin.data && admin.data.token) {
                    // store admin details and jwt token in local storage to keep admin logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(admin.data));
                    localStorage.setItem('isLogin', 'true');
                }
                return admin;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('isLogin');
        return true;
    }

    getLogoutStatus(): Observable<any> {
        return this.subject.asObservable();
    }

    postLogoutStatus(data) {
        this.subject.next(data);
    }

}
