import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private showSidebarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private showHeaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  sideBar$: Observable<boolean> = this.showSidebarSubject.asObservable();
  header$: Observable<boolean> = this.showHeaderSubject.asObservable();

  setShowSidebar(show: boolean): void {
    this.showSidebarSubject.next(show);
  }

  setShowHeader(show: boolean): void {
    this.showHeaderSubject.next(show);
  }
  constructor() { }
}
