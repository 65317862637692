import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ShareService } from '../share.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Title } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { JobseekerDetailsPopupComponent } from 'src/app/admin/popup/jobseeker-details-popup/jobseeker-details-popup.component';
import {AuthenticationService } from '../../app.service';
  
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';

@Component({
  selector: 'app-addpool-seeker',
  templateUrl: './addpool-seeker.component.html',
  styleUrls: ['./addpool-seeker.component.css'],
  providers: [    
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class AddpoolSeekerComponent implements OnInit {
   // Datepicker takes `Moment` objects instead of `Date` objects.
   date = new FormControl(moment([2017, 0, 1]));
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  preloader: boolean;
  groupRequest: any;
  PoolID: any;
  jobseekerIdSelected: any = [];
  filterOption: FormGroup;
  filterList: any;
  areAllSelected = false;
  paginator = {
    pageSize: 10,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [10, 20, 50, 100]
  };
  jobseekerForm: FormGroup;
  listOfAllJobseeker: any;
  listView: boolean;
  jobseekerlist: any;
  Savedjobseekerlist: any;
  showDetails: boolean;
  islstopen: boolean;
  ddlFilterList: any;
  selectedCount = 0;
  enterprise: any;
  enterpriseList: any;
  body: any;
  isEnterprise = false;
  updateseeker: any;
  allEnterprise: any;
  userData:any={};
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];

  constructor(
    public dialog: MatDialog,
    private tableService: ShareService,
    private _formBuilder: FormBuilder,
    private tostr: ToastrManager,
    private paramval: ActivatedRoute,
    private _titleService: Title, private _router: Router,private datePipe: DatePipe,
   private AuthenticationService: AuthenticationService,
   private shareservice:ShareService
    ) {

    let newTitle = 'Verisource | Add Seeker';
    this._titleService.setTitle(newTitle);
    this.PoolID = this.paramval.snapshot.params.id;
    this.enterprise = this.paramval.snapshot.params.enterprise;
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.updateseeker = this.paramval.snapshot.params.updatePoolseeker;
    //console.log('this.paramval.snapshot.params.enterprise', this.paramval.snapshot.params.enterprise);
    this.groupRequest = JSON.parse(localStorage.getItem('CurrentGroupRequest'));
    this.body = this._formBuilder.group({
      status: 'All',
      fromdate: '',
      todate: '',
      search: '',
      PageSize: 100,
      PageNumber: 1
    });

    
    this.tableService.getfiltersMyCandidates().subscribe((res: any) => {
      if (res.success) {
        this.filterList = res.data;
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
    this.islstopen = false;
    this.filterInit();
  }
  ngOnInit() {       
    //console.log('this.enterprise', this.enterprise);
    if (this.enterprise === 'enterprise') {

      this.isEnterprise = true;
      this.fillEnter();
      this.loadAsignSeekerList();
    } else {
      if (this.updateseeker === 'updateseeker') {
        this.isEnterprise = false;
        this.loadDataForUpdate();
      } else {
        this.isEnterprise = false;
        this.loadjobseekerlist();       
      }
    }
  }
   fillEnter() {
     this.tableService.filterforenterprise(this.body.value).pipe(first()).subscribe((res: any) => {
       if (res.success) {
         this.enterpriseList = res.data;
         this.allEnterprise =this.enterpriseList
         //console.log('this.enterpriseList', this.enterpriseList);
       }
     });
   }


   searchClientValue = false;
   //Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
   searchEnterprise(e) {
    let mspId: any;
    if (this.userData.data.credentials.CreatedBy){
      mspId = this.userData.data.credentials.CreatedBy;
    }else{
      mspId = this.userData.data.credentials.userId;
    }
     if (e.target.value != '') {
       this.tableService.searchClient(e.target.value,mspId).subscribe((res: any) => {
        
         if (res.data.length == 0) {
           this.searchClientValue = true;
         }
         if (res.success) {
           this.enterpriseList = res.data;
           if (res.data.length != 0) {
             this.searchClientValue = false;
           }
         } 
       });
     }
     else {
 
       this.enterpriseList = this.allEnterprise;
       this.searchClientValue = false;
     }
 
   }

  loadAsignSeekerList() {
   
    this.AuthenticationService.jwtTokenExpCheck();
    this.preloader = true;
    this.tableService.AssigncandidateList(this.filterOption.value).pipe(first()).subscribe(res => {
      // assign this.jobseekerIdSelected data to variable becouse this create problem
      const tempjobseekerIdSelected = this.jobseekerIdSelected;
      if (res.success) {
        this.preloader = false;
        this.jobseekerlist = res.data;
        //console.log('jobseekerlist',this.jobseekerlist);
        //const tempCandidate = res.data;       
        if (this.jobseekerlist.length > 0) {
          this.paginator.length = this.jobseekerlist[0].totalCount;
        } else {
          this.paginator.length = 0;

        }
        this.jobseekerlist.forEach(function (candidate: { selected: boolean; jobseekerId: string; }) {
          candidate.selected = false;
        });
        this.jobseekerlist.forEach(function (candidate: { selected: boolean; jobseekerId: string; }) {
          // here we check condition and select previous data
          if (tempjobseekerIdSelected.length > 0) {
            tempjobseekerIdSelected.forEach(function (Value) {
              if (candidate.jobseekerId === Value.jobseekerId) {
                candidate.selected = true;
              }
            });
          }
        });
      }
    });
  }


  filterInit() {
    this.filterOption = this._formBuilder.group({
      resumeStatus: ['All'],
      profileStatus: 'All',
      highestDegree: 'Any',
      minExperience: 0,
      usMasterDegree: 'All',
      country: '',
      citizenship: '',
      usaExperience: '',
      minUSAExperience: 0,
      maxUSAExperience: 0,
      skills: '',
      maxExperience: 0,
      favourite_status: ['All'],
      search: '',
      PageSize: 10,
      PageNumber: 1,
      zipCode: '',
      state: '',
      enterpriseId: '',
      PoolID: this.PoolID,
      actiontype: 'Insert',
      fromdate :'',
      todate : '',
      createdDate:'',
      source:['All']
    });
  }

  OpenlfMenu() { this.islstopen = !this.islstopen; }

  
  searchButton = false;
  loadData(){
    this.searchButton= true;
    this.loadDataForUpdate();
  }

  loadjobseeker(){
    this.searchButton= true;
    this.loadjobseekerlist();
  }

  loadDataForUpdate() {
    if(this.updateseeker === 'updateseeker'){
      this.filterOption.controls.actiontype.setValue('Update');
    } 
    this.loadjobseekerlist();
  }
  loadjobseekerlist() {
    
    this.AuthenticationService.jwtTokenExpCheck();
    //this.filterOption.controls.searc;
    // now we get jobseeker list
    this.preloader = true;
    this.filterOption.controls["fromdate"].setValue(this.datePipe.transform(this.filterOption.controls["fromdate"].value, "yyyy-MM-dd")) 
    this.filterOption.controls["todate"].setValue(this.datePipe.transform(this.filterOption.controls["todate"].value, "yyyy-MM-dd 23:59:59")) 
    if(this.searchButton == true){
      this.filterOption.controls['PageNumber'].setValue(1);
    }
    this.filterOption.controls["fromdate"].setValue(this.shareservice.convertDateIntoUtc( this.filterOption.controls["fromdate"].value))
    this.filterOption.controls["todate"].setValue(this.shareservice.convertDateIntoUtc( this.filterOption.controls["todate"].value))

    this.tableService.mycandidateList(this.filterOption.value).pipe(first()).subscribe(res => {
      this.preloader = false;
      // assign this.jobseekerIdSelected data to variable becouse this create problem
      const tempjobseekerIdSelected = this.jobseekerIdSelected;
      if (res.success) {
        this.jobseekerlist = res.data;
        this.searchClientValue = false;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }

        //console.log('test', this.jobseekerlist)
        const tempCandidate = res.data;
        // 20201205,Akhilesh: Jira no 208(4)
        if (this.jobseekerlist.length ) {
          this.paginator.length = this.jobseekerlist[0].totalCount;
         } 
         //else {
        //   this.paginator.length = 0;

        // }
        this.jobseekerlist.forEach(function (candidate: { selected: boolean; jobseekerId: string; }) {
          candidate.selected = false;
        });
        this.jobseekerlist.forEach(function (candidate: { selected: boolean; jobseekerId: string; }) {
          // here we check condition and select previous data
          if (tempjobseekerIdSelected.length > 0) {
            tempjobseekerIdSelected.forEach(function (Value) {
              if (candidate.jobseekerId === Value.jobseekerId) {
                candidate.selected = true;
              }
            });
          }
        });
      }
    });
  }

  clearjobseekerList(){
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.filterOption = this._formBuilder.group({
      resumeStatus: ['All'],
      profileStatus: 'All',
      highestDegree: 'Any',
      minExperience: 0,
      usMasterDegree: 'All',
      country: '',
      citizenship: '',
      usaExperience: '',
      minUSAExperience: 0,
      maxUSAExperience: 0,
      skills: '',
      maxExperience: 0,
      favourite_status: ['All'],
      search: '',
      PageSize: 10,
      PageNumber: 1,
      zipCode: '',
      state: '',
      enterpriseId: '',
      PoolID: this.PoolID,
      actiontype: 'Insert',
      fromdate :'',
      todate : '',
      createdDate:'',
      source:['All']
    });
    //this.preloader = false;
    this.paginator.pageSize=10;
    this.loadDataForUpdate();
    this.paginators.pageIndex = 0;
  }

  getPaginatorData(e: { pageSize: number; pageIndex: number; }) {
    this.searchButton = false;
    this.paginator.pageIndex = e.pageIndex;
    this.paginator.pageSize = e.pageSize;
    this.filterOption.controls.PageSize.setValue(e.pageSize);
    this.filterOption.controls.PageNumber.setValue(e.pageIndex + 1);
    if (this.enterprise === 'enterprise') {
       this.loadAsignSeekerList();
    } else {
      if (this.updateseeker === 'updateseeker') {
        this.loadDataForUpdate();
      } else {
        this.loadjobseekerlist();
      }
    }

  }

  selectJob(data: { jobseekerId: any; }, event: { checked: any; }) {

    if (event.checked) {
      this.jobseekerIdSelected.push({
        jobseekerId: data.jobseekerId,
      });
    } else {
      for (let i = 0; i < this.jobseekerIdSelected.length; i++) {
        //console.log(this.jobseekerIdSelected[i].jobseekerId);
        if (this.jobseekerIdSelected[i].jobseekerId === data.jobseekerId) {
          this.jobseekerIdSelected.splice(i, 1);
        }
      }
    }
  }

  SaveJobSeeker(record: any, poolid: any) {
  
    this.AuthenticationService.jwtTokenExpCheck();
    //console.log('record', record);
    let selectedCount1 = 0;
    const recordlenth = record.length;
    //console.log('recordlenth', recordlenth);
    record.forEach((count) => {
      if (count.selected === false) {
        selectedCount1 = selectedCount1 + 1;
      }

    });
    if (recordlenth !== selectedCount1) {
      //console.log(this.Savedjobseekerlist);
      record.forEach((element) => {
        if (element.selected) {
          element.pool_Id = poolid;
          element.createdDate = this.shareservice.convertDateIntoUtc(new Date());
          if (this.updateseeker === 'updateseeker') {
            element.isAdded = false;
          } else {
            element.isAdded = true;
          }
         
          this.tableService.saveJobseekerPooldetails(element).pipe(first()).subscribe(res => {
            if (res.success) {
            if(element.isAdded == true){
              this.tostr.successToastr('Job seeker is added to group successfully.', 'Success!');
            }
            else if(element.isAdded == false){
              this.tostr.successToastr('Job seeker is removed from group successfully.', 'Success!');
            }
              if(this.userData.data.credentials.userType === 'AD'){
                this._router.navigate(['/ad/admin/group']);        
              }
              
              if(this.userData.data.credentials.userType === 'EP'){
                this._router.navigate(['/ad/enterprise/Group']);       
              }
            } else {
              this.tostr.errorToastr(res.message, 'OPPS!');
            }
          });
        }

      });


    
     
      
    } else {
      this.tostr.errorToastr('Please Select At least One Candidate');
      return false;

    }
  }


  toggleAllSelection() {
    this.areAllSelected = !this.areAllSelected;
    const isAllSelected = this.areAllSelected;
    this.jobseekerlist.forEach((item: { selected: boolean; }) => {
      item.selected = isAllSelected;
    });

  }

  checkedUnChecked(candidate: { jobseekerId: any; }, event: { checked: any; }) {
    let notChecked = 0;
    this.jobseekerlist.forEach((item: { jobseekerId: any; selected: any; }) => {
      if (item.jobseekerId === candidate.jobseekerId) {
        item.selected = event.checked;
      }
      if (!item.selected) {
        notChecked++;
      }
    });
    this.areAllSelected = (notChecked === 0);
  }




  countryData(ddlType, values) {
    this.ddlFilterList = [];
    switch (ddlType) {
      case 'country': {
        if (this.filterOption.controls['country'].value.length >= 2) {
          this.tableService.country().subscribe((res: any) => {
            if (res.success) {
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }
          });
        }
        break;
      }
      case 'state': {
        if (this.filterOption.controls['state'].value.length >= 2) {
          this.tableService.state_staff(this.filterOption.controls['state'].value).subscribe((res: any) => {
            if (res.success) {
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter State'];
            }
          });
        }
        break;
      }
      case 'jobLocation': {
        if (this.filterOption.controls['jobLocation'].value.length >= 2) {
          this.tableService.state_staff(this.filterOption.controls['jobLocation'].value).subscribe((res: any) => {
            if (res.success) {
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter Job Location'];
            }
          });
        }
        break;
      }
    }
  }
  clearData() {
    this.ddlFilterList = [];
  }

  resetList() {
    this.filterInit();
    if (this.enterprise === 'enterprise') {
      this.isEnterprise = true;
      //  this.fillEnter();
      // this.loadAsignSeekerList();
    } else {
      if (this.updateseeker === 'updateseeker') {
        this.isEnterprise = false;
        this.loadDataForUpdate();
      } else {
        this.isEnterprise = false;
        this.loadjobseekerlist();
      }

    }
  }
  setFilter() {

    var minEx = this.filterOption.controls['minUSAExperience'].value;
    var maxEx = this.filterOption.controls['maxUSAExperience'].value;
    //console.log('minEx', minEx);
    if (minEx === '') {
      this.filterOption.controls.minUSAExperience.setValue(0);
      
    }
    if (maxEx === '') {
      this.filterOption.controls.maxUSAExperience.setValue(0);

    }
    //console.log('minEx', minEx);
    if (this.enterprise === 'enterprise') {
      this.isEnterprise = true;
      // this.fillEnter();
      // this.loadAsignSeekerList();
    } else {
      if (this.updateseeker === 'updateseeker') {
        this.isEnterprise = false;
        this.loadDataForUpdate();
      } else {
        this.isEnterprise = false;
        this.loadjobseekerlist();
      }
    }
    // this.loadjobseekerlist();
  }


  jsonArrayCnvrtVl(val) {
    return Object.keys(val);
  }
  jsonArrayCnvrt(val) {
    return Object.values(val);
  }

  isLogin='true';
  jobseekerpopup(jobseeker){
    
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(JobseekerDetailsPopupComponent, {
      width: 'auto',
      disableClose: true,
      data: jobseeker
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  }
  backClick() {
    if (this.enterprise === 'enterprise') {
      this._router.navigate(['/ad/admin/assignSeeker']);
    } else {
      this._router.navigate(['/ep/enterprise/Group']);
    }
  }

  
  loadAssignjobseekerlist() {
    //console.log('enterpriseId', this.filterOption.value);
    this.loadAsignSeekerList();

  }

  from_Date:String;
dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datePipe.transform(this.filterOption.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datePipe.transform(this.filterOption.controls['todate'].value)).toISOString();
  }
}

  InsertAssignJobSeeker(record: any) {
    let selectedCount1 = 0;
    const recordlenth = record.length;
    if (this.filterOption.controls.enterpriseId.value !== '') {
      record.forEach((count) => {
        if (count.selected === false) {
          selectedCount1 = selectedCount1 + 1;
        }

      });
      if (recordlenth !== selectedCount1) {
        this.filterOption.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        record.forEach((element) => {
          if (element.selected) {
            element.enterpriseId = this.filterOption.controls.enterpriseId.value; //enterpriseId;  
            element.isActive = true;
            element.createdDate = this.filterOption.controls['createdDate'].value;
            this.tableService.insertUpdateAssignJobseeker(element).pipe(first()).subscribe(res => {
              if (res.success) {
                // this.tostr.successToastr(res.message, 'Success!');
              } else {
                this.tostr.errorToastr(res.message, 'OPPS!');
              }
            });
          }
        });
        this.tostr.successToastr('Data Save Successfully', 'Success!');
        this._router.navigate(['/ad/admin/assignSeeker']);
      } else {
        this.tostr.errorToastr('Please Select At least One Candidate');
        return false;

      }
    } else {
      this.tostr.errorToastr('Please Select Client');
      return false;
    }


  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

}
