import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LayoutService } from 'src/app/share-module/layout.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  constructor(private _titleService : Title,private layoutService:LayoutService) {
    var newTitle = 'Verisource | About Us';
    this._titleService.setTitle( newTitle );
   }

  ngOnInit() {
    this.layoutService.setShowSidebar(false);
    // console.log('fdhgfghjgjgj');
  }
}
