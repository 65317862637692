import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { EnterpriseService } from '../enterprise.service';
import { AuthenticationService } from '../../app.service';
//import {AuthenticationService } from '../../app.service';



export interface DialogData {
  jobId: string;
  date: Date;
  // time: Time
}

@Component({
  selector: 'app-job-detail-popup',
  templateUrl: './job-detail-popup.component.html',
  styleUrls: ['./job-detail-popup.component.css']
})


export class JobDetailPopupComponent implements OnInit {
  baseUrl: String = '';
  isDataready: boolean = false;
  jobData: any;
  readmore:boolean = false;
  constructor(
    private mainApi: AuthenticationService,
    private dialogRef: MatDialogRef<JobDetailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private AuthenticationService: AuthenticationService,
    private epAPI: EnterpriseService,
  ) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.baseUrl = `${this.mainApi.url}/api/enterprise/image/resume/`;
  }

  ngOnInit() {
    this.initData();
  }
  initData() {
    try {
      this.isDataready = true;
      this.epAPI.LoadSingleJobDetail(this.data.jobId).subscribe((res: any) => {
        this.isDataready = false;
        if (res.success) {
          this.jobData = res;
        } else {
          this.jobData = [];
        }
      });
    } catch (error) {
    }

  }
  onNoClick(): void {
    this.dialogRef.close();
  }



}
