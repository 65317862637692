import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
import { ShareService } from '../share.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { TableService } from 'src/app/admin/service';
import { LayoutService } from '../layout.service';


@Component({
  selector: 'app-invitaion-link-result',
  templateUrl: './invitaion-link-result.component.html',
  styleUrls: ['./invitaion-link-result.component.css']
})
export class InvitaionLinkResultComponent implements OnInit {
 assignData: FormGroup;
  resData:any;
  jobseekerId:any;
  jobId:any;
  enterpriseId:any;
  resIdData:any;
  userData: any;
  jobLoader: boolean = false;
  skils: any;
  isSAYes: boolean = true;
  isSANo: boolean;
  isSA: boolean;
  staffReg: any;
  staffingId: Number;
  stateLoader: boolean;
  stateList: any;
  cityLoader: boolean;
  cityList: any;
  countryList: any;
  StaffingAgencyName: any;
  StaffingEmailID: any;
  StaffingContact: any;
  StaffingWebSite: any;
  StaffingEmailID1: any;
  [x: string]: any;
  id = '';
  Invitationtbl: any;
  invitationForm: FormGroup;
  savedinvitationForm: FormGroup;
  invitationRejectForm: FormGroup;
  agencyForm : FormGroup;
  ComapanyName: string;
  title: string;
  city: string;
  postedByVal: string;
  state: string;
  postalCode: string;
  JobseekrName: string;
  email: string;
  phone: string;
  updateInvitationResult: any;
  mergetableData: any;
  Interested: boolean;
  Notclick: boolean = false;
  NotInterstedclick: boolean = false;
  jobseekerStatus: boolean;
  submitted: boolean;
  disableYesButton:boolean=false;
  signupFormStaffing: FormGroup;
  TypeList = [
    { name: 'Supplier', value: 'SA' },
    { name: 'Self', value: 'Self' }
  ];
  allStaffingAgency:any = {};
  cloneStaffingAgnecy:any={};
  SaveinvitationForm: FormGroup;
  baseUrl = '';
  typeUser:number;
  createdId:any;
  detailValue = false;
  suppNameExist = true;
  suppName: string;
  websiteExist = true;
  website: string;

  constructor(private _activated: ActivatedRoute,private layoutService:LayoutService,private enterpriseApis: EnterpriseService, private stafingagencyService: staffingAgencyServices,
    private tostr: ToastrManager, private _formBuilder: FormBuilder, private router: Router,private shareservice:ShareService,private AdminApi: TableService) {
 
  
      this.userData = JSON.parse(window.localStorage.getItem('udata'));
      this.id = this._activated.snapshot.params['id'];
    this.typeUser = this._activated.snapshot.params['type'];
    this.createdId=this._activated.snapshot.params['createdby'];
    this.baseUrl = "/";
    this.savedinvitationForm = this._formBuilder.group({
      rate: 0
    });
   
    this.invitationForm=this._formBuilder.group({
      AgencyType: ['', Validators.required],
      Agency: [''],
      rate: [0],
      //new
      AgencyName: ['',[Validators.required]],
      website: ['', [Validators.required, Validators.pattern(
        "(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      firstNameStaffing: ['', Validators.required],
      middleNameStaffing: [''],
      lastNameStaffing: ['', Validators.required],
      staffingemailId: ['', [Validators.required, Validators.email, Validators.pattern(
        '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,3}(\\.[a-zA-Z]{2,2})?(\\.[a-zA-Z]{2,2})?$')]],
      passwordStaffing: ['Admin@123'],
      contactNo: ['', Validators.required],
      createdDate:[''],
      createdby:[''],
      vdStaffingId:[''],
      enterpriseId:['']
    });

    // this.stafingagencyService.getStaffingAgency_onType(this.id).subscribe(res => {
    //   this.allStaffingAgency = res['data'];
    //   this.cloneStaffingAgnecy = this.allStaffingAgency;
    // });

    this.verifyinvitation(this.id);
    this.stafingagencyService.country().subscribe((res: any) => {
      if (res.success) {
        this.countryList = res.data;
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }

    });
  }

  ngOnInit() {
    this.layoutService.setShowSidebar(false);
  }

  //get pl() { return this.invitationForm.controls; }
  // for reject invitation
  rejectinvitation(code: any) {
    this.stafingagencyService.invitationRejected(code).subscribe(res => {
      this.Invitationtbl = res;
      //this.stafingagencyService
    });
  }

  selectType(){  
  
    if(this.invitationForm.controls['AgencyType'].value == 'SA'){
      this.getSupplier();
    this.showSA = true;
    this.submitted = false;
    this.invitationForm.controls['AgencyName'].reset();
    this.invitationForm.controls['Agency'].reset();
    this.invitationForm.controls['website'].reset();
    this.invitationForm.controls['firstNameStaffing'].reset();
    this.invitationForm.controls['lastNameStaffing'].reset();
    this.invitationForm.controls['staffingemailId'].reset();
    this.invitationForm.controls['contactNo'].reset();
    this.invitationForm.controls['AgencyType'].setValue('SA');
    this.invitationForm.controls['rate'].setValue(0);
    }

  if(this.invitationForm.controls['AgencyType'].value == 'Self'){
    this.showSA = false;
    this.submitted = false;
    this.detailValue = false;
    this.invitationForm.controls['AgencyName'].reset();
    this.invitationForm.controls['website'].reset();
    this.invitationForm.controls['firstNameStaffing'].reset();
    this.invitationForm.controls['lastNameStaffing'].reset();
    this.invitationForm.controls['staffingemailId'].reset();
    this.invitationForm.controls['contactNo'].reset();
    this.invitationForm.controls['AgencyType'].setValue('Self');
    this.invitationForm.controls['rate'].setValue(0);
    this.invitationForm.controls['Agency'].clearValidators();
  }

}

  // for verify invitation by staffing agency
  verifyinvitation(code: any) {
    this.stafingagencyService.invitationVerifiy(code).subscribe(res => {
      this.Invitationtbl = res;
      //console.log('this.Invitationtbl', this.Invitationtbl);
      if (this.Invitationtbl.success && this.Invitationtbl.data.length > 0) {
        this.jobId=this.Invitationtbl.data[0].jobId;
        this.stafingagencyService.getClientId(this.jobId).subscribe(res => {
         
          this.resIdData=res
          if(this.resIdData.length > 0){
            this.enterpriseId=this.resIdData.data[0].enterPriseId;
          }
        })
        this.jobseekerId=this.Invitationtbl.data[0].jobseekerId;
        this.postedByVal = this.Invitationtbl.data[0].postedBy;
        this.Invitationtbl.data.forEach(element => {
          if (element.jobseekerStatus === null) {
            this.Notclick = true;
            if (element.VerifiyCode.trim() === code) {
              this.stafingagencyService.JobAndSeekerDetails(code,this.typeUser).subscribe(res => {
                this.mergetableData = res;
                // this.cloneEmail =this.mergetableData.data[2].StaffingEmailID;
                if (this.mergetableData.success) {

                  if (this.mergetableData.data.length > 2) {
                    this.ComapanyName = this.mergetableData.data[0].ComapanyName;
                    this.title = this.mergetableData.data[0].title;
                    this.city = this.mergetableData.data[0].city;
                    this.state = this.mergetableData.data[0].state;
                    this.postalCode = this.mergetableData.data[0].postalCode;
                    this.JobseekrName = this.mergetableData.data[1].JobseekrName;
                    this.email = this.mergetableData.data[1].email;
                    this.phone = this.mergetableData.data[1].phone;
                    this.skils = this.mergetableData.data[1].skils;
                    this.StaffingAgencyName = this.mergetableData.data[2].StaffingAgencyName,
                      this.StaffingEmailID = this.mergetableData.data[2].StaffingEmailID,
                      this.StaffingContact = this.mergetableData.data[2].StaffingContact,
                      this.StaffingWebSite = this.mergetableData.data[2].StaffingWebSite,
                      this.invitationForm = this._formBuilder.group({
                        jobseekerStatus: true,
                        verifycode: code,
                        ComapanyName: this.ComapanyName,
                        title: this.title,
                        city: this.city,
                        state: this.state,
                        postalCode: this.postalCode,
                        JobseekrName: this.JobseekrName,
                        email: this.email,// this is candidate email
                        phone: this.phone,// candidate phone
                        verifyUrl: element.VerifiyCode.trim(),
                        rejectUrl: element.RejectedCode.trim(),
                        AgencyType: ['', Validators.required],
                        rate: 0,
                        //new
                        AgencyName: ['', Validators.required],
                        Agency:'',
                        website: ['', [Validators.required, Validators.pattern(
                          "(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
                        firstNameStaffing: ['', Validators.required],
                        middleNameStaffing: [''],
                        lastNameStaffing: ['', Validators.required],
                        staffingemailId: ['', [Validators.required, Validators.email, Validators.pattern(
                          '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,3}(\\.[a-zA-Z]{2,2})?(\\.[a-zA-Z]{2,2})?$')]],
                        passwordStaffing: ['Admin@123'],
                        contactNo: ['', Validators.required],
                        JobRecomenddStaffing: [true],
                        remembermeStaffing: [false],
                        //termsStaffing: [false, Validators.required],
                        isRegistered: [false],
                        createdDate:[''],
                        createdby:[''],
                        vdStaffingId:[''],
                        enterpriseId:[]
                      });
                    this.savedinvitationForm = this._formBuilder.group({
                      AgencyName: this.mergetableData.data[2].StaffingAgencyName,
                      staffingemailId: this.mergetableData.data[2].StaffingEmailID,
                      contactNo: this.mergetableData.data[2].StaffingContact,
                      website: this.mergetableData.data[2].StaffingWebSite,
                      jobseekerStatus: true,
                      verifycode: code,
                      ComapanyName: this.ComapanyName,
                      title: this.title,
                      city: this.city,
                      state: this.state,
                      postalCode: this.postalCode,
                      JobseekrName: this.JobseekrName,
                      email: this.email,
                      phone: this.phone,
                      verifyUrl: element.VerifiyCode.trim(),
                      rejectUrl: element.RejectedCode.trim(),
                      AgencyType: '',
                      rate: 0,
                      //new
                      firstNameStaffing: [''],
                      middleNameStaffing: [''],
                      lastNameStaffing: [''],
                      passwordStaffing: [''],
                      JobRecomenddStaffing: [true],
                      remembermeStaffing: [false],
                      //  termsStaffing: [false],
                      isRegistered: [false]
                    });
                  }
                  else {

                    this.ComapanyName = this.mergetableData.data[0].ComapanyName;
                    this.title = this.mergetableData.data[0].title;
                    this.city = this.mergetableData.data[0].city;
                    this.state = this.mergetableData.data[0].state;
                    this.postalCode = this.mergetableData.data[0].postalCode;
                    this.JobseekrName = this.mergetableData.data[0].JobseekrName;
                    this.email = this.mergetableData.data[0].email;
                    this.phone = this.mergetableData.data[0].phone;
                    this.skils = this.mergetableData.data[0].skils;
                    this.Notclick = true;
                    this.isSAYes = false;
                    this.isSANo = true;
                    this.disableYesButton= true;
                    this.invitationForm = this._formBuilder.group({
                      jobseekerStatus: true,
                      verifycode: code,
                      ComapanyName: this.ComapanyName,
                      title: this.title,
                      city: this.city,
                      state: this.state,
                      postalCode: this.postalCode,
                      JobseekrName: this.JobseekrName,
                      email: this.email,// this is candidate email
                      phone: this.phone,// candidate phone
                      verifyUrl: element.VerifiyCode.trim(),
                      rejectUrl: element.RejectedCode.trim(),
                      AgencyType: ['', Validators.required],
                      Agency:'',
                      rate: 0,
                      //new
                      AgencyName: ['', Validators.required],
                      website: ['', [Validators.required, Validators.pattern(
                        "(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
                      firstNameStaffing: ['', Validators.required],
                      middleNameStaffing: [''],
                      lastNameStaffing: ['', Validators.required],
                      staffingemailId: ['', [Validators.required, Validators.email, Validators.pattern(
                        '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,3}(\\.[a-zA-Z]{2,2})?(\\.[a-zA-Z]{2,2})?$')]],
                      passwordStaffing: ['Admin@123'],
                      contactNo: ['', Validators.required],
                      JobRecomenddStaffing: [true],
                      remembermeStaffing: [false],
                      //  termsStaffing: [false, Validators.required],
                      isRegistered: [false],
                      createdDate:[''],
                      createdby:[''],
                        vdStaffingId:[''],
                        enterpriseId:[]
                    });

                    this.savedinvitationForm = this._formBuilder.group({
                      AgencyName: '',
                      staffingemailId: '',
                      contactNo: '',
                      website: '',
                      jobseekerStatus: true,
                      verifycode: code,
                      verifyUrl: element.VerifiyCode.trim(),
                      rejectUrl: element.RejectedCode.trim(),
                      rate: 0,
                      //new
                      firstNameStaffing: [''],
                      middleNameStaffing: [''],
                      lastNameStaffing: [''],
                      JobRecomenddStaffing: [true],
                      remembermeStaffing: [false],
                      //  termsStaffing: [false],
                      isRegistered: [false]
                    });
                    this.jobseekerStatus = true;
                    this.Interested = true;
                  }
                }
              });
              this.jobseekerStatus = true;
              this.Interested = true;
            } else {
              if (element.RejectedCode.trim() === code) {
                this.jobseekerStatus = false;
                this.Interested = false;
                this.invitationForm = this._formBuilder.group({
                  RejectedCode: element.RejectedCode.trim(),
                  IsVerified: false,
                  jobId: element.jobId,
                  jobseekerId: element.jobseekerId,
                  postedBy: element.postedBy,
                  createdDate:[''],
                  createdby:[''],
                        vdStaffingId:[''],
                        enterpriseId:[]
                });
                this.stafingagencyService.updateinvitation_request(code).subscribe(res => {
                  this.updateInvitationResult = res;
                  if (this.Invitationtbl.success) {
                  }
                });
              }
            }
          } else {
            this.NotInterstedclick = true;
          }
          // }
          // else {
          //   this.Notclick = true;
          //   this.isSAYes = false;
          //   this.isSANo = true;
          //   this.invitationForm = this._formBuilder.group({
          //     jobseekerStatus: true,
          //     verifycode: code,
          //     ComapanyName: this.ComapanyName,
          //     title: this.title,
          //     city: this.city,
          //     state: this.state,
          //     postalCode: this.postalCode,
          //     JobseekrName: this.JobseekrName,
          //     email: this.email,// this is candidate email
          //     phone: this.phone,// candidate phone
          //     verifyUrl: element.VerifiyCode.trim(),
          //     rejectUrl: element.RejectedCode.trim(),
          //     AgencyType: '',
          //     rate: '',
          //     //new
          //     AgencyName: ['', Validators.required],
          //     website: ['', [Validators.required, Validators.pattern(
          //       "(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
          //     firstNameStaffing: ['', Validators.required],
          //     middleNameStaffing: [''],
          //     lastNameStaffing: ['', Validators.required],
          //     staffingemailId: ['', [Validators.required, Validators.email, Validators.pattern(
          //       '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,3}(\\.[a-zA-Z]{2,2})?(\\.[a-zA-Z]{2,2})?$')]],
          //     passwordStaffing: ['Admin@123'],
          //     contactNo: ['', Validators.required],
          //     JobRecomenddStaffing: [true],
          //     remembermeStaffing: [false],
          //     termsStaffing: [false, Validators.required],
          //     isRegistered: [false]
          //   });
          //   this.jobseekerStatus = true;
          //   this.Interested = true;

          // }

        });
      }
    });
  }

  getSupplier(){
    this.jobLoader = true;
    this.stafingagencyService.getStaffingAgency_onType(this.id,this.postedByVal).subscribe(res => {
      this.jobLoader = false;
      this.allStaffingAgency = res['data'];
      this.cloneStaffingAgnecy = this.allStaffingAgency;
      this.jobLoader = false;
    });
  }
  get f() {
    return this.invitationForm.controls;
  }
  get a(){
    return this.agencyForm.controls;
  }
  get sf() {
    return this.savedinvitationForm.controls;
  }

  //for add staffing details by candidate
  AddStaffingdetails() {
this.agencyForm;
    this.submitted = true;
    if (this.savedinvitationForm.valid) {
    this.stafingagencyService.UpdateSeekerAndStaffingDetails(this.savedinvitationForm.value,this.typeUser).pipe(first()).subscribe(res => {
      this.updateInvitationResult = res;
      if (this.updateInvitationResult.success) {
    

// this.assignData.push({
//   'jobid': this.jobId,
//   'staffingagencyId': this.updateInvitationResult.data[0].staffingagencyId,
//   'status': true,
//   //'staffingAgencyGroup_Id': jobseekerId,
//   'assignStatus': 'Assign'
// });

this.assignData = this._formBuilder.group({
  jobid: this.jobId,
  empId:this.updateInvitationResult.data[0].staffingagencyId,//staffingagencyid
  status: true,
  assignStatus: 'Assign'
});
        this.enterpriseApis.InsertAsignedJobToSAByGroupFunctionality(this.assignData.value,this.shareservice.convertDateIntoUtc(new Date())).pipe(first()).subscribe(res => {
          this.Interested = false;
          // location.href = environment.verisourceUrl; //"https://verisource.veriklick.com";
          this.submitted = false;
        });
     
      }
    });
     }
  }

  saved =false;
  // for set the email on the basis of staffing agency select
  AddStaffingdetail(){

    this.saved = true;
    this.submitted = true;
    this.savedinvitationForm.controls['rate'].setValue(this.invitationForm.controls['rate'].value);
    this.savedinvitationForm.controls['AgencyName'].setValue(this.invitationForm.controls['AgencyName'].value);
    this.savedinvitationForm.controls['website'].setValue(this.invitationForm.controls['website'].value);
    this.savedinvitationForm.controls['staffingemailId'].setValue(this.invitationForm.controls['staffingemailId'].value);
    this.savedinvitationForm.controls['contactNo'].setValue(this.invitationForm.controls['contactNo'].value);
    if(this.invitationForm.valid){
      this.AddStaffingdetails();
    }
  }

  // for remove spaces
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  // for change contact format
  OnChangePhoneNo(e) {
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== '' && e.keyCode != 8) {
      this.invitationForm.controls['contactNo'].setValue( npa + '-' + nxx + '-' + last4);
    }
  }

  // for open popup on the basis of type
  Popupjobsk(val) {
    if (val === 'Yes') {
      this.isSAYes = true;
      this.isSANo = false;
      this.invitationForm.controls['rate'].setValue(0);
      this.showSA= false;
      this.detailValue = false;
    } 
    if(val == 'No'){
      this.submitted = false;
      this.showSA= false;
      this.invitationForm.controls['AgencyName'].reset();
      this.invitationForm.controls['website'].reset();
      this.invitationForm.controls['firstNameStaffing'].reset();
      this.invitationForm.controls['lastNameStaffing'].reset();
      this.invitationForm.controls['staffingemailId'].reset();
      this.invitationForm.controls['contactNo'].reset();
      this.invitationForm.controls['AgencyType'].reset();
      this.invitationForm.controls['rate'].setValue(0);
      this.isSAYes = false;
      this.isSANo = true;
      this.detailValue = false;
    }
  }

  searchValue = false;
  agencyname='';

  // for search staffing agency in dropdown list
  searchStaffingAgency(e) {
    this
    if(this.agencyForm.controls['search'].value == ''){
      this.agencyForm.controls['Agency'].setValue('');
      this.allStaffingAgency = this.cloneStaffingAgnecy;
    }
    else{
      this.allStaffingAgency =  this.allStaffingAgency.filter(x => x.companyName == this.agencyForm.controls['search'].value);
      //todo
      // if(this.allStaffingAgency != []){
      //   this.agencyForm.controls['Agency'].markAsPristine();
      // }
      if(this.allStaffingAgency.length >0){
        this.agencyForm.controls['Agency'].markAsPristine();
      }
    } 
  }

  chekValue = false;
  showSA = false;
  // for get details of staffing agency on the basis of dropdown select
  getStaffingDetails(){

    let jobdata = this.allStaffingAgency.filter(x=>x.staffingagencyId == this.invitationForm.controls['Agency'].value)
    this.chekValue = true;
    this.detailValue = true;
    this.invitationForm.controls['firstNameStaffing'].setValue(jobdata[0].firstName);
    this.invitationForm.controls['lastNameStaffing'].setValue(jobdata[0].lastName);
    this.invitationForm.controls['AgencyName'].setValue(jobdata[0].companyName);
    this.invitationForm.controls['website'].setValue(jobdata[0].website);
    this.invitationForm.controls['staffingemailId'].setValue(jobdata[0].emailId);
    this.invitationForm.controls['contactNo'].setValue(jobdata[0].contactNo); 
  }

  // for checked policy
  checkedpolicy() {
    this.invitationForm.controls['termsStaffing'].setValue(this.invitationForm.controls['termsStaffing'].value ? false : true)
  }

  // for staffing sign up by candidate 
  staffingSignup() {
    
    this.invitationForm.controls['Agency'].setErrors(null);
    this.submitted = true;
    
    if(this.jobseekerId !=null || this.jobseekerId!=''){
      this.stafingagencyService.GetEnterpriseId(this.jobseekerId).subscribe(res => {

      this.resData=res;
      //Manu Datta: 20210305, VerisourceBugSheetAfterMerge/commonLogin 413 Same Supplier should be registered through different jobseekers, clients and admin
      // var ID=this.resData.data[0].enterpriseId;
      // let entId=ID.substring(6)
      if(!this.createdId){
        this.createdId = this.resData.data[0].createdBy;
      }
      this.invitationForm.controls['enterpriseId'].setValue(this.resData.data[0].enterpriseId);
       })
       
    }

    if (this.invitationForm.valid) {   
      this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
      this.invitationForm.controls['createdDate'].setErrors(null);

      if(this.typeUser !=null)
      {
       this.invitationForm.controls['createdby'].setValue(this.createdId);
      }
        this.stafingagencyService.UpdateSeekerAndStaffingDetails(this.invitationForm.value,this.typeUser).subscribe(res => {
          this.staffReg = res;
          this.staffingId=this.staffReg.data[0].staffingagencyId;
          if (this.staffReg.success) {
            this.assignData = this._formBuilder.group({
              jobid: this.jobId,
              empId:this.staffReg.data[0].staffingagencyId,//staffingagencyid
              status: true,
              assignStatus: 'Assign'
            });
            this.enterpriseApis.InsertAsignedJobToSAByGroupFunctionality(this.assignData.value,this.shareservice.convertDateIntoUtc(new Date())).pipe(first()).subscribe((resp:any) => {
            });

            if(this.invitationForm.controls['AgencyType'].value == 'Self'){
            this.enterpriseApis.addInvitationstaffingagencyVD(this.staffReg.data[0].staffingagencyId,this.invitationForm.value).subscribe((response:any) => {
              this.staffReg = response;
              //console.log('this.staffReg', this.staffReg);
              if (this.staffReg.success) {
                this.enterpriseApis.UpdateIdFromVeridialForEnterprise(Number(this.staffingId),Number(this.staffReg.data),'SA').subscribe(async (output: any) => {
                  //console.log('=========== final ',response);
                });
                this.invitationForm.controls['vdStaffingId'].setValue(this.staffReg.data);
                this.tostr.successToastr(this.staffReg.message, 'Success!');
                this.Interested = false;
                this.submitted = false;
              }
            });
          }
          } else {
            this.tostr.errorToastr(this.staffReg.message, 'Error!');
          }
        });
     // }
      // else {
      //       this.tostr.errorToastr(this.staffReg.message, 'Error!');
      //     }
      // })


     }
  }


// for change country
  countryChange() {
    let CountryId: any = 0;
    this.countryList.forEach(element => {
      if (this.invitationForm.controls['countryStaffing'].value === element.name) {
        CountryId = element.Id;
      }
    });
    this.stateLoader = true;
    this.stafingagencyService.state(CountryId).subscribe((res: any) => {
      this.stateLoader = false;
      if (res.success) {
        this.stateList = res.data;
        //console.log(this.stateList);
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }

  //for get states
  stateChange() {
    this.cityLoader = true;
    let stateId: any = 0;
    this.stateList.forEach(element => {
      if (this.invitationForm.controls['stateStaffing'].value === element.name) {
        stateId = element.Id;
      }
    });
    this.stafingagencyService.city(stateId).subscribe((res: any) => {
      this.cityLoader = false;
      if (res.success) {
        this.cityList = res.data;
        //console.log(this.cityList);
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }

  // for ignore special characters
  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }


  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }

  // saEmail = false;
  // emailValidation(id,event) {
  //   if(event.target.value != '') {
  //     this.tableService.checksamail(id,event.target.value).subscribe((res: any) => {
  //       if (res.success) {
  //         if (res.data.exists) {
  //               this.signupstaffingForm.controls['emailId'].setErrors({ 'errors': true });
  //               this.saEmail = true;
  //             }
  //         else {
  //           this.saEmail = false;
  //         }
  //       } else {
  //         this.tostr.errorToastr(res.message, 'Error!');
  //       }
  //     });
  //   }
  //   this.saEmail = false;
  //   }

  searchAgencyValue = false;
  searchAgency(e) {
  
    if (e.target.value != '') {
      this.stafingagencyService.searchAgency(this.id,e.target.value).subscribe((res: any) => {
       
        if (res.data.length == 0) {
          this.searchAgencyValue = true;
        }
        if (res.success) {
          this.allStaffingAgency = res.data;
          if (res.data.length != 0) {
            this.searchAgencyValue = false;
          }
        } 
      });
    }
    else {

      this.allStaffingAgency = this.cloneStaffingAgnecy;
      this.searchAgencyValue = false;
    }

  }

  //Manu Datta: 20210310, VerisourceBugSheetAfterMerge/commonLogin Company name and website check before page submit
  supplierNameExist(event) {
    this.suppNameExist = false;
    this.suppName = this.invitationForm.controls['AgencyName'].value;
    if(!this.detailValue){
      this.AdminApi.checkSuplireName(this.suppName).subscribe((res: any) => {
        if (res.success) {
          if (res.data.exists) {      
            this.suppNameExist = true;
            this.invitationForm.controls['AgencyName'].setErrors({ 'invalid': true });     
          } 
          else {
            this.suppNameExist = false;
          }
        } else {
          this.tostr.errorToastr(res.message, 'Error!');
        }
      });
    }
  }
//Manu Datta: 20210310, VerisourceBugSheetAfterMerge/commonLogin Company name and website check before page submit
  websiteNameExist(event) {
    this.websiteExist = false;
    this.website = this.invitationForm.controls['website'].value;
    if(!this.detailValue){
      this.AdminApi.checkWebsite(this.website).subscribe((res: any) => {
        if (res.success) {
          if (res.data.exists) {      
            this.websiteExist = true;
            this.invitationForm.controls['website'].setErrors({ 'invalid': true });     
          } 
          else {
            this.websiteExist = false;
          }
        } else {
          this.tostr.errorToastr(res.message, 'Error!');
        }
      });
    }
}
}
