import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Time } from '@angular/common';
import { EnterpriseService } from '../enterprise.service';
import { AuthenticationService } from '../../app.service';
import { Router } from '@angular/router';
import { JobseekerService } from '../../JobSeeker/jobseeker.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { PoolJobSeekerComponent } from 'src/app/share-module/pool-job-seeker/pool-job-seeker.component';
import { ShareService } from 'src/app/share-module/share.service';
import { environment } from 'src/environments/environment';




export interface DialogData {
  jobseekerId: string;
  date: Date;
  time: Time;
}


@Component({
  selector: 'app-preview-resume-popup',
  templateUrl: './preview-resume-popup.component.html',
  styleUrls: ['./preview-resume-popup.component.css']
})
export class PreviewResumePopupComponent implements OnInit {
  preLoader = false;
  isSuccess: any;
  jobSeekerData: any;
  isDataready = false;
  baseUrl = '';
  showResumeUploader = false;
  deleteId = '';
  resumeFile = '';
  iswithdraw = false;
  colors = '';
  localStorageData: any = {};
  userType = ''
  months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sept',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec'
  };
  userData: any;
  isResumeExits: boolean = false;
  resumeName: any;
  response: any;
  resume_path: any;
  resume_name: any;
  seekerId: any;
  loginUserId: any;
  constructor(
    private enterPriseAPI: EnterpriseService,
    public dialogRef: MatDialogRef<PreviewResumePopupComponent>,
    public _dialogRef: MatDialogRef<PoolJobSeekerComponent>,
    private datePipe: DatePipe,
    private mainApi: AuthenticationService,
    private router: Router,
    private JobseekerApi: JobseekerService,
    private toastr: ToastrManager,
    private AuthenticationService: AuthenticationService,
    private shareservice:ShareService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,

  ) {
   data;
    this.AuthenticationService.jwtTokenExpCheck();
 
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    if(this.userData.data.credentials.userType == 'AD'){
    this.loginUserId = this.userData.data.credentials.userId;
    }else if(this.userData.data.credentials.userType == 'EP'){
      this.loginUserId = this.userData.data.credentials.AppRefId;
    }else if(this.userData.data.credentials.userType == 'SA'){
      this.loginUserId = this.userData.data.credentials.AppRefId;
    }

    this.baseUrl = `${this.mainApi.url}/api/enterprise/image/resume/`;
 let id = data.jobseekerId;
      this.JobseekerResume(data['jobseekerId']);
  }



  ngOnInit() {
   // this.userData = JSON.parse(window.localStorage.getItem('udata'));
    // this.resumeFileExits();
    this.initData();
  }

  initData() {
   
    
    try {
      // get jobseeker  deails by id
      this.isDataready = true;
      this.enterPriseAPI.resumepreview(this.data['jobseekerId']).subscribe((res: any) => {
        this.isDataready = false;
        if (res.success) {
          this.localStorageData = JSON.parse(localStorage.getItem('udata'));
          this.userType = this.localStorageData.data.credentials.userType;
         
          this.jobSeekerData = res;
          this.jobSeekerData.data.resumedetails.dob_month = this.months[this.jobSeekerData.data.resumedetails.dob.split('-')[0]];
          this.jobSeekerData.data.resumedetails.dob_day = this.jobSeekerData.data.resumedetails.dob.split('-')[1];
          this.resumeName = this.jobSeekerData.data.resumedetails.resume;
          this.resumeFileExits(this.jobSeekerData.data.resumedetails.resume);
        } else {
          this.jobSeekerData = [];
        }
      });
    } catch (error) {
      ////console.log(error);
    }
    if(this.seekerId != undefined){
      this.JobseekerResume(this.seekerId);
      }
  }

  updateResume(jobSeekerId) {
    this.AuthenticationService.jwtTokenExpCheck();
    let isOnreset: boolean;
    this.onNoClick();
    if (this.userData.data.credentials.userType == 'AD') {     
      this.router.navigate(['/ad/admin/update-jobseeker', jobSeekerId]);
      //window.location.reload();
    }
    if (this.userData.data.credentials.userType == 'SA') {
      this.router.navigate(['/st/staff/vms/updateCandidate', jobSeekerId]);
    }
    if (this.userData.data.credentials.userType == 'SA') {
      this.router.navigate(['/st/staff/vms/updateCandidate', jobSeekerId]);
    }
  }
  onNoClick(): void {
    this.dialogRef.close();   
  //  this._dialogRef.close();
  }

  //this function is not in use 

  // DeleteResume() {
  //   this.AuthenticationService.jwtTokenExpCheck();
  //   this.showResumeUploader = false;
  //   this.enterPriseAPI.JobseekerDeleteResume(this.deleteId, this.jobSeekerResume).subscribe(res => {
  //     this.isSuccess = res;
  //     ////console.log(this.isSuccess);
  //     if (this.isSuccess.success) {
  //       this.initData();
  //       this.toastr.successToastr(this.isSuccess.message, "", { position: "top-right" });
  //     } else {
  //       this.toastr.errorToastr(this.isSuccess.message, "", { position: "top-right" });
  //     }
  //   });
  //   this.iswithdraw = false;
  // }

  DeletejobseekerResume() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.showResumeUploader = false;
    let id = this.deleteId;
    this.seekerId = id;
    this.enterPriseAPI.deleteJbseekerResumeVD(this.deleteId).subscribe(res => {
      this.isSuccess = res;
      ////console.log(this.isSuccess);
      if (this.isSuccess.success) {
        var resumeName = this.resume_name = '';
        var resumepath = this.resume_path = '';
        localStorage.setItem('resumeName',  resumeName);   
        localStorage.setItem('resumepath',  resumepath); 
        this.JobseekerResume(this.deleteId);
        this.initData();
        this.toastr.successToastr(this.isSuccess.message, "", { position: "top-right" });
      } else {
        this.toastr.errorToastr(this.isSuccess.message, "", { position: "top-right" });
      }
    });
    this.iswithdraw = false;
  }

  //Update resume to s3 Buket
  onSelectUpdateResumeFile(jobSeekerId, event) {
    // let id = jobSeekerId;
    // this.seekerId = id;
    this.JobseekerResume(jobSeekerId);
    this.AuthenticationService.jwtTokenExpCheck();
    const CandidateName = this.jobSeekerData.data.resumedetails.fullName;
    CandidateName.replace(" ", "_");
    const Name = CandidateName.replace(" ", "_");
    let mydata = new FormData();
    let date:string=this.shareservice.convertDateIntoUtc(new Date());
    // New file name
    let dateFormat = require('dateformat');
    let now = new Date();
    const mmddyy =  dateFormat(new Date(), "mmddyy_ss");
    var randomNo = Math.floor(Math.random() * 999);
  if(this.jobSeekerData.data.resumedetails != null){
    const fname= this.jobSeekerData.data.resumedetails.firstName;
    const lname= this.jobSeekerData.data.resumedetails.lastName;  
      const ext = event.target.files[0].name.split('.')[1];
      var filename = fname +'_'+ lname +'_'+ this.loginUserId +'_'+ mmddyy + randomNo + '' +'.' + ext;              
    }  //
    mydata.append("NewFileName", filename);
    mydata.append("FilePath", event.target.files[0]);
    mydata.append("UserId", jobSeekerId); 
    mydata.append("AppType",environment.AppType);
    mydata.append("AppRefType", environment.AppRefTypeJobseeker);
    if (event.target.files[0].size < (2029410 / 2)) {
      this.enterPriseAPI.updateResumeVD(mydata).subscribe(res => {
        this.isSuccess = res;
        if (this.isSuccess.success) {
          this.initData();      
          this.JobseekerResume(jobSeekerId);    
          this.showResumeUploader = false;
          this.toastr.successToastr(this.isSuccess.message, '', { position: 'top-right' });

        } else {

          this.toastr.errorToastr(this.isSuccess.message, '', { position: 'top-right' });
        }
      });
    }

    else {
      this.toastr.errorToastr('Resume file should be less than 1 MB.');
    }
  }

  resumeUploader() {
    this.showResumeUploader = true;
  }

  jobSeekerResume = '';
  popUpDelete(jobSeekerId, resume) {
    this.deleteId = jobSeekerId;
    this.jobSeekerResume = resume;
    // ////console.log(this.deleteId);
    this.iswithdraw = true;
  }

  deleteColor(p) {
    if (this.jobSeekerData.data.resumedetails.resume == '') { return 'textGray' }
    if (this.jobSeekerData.data.resumedetails.resume != '') { return 'text-danger' }
  }

  uploadColor(p) {
    if (this.jobSeekerData.data.resumedetails.resume == '') { return 'text-success' }
    if (this.jobSeekerData.data.resumedetails.resume != '') { return 'textGray' }
  }

  downloadColor(p) {
    if (this.jobSeekerData.data.resumedetails.resume == '') { return 'textGray' }
    if (this.jobSeekerData.data.resumedetails.resume != '') { return 'text-primary' }
  }

//Akhilesh:20200912, file check exits on root folder
  resumeFileExits(resumename) {   
    ////console.log("resumename",this.resumeName);
    this.enterPriseAPI.fileExists("resume",resumename).subscribe((res: any) => {
   
      if (res.data == true) {       
        ////console.log("response",res.data);
        this.isResumeExits = true;        
      }
     
    });
  }
   
  // Get resume path
  JobseekerResume(id) {   
    
    this.preLoader = true;       
   // let id = this.jobSeekerData.data.resumedetails.jobseekerId.substring(6);       
      this.enterPriseAPI.jobseekerProfileResume(id).subscribe(res => {              
        this.response = res;  
        if(this.response.data.length > 0){     
        var resumeName = this.resume_name = this.response.data[0].fileName;
        var resumepath = this.resume_path = this.response.data[0].filePath;
        localStorage.setItem('resumeName',  resumeName);   
        localStorage.setItem('resumepath',  resumepath);           
        this.preLoader = false;     
        }
    });    
  }
}
