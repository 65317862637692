import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogData } from 'src/app/component/header/header.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnterpriseService } from '../enterprise.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.css']
})
export class EmailPopupComponent implements OnInit {
  sendEmailToCandidate: FormGroup;
  issendml: boolean = false;
  submitted: boolean;
  emailsuccess: any;
  constructor(private _formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
    private enterpriseApis: EnterpriseService, private toastr: ToastrManager,
    public dialogRef: MatDialogRef<EmailPopupComponent>, ) {
    this.sendEmailToCandidate = this._formBuilder.group({
      'jobseekerId': [''],
      'to': ['', [Validators.required, Validators.email]],
      'subject': ['', [Validators.required]],
      'content': ['', [Validators.required]],
      'jobId':[''],
      'jobDesc':[''],
      'interviewTime':['']
    });
    this.issendml = !this.issendml;
    this.sendEmailToCandidate.controls['jobseekerId'].setValue(data.jobseekerId);
    this.sendEmailToCandidate.controls['to'].setValue(data.emailId);
    
  }

  ngOnInit() {
  }
  // validate form
  get a() { return this.sendEmailToCandidate.controls; }
  // send mail to user
  sendemail() {
    this.submitted = true;
    this.sendEmailToCandidate.controls['jobId'].setValue(this.data.jobId);
    this.sendEmailToCandidate.controls['jobDesc'].setValue(this.data.jobDescription);
    this.sendEmailToCandidate.controls['interviewTime'].setValue(this.data.scheduleDate);
    if (this.sendEmailToCandidate.valid) {
      this.enterpriseApis.sendemailtocandidate(this.sendEmailToCandidate.value).subscribe(res => {
        this.emailsuccess = res;
        if (this.emailsuccess.success) {
          this.toastr.successToastr(this.emailsuccess.message, '', { position: 'bottom-right' });
          this.sendEmailToCandidate.reset();
          this.issendml = false;
          this.dialogRef.close('close');
        } else {
          this.toastr.errorToastr(this.emailsuccess.message, '', { position: 'bottom-right' });
        }
      });
    } else {
      return false;
    }
  }
  // remove space from 1st pos
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  // reset the form
  resetform() {
    this.issendml = false;
    this.sendEmailToCandidate.reset();
    this.dialogRef.close('close');
  }

}
