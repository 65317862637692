import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SuuplierPopupComponent } from '../addSupplier/suuplier-popup/suuplier-popup.component';
import { environment } from 'src/environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TableService } from 'src/app/admin/service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent {
  iswithdraw = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ConfirmDeleteComponent>,
  private toastr:ToastrManager, private tableService:TableService){

  }

  loader=false;
  SendUserActivationLink() {
    this.loader=true;
    try {
      let params = {
        Email: this.data.Email,
        AppType: environment.AppType
      };
        this.tableService.SendUserActivationLink(params).subscribe((res: any) => {
        this.loader=false;
        if (res.success) {
          this.toastr.successToastr(res.message, 'Success!');
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
        this.dialogRef.close('close');
      });
    } catch (error) {
      this.loader=false;
      this.toastr.errorToastr('Something went wrong !', 'Error!');
    }
  }
}
