import { Injectable, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { AuthenticationService } from '../app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { ShareService } from '../share-module/share.service';
//import {AuthenticationService } from '../../app.service';

	

@Injectable()
export class staffingAgencyServices {
    baseUrl = '';
    token = '';
    apipath = '';
    userData:any={};
    isLogin: string = 'false';
    constructor( private API: AuthenticationService,private AuthenticationService: AuthenticationService,
       private http: HttpClient, private _router: Router ,private route:ActivatedRoute , private datepipe:DatePipe,private shareservice:ShareService) {
        this.apipath = `${environment.apipath}`;
        this.baseUrl = this.API.url;
        this.userData = JSON.parse(window.localStorage.getItem('udata'));
        this.token = localStorage.getItem('token');
        //console.log('this.token const token',this.token);
    }


    updateCandidateDetails(body) {
      this.token = localStorage.getItem('token');
      //console.log(body);
      const localurl = this.baseUrl + '/api/staffingagency/beeline/updatecandidatedetails';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
        })
      };
      return this.http.post(localurl, body, httpOptions);
    } 

    staffingDashboard = () => {
      this.token = localStorage.getItem('token');
      this.AuthenticationService.jwtTokenExpCheck();
        const localurl = this.baseUrl + '/api/staffingagency/dashboard';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': this.token
            })   
        };
        return this.http.get(localurl, httpOptions);
    }

    staffingjoblist = (data: { PageSize: any; PageNumber: any; postedDate: any; jobType: any; search: any; }) => {
      this.token = localStorage.getItem('token');
      this.AuthenticationService.jwtTokenExpCheck();
        const localurl = this.baseUrl + '/api/staffingagency/browsejobs';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': this.token
            })
        };
        const body = {
            'PageSize': data.PageSize,
            'PageNumber': data.PageNumber,
            'postedDate': data.postedDate,
            'jobType': data.jobType,
            'search': data.search
        };
        return this.http.post(localurl, body, httpOptions);
    }

    staffingdetails = () => {
      this.token = localStorage.getItem('token');
        const localurl = this.baseUrl + '/api/staffingagency/myprofile';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': this.token
            })
        };
        return this.http.get(localurl, httpOptions);
    }

    staffingdetailsVS = (token:any) => {
        const localurl = this.baseUrl + '/api/staffingagency/myprofile';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': token
            })
        };
        return this.http.get(localurl, httpOptions);
    }



    Entrpzdetails = () => {
      this.token = localStorage.getItem('token');
      let localurl = this.baseUrl + "/api/enterprise/myprofile";
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
        })
      };
      let body = {};
      return this.http.get(localurl, httpOptions);
    }

    adminMyProfile = () => {    
      this.token = localStorage.getItem('token');  
      let url = `${environment.url}/api/staffingagency/admindProfiledetails`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      return this.http.get<any>(url, httpOptions).pipe(map(res => {
        return res;
      }));
      } 
    
    
    staffJobseekerCreation = (data: any) => {
      this.token = localStorage.getItem('token');
        const localurl = this.baseUrl + '/api/staffingagency/uploadresume';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': this.token
            })
        };

        return this.http.post(localurl, data, httpOptions);
    }

  // staffing profile update API
  staffingFormupdate = (data: { companyName: any; website: any; firstName: any;
     middleName: any; lastName: any; emailId: any; contactNo: any; profileimage: any; }) => {
      this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/updateprofile';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };

    const body = {
      'companyName': data.companyName,
      'website': data.website,
      'firstName': data.firstName,
      'middleName': data.middleName,
      'lastName': data.lastName,
      'emailId': data.emailId,
      'contactNo': data.contactNo,
      'profileimage': data.profileimage,
    };
    return this.http.post(localurl, body, httpOptions);
  }

  staffJobseekerProfileImg(body) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/uploadnewfile';
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }


  //  Update client profile image to VD
 seekerProfileImgUpdateVD = (formData) => {
 
  //console.log('data for ent',formData);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/UpdateUserImage';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
   
    return this.http.post(url, formData, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}

uploadParseResumeVD = (formData) => {
 
  //console.log('data for ent',formData);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/UpdateUserImage';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
   
    return this.http.post(url, formData, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}

POSTJOBSEEKER_DOCUMENTTOS3_VDIAL<ResultModel>(formData): Observable<ResultModel> { 
  // var token =  this.JwtToken.getToken();
  //Manu Datta: 20210601, for updated token
  this.token =  localStorage.getItem("token");  
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  const url = this.apipath + 'Account/UpdateResume';
  if (this.isLogin == 'true') {   
    const  httpOptions = {
      headers: new HttpHeaders({             
        'Authorization': 'Bearer ' + this.token
      })
      
    };  
    return this.http.post<ResultModel>(url, formData, httpOptions);    
  }
  else {
    this._router.navigate(['/']);
  }
  
}

GETJOBSEEKER_S3DOCUMENTURL<ResultModel>(ID): Observable<ResultModel> {
  this.token =  localStorage.getItem("token");  
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  const url = this.apipath+'Account/GetVKUserProfileResume';
  if (this.isLogin == 'true') {   
    const  httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
    };  
    return this.http.post<ResultModel>(url, ID, httpOptions);      
  }
  else {
    this._router.navigate(['/']);
  }
  
 }
  
  staffUpdateProfileImg(body) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/uploadprofileimage';
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  recentActivity(size: any, PageNo: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/activities';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    const body = {
      PageSize: size,
      PageNumber: PageNo
    };
    return this.http.post(localurl, body, httpOptions);
  }

  announcement(pageno, size) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/getannouncement';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    const body = {
      'PageNumber': pageno,
      'PageSize': size
    };
    return this.http.post(localurl, body, httpOptions);
  }


    mycandidateList(body: any) {
      this.token = localStorage.getItem('token');
      this.AuthenticationService.jwtTokenExpCheck();
        const localurl = this.baseUrl + '/api/staffingagency/mycandidates';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': this.token
            })
        };
        return this.http.post(localurl, body, httpOptions);
    }

    clientcandidateList(body: any) {
      this.token = localStorage.getItem('token');
      this.AuthenticationService.jwtTokenExpCheck();
      const localurl = this.baseUrl + '/api/staffingagency/verifiedcandidates';
      const httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'authenticate': this.token
          })
      };
      return this.http.post(localurl, body, httpOptions);
  }

  getInvoiceReport(body: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/getinvoice';
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'authenticate': this.token
        })
    };
    return this.http.post(localurl, body, httpOptions);
}

getRaiseInvoiceReport(body: any) {
  this.token = localStorage.getItem('token');
  this.AuthenticationService.jwtTokenExpCheck();
  const localurl = this.baseUrl + '/api/staffingagency/getraiseinvoicereport';
  const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
      })
  };
  return this.http.post(localurl, body, httpOptions);
}


generateInvoiceByMail(body:any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/generateInvoiceByMail';
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'authenticate': this.token
        })
    };

    const invdata = {  
      "bcc":body.bcc,
      "invdetail": body.invoicedetail,
      "invoicedata":body.invoicedata,
      "period":body.period,
      "supplieremail":body.supplieremail,
      "raiseinvoicedate":this.shareservice.convertDateIntoUtc(new Date())
    }
    return this.http.post(localurl, invdata, httpOptions);
  }


  getinvoicefile(file:string) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/getinvoicefile';
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'authenticate': this.token
        })
    };

    const body={
      invfile:file
    }

  
    return this.http.post(localurl,body, httpOptions);
  }
  AcceptInvoice(data) {
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    let localurl = `${this.baseUrl}/api/timesheet/acceptinvoice`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    const body=data;
    return this.http.post(localurl,body, httpOptions);
  }

    MarksAsFavouriteCandidateApi(jobseekerId: string, isFavourite: string) {
      this.token = localStorage.getItem('token');
        const localurl = this.baseUrl + '/api/staffingagency/updatefavourite/' + jobseekerId + '/' + isFavourite;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'authenticate': this.token
            })
        };
        return this.http.get(localurl, httpOptions);
    }

    profileBasicUpdatesVD(body, id, type) {
    
      // let idU= id.splite('-0');
       this.token = localStorage.getItem("token");
       this.AuthenticationService.jwtTokenExpCheck();
       const localurl = this.apipath + 'Recruiter/EditRecruiter';
       const httpOptions = {
         // headers: new HttpHeaders({
         //   'Content-Type': 'application/json',
         //   'authenticate': 'Bearer' + this.token + ""
         // })
   
         headers: {
           Authorization: "Bearer " + this.token // token
         }
       };
     
       const data = {
   
         "userId": id,
         "name": body.firstName + body.lastName,
         "phone": body.contactNo,
         "skillIds": "127",
         "modifiedBy":0,
         "subscriberId": 0,
         "permissionId": 108,
         "email": body.emailId,
         "roleManagerId": 0,
         "AppType":environment.AppType,
         "AppRefType":type
   
       }
       return this.http.post(localurl, data, httpOptions);
     }

  profileBasicUpdate(body) {
    this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/updateprofile';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  profileAboutUpdate(body) {
    this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/updateaboutprofile';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  SubmitVendorProfileForDocusign(body) {
    this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/submitVendorProfileForDocusign';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  UpdateStaffingAgencyAddress(body) {
    this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/updateStaffingAgencyAddress';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  profileSocialUpdate(body: any) {
    this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/updatelinkedprofile';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }


  feesUpdate(body: any) {
    this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/feesUpdate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  jobforPushCandidates = (body) => {
    this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/updateaboutprofile';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }

  candidateListForJob = (body) => {
    this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/browsejobcandidates';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  CandidatePushed = (data) => {
    this.token = localStorage.getItem('token');
    const localurl = this.baseUrl + '/api/staffingagency/pushbrowsejobs';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    const body = { 'postedjobs': data };
    return this.http.post(localurl, body, httpOptions);
  }

  jobseekerDetails = (id) => {
    this.token = localStorage.getItem('token');
    const localurl = `${this.baseUrl}/api/staffingagency/resumepreview/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  postedjobsprofileview = (body, id) => {
    this.token = localStorage.getItem('token');
    const localurl = `${this.baseUrl}/api/staffingagency/postedjobsprofileview/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }


  //all posted jobs
  getallpostedjobsprofileview = (body) => {
    this.token = localStorage.getItem('token');
    const localurl = `${this.baseUrl}/api/staffingagency/getallpostedjobsprofileview`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  Postedprofiledelete = (SrNo) => {
    this.token = localStorage.getItem('token');
    const localurl = `${this.baseUrl}/api/staffingagency/deletepostedjob/${SrNo}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.delete(localurl, httpOptions);
  }

  changeStatusOfPostedJob= (id,jobseekerId) => {
    this.token = localStorage.getItem('token');
    const localurl = `${this.baseUrl}/api/staffingagency/changeStatusOfPostedJob/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    const body = {
      'jobseekerId': jobseekerId,
    };
    return this.http.post(localurl,body,httpOptions);
  }

  substaffingagencies = () => {
    this.token = localStorage.getItem('token');
    const localurl = `${this.baseUrl}/api/staffingagency/substaffingagencies`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  registersubstaffing = (body) => {
    this.token = localStorage.getItem('token');
    const localurl = `${this.baseUrl}/api/staffingagency/registersubstaffing`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  addstaffingagencyVD = (data,appRefId:Number) => {
    
    //console.log('data for ent', data);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
      const url = this.apipath + 'Account/AddSupplierManager'; // this is for VD register
      const httpOptions = {
        headers: {
          Authorization: "Bearer " + this.token // token
        }
      };
      const body = // data;
      {
        'id': 0,
        'name': data.firstName + ' ' + data.lastName,
        'email': data.emailId,
        'phone': data.contactNo,
        'skillId': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        'subscriberId': 0,
        'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        'roleManagerId': 0,
        'AppType':environment.AppType,
        'AppRefId':appRefId,
        'AppRefType':environment.AppRefTypeSupplier
      }
      return this.http.post(url, body, httpOptions);
    // } else {
    //   this._router.navigate(['/']);
    // }
  }

  updatesubstaffingagencyVD = (data, id) => {
    //console.log('data for ent', data);
    this.token = localStorage.getItem("token");
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.apipath + 'Recruiter/EditSupplierManager'; // this is for VD register
    const httpOptions = {
      headers: {
        Authorization: "Bearer " + this.token // token
      }
    };
    const body = {
        'userId': id,
        'name': data.firstName + ' ' + data.lastName,
        'phone': data.contactNo,              
        'skillIds': environment.VeridialSkillId,//Manu Datta: 20210710, comes from env file
        'modifiedBy': 123,
        'subscriberId': 0,
        'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
        'email': data.emailId,
        'roleManagerId': 0,
        'AppType':environment.AppType,
        'AppRefType':environment.AppRefTypeSupplier
    };
    return this.http.post(localurl, body, httpOptions);
  }

  updatesubstaffingagency = (data, id) => {
    this.token = localStorage.getItem('token');
     
    const localurl = `${this.baseUrl}/api/staffingagency/updatesubstaffingagency/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    const body = {
      contactNo: data.contactNo,
      emailId: data.emailId,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
    };
    return this.http.post(localurl, body, httpOptions);
  }

  updatecandidateprofile = (body, id) => {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/updatecandidateprofile/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }


  invitationRejected(code: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/invitationRejectedCode/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getStaffingAgency_onType(code: any,postedBy: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/getStaffingAgency_onType/${code}/${postedBy}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  searchAgency(code: any,search:string) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/getStaffingAgencyAll/${code}/${search}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  invitationVerifiy(code: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/invitationVerifiyCode/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  getClientId(jobId: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/getClientId/${jobId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  JobAndSeekerDetails(code: any,type) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    if(type == '1'){
    var localurl = `${this.baseUrl}/api/staffingagency/JobAndSeekerDetails/${code}`;    
    }
    if(type == '2'){
      var localurl = `${this.baseUrl}/api/staffingagency/enterprise_JobAndSeekerDetails/${code}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  //this api use for get enterprise ID from jobseeker table for update in supplier table at the time of create supplier by jobseeker in invitation functinality
  GetEnterpriseId(jobseekerId: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
      var localurl = `${this.baseUrl}/api/staffingagency/get_enterpriseId/${jobseekerId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  
  updateinvitation_request(code: any) {
    const localurl = `${this.baseUrl}/api/staffingagency/rejectBySeekerDetails/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(localurl, httpOptions);
  }
  
  UpdateSeekerAndStaffingDetails = (body,type) => {
    // let Option = new Http()
    if(type == '1'){
      var localurl = `${this.baseUrl}/api/staffingagency/UpdateSeekerAndStaffingDetails`;
    }
    if(type == '2'){
      var localurl = `${this.baseUrl}/api/staffingagency/UpdateSeekerAndStaffingDetailsFromEnterprise`;
    }
    // //console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(localurl, body, httpOptions);

  }

  updateinvitation_requestSA(code: any) {
    const localurl = `${this.baseUrl}/api/staffingagency/rejectBySADetails/${code}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(localurl, httpOptions);
  }

  assignJobseekerBySAVerify = (body: any) => {
    const url = `${this.baseUrl}/api/staffingagency/beeline/assignJobseekerBySAVerify`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(url, body, httpOptions);
  }

  GetbeelingdataForVms(code: any) {
    if(this.userData.data.credentials.userType === 'AD'){
      var localurl = `${this.baseUrl}/api/staffingagency/getBeelingdataforVms/${code}`;
    }
    if(this.userData.data.credentials.userType === 'EP'){
      var localurl = `${this.baseUrl}/api/staffingagency/getenterpriseDataForJob/${code}`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  
  UpdateStafingStatus(code: any, rate: any) {
    const localurl = `${this.baseUrl}/api/staffingagency/UpdateStafingStatus/${code}/${rate}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(localurl, httpOptions);
  }


  deteleProfileImage = (jobSeekerId, imgProfile) => {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/deteleProfileImage/${jobSeekerId}/${imgProfile}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  candidatedetails(id: string) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/candidatedetails/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  getfiltersMyCandidates() {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/getfilters/SA/mycandidates`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  getfiltersVMSRequest() {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/getfilters/SA/VMSRequest`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  browsejobcandidatesFilter() {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/getfilters/SA/browsejobcandidates`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  // countries API
  country() {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
	

    const localurl = `${this.baseUrl}/api/guestuser/countries`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  // state API
  state(searchText: string) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = `${this.baseUrl}/api/guestuser/states/${searchText}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }


    // Search state API
    searchState(countryId,data:string) {
      this.token = localStorage.getItem('token');
      this.AuthenticationService.jwtTokenExpCheck();

      const localurl = `${this.baseUrl}/api/guestuser/searchState/${countryId}/${data}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
        })
      };
      return this.http.get(localurl, httpOptions);
    }

     
    
    // Search city API
    searchCity(stateid,data:string) {
      this.token = localStorage.getItem('token');
      this.AuthenticationService.jwtTokenExpCheck();

       
      //console.log(stateid)
      //console.log(data)
      const localurl = `${this.baseUrl}/api/staffingagency/searchCity/${stateid}/${data}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
        })
      };
      return this.http.get(localurl, httpOptions);
    }


  // City API
  city(stateId: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = `${this.baseUrl}/api/staffingagency/cities/${stateId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  // nationality API
  nationality() {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = `${this.baseUrl}/api/staffingagency/nationality`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

 


  // nationality API
  searchNationality(data:string) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = `${this.baseUrl}/api/staffingagency/searchNationality/${data}`;
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }



  // check email profile  details api Start
  staffingCheckEmail = (staffingagencyId, emailId) => {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();

    
    const localurl = this.baseUrl + '/api/staffingagency/checkemail/' + staffingagencyId + '/' + emailId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // check email profile  details api End

  // check phone  details api Start
  staffingCheckPhoneNo = (staffingagencyId: string, contactNo: string) => {
		this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();

    const localurl = this.baseUrl + '/api/staffingagency/checkmobile/' + staffingagencyId + '/' + contactNo;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  // Check phone details api End

  checkmail(jobseekerId, email) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();

    let localurl = '';
    if (jobseekerId !== '') {
      localurl = this.baseUrl + '/api/jobseeker/checkemail/' + jobseekerId + '/' + email;
    } else {
      localurl = this.baseUrl + '/api/staffingagency/checkecandidatemail/' + email;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  checkmobile(jobseekerId, phone) {
		this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();

    let localurl = '';
    if (jobseekerId !== '') {
      localurl = this.baseUrl + '/api/jobseeker/checkmobile/' + jobseekerId + '/' + phone;
    } else {
      localurl = this.baseUrl + '/api/staffingagency/checkcandidatemobile/' + phone;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  
  changePassword(body, Id:string,usertype:string,emailId:string) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();

    let url = `${this.baseUrl}/api/staffingagency/changepassword/${Id}/${usertype}/${emailId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token 
      })
    };
    return this.http.post(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  browseBeelineJobs = (data: any) => {
    this.token = localStorage.getItem('token');
    //console.log(' this.token browseBeelineJobs', this.token)
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.baseUrl}/api/staffingagency/beeline/browseBeelineJobs`;
    const body = {
      PageSize: data.PageSize,
      PageNumber: data.PageNumber,
      JobStatus: data.JobStatus,
      fromdate: data.fromdate,
      todate: data.todate,
      search: data.search,
      totalapplied: data.totalapplied,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }

  beelineCandidateList(body: any, beelineRequestId: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    body['beelineRequestId'] = beelineRequestId;
    const localurl = this.baseUrl + '/api/staffingagency/beeline/candidates';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }

  assignJobseekerToBeelineRequest = (body: any) => {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/staffingagency/beeline/assignJobseeker`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(url, body, httpOptions);
  }

  submitVMSChangeRequest = (body: any,id:any) => {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/staffingagency/vms/submitVMSChangeRequest/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })    
    };
    return this.http.post(url, body, httpOptions);
  }

  getChangeRequestDetails = (body: any) => {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const url = `${this.baseUrl}/api/staffingagency/vms/changeRequestDetails`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(url, body, httpOptions);
  }

  parseCandidateResume(body,date:string) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/parseresume';
    const httpOptions = {
      headers: new HttpHeaders({
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }


  getverifiedcandidateList(body: any) {
    this.token = localStorage.getItem('token');
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = this.baseUrl + '/api/staffingagency/verifiedcandidates';
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'authenticate': this.token
        })
    };
    return this.http.post(localurl, body, httpOptions);
}

getverifiedtimesheetcandidateList(body: any) {
  this.token = localStorage.getItem('token');
  this.AuthenticationService.jwtTokenExpCheck();
  const localurl = this.baseUrl + '/api/staffingagency/verifiedtimesheetcandidates';
  const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
      })
  };
  return this.http.post(localurl, body, httpOptions);
}

createInvoice(body: any) {
  this.token = localStorage.getItem('token');
  this.AuthenticationService.jwtTokenExpCheck();
  const localurl = this.baseUrl + '/api/staffingagency/createInvoice';
  const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token
      })
  };
  return this.http.post(localurl, body, httpOptions);
}

//update supplier sub user to veridial
updatesuppliersubuserVD = (data, id) => {
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  const localurl = this.apipath + 'Recruiter/EditSupplierManager'; 
  const httpOptions = {
    headers: {
      Authorization: "Bearer " + this.token // token
    }
  };
  const body = {
      'userId': Number(id),
      'name': data.firstName + ' ' + data.lastName,
      'phone': data.contactNo,              
      'skillIds': environment.VeridialSkillId,  // fix skills id //Manu Datta: 20210710, comes from env file
      'modifiedBy': 123,
      'subscriberId': 0,
      'permissionId': environment.VeridialPermissionId,//Manu Datta: 20210710, comes from env file
      'email': data.emailId,
      'roleManagerId': 0,
      'AppType':environment.AppType,
      'AppRefType':environment.AppRefTypeSupplier
  };
  return this.http.post(localurl, body, httpOptions);
}

//This API used for reset Password by veridial
resetPasswordVD(formData, emailId) {

  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();

  ///let url = `${environment.url}/api/admin/resetpassword/${Id}/${usertype}`;

  const localurl = this.apipath + 'Account/ChangePassword';
  const httpOptions = {
    // headers: new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'authenticate': this.token + ""
    // })
    headers :{
      Authorization:"Bearer " + this.token // token
  }
  };

  const body=
  {        
      'email': emailId,
      'oldPassword': formData.oldPassword,
      'newPassword': formData.newPassword,
    }

  return this.http.post(localurl, body, httpOptions).pipe(map(res => {
    return res;
  }));
}

profileImageVD = (Id) => {
  //console.log('data for ent',Id);
  this.token = localStorage.getItem("token");
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if (this.isLogin == 'true') {   
    const url = this.apipath + 'Account/GetUserProfileImage';
    const httpOptions = {     
      headers :{
        Authorization:"Bearer " + this.token // token
    }
    };
    let body = {
      "UserId": Id,
      "AppType":environment.AppType,
      "AppRefType":environment.AppRefTypeSupplier
      
    }
    return this.http.post(url, body, httpOptions);
  } else {
    this._router.navigate(['/']);
  }
}
}


