import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators, FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AuthenticationService } from '../../app.service';
import { Observable, empty, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { getMatIconFailedToSanitizeLiteralError } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { EnterpriseService } from '../enterprise.service';
import { DatePipe } from '@angular/common';
import { TableService } from 'src/app/admin/service';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
//import {AuthenticationService } from '../../app.service';
 
import { ShareService } from 'src/app/share-module/share.service';
  
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { JobseekerService } from 'src/app/JobSeeker/jobseeker.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-candidate-profile-create',
  templateUrl: './candidate-profile-create.component.html',
  styleUrls: ['./candidate-profile-create.component.css']
  // providers: [    
  //   {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  //   {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  // ],
})
export class CandidateProfileCreateComponent implements OnInit {
  private subscriptions:Subscription[]=[];
  @ViewChild('myFileInput',{static:false}) myFileInput;
  preLoader = false;
  ProfileData: any;
  staffID:any;
  createdby:any;
  createdby1:any;
  registerdby:any;
  jobRegID:any;
  isValueWorkAuth: boolean=  true;
  isValidTill: boolean =  true;
  isValueJobType: boolean = false;
   // Datepicker takes `Moment` objects instead of `Date` objects.
   date = new FormControl(moment([2017, 0, 1]));
  
  iserror:boolean = false;
  interpriseId: any;
  resumedisable = true;
  todayDate: number;
  fileresponse: any;
  imgSrc: any = [];
  docsList: any = [];
  optionsHTML = `<ngx-img (onSelect)='onSelect($event)' (onReset)='onReset()' [config]='options'></ngx-img>`;
  skillsForm: FormGroup;
  resumeName: String = '';
  submited = false;
  skSubmited = false;
  rateError = false;
  salaryError = false;
  isvisibleEAD = true;
  isvisibleNewH1B = true;
  public shouldShow = true;
  serverpth: String = '';
  resumeContent: String = '';
  citizen = ['Antigua and Barbuda', 'Argentina', 'Barbados', 'Belize', 'Bolivia', 'Brazil',
    'Canada', 'Chile', 'Costa Rica', 'Cuba', 'Dominica', 'Ecuador', 'El Salvador', 'Fiji',
    'Grenada', 'Guatemala', 'Guyana', 'Honduras', 'Jamaica', 'Lesotho', 'Mexico', 'Nicaragua',
    'Pakistan', 'Panama', 'Paraguay', 'Peru', 'Saint Kits and Nevis', 'Saint Lucia',
    'Saint Vincent and the Grenadines', 'Tanzania', 'Trinidad and Tobago', 'Tuvalu',
    'United States', 'Uruguay', 'Venezuela'];
  docList = [
    { name: 'Passport', value: 'Passport' },
    { name: 'Driver license', value: 'Driver license' },
    { name: 'State ID', value: 'State ID' },
    { name: 'Any Other', value: 'Any Other' }
  ];

  jobType = [
    { id: 'W2 PERMANENT', name: 'FTE', selected: false },
    { id: 'CORP-CORP', name: 'CORP to CORP', selected: false },
    { id: 'W2 CONTRACT', name: 'W2 CONTRACT', selected: false },
    { id: '1099 CONTRACT', name: '1099 CONTRACT', selected: false },
    { id: 'NEED H1B', name: 'H1B', selected: false },
    { id: 'H1B TRANSFER', name: ' H1B TRANSFER', selected: false }
  ];

  EADList = [
    { name: 'OPT EAD', value: 'OPT EAD' },
    { name: 'CPT EAD', value: 'CPT EAD' },
    { name: 'GC EAD', value: 'GC EAD' },
    { name: 'L2 EAD', value: 'L2 EAD' },
    { name: 'H4 EAD', value: 'H4 EAD' },
    { name: 'TN Visa', value: 'TN Visa' },
    { name: 'E3 Visa', value: 'E3 Visa' }
  ];



  domainExperienceList = [
    { name: 'IT SOFTWARE ', value: 'IT SOFTWARE' },
    { name: 'GOVERNMENT', value: 'GOVERNMENT' },
    { name: 'RETAIL', value: 'RETAIL' },
    { name: 'HEALTHCARE', value: 'HEALTHCARE' },
    { name: 'TELECOM', value: 'TELECOM' },
    { name: 'DOT.COM', value: 'DOT.COM' },
    { name: 'DFINANCIAL', value: 'FINANCIAL' },
    { name: 'MANUFACTURING', value: 'MANUFACTURING' },
    { name: 'INSURANCE', value: 'INSURANCE' },
    { name: 'PHARMACEUTICALS', value: 'PHARMACEUTICALS' },
  ];



  prefrredEmpList = [
    { name: 'FTE', value: 'W2 PERMANENT' },
    { name: 'CORP to CORP', value: 'CORP-CORP' },
    { name: 'W2 CONTRACT', value: 'W2 CONTRACT' },
    { name: '1099 CONTRACT', value: '1099 CONTRACT' },
    { name: 'H1B', value: 'NEED H1B' },
    { name: 'H1B TRANSFER', value: 'H1B TRANSFER' },
  ];





  H1BVisaTypeList = [
    { name: 'B1/B2', value: 'B1/B2' },
    { name: 'L1', value: 'L1' },
    { name: 'F1', value: 'F1' },
    { name: 'F2', value: 'F2' },
    { name: 'J1', value: 'J1' },
    { name: 'NAME THE OTHER VISA', value: 'OTHER VISA' },
  ];


  SecurityClear = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' },

  ];




  visaTypeList = [
    { name: 'US Citizen', value: 'USCitizen' },
    { name: 'Green Card ', value: 'GreenCard ' },
    { name: 'H1B', value: 'H1B' },
    { name: 'OPT', value: 'OPT EAD' },
    { name: 'CPT', value: 'CPT EAD' },
    { name: 'GC', value: 'GC EAD' },
    { name: 'L2', value: 'L2 EAD' },
    { name: 'H4', value: 'H4 EAD' },
    { name: 'TN', value: 'TN EAD' },
  ];
  daysList = [
    { name: '01', value: '01' },
    { name: '02', value: '02' },
    { name: '03', value: '03' },
    { name: '04', value: '04' },
    { name: '05', value: '05' },
    { name: '06', value: '06' },
    { name: '07', value: '07' },
    { name: '08', value: '08' },
    { name: '09', value: '09' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
    { name: '13', value: '13' },
    { name: '14', value: '14' },
    { name: '15', value: '15' },
    { name: '16', value: '16' },
    { name: '17', value: '17' },
    { name: '18', value: '18' },
    { name: '19', value: '19' },
    { name: '20', value: '20' },
    { name: '21', value: '21' },
    { name: '22', value: '22' },
    { name: '23', value: '23' },
    { name: '24', value: '24' },
    { name: '25', value: '25' },
    { name: '26', value: '26' },
    { name: '27', value: '27' },
    { name: '28', value: '28' },
    { name: '29', value: '29' },
    { name: '30', value: '30' },
    { name: '31', value: '31' }
  ];
  countryList: any = [];
  nationalityList: any = [];
  stateList: any = [];
  cityList: any = [];
  allNationality: any = [];
  allCountryList: any =[];
  today: String = new Date().toISOString().split('T')[0];
  uploadData: any;
  Epsubmitted = false;
  profileLoader = false;
  resImageData: any;
  imgProfile: String = '';
  select_img_path: String ='';
  sync = false;
  resumeResponse: any;
  sync1 = false;
  resumeResponse1: any;
  voiceLoader = false;
  sync2 = false;
  resumeResponse2: any;
  sync3 = false;
  resumeResponse3: any;
  eduLoader = false;
  isVisaToggle = false;
  isEADToggle = false;
  isSubVisaToggle = false;
  body: FormGroup;
  mobileError = false;
  mobileError1 = false;
  phoneError = false;
  mailError: boolean = false;
  baseUrl: String = '';
  yearslist = [];
  presentYear: number = new Date().getFullYear();
  userData: any;
  isvisibleNeedNewH1B: boolean;
  isShow: boolean = false;
  isUsDegree: any;
  country_Id: any = 0;
  state_Id: any = 0;
  allStateList: any = [];
  allCityList: any = [];
  stateLoader:boolean= false;
  cityLoader:boolean= false;
  isTrue: boolean = true;
  DataST: any = '';
  jobSeekerIds: any = [];
  jobSeekerData: any = [];
  jobData: any = [];
  a1: any;
  candidateListData: any;
  isDocToggle = false;
  baseUrl2 = '';
  baseUrlDoc = '';
  isRequired = false;
  isWorkAuthRequired = false;
  activeTab = 4;
  editUploadProfile: FormGroup;
  htmlCode1 = `<ngx-img (onSelect)='onSelect($event)' (onReset)='onReset()'></ngx-img>`;
  submitMsg = '';
  professionalForm: FormGroup;
  socialForm: FormGroup;
  educationalForm: FormGroup;
  jobseekerId = '';
  docName = '';

  yearList = [
    { name: '2020', value: '2020' },
    { name: '02', value: '02' },
    { name: '03', value: '03' },
    { name: '04', value: '04' },
    { name: '05', value: '05' },
    { name: '06', value: '06' },
    { name: '07', value: '07' },
    { name: '08', value: '08' },
    { name: '09', value: '09' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
    { name: '13', value: '13' },
    { name: '14', value: '14' },
    { name: '15', value: '15' },
    { name: '16', value: '16' },
    { name: '17', value: '17' },
    { name: '18', value: '18' },
    { name: '19', value: '19' },
    { name: '20', value: '20' },
    { name: '21', value: '21' },
    { name: '22', value: '22' },
    { name: '23', value: '23' },
    { name: '24', value: '24' },
    { name: '25', value: '25' },
    { name: '26', value: '26' },
    { name: '27', value: '27' },
    { name: '28', value: '28' },
    { name: '29', value: '29' },
    { name: '30', value: '30' },
    { name: '31', value: '31' }
  ];
  voiceFileName: String = '';
  veriFileName: String = '';
  profileImage: string = '';
  resumeLoader = false;
  docsLoader = false;

  nextButton = true;
  isVisa = false;
  visaType = false;
  isSubVisaToggl;
  //isEADToggle;
  //selectVisa;
  checkWA = '';
  sub: any;
  secondseekerId: any;
  page: number;
  dob_year: any;
  viewer = 'google';
  doc = "http://api.veriklick.in/Content/sampleresume.docx";
  isReset: boolean =true;
  abc: any;
  resumeFile: any;
  isResumeExits: boolean = false;
  imageName: any;
  isImgExits: boolean =false;
  charCodeValue: any;
  isvisaValue: any;
  pageValue:boolean=false;
  userData1:any;
  response: any;
  resume_path:  any;
  resume_name: any;
  fileEvent: any;
  imageFileEvent: any;
  loginUserId: any;
  currency = [
    { value: 'USD', name: 'USD',selected:false},
    { value: 'CAD', name: 'CAD',selected:false},
    { value: 'INR', name: 'INR',selected:false},
  ];
  RegisteredEmail = '';
  constructor(
    private _formBuilder: FormBuilder,
    private API: EnterpriseService,
    private AuthenticationService: AuthenticationService,
    private staffAPI: staffingAgencyServices,
    private APIA: TableService,
    private appAPI: AuthenticationService,
    private toastr: ToastrManager, vcr: ViewContainerRef, private _router: Router,
    private _titleService: Title,
    private datePipe: DatePipe,
    private shareservice:ShareService,
    private enterpriseService:EnterpriseService,
    private activatedRoute: ActivatedRoute,
    private jobseekerService:JobseekerService
  ) {
    this.AuthenticationService.jwtTokenExpCheck();
// Start: Get auth user id
    this.userData1 = JSON.parse(window.localStorage.getItem('udata'));
    if(this.userData1.data.credentials.userType == 'AD'){
    this.loginUserId = this.userData1.data.credentials.userId;
    }else if(this.userData1.data.credentials.userType == 'EP'){
      this.loginUserId = this.userData1.data.credentials.AppRefId;
    }else if(this.userData1.data.credentials.userType == 'SA'){
      this.loginUserId = this.userData1.data.credentials.AppRefId;
    }
    // End

    this.jobseekerId = this.activatedRoute.snapshot.params['id'];
    //console.log('==================',this.jobseekerId)
    if(this.select_img_path != null){
    this.imgProfile = this.select_img_path;
  }else {
    this.imgProfile = localStorage.getItem('profileImagePath');
  }

    if(this.jobseekerId == undefined){
      var newTitle = 'Verisource | Add Jobseeker';
    }
    else{
      var newTitle = 'Verisource | Update Jobseeker';
      this.resume_path =  localStorage.getItem('resumepath'); 
    }
   
    this._titleService.setTitle(newTitle);
    this.baseUrl = `${this.appAPI.url}/api/enterprise/image/resume/`;
    this.baseUrl2 = `${this.appAPI.url}/api/enterprise/image/verification/`;
    this.baseUrlDoc = `${this.appAPI.url}/api/enterprise/image/resume/`;
    this.serverpth = `${this.appAPI.url}`;
    this.interpriseId = localStorage.getItem('interpriseId');
   
    if(this.jobseekerId != null || this.jobseekerId != undefined){
    this.profileupdateImageLoad(this.jobseekerId);    
  }
  this.JobseekerResume(this.jobseekerId);
    this.select_img_path = localStorage.getItem('profileUpdateImagePath');
    if(this.jobseekerId == undefined){
      this.pageValue = false;
      this.API.country().subscribe((res: any) => {
        if (res.success) {
          this.countryList = res.data;
          this.allCountryList=this.countryList;
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
      this.API.nationality().subscribe((res: any) => {
        if (res.success) {
          this.nationalityList = res.data;
          this.allNationality = this.nationalityList
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else{
      this.pageValue = true;
    }
      
// Get Job seeker Details
  if(this.jobseekerId != undefined){
    this.API.candidatedetails(this.jobseekerId).subscribe((res: any) => {
      if (res.success) {
        //console.log('res.data', res.data);
        this.jobRegID = res.data.details.jobseekerId;
        this.RegisteredEmail = res.data.details.emailId;
        this.setValueCandidate(res.data);
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }

    this.formInit();
    for (let i = 1950; i <= (this.presentYear - 18); i++) {
      this.yearslist.push(i);
    }
  }
  ngOnInit() {
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    //console.log('{userData.data.credentials.userType}', this.userData.data.credentials.userType);
    if(this.jobseekerId != undefined){
      this.sub = this.activatedRoute
      .queryParams
      .subscribe(params => {
        this.page = +params['project'] || 0;
      });
    }
    
  }

  resetResume(){
    this.isReset = false;
  }
  get pu() {
    return this.editUploadProfile.controls;
  }
  get cp() {
    return this.professionalForm.controls;
  }
  get sk() {
    return this.skillsForm.controls;
  }
  get ot() {
    return this.socialForm.controls;
  }

  // get df(){
  //   return this.dynamicFirmForm.get('formRows')[0].controls;
  // }


  deleteProfile(jobseekerId, profileImage) {
    if (profileImage == "") {
      this.imgProfile = '';
    }
    this.AuthenticationService.jwtTokenExpCheck();
    //console.log('jobseekerId, profileImage', jobseekerId, profileImage);
    this.API.deteleProfileImage(jobseekerId, profileImage).subscribe((res: any) => {
      if (res.success) {
        this.imgProfile = '';
        this.toastr.successToastr(res.message, "", { position: "top-right" });
      }
    });
  }

  deleteProfileImage(jobseekerId) { 
    //let id = jobseekerId.substring(6);
    this.AuthenticationService.jwtTokenExpCheck();
    this.API.deleteProfileImageVD(jobseekerId).subscribe((res: any) => {
      if (res.success) {
        this.imgProfile = '';
        var profileImagePath = this.imgProfile = '';
        localStorage.setItem('profileUpdateImagePath',  profileImagePath);
        this.toastr.successToastr(res.message, "", { position: "top-right" });
      }
    });
  }

  clearFile(event) { 
    this.myFileInput.nativeElement.value = '';
  }

  profileupdateImageLoad(jobseekerId) {  
    this.preLoader = true;
      //let Id = jobseekerId.substring(6);
      this.AuthenticationService.jwtTokenExpCheck();
      this.jobseekerService.profileImageVD(jobseekerId).subscribe(res => {
        
        this.ProfileData = res;   
        if(this.ProfileData.data.length > 0){          
        var profileImagePath = this.imgProfile = this.ProfileData.data[0].profileImagePath;
        localStorage.setItem('profileUpdateImagePath',  this.ProfileData.data[0].profileImagePath);         
       // this.dataLoad = true;
        this.preLoader = false;
        }
      });    
  }

  //
  profileupdateImage() { 

    this.preLoader = true;
    const _promisses = new Promise((resolve, reject) => {
      let Id = this.jobseekerId.substring(6);
      this.AuthenticationService.jwtTokenExpCheck();
      this.jobseekerService.profileImageVD(Id).subscribe(res => {
        
        this.ProfileData = res;   
        if(this.ProfileData.data.length > 0){ 
        this.ProfileData.data[0].profileImagePath;  
        var profileImagePath = this.imgProfile = this.ProfileData.data[0].profileImagePath;
        localStorage.setItem('profileUpdateImagePath',  this.ProfileData.data[0].profileImagePath); 
        resolve(res);
       // this.dataLoad = true;
        this.preLoader = false;
        }
      }, err => {
        reject(err);
      });
    });
    return _promisses;
  }

  onSelect(event) {  
  this.imageFileEvent = event; 
  // if(this.jobseekerId != undefined){    
    this.profileLoader = true;
    const formData = new FormData();
    formData.append('profileimage', event.target.files[0]);
    if (event.target.files[0].type === 'image/png'
      || event.target.files[0].type === 'image/jpg'
      || event.target.files[0].type === 'image/jpeg') {
      this.profileLoader = true;
      if (event.target.files[0].size < (2029410 / 2)) {
        if(this.jobseekerId != undefined)
        {
          formData.append("ImageUrl", event.target.files[0]);
          formData.append("UserId", this.jobRegID);  
        }
        else{
          formData.append("ImageUrl", event.target.files[0]);
        }  
        this.AuthenticationService.jwtTokenExpCheck();            
        this.staffAPI.seekerProfileImgUpdateVD(formData).subscribe((res:any) => { 
        this.resImageData = res;
        if (this.resImageData.success) {
          this.imgProfile = res.data.filePath;
          this.profileLoader = false;
          this.myFileInput.nativeElement.value = '';
          this.toastr.successToastr(this.resImageData.message, '', { position: 'bottom-right' });
          this.editUploadProfile.controls['profileImage'].setValue(this.resImageData.data.fileName);
          this.profileLoader = false;
          this.isImgExits = true;          
          this.profileImage =this.resImageData.data.fileName;
        } else {
          this.toastr.errorToastr(this.resImageData.message, '', { position: 'bottom-right' });
        }
        });
      }
      else {
        this.toastr.errorToastr('Profile image should be less than 1 MB.');
        this.profileLoader = false;
      }
    }
    else {
      this.toastr.errorToastr('Profile image should be in png or jpg format.', 'Error!');
    }
  // }
  }

  
//Update candidate resume to s3 buket
  uplaodResume(event) {
    this.AuthenticationService.jwtTokenExpCheck();
    let dateFormat = require('dateformat');
    let now = new Date();
    const mmddyy =  dateFormat(new Date(), "mmddyy_ss");
    var randomNo = Math.floor(Math.random() * 999);

       this.fileEvent = event;
    sessionStorage.setItem('resumeevent',JSON.stringify(event.target));
    const check = JSON.parse(sessionStorage.getItem("resumeevent"));
  //  this.professionalForm.controls['resume'].setValue("test.png");
    if(this.jobseekerId == undefined){
      if( event.target.files.length > 0){
      if (this.pu.firstName.value != '' && this.pu.firstName.value != null) {
        if (this.pu.lastName.value != '' && this.pu.lastName.value != null) {
          const formData = new FormData();
          formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
          formData.append('resume', event.target.files[0]);
          this.resumeLoader = true;
          const type = event.target.files[0].name.split('.');
          //console.log('type', type[1]);
          if (type[1] === 'docx'
            || type[1] === 'pdf'
            || type[1] === 'doc') {
            if (event.target.files[0].size < (2029410 / 2)) {
              this.API.staffJobseekerProfileImg(formData).subscribe(res => {
                this.resumeResponse = res;
                if (this.resumeResponse.success) {
                  this.resumeLoader = false;
                  this.isShow = true;
                  this.professionalForm.controls['resume'].setValue(this.resumeResponse.data.fileName);
                  this.resumeName = this.resumeResponse.data.fileName;
                  this.todayDate = Date.now();
                  this.toastr.successToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
                } else {
                  this.toastr.errorToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
                }
                this.sync = true;
              });
            } else {
              this.toastr.errorToastr('Resume file should be less than 1 MB.');
              this.resumeLoader = false;
  
            }
          } else {
            this.toastr.warningToastr('Only .pdf,.doc,.docx Support');
              this.resumeLoader = false;
          }
        } else {
          this.toastr.errorToastr('Please Enter Last Name.');
        }
      } else {
        this.toastr.errorToastr('Please Enter First Name');
      }
    }
    }
    
    if(this.jobseekerId != undefined){
      
      if( event.target.files.length > 0){  
        const fname= this.editUploadProfile.controls["firstName"].value;
        const lname= this.editUploadProfile.controls["lastName"].value;
          const ext = event.target.files[0].name.split('.')[1];
          var filename = fname +'_'+ lname +'_'+ this.loginUserId +'_'+ mmddyy + randomNo + '' +'.' + ext;          
          
          this.resumeName=filename;
         // event.target.files[0].name = filename;
     
        
     
    this.resumeLoader = true;
    const formData = new FormData();
    // formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
    // formData.append('resume', event.target.files[0]);
    let id = [];
    //id = this.jobseekerId.split('-'); 
    formData.append("NewFileName", filename);
    formData.append("FilePath", event.target.files[0]);
    formData.append("UserId", this.jobseekerId);  
    formData.append("AppType", environment.AppType);
    formData.append("AppRefType", environment.AppRefTypeJobseeker);
    
    const type = event.target.files[0].name.split('.');
    //console.log('type', type[1]);
    if (type[1] === 'docx'
      || type[1] === 'pdf'
      || type[1] === 'doc') {
      if (event.target.files[0].size < (2029410 / 2)) {
        this.API.updateResumeVD(formData).subscribe(res => {
           
          this.resumeResponse = res;
          if (this.resumeResponse.success != true) {
            this.resumeLoader = false;
          }
          if (this.resumeResponse.success) {
            this.JobseekerResume(this.jobseekerId);
            this.resumeLoader = false;
            this.isShow = true;
            this.isResumeExits = true;
            this.professionalForm.controls['resume'].setValue(filename);
            this.resumeName = this.resumeResponse.data.fileName;
            this.todayDate = Date.now();
            this.toastr.successToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
          } else {
            this.toastr.errorToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
          }
          this.sync = true;
        });
      } else {
        this.toastr.errorToastr('Resume file should be less than 1 MB.');
        this.resumeLoader = false;

      }
    } else {
      this.toastr.warningToastr('Only .pdf,.doc,.docx Support');
      this.resumeLoader = false;
      }
    }
  }
  }

  resumeUplaod(event) {
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.jobseekerId == undefined){
      if( event.target.files.length > 0){
      if (this.pu.firstName.value != '' && this.pu.firstName.value != null) {
        if (this.pu.lastName.value != '' && this.pu.lastName.value != null) {
          const formData = new FormData();
          formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
          formData.append('resume', event.target.files[0]);
          this.resumeLoader = true;
          const type = event.target.files[0].name.split('.');
          //console.log('type', type[1]);
          if (type[1] === 'docx'
            || type[1] === 'pdf'
            || type[1] === 'doc') {
            if (event.target.files[0].size < (2029410 / 2)) {
              this.API.staffJobseekerProfileImg(formData).subscribe(res => {
                this.resumeResponse = res;
                if (this.resumeResponse.success) {
                  this.resumeLoader = false;
                  this.isShow = true;
                  this.professionalForm.controls['resume'].setValue(this.resumeResponse.data.fileName);
                  this.resumeName = this.resumeResponse.data.fileName;
                  this.todayDate = Date.now();
                  this.toastr.successToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
                } else {
                  this.toastr.errorToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
                }
                this.sync = true;
              });
            } else {
              this.toastr.errorToastr('Resume file should be less than 1 MB.');
              this.resumeLoader = false;
  
            }
          } else {
            this.toastr.warningToastr('Only .pdf,.doc,.docx Support');
              this.resumeLoader = false;
          }
        } else {
          this.toastr.errorToastr('Please Enter Last Name.');
        }
      } else {
        this.toastr.errorToastr('Please Enter First Name');
      }
    }
    }
    
    if(this.jobseekerId != undefined){
      if( event.target.files.length > 0){
      
        
    var aa=event.target.files.length;     
    this.resumeLoader = true;
    const formData = new FormData();
    formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
    formData.append('resume', event.target.files[0]);
    const type = event.target.files[0].name.split('.');
    //console.log('type', type[1]);
    if (type[1] === 'docx'
      || type[1] === 'pdf'
      || type[1] === 'doc') {
      if (event.target.files[0].size < (2029410 / 2)) {
        this.API.staffJobseekerProfileImg(formData).subscribe(res => {
          this.resumeResponse = res;
          if (this.resumeResponse.success != true) {
            this.resumeLoader = false;
          }
          if (this.resumeResponse.success) {
            this.resumeLoader = false;
            this.isShow = true;
            this.isResumeExits = true;
            this.professionalForm.controls['resume'].setValue(this.resumeResponse.data.fileName);
            this.resumeName = this.resumeResponse.data.fileName;
            this.todayDate = Date.now();
            this.toastr.successToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
          } else {
            this.toastr.errorToastr(this.resumeResponse.message, '', { position: 'bottom-right' });
          }
          this.sync = true;
        });
      } else {
        this.toastr.errorToastr('Resume file should be less than 1 MB.');
        this.resumeLoader = false;

      }
    } else {
      this.toastr.warningToastr('Only .pdf,.doc,.docx Support');
      this.resumeLoader = false;
      }
    }
  }
  }


  voiceUpload(event) {
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.jobseekerId == undefined){
      const formData = new FormData();
    formData.append('voicesample', event.target.files[0]);
    if (event.target.files[0].type === 'audio/wav' || event.target.files[0].type === 'audio/mp3') {

      this.voiceLoader = true;
      if (event.target.files[0].size < (2029410 / 2)) {
        this.API.staffJobseekerProfileImg(formData).subscribe(res => {
          this.resumeResponse1 = res;
          if (this.resumeResponse1.success) {
            this.voiceLoader = false;
            this.toastr.successToastr(this.resumeResponse1.message, '', { position: 'bottom-right' });
            this.socialForm.controls['voiceSample'].setValue(this.resumeResponse1.data.fileName);
            document.getElementById('voiceList').innerHTML = `<b class='badge badge-info'>${this.resumeResponse1.data.fileName}</b>`
          } else {
            this.toastr.errorToastr(this.resumeResponse1.message, '', { position: 'bottom-right' });
          }
          this.sync1 = true;
        });
      } else {
        this.toastr.errorToastr('voice sample should be less than 1 MB.');
        this.voiceLoader = false;
      }
    } else {
      this.toastr.errorToastr('Only .wav and .mp3 file supported for voice sample.', 'Warning!');
      }
    }

    if(this.jobseekerId != undefined){
      this.voiceLoader = true;
    const formData = new FormData();
    formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
    formData.append('voicesample', event.target.files[0]);
    this.API.staffJobseekerProfileImg(formData).subscribe(res => {
      this.resumeResponse1 = res;
      if (this.resumeResponse1.success) {
        this.voiceLoader = false;
        this.toastr.successToastr(this.resumeResponse1.message, '', { position: 'bottom-right' });
        this.socialForm.controls['voiceSample'].setValue(this.resumeResponse1.data.fileName);
        this.voiceFileName = this.resumeResponse1.data.fileName;
        // document.getElementById('voiceList').innerHTML = `<b class='badge badge-info'>${this.resumeResponse1.data.fileName}</b>`
      } else {
        this.toastr.errorToastr(this.resumeResponse1.message, '', { position: 'bottom-right' });
      }
      this.sync1 = true;
    });
    }
  }

  verificationDocs(event) {
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.jobseekerId == undefined){
      if(event.target.files.length > 0){
      const formData = new FormData();
      formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
      formData.append('verificationIdType', 'voterID');
      formData.append('verification', event.target.files[0]);
      if (event.target.files[0].type === 'image/png'
        || event.target.files[0].type === 'image/jpg'
        || event.target.files[0].type === 'image/jpeg') {
        if (event.target.files[0].size < (2029410 / 2)) {
          this.docsLoader = true;
          this.API.staffJobseekerProfileImg(formData).subscribe(res => {
            this.resumeResponse2 = res;
            if (this.resumeResponse2.success) {
              this.docsLoader = false;
              this.toastr.successToastr(this.resumeResponse2.message, '', { position: 'bottom-right' });
              document.getElementById('docList').innerHTML = `<b class='badge badge-info'>${this.resumeResponse2.data.docName}</b>`
  
              this.docsList.push(this.resumeResponse2.data.docId);
  
              this.socialForm.controls['docIds'].setValue(this.docsList);
            } else {
              this.toastr.errorToastr(this.resumeResponse2.message, '', { position: 'bottom-right' });
            }
            this.sync2 = true;
  
          });
        } else {
          this.toastr.errorToastr('Document file should be less than 1 MB.');
        }
      } else {
        this.toastr.errorToastr('Only .png and .jpeg file supported for document.', 'Warning!')
      }
    }
    }

    if(this.jobseekerId != undefined){
      if(event.target.files.length > 0){
      this.docsLoader = true;
    const formData = new FormData();
    formData.append('verificationIdType', 'voterID');
    formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
    formData.append('verification', event.target.files[0]);
    this.API.staffJobseekerProfileImg(formData).subscribe(res => {
      this.resumeResponse2 = res;
      if (this.resumeResponse2.success) {
        this.docsLoader = false;
        this.toastr.successToastr(this.resumeResponse2.message, '', { position: 'bottom-right' });
        document.getElementById('docList').innerHTML = `<b class='badge badge-info'>${this.resumeResponse2.data.docName}</b>`;
        this.docName = this.resumeResponse2.data.docName;
        this.docsList.push(this.resumeResponse2.data.docId);
        this.socialForm.controls['docIds'].setValue(this.docsList);
      } else {
        this.toastr.errorToastr(this.resumeResponse2.message, '', { position: 'bottom-right' });
      }
      this.sync2 = true;

    });
  }
    }
  }

  qualificationUpload(event) {
    this.AuthenticationService.jwtTokenExpCheck();
    if(event.target.files.length > 0){
    this.eduLoader = true;
    const formData = new FormData();
    formData.append('candidateName', this.pu.firstName.value + "_" + this.pu.lastName.value);
    formData.append('qualificationlevel', 'graduation');
    formData.append('qualification', event.target.files[0]);
    this.API.staffJobseekerProfileImg(formData).subscribe(res => {
      this.resumeResponse3 = res;
      if (this.resumeResponse3.success) {
        this.eduLoader = false;
        this.toastr.successToastr(this.resumeResponse3.message, '', { position: 'bottom-right' });
        this.docsList.push(this.resumeResponse2.data.docId);
        this.socialForm.controls['docIds'].setValue(this.docsList);
      } else {
        this.toastr.errorToastr(this.resumeResponse3.message, '', { position: 'bottom-right' });
      }
      this.sync3 = true;
    });
  }
}

  changeTab(i: number) {
    this.imgSrc = [];
    this.activeTab = i;
  }

  reset() {
    this.imgSrc = [];
  }

  basicDetails() {
    if(this.jobseekerId == undefined){
      this.editUploadProfile.valid
      if (!this.editUploadProfile.controls['jobType'].value) {
        this.toastr.warningToastr('Please select at least one Job Type', '');
      }
      if (this.editUploadProfile.controls['isVisa'].value) {
        let b = this.editUploadProfile.controls['WorkAuth'].value;
        if (this.editUploadProfile.controls['WorkAuth'].value == null) {
          this.toastr.warningToastr('Please select at least one Work Authorization', '');
        }
      }
      this.Epsubmitted = true;
    }

    if(this.jobseekerId != undefined){
      this.editUploadProfile.valid;
    let b = this.editUploadProfile.controls['WorkAuth'].value;

    var aaaa = this.editUploadProfile.setValidators([Validators.required])
    this.editUploadProfile.updateValueAndValidity();
    if (this.jobSeekerIds.length <= 0) {
      this.Epsubmitted = false;
      this.toastr.warningToastr('Please select at least one Job Type', '');
    }
    else {
      if (this.editUploadProfile.controls['isVisa'].value == true) {
        if (!this.editUploadProfile.controls['WorkAuth'].value) {
          this.isWorkAuthRequired = true;
          this.editUploadProfile.controls['WorkAuth'].setValidators(Validators.required);
          this.toastr.warningToastr('Please select at least one Work Authorization', '');
        }
      }
      else if (this.editUploadProfile.controls['isVisa'].value == false) {
        if (!this.editUploadProfile.controls['WorkAuth'].value) {
          this.isWorkAuthRequired = false;
          this.editUploadProfile.controls['WorkAuth'].reset();
          this.editUploadProfile.controls['WorkAuth'].clearValidators();
          this.editUploadProfile.controls['WorkAuth'].updateValueAndValidity();
        }
      }
    }
    this.Epsubmitted = true;
    }
  }



  professionalSubmit() {
    this.dfSubmitted = true;
    this.submited = true;
  }
  dfSubmitted = false;
  skillsSubmit() {
    this.dfSubmitted = true;
    this.skSubmited = true;
  }

  jsIds:number;
   onUpdateProfile() {
    if(this.jobseekerId == undefined){
      this.preLoader = true;
      this.AuthenticationService.jwtTokenExpCheck();
      this.Epsubmitted = true;
      this.submited = true;
      this.skSubmited = true;
      if (this.jobSeekerIds.length > 0) {
        for (var i = 0; i < this.jobSeekerIds.length; i++) {
          var jobData = this.jobSeekerIds[i].name;
          if (this.DataST !== '')
            this.DataST = this.DataST + ',' + jobData;
          else
            this.DataST = jobData;
        }
      } 
      const EAD = this.editUploadProfile.controls['WorkAuthDropDownEAD'].value;
      const NewH1B = this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].value;
      const workAuthV = this.editUploadProfile.controls['WorkAuth'].value;
  
      if (workAuthV === 'EAD') {
        this.editUploadProfile.controls['visaType'].setValue(EAD);
      } else {
        if (workAuthV === 'NeedNewH1B') {
          this.editUploadProfile.controls['visaType'].setValue(NewH1B);
        } else {
          this.editUploadProfile.controls['visaType'].setValue(workAuthV);
        }
      }
      if (this.editUploadProfile.controls['validTill'].value == null) {
        this.editUploadProfile.controls['validTill'].markAsTouched();
      }
      if (this.jobSeekerIds.length <= 0) {
       this.isValueJobType = true;
      }else{
      this.isValueJobType = false}
      if (this.skillsForm.valid && this.professionalForm.valid && this.editUploadProfile.valid && this.socialForm) {
        if(this.professionalForm.controls["refDate"].value != "" || this.professionalForm.controls["refDate"].value != null || this.professionalForm.controls["refDate"].value != "Invalid date" || this.professionalForm.controls["refDate"].value != "1900-01-01"){
          this.professionalForm.controls["refDate"].setValue(this.datePipe.transform(this.professionalForm.controls["refDate"].value,'yyyy-MM-dd'))
        }
        if(this.editUploadProfile.controls["validTill"].value != "" || this.editUploadProfile.controls["validTill"].value != null || this.editUploadProfile.controls["validTill"].value != "Invalid date" || this.editUploadProfile.controls["validTill"].value != "1900-01-01"){
          this.editUploadProfile.controls["validTill"].setValue(this.datePipe.transform(this.editUploadProfile.controls["validTill"].value,'yyyy-MM-dd'));
        }
       
        if(this.professionalForm.controls["refDate"].value == ""){
          this.professionalForm.controls["refDate"].setValue(null);
        }
        if(this.editUploadProfile.controls["validTill"].value == ""){
          this.editUploadProfile.controls["validTill"].setValue(null);
        }
        if (this.professionalForm.controls['refDate'].value == "") {
          this.professionalForm.controls['refDate'].markAsTouched();
        }
        this.professionalForm.controls['refDate'].setErrors(null);
        this.editUploadProfile.controls['createdDate'].setValue(this.datePipe.transform(new Date(),'yyyy-MM-dd'));
        
      if (this.userData.data.credentials.userType === 'AD') {       
        this.registerdby=this.userData.data.credentials.userId
        this.editUploadProfile.controls['registerBy'].setValue(this.registerdby);
        this.editUploadProfile.controls['createdBy'].setValue(this.registerdby);
        let userid=this.userData.data.credentials.userId
        this.editUploadProfile.controls['createdBy'].setValue(userid);
      }
      if (this.userData.data.credentials.userType === 'SA') {
        var ID=this.userData.data.credentials.userId;
        this.staffID=this.userData.data.credentials.userId;
        this.createdby = localStorage.getItem('CreatedBy');     
        this.editUploadProfile.controls['createdBy'].setValue(this.createdby);
        this.editUploadProfile.controls['registerBy'].setValue(this.userData.data.credentials.CreatedBy);
      }
      if (this.userData.data.credentials.userType === 'EP') {      
        var ID=this.userData.data.credentials.enterpriseId
        this.staffID=ID.substring(6)
        this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
        this.editUploadProfile.controls['createdBy'].setValue(this.createdby);
        this.editUploadProfile.controls['registerBy'].setValue(this.staffID);
      }
        const body = {
          professional: this.professionalForm.value,
          profile: this.editUploadProfile.value,
          skills: this.skillsForm.value,
          other: this.socialForm.value,
          allJobtype: this.DataST,
          AppType:environment.AppType,
          AppRefId:0,
          AppRefType:environment.AppRefTypeJobseeker
        };
        if (this.userData.data.credentials.userType == 'AD') {
              this.APIA.staffJobseekerCreation(body).subscribe(async (res: any) => {
                if (res.success && res.data.jsId > 0) {               
                  body.AppRefId = res.data.jsId;
                  this.jsIds = res.data.jsId;
                  this.APIA.staffJobseekerCreationVD(body).subscribe((resp:any)=>{
                      if (res.success) {                     
                        this.jobRegID = resp.data;
                        this.APIA.UpdateIdFromVeridialForJobseeker(Number(this.jsIds),Number(this.jobRegID)).subscribe(async (response: any) => {
                        //this.editUploadProfile.controls['vdJobskrId'].setValue(this.jobRegID.data);
                        // const body = {
                        //   professional: this.professionalForm.value,
                        //   profile: this.editUploadProfile.value,
                        //   skills: this.skillsForm.value,
                        //   other: this.socialForm.value,
                        //   allJobtype: this.DataST,
                        // };
                        this.preLoader = false;

                        });   
                      }
                      else{
                        this.preLoader = false;
                      }
                    });
                      
                   
                //   this.jobseekerId = res.data.jobseekerId;
                //   if(this.imageFileEvent != undefined){
                //   await this.onSelect(this.imageFileEvent);               
                // }else if(this.fileEvent != undefined){
                //   await  this.uplaodResume(this.fileEvent);
                // }
                  if(this.isResumeUploaded != false){
                    await this.onSelectUpdateResumeFile(Number(this.jsIds));
                  }
                  this.uploadData = res;
                  this.preLoader = false;
                  this.toastr.successToastr(res.message, 'Succcess!', { position: 'top-right' });
                  setTimeout(() => {
                    this._router.navigate(['/ad/admin/jobseeker']);
                  }, 500);
                } else {
                  this.preLoader = false;
                  this.toastr.errorToastr(res.message);
                }
              },(errorResponse: any) => {
                this.preLoader = false;      
                if (errorResponse instanceof HttpErrorResponse) {
                  if (errorResponse.status === 422) {
                    this.handleServerValidationErrors(errorResponse['error']['message'].errors);
                    if(this.professionalForm.get('currency').errors?.serverError){
                      this.professionalForm.controls['currency'].markAsTouched();
                    }
                    else if(this.editUploadProfile.get('WorkAuth').errors?.serverError){
                      this.editUploadProfile.controls['WorkAuth'].markAsTouched();
                      this.isValueWorkAuth = true;
                    }
                  }
                  else{
                    this.toastr.errorToastr(errorResponse['error']['message']);
                  }
                }
               });


  
          //  }
          //})
         
        } 
        
        else {
              this.API.staffJobseekerCreation(body).subscribe(async res => {               
                if (res['success'] && res['data'].jsId > 0) {
                   
                  body.AppRefId = res['data'].jsId;
                  this.jsIds = res['data'].jsId;
                  this.APIA.staffJobseekerCreationVD(body).subscribe((resp:any) => {
            
                  this.jobRegID = resp.data;
                    if(resp.success){
                      this.APIA.UpdateIdFromVeridialForJobseeker(Number(this.jsIds),Number(this.jobRegID)).subscribe(async (response: any) => {
                      if(response.success){
                        const event = sessionStorage.getItem("resumeevent");  
                        if(this.isResumeUploaded != false){
                          await this.onSelectUpdateResumeFile(Number(this.jsIds));
                        }
                        this.uploadData = res;
                        this.preLoader = false;
                        this.toastr.successToastr(res['message'], 'Succcess!', { position: 'top-right' });
                        setTimeout(() => {
                          if (this.userData.data.credentials.userType === 'SA') {
                            this._router.navigate(['/st/staff/Candidatestaffing']);           
                          } if (this.userData.data.credentials.userType === 'EP') {
                            this._router.navigate(['/ep/enterprise/Jobseeker']);
                          }
                        }, 500);
                      }
                      else{
                        this.preLoader = false;
                        this.toastr.successToastr(response.message, 'Error!', { position: 'top-right' });
                      }                   
                      }); 
                    }
                    else{
                      this.preLoader = false;
                    }
                  });
    
                } else {
                  this.preLoader = false;
                  this.toastr.errorToastr(res['message']);
                }
              },(errorResponse: any) => {
                this.preLoader = false;      
                if (errorResponse instanceof HttpErrorResponse) {
                  if (errorResponse.status === 422) {
                    this.handleServerValidationErrors(errorResponse['error']['message'].errors);
                    if(this.professionalForm.get('currency').errors?.serverError){
                      this.professionalForm.controls['currency'].markAsTouched();
                    }
                    else if(this.editUploadProfile.get('WorkAuth').errors?.serverError){
                      this.editUploadProfile.controls['WorkAuth'].markAsTouched();
                      this.isValueWorkAuth = true;
                    }
                  }
                  else{
                    this.toastr.errorToastr(errorResponse['error']['message']);
                  }
                }
               });
          
        }
  
      } else {
        this.preLoader = false;
        this.DataST = '';
        this.submitMsg = 'Invalid data please fill all required fileds.';
        this.toastr.errorToastr('Invalid data please fill all required fields.', 'Error!', { position: 'bottom-right' });
        return false;
      }
    }

    if(this.jobseekerId != undefined){
      this.preLoader = true;
      this.AuthenticationService.jwtTokenExpCheck();   
      this.Epsubmitted = true;
      this.submited = true;
      this.skSubmited = true;
      this.Epsubmitted = true;
      this.submited = true;
      this.skSubmited = true;
      this.DataST = '';
      const EAD = this.editUploadProfile.controls['WorkAuthDropDownEAD'].value;
      const NewH1B = this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].value;
      const workAuthV = this.editUploadProfile.controls['WorkAuth'].value;
  
      if (workAuthV === 'EAD') {
        this.editUploadProfile.controls['visaType'].setValue(EAD);
      } else {
        if (workAuthV === 'NeedNewH1B') {
          this.editUploadProfile.controls['visaType'].setValue(NewH1B);
        } else {
          this.editUploadProfile.controls['visaType'].setValue(workAuthV);
        }
      }
      if (this.jobSeekerIds.length > 0) {
        for (var i = 0; i < this.jobSeekerIds.length; i++) {
          var a1 = this.jobSeekerIds[i].name;
          if (this.DataST !== '')
            this.DataST = this.DataST + ',' + a1;
          else
            this.DataST = a1;
        }
      }
  
      if (this.editUploadProfile.controls['validTill'].value == null) {
        this.editUploadProfile.controls['validTill'].markAsTouched();
      }
      if (this.jobSeekerIds.length <= 0) {
        this.isValueJobType = true;
       }else{
       this.isValueJobType = false}
       
      if (this.skillsForm.valid && this.professionalForm.valid && this.editUploadProfile.valid && this.socialForm.valid) {
        this.editUploadProfile.controls['createdDate'].setValue(this.datePipe.transform(new Date(),'yyyy-MM-dd'));
        if(this.professionalForm.controls["refDate"].value != "" || this.professionalForm.controls["refDate"].value != null || this.professionalForm.controls["refDate"].value != "Invalid date" || this.professionalForm.controls["refDate"].value != "1900-01-01"){
          this.professionalForm.controls["refDate"].setValue(this.datePipe.transform(this.professionalForm.controls["refDate"].value,'yyyy-MM-dd'))
        }
        if(this.editUploadProfile.controls["validTill"].value != "" || this.editUploadProfile.controls["validTill"].value != null || this.editUploadProfile.controls["validTill"].value != "Invalid date" || this.editUploadProfile.controls["validTill"].value != "1900-01-01"){
          this.editUploadProfile.controls["validTill"].setValue(this.datePipe.transform(this.editUploadProfile.controls["validTill"].value,'yyyy-MM-dd'));
        }
        
        if(this.professionalForm.controls["refDate"].value == ""){
          this.professionalForm.controls["refDate"].setValue(null);
        }
        //Manu Datta: 20210331, VerisourceBugSheetAfterMerge/commonLogin 503
        if (this.professionalForm.controls['refDate'].value == "") {
          this.professionalForm.controls['refDate'].markAsTouched();
        }
        this.professionalForm.controls['refDate'].setErrors(null);
        if(this.editUploadProfile.controls["validTill"].value == ""){
          this.editUploadProfile.controls["validTill"].setValue(null);
        }
  
        let body: any;
        if (this.userData.data.credentials.userType === 'SA') {
          body = {
            professional: this.professionalForm.value,
            profile: this.editUploadProfile.value,
            skills: this.skillsForm.value,
            other: this.socialForm.value,
            allJobtype: this.DataST,
            enterpriseId: this.interpriseId == null ? 'NULL' : this.interpriseId
          };
        } else {
          body = {
            professional: this.professionalForm.value,
            profile: this.editUploadProfile.value,
            skills: this.skillsForm.value,
            other: this.socialForm.value,
            allJobtype: this.DataST,
          };
        }
        // let id = this.userData1.data.credentials.userId;
        this.APIA.staffJobseekerupdateVD(body,this.jobseekerId).subscribe((res:any)=>{
          if (res.success) {
            
            this.jobRegID = res;
            this.editUploadProfile.controls['vdJobskrId'].setValue(this.jobRegID.data);
            const body = {
              professional: this.professionalForm.value,
              profile: this.editUploadProfile.value,
              skills: this.skillsForm.value,
              other: this.socialForm.value,
              allJobtype: this.DataST,
            };
        this.API.updatecandidateprofile(body, this.jobseekerId).subscribe((resp: any) => {
          if (resp.success) {
            this.uploadData = resp;
            this.preLoader = false;
            this.toastr.successToastr(resp.message, 'Succcess!', { position: 'top-right' });
            setTimeout(() => {
  
              if (this.userData.data.credentials.userType === 'AD') {
                this._router.navigate(['/ad/admin/jobseeker']);
              }
              if (this.userData.data.credentials.userType === 'SA') {
                this._router.navigate(['/st/staff/Candidatestaffing']);
              }
              if (this.userData.data.credentials.userType === 'EP') {
                if (this.page === 1) {
                  this._router.navigateByUrl('/ep/enterprise/search_profile');
                }
                else if (this.page == 2) {
                  this._router.navigateByUrl("/ep/enterprise/jobseeker-view/" + this.jobseekerId);
                }
  
                else if (this.page == 3) {
                  this._router.navigateByUrl("/ep/enterprise/search_profile");
                }
                else {
                  this._router.navigate(['/ep/enterprise/dashboard']);
                }
              }
              this._router.isActive;
            }, 500);
          } else {
            this.preLoader = false;
            this.toastr.errorToastr(resp.message);
            return false;
          }
        },(errorResponse: any) => {
          this.preLoader = false;      
          if (errorResponse instanceof HttpErrorResponse) {
            if (errorResponse.status === 422) {
              this.handleServerValidationErrors(errorResponse['error']['message'].errors);
              if(this.professionalForm.get('currency').errors?.serverError){
                this.professionalForm.controls['currency'].markAsTouched();
              }
              else if(this.editUploadProfile.get('WorkAuth').errors?.serverError || this.editUploadProfile.get('WorkAuthDropDownEAD').errors?.serverError || this.editUploadProfile.get('WorkAuthDropDownNewH1B').errors?.serverError){
                this.editUploadProfile.controls['WorkAuth'].markAsTouched();
                this.isValueWorkAuth = true;
              }
            }
            else{
              this.toastr.errorToastr(errorResponse['error']['message']);
            }
          }
         });
      }
      else{
        this.preLoader = false;
      }
    })
      } else {
        this.preLoader = false;
        this.DataST = '';
        this.submitMsg = 'Invalid data please fill required  field.';
        this.toastr.errorToastr('Invalid data please fill required  field.', 'Error!', { position: 'bottom-right' });
        return false;
      }
    }
   
  }


  // Function to update form controls with server-side validation errors
  handleServerValidationErrors(errors) {
    for (let control of errors) {
      if (control.param.split('.')[0] == 'profile' && this.editUploadProfile.get(control.param.split('.')[1])) {
        this.editUploadProfile.get(control.param.split('.')[1]).setErrors({ serverError: control.msg });
      }
      else if(control.param.split('.')[0] == 'professional' && this.professionalForm.get(control.param.split('.')[1])){
        this.professionalForm.get(control.param.split('.')[1]).setErrors({ serverError: control.msg });
      }
      else if(control.param.split('.')[0] == 'skills' && this.skillsForm.get(control.param.split('.')[1])){
        this.skillsForm.get(control.param.split('.')[1]).setErrors({ serverError: control.msg });
      }
      else if(control.param.split('.')[0] == 'other' && this.socialForm.get(control.param.split('.')[1])){
        this.socialForm.get(control.param.split('.')[1]).setErrors({ serverError: control.msg });
      }
      else{
        this.toastr.errorToastr(control.msg);
      }
      
    }
  }


  resetUpdate() {
    if (this.userData.data.credentials.userType == 'AD') {
      this._router.navigate(['/ad/admin/dashboard']);
    }
    if (this.userData.data.credentials.userType == 'EP') {
      this._router.navigate(['/ep/enterprise/dashboard']);
    }
    if (this.userData.data.credentials.userType === 'SA') {
      this._router.navigate(['/st/staff/dashboard']);

    }
  }
  uploadDoc(val) {
    document.getElementById(val).click();
  }
  ResetEAD() {
    if(this.jobseekerId == undefined){
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].setValue('');
    }
    if(this.jobseekerId != undefined){
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].reset();
    }
   

  }
  ResetNewH1B() {
    if(this.jobseekerId == undefined)
    {
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].setValue('');
    }
    if(this.jobseekerId != undefined)
    {
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].reset();
    }
  }

  // dynamicFirmForm:FormGroup;
  formInit() {
    this.editUploadProfile = this._formBuilder.group({
      'firstName': ['',[Validators.required]],
      'middleName': [''],
      'lastName': ['',[Validators.required]],
      'emailId': ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      'phone_mobile': ['', Validators.required],
      'ssnNo': ['', Validators.required],
      'dob_month': [''],
      'dob_day': [''],
      'dob_year': [''],
      'streetName': [''],
      'country': ['', Validators.required],
      'state': ['', Validators.required],
      'zipCode': ['', Validators.required],
      'city': ['', Validators.required],
      'phone_home': [''],
      'extn_no': [''],
      'phone_work': [''],
      'citizenship': ['', [Validators.required]],
      'isVisa': ['', [Validators.required]],
      'validTill':  this.jobseekerId != undefined?['']:['', Validators.required],
      'visaType': [''],
      'profileImage': [''],
      'docIds': [this.docsList],
      'searchNationality': [''],
      'searchState': [''],
      'searchCity': [''],
      'jobType': [''],
      'WorkAuth': this.jobseekerId != undefined?['']:['', Validators.required],
      'WorkAuthDropDownEAD': [''],
      'WorkAuthDropDownNewH1B': [''],
      'SecurityClearance': this.jobseekerId != undefined?['']:[''],
      'vectorfile': [],
      'createdDate':'',
      'registerBy':[''],
       'createdBy':[''],
      'vdJobskrId':[''],
      'availabilityTime':[],
      'availabilityJoin':[]
      
    });
    this.skillsForm = this._formBuilder.group({
      skill: ['', [Validators.required]],
      suggested_keywords: ['',[Validators.required]],
      resumeSummary: ['', [Validators.required]]
    });
    this.educationalForm = this._formBuilder.group({

    });
    this.socialForm = this._formBuilder.group({
      facebookUrl: [''],
      twitterUrl: [''],
      linkdinUrl: ['',Validators.required],
      blogUrl: [''],
      voiceSample: [''],
      docIds: [this.docsList],
      educationFile: [''],
      verrifficationnDocs: [''],
    });
    this.professionalForm = this._formBuilder.group({
      highest_degree: ['', Validators.required],
      us_master_degree: [''],
      // expected_salary: ['', Validators.required],
      expected_salary: [''],
      // rate: ['', Validators.required],
      rate: [''],
      current_job_title: ['', Validators.required],
      total_experience: ['', Validators.required],
      usa_experience: [''],
      resume_title: ['', [Validators.required]],
       resume: ['', Validators.required],
    //  resume: [''],
      companyName: ['',[Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      contact_person: ['',[Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      refDate: [''],
      jobTitle: ['',[Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      contact_phone: [''],
      contact_email: ['', [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      re_location: [],
      frequency:[''],
      recentEmployer:[''],
      currency:['']
      //re_location: ['']
    });

    // this.dynamicFirmForm = this._formBuilder.group({
    //   formRows: this._formBuilder.array([this.initFormRow()])
    // });   
  }

  // initFormRow(){
  //   return this._formBuilder.group({
  //     name: ['', Validators.required]
  //   });
  // }

  // addFormRow() {
  //   const formRows = this.dynamicFirmForm.get('formRows') as FormArray;
  //   formRows.push(this.initFormRow());
  //   //console.log('=========',formRows)
  // }

  // removeFormRow(index: number) {
  //   const formRows = this.dynamicFirmForm.get('formRows') as FormArray;
  //   formRows.removeAt(index);
  // }

  // firFormSubmit(){
  //   this.dfSubmitted = true;
  // }

  selectVisa() {
    if(this.jobseekerId == undefined){
      const validTills = this.editUploadProfile.get('validTill');
      this.editUploadProfile.controls['isVisa'].value ?
        (
          this.isVisaToggle = true,
          this.isRequired = true,
          validTills.setValidators(Validators.required),
          this.editUploadProfile.controls['visaType'].reset(),
          this.editUploadProfile.controls['validTill'].reset(),
          this.editUploadProfile.controls['WorkAuth'].reset(),
          this.editUploadProfile.controls['WorkAuthDropDownEAD'].reset(),
          this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].reset(),
          this.isvisibleEAD = true,
          this.isvisibleNeedNewH1B = true,
          this.isValueWorkAuth = true,
          this.isValidTill = true
  
        ) :
        (
          this.isValidTill = false,
          this.isValueWorkAuth = false,
          this.isVisaToggle = false,
          this.isRequired = false,
          validTills.clearValidators(),
          this.editUploadProfile.controls['WorkAuth'].clearValidators(),
          this.editUploadProfile.controls['visaType'].reset(),
          this.editUploadProfile.controls['validTill'].reset(),
          this.editUploadProfile.controls['WorkAuthDropDownEAD'].clearValidators(),
          this.editUploadProfile.controls['WorkAuth'].reset(),
          this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].clearValidators(),
          this.editUploadProfile.controls['WorkAuthDropDownEAD'].reset(),
          this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].reset()
        );
      validTills.updateValueAndValidity();
  
      let a = this.editUploadProfile.controls['isVisa'].value;
      if (this.editUploadProfile.controls['isVisa'].value == true) {
  
        if (!this.editUploadProfile.controls['WorkAuth'].value) {
          this.isWorkAuthRequired = true;
          this.editUploadProfile.controls['WorkAuth'].setValidators(Validators.required);
          this.isvisibleNeedNewH1B = true;
          this.toastr.warningToastr('Please select at least one Work Authorization', '');
        }
      }
      else if (this.editUploadProfile.controls['isVisa'].value == false) {
  
        if (!this.editUploadProfile.controls['WorkAuth'].value) {
          this.isWorkAuthRequired = false;
          this.editUploadProfile.controls['WorkAuth'].reset();
          this.editUploadProfile.controls['WorkAuth'].clearValidators();
          this.editUploadProfile.controls['WorkAuth'].updateValueAndValidity();
          this.editUploadProfile.controls['WorkAuthDropDownEAD'].clearValidators(),
            this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].clearValidators()
        }
      }
      else if (this.editUploadProfile.controls['isVisa'].value == '') {
        this.isvisibleNeedNewH1B = true;
      }
      if (this.editUploadProfile.controls['WorkAuth'].value == null) {
        this.editUploadProfile.controls['WorkAuth'].setValue('');
      }
      if (this.editUploadProfile.controls['validTill'].value == null) {
        this.editUploadProfile.controls['validTill'].markAsTouched();
  
      }
    }
    
    if(this.jobseekerId != undefined){
      const validTills = this.editUploadProfile.get('validTill');
    this.isvisaValue =  this.editUploadProfile.controls['isVisa'].value ;
    // Akhilesh : 20200910, convert ternary check condition  in into if else
    if(this.isvisaValue == true)    {
      this.isVisaToggle = true;
      this.isRequired = true;
      this.isValueWorkAuth = true;
      validTills.setValidators(Validators.required);
      this.editUploadProfile.controls['visaType'].reset();
      this.editUploadProfile.controls['validTill'].reset();
    }
    else{
      this.isValueWorkAuth = false;
      this.isVisaToggle = false;
      this.isRequired = false;
      validTills.clearValidators(),
      this.editUploadProfile.controls['visaType'].reset();
      this.editUploadProfile.controls['validTill'].reset();
      this.editUploadProfile.controls['WorkAuth'].reset();
    }
    validTills.updateValueAndValidity();  

    let a = this.editUploadProfile.controls['isVisa'].value;
    if (this.editUploadProfile.controls['isVisa'].value == true) {

      if (!this.editUploadProfile.controls['WorkAuth'].value) {
        this.isWorkAuthRequired = true;
        this.editUploadProfile.controls['WorkAuth'].setValidators(Validators.required);
        this.toastr.warningToastr('Please select at least one Work Authorization', '');
      }
    }
    else if (this.editUploadProfile.controls['isVisa'].value == false) {
      if (!this.editUploadProfile.controls['WorkAuth'].value) {
        this.isWorkAuthRequired = false;
        this.editUploadProfile.controls['WorkAuth'].reset();
        this.editUploadProfile.controls['WorkAuth'].clearValidators();
        this.editUploadProfile.controls['WorkAuth'].updateValueAndValidity();
      }
    }
    if (this.editUploadProfile.controls['validTill'].value == null) {
      this.editUploadProfile.controls['validTill'].markAsTouched();

    }
    }
  }

  setState() {
    this.countryList.forEach(element => {
    });
  }

  countryChange() {
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.jobseekerId == undefined){
      let CountryId: any = 0;
      this.editUploadProfile.controls['state'].setValue('');
      this.editUploadProfile.controls['city'].setValue('');
      this.editUploadProfile.get('state').setValidators(Validators.required);
      this.editUploadProfile.get('state').updateValueAndValidity();
      this.editUploadProfile.get('city').updateValueAndValidity();
      this.countryList.forEach(element => {
        if (this.editUploadProfile.controls['country'].value === element.name) {
          CountryId = element.Id;
          this.country_Id = element.Id;
        }
      });
      
      this.stateLoader = true;
      this.API.state(CountryId).subscribe((res: any) => {
        this.stateLoader = false;
        if (res.success) {
          this.stateList = res.data;
          this.allStateList = res.data;
          //this.editUploadProfile.controls['state'].setValidators(Validators.required);
          //this.editUploadProfile.controls['state'].setErrors({ 'incorrect': true });
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }

    if(this.jobseekerId != undefined){
      let CountryId: any = 0;
      this.countryList.forEach(element => {
        if (this.editUploadProfile.controls['country'].value === element.name) {
          CountryId = element.Id;
          this.country_Id = element.Id;
        }
      });
      this.editUploadProfile.controls['state'].setValue('');
      this.editUploadProfile.controls['city'].setValue('');
      this.editUploadProfile.get('state').setValidators(Validators.required);
      this.editUploadProfile.get('state').updateValueAndValidity();
      this.editUploadProfile.get('city').updateValueAndValidity();
      return new Promise((resolve, reject) => {
        this.API.state(CountryId).subscribe((res: any) => {
          if (res.success) {
            this.stateList = res.data;
            this.allStateList = res.data;
            resolve(res.data);
          } else {
            this.toastr.errorToastr(res.message, 'Error!');
            reject('Error!');
          }
        });
        });
    }
    
  }


  stateChange() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.editUploadProfile.controls['city'].setValue('');
    this.editUploadProfile.get('city').setValidators(Validators.required);
    this.editUploadProfile.get('city').updateValueAndValidity();
    if(this.jobseekerId == undefined){
      this.cityLoader = true;
      let stateId: any = 0;
      this.stateList.forEach(element => {
        if (this.editUploadProfile.controls['state'].value === element.name) {
          stateId = element.Id;
          this.state_Id = element.Id;
        }
      });
  
      this.API.city(stateId).subscribe((res: any) => {
        this.cityLoader = false;
        if (res.success) {
          this.cityList = res.data;
          this.allCityList = res.data;
          if(this.cityList.length == 0){           
            this.editUploadProfile.controls['city'].clearValidators();
            this.editUploadProfile.controls['city'].updateValueAndValidity();
            this.editUploadProfile.controls['city'].setErrors(null);
            this.editUploadProfile.controls['city'].setValue('');
            
            }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      })
    } 

    if(this.jobseekerId != undefined){
      let stateId: any = 0;
    this.stateList.forEach(element => {
      if (this.editUploadProfile.controls['state'].value == element.name) {
        stateId = element.Id;
        this.state_Id = element.Id;
      }
    });
    return new Promise((resolve, reject) => {
      this.API.city(stateId).subscribe((res: any) => {
        if (res.success) {
          resolve(res.data);
          this.cityList = res.data;
          this.allCityList = res.data;
          if(this.cityList.length == 0){           
            this.editUploadProfile.controls['city'].clearValidators();
            this.editUploadProfile.controls['city'].updateValueAndValidity();
            this.editUploadProfile.controls['city'].setErrors(null);
            this.editUploadProfile.controls['city'].setValue('');
            
            }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
          reject('Error!');
        }
      });
      });
    }
  }

  resetAllForm() {
    this.formInit();
    this.AuthenticationService.jwtTokenExpCheck();
    this.API.candidatedetails(this.jobseekerId).subscribe((res: any) => {
      if (res.success) {
        this.setValueCandidate(res.data);
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }


  OnChangePhoneNo(tab, e) {
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== ''  && e.keyCode != 8) {
      if (tab === 'EP') {
        this.editUploadProfile.controls['phone_mobile'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 'SA') {
        this.editUploadProfile.controls['phone_home'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 'JS') {
        this.editUploadProfile.controls['phone_work'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 'CP') {
        this.editUploadProfile.controls['contact_person'].setValue(npa + '-' + nxx + '-' + last4);
      } else if (tab === 5) {
        this.professionalForm.controls['contact_phone'].setValue(npa + '-' + nxx + '-' + last4);
      }
    } else {
      this.isRequired = false;
    }
  }


  OnChangeChkPhoneNo(tab1, e) {
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== ''  && e.keyCode != 8) {
    if (tab1 === 'CPN') {
      this.professionalForm.controls['contact_person'].setValue(npa + '-' + nxx + '-' + last4);
    }
  }
  }


  emailValidation(e: { target: { value: string; }; }) {
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.jobseekerId == undefined){
      if (e.target.value !== '') {
        this.API.checkmail('', e.target.value).subscribe((res: any) => {
          if (res.success) {
            if (res.data.exists) {
              this.mailError = true;
              this.editUploadProfile.controls['emailId'].setErrors({ 'invalid': true });
            } else {
              this.mailError = false;
            }
          } else {
            this.toastr.errorToastr(res.message, 'Error!');
          }
        });
      }
      else {
        this.mailError = false;
      }
    }
    
    if(this.jobseekerId != undefined){
      if(this.editUploadProfile.controls['emailId'].value != this.RegisteredEmail){
        if (e.target.value !== '') {
          this.API.checkmail(this.jobseekerId, e.target.value).subscribe((res: any) => {
            if (res.success) {
              if (res.data.exists) {
                this.mailError = true;
                this.editUploadProfile.controls['emailId'].setErrors({ 'errors': true });
              } else {
                this.mailError = false;
              }
            } else {
              this.toastr.errorToastr(res.message, 'Error!');
            }
          });
        }
        else {
          this.mailError = false;
        }
      }
      
    }
  }

  mobileValidation(e) {
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.jobseekerId == undefined){
      if (e.target.value !== '') {
        this.API.checkmobile('', e.target.value).subscribe((res: any) => {
          if (res.success) {
            if (res.data.exists) {
              if (e.target.name === 'phone_mobile') {
                this.mobileError = true;
                this.editUploadProfile.controls['phone_mobile'].setErrors({ 'invalid': true });
              }
              if (e.target.name === 'phone_home') {
                this.phoneError = true;
                this.editUploadProfile.controls['phone_home'].setErrors({ 'invalid': true });
              }
            } else {
              this.mobileError = false;
              this.phoneError = false;
            }
          } else {
            this.toastr.errorToastr(res.message, 'Error!');
          }
        });
      } else {
        this.mobileError = false;
        this.phoneError = false;
      }
    }
    
    if(this.jobseekerId != undefined){
      if (e.target.value != '') {
        this.API.checkmobile(this.jobseekerId, e.target.value).subscribe((res: any) => {
          if (res.success) {
            if (res.data.exists) {
              if (e.target.name == 'phone_mobile') {
                this.mobileError = true;
                this.editUploadProfile.controls['phone_mobile'].setErrors({ 'invalid': true });
              }
              if (e.target.name == 'phone_home') {
                this.phoneError = true;
                this.editUploadProfile.controls['phone_home'].setErrors({ 'invalid': true });
              }
            } else {
              this.mobileError = false;
              this.phoneError = false;
            }
          } else {
            this.toastr.errorToastr(res.message, 'Error!');
          }
        });
      }
      else {
        this.mobileError = false;
        this.phoneError = false;
      }
    }
  }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }


   omit_validate_specialchar2(event: { charCode: any; }) {
    const k = event.charCode; 
    this.API.validatespecialchar(k).subscribe((res: any) => {
      if (res) {
        return false;
      }
    });
  }

// 20200929: Akhilesh, Special charcter validation, jira no 70
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;   
     if(k==33 || k==36 || k==37  || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91 
      || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
       this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
       return false;
       }    
      
  }

  
  workauthclick(){        
    this.isValueWorkAuth = false;
  }


  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  salaryValidation(e) {
    if (e.target.value != '') {
      if (e.target.value != 0) {
        this.salaryError = false;
      } else {
        this.salaryError = true;
        this.professionalForm.controls['expected_salary'].setErrors({ 'invalid': true });
      }
    } else {
      this.salaryError = false;
    }
  }

  rateValidation(e) {
    if (e.target.value != '') {
      if (e.target.value != 0) {
        this.rateError = false;
      } else {
        this.rateError = true;
        this.professionalForm.controls['rate'].setErrors({ 'invalid': true });
      }
    } else {
      this.rateError = false;
    }
  }

  setDays(month: string) {
    if (month === '02') {
      this.daysList = [];
      for (let i = 1; i <= 29; i++) {
        if (i < 10) {
          this.daysList.push({ name: '0' + i, value: '0' + i });
        } else {
          this.daysList.push({ name: '' + i, value: '' + i });
        }

      }
    }

    if (month !== '02') {
      this.daysList = [];
      for (let i = 1; i <= 30; i++) {
        if (i < 10) {
          this.daysList.push({ name: '0' + i, value: '0' + i });
        } else {
          this.daysList.push({ name: '' + i, value: '' + i });
        }
      }
    }

    if (month === '01' || month === '03' || month === '05' || month === '07' || month === '08' || month === '10' || month === '12') {
      this.daysList = [];
      for (let i = 1; i <= 31; i++) {
        if (i < 10) {
          this.daysList.push({ name: '0' + i, value: '0' + i });
        } else {
          this.daysList.push({ name: '' + i, value: '' + i });
        }
      }
    }
  }


  selectDocName() {
    var verrifficationnDocs = this.socialForm.value.verrifficationnDocs
    if (verrifficationnDocs = "Any Other") {
      this.isDocToggle = true;
    } else
      this.isDocToggle = false;
  }

  MatchPhoneNo() {
    var verrifficationnDocs = this.editUploadProfile.value.phone_mobile
    var verrifficationnDocs2 = this.editUploadProfile.value.phone_home
    if (verrifficationnDocs === verrifficationnDocs2) {
      this.mobileError1 = true;
    }
    else
      this.mobileError1 = false;
  }
  searchValue = false;
  searchNationality(e) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (e.target.value != '') {
      this.API.searchNationality(e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchValue = true;
        }
        if (res.success) {
          this.nationalityList = res.data;
          if (res.data.length != 0) {
            this.searchValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.nationalityList = this.allNationality;
      this.searchValue = false;
    }

  }

  searchStateValue = false;
  searchState(countryId, e) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (e.target.value != '') {
      this.API.searchState(countryId, e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchStateValue = true;
        }
        if (res.success) {
          this.stateList = res.data;
          if (res.data.length != 0) {
            this.searchStateValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {

      this.stateList = this.allStateList;
      this.searchStateValue = false;
    }

  }



  searchCityValue = false;
  searchCity(stateId, e) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (e.target.value != '') {
      this.API.searchCity(stateId, e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchCityValue = true;
        }
        if (res.success) {
          this.cityList = res.data;
          if (res.data.length != 0) {
            this.searchCityValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.cityList = this.allCityList;
      this.searchCityValue = false;
    }

  }



  selectJobType(data, event) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (event.currentTarget.checked) {
      this.jobSeekerIds.push({
        'id': data.id,
        'name': data.name,
        'selected': true
      });
    }
    else {
      this.jobSeekerData.push({
        'id': data.id,
        'name': data.name,
        'selected': false
      });

      for (var i = this.jobSeekerIds.length - 1; i >= 0; i--) {
        for (var j = 0; j < this.jobSeekerData.length; j++) {
          if (this.jobSeekerIds[i] && (this.jobSeekerIds[i].name === this.jobSeekerData[j].name)) {
            this.jobSeekerIds.splice(i, 1);
          }
        }
      }
    }
    if (this.jobSeekerIds.length <= 0) {
      this.editUploadProfile.controls['jobType'].setErrors({ 'incorrect': true });
      this.isValueJobType = true;
    }
    else if (this.jobSeekerIds.length > 0){
      this.isValueJobType = false;
    }
  }

  checkedUnChecked(candidate: { selected: any; }, event) {
    candidate.selected = event.currentTarget.checked;
  }


  removeError() {
    if(this.jobseekerId == undefined){
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].reset();
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].reset();
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].clearValidators();
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].clearValidators();
    }
  }
  setRequiredEad() {
    if(this.jobseekerId == undefined){
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].setValidators(Validators.required);
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].clearValidators();
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].reset();
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].reset();
    }
  }
  setRequiredNeed() {
    if(this.jobseekerId == undefined){
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].setValidators(Validators.required);
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].clearValidators();
      this.editUploadProfile.controls['WorkAuthDropDownEAD'].reset();
      this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].reset();
    }
  }

  resumePath='';
  isResumeUploaded=false;
  parseUplaodedResume(event) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.selectevent = event;
    if(this.jobseekerId == undefined){
      const fileName = Object.values(event.target.files)[0]['name'];
      const type = event.target.files[0].name.split('.');
      if (type[1] === 'docx'
        || type[1] === 'pdf'
        || type[1] === 'doc') {
          let myData = new FormData();
          let date:string=this.shareservice.convertDateIntoUtc(new Date());
          const current = new Date();
          const timestamp = current.getTime();
          myData.append('file', event.target.files[0]);
          myData.append('date',date );
          const index1file = fileName.substring(0, fileName.lastIndexOf('.'));
          const index2file = fileName.split('.').pop();
          this.customResumeFileName = index1file + "_" + timestamp + "." + index2file;
          this.professionalForm.controls['resume'].setValue(this.customResumeFileName);
        this.UPLOAD_RESUMETOS3(this.customResumeFileName);
        this.resumePath= this.customResumeFileName;
      } else {
        this.isResumeUploaded = false;
        this.toastr.warningToastr('Only .pdf,.doc,.docx Support')
  
      }
    } 
  }

  isSuccess:any;
  //Update resume to s3 Buket
  onSelectUpdateResumeFile(jobSeekerId) {
    this.AuthenticationService.jwtTokenExpCheck();
    let mydata = new FormData();
    // New file name
    mydata.append("NewFileName", this.customResumeFileName);
    mydata.append("FilePath", this.selectevent.target.files[0]);
    mydata.append("UserId", jobSeekerId); 
    mydata.append("AppType",environment.AppType);
    mydata.append("AppRefType", environment.AppRefTypeJobseeker);
    if (this.isResumeUploaded && this.selectevent.target.files[0].size < (2029410 / 2)) {
      this.enterpriseService.updateResumeVD(mydata).subscribe(res => {
        this.isSuccess = res;
        // if (this.isSuccess.success) {    
        //   this.toastr.successToastr(this.isSuccess.message, '', { position: 'top-right' });
        // } else {
        //   this.toastr.errorToastr(this.isSuccess.message, '', { position: 'top-right' });
        // }    
      });
    }

    else {
      this.toastr.errorToastr('Resume file should be less than 1 MB.');
    }
  }


  refrencedate :any
  setValueCandidate(data) {
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.jobseekerId == undefined){
      let thisObj = this;
      Object.keys(data.profile).forEach(function (key) {
        thisObj.fillCandidateForm("editUploadProfile", key, data.profile[key]);
      });
      Object.keys(data.other).forEach(function (key) {
        thisObj.fillCandidateForm("socialForm", key, data.other[key]);
      });
      Object.keys(data.professional).forEach(function (key) {
        thisObj.fillCandidateForm("professionalForm", key, data.professional[key]);
      });
      let skillsArray = [];
      data.skills.forEach(function (obj: { skill: string | number; }) {
        skillsArray.push(obj.skill);
      });
      thisObj.fillCandidateForm("skillsForm", 'skill', skillsArray.join());
      thisObj.fillCandidateForm('editUploadProfile', 'vectorfile', data.vectorfile);
    }
   
    if(this.jobseekerId != undefined){
      var docsname = '';
      if(data.docs != undefined){
        if (data.docs.length > 0) {
          docsname = data.docs[0].docName;
        }
      }
      else{
        docsname = '';
      }
      
      this.docsList = [];
      this.nationality().then(naData => {
        this.nationalityList = naData;
        if (data.details.citizenship != null && data.details.citizenship != '') {
          this.editUploadProfile.controls['citizenship'].setValue(data.details.citizenship);
        }
      });
      if (data.details.isVisa) {
        this.isVisaToggle = true;
      } else {
        this.isVisaToggle = false;
      }
      if (data.details.jobtype != "" && data.details.jobtype != null) {
        this.jobData = data.details.jobtype.split(',');
        for (var i = 0; i < this.jobData.length; i++) {
          this.jobSeekerIds.push({
            'name': this.jobData[i],
            'selected': true
          });
        }
        for (var i = this.jobType.length - 1; i >= 0; i--) {
          for (var j = 0; j < this.jobSeekerIds.length; j++) {
            if (this.jobType[i] && (this.jobType[i].name === this.jobSeekerIds[j].name)) {
              this.jobType.splice(i, 1);
            }
          }
        }
  
        for (var i = 0; i < this.jobData.length; i++) {
          this.jobType.push({
            'id': this.jobData[i],
            'name': this.jobData[i],
            'selected': true
          });
        }
      }
  
      this.isValueWorkAuth = false;
      if (this.jobData.length > 0) {
        this.editUploadProfile.controls['jobType'].clearValidators();
        this.editUploadProfile.controls['jobType'].updateValueAndValidity();
      }
      this.jobseekerId = data.details.jsId;
      this.profileImage = data.details.profileImage;
      this.editUploadProfile.controls['firstName'].setValue(data.details.firstName);
      this.editUploadProfile.controls['middleName'].setValue(data.details.middleName);
      this.editUploadProfile.controls['lastName'].setValue(data.details.lastName);
      this.editUploadProfile.controls['emailId'].setValue(data.details.emailId);
      this.editUploadProfile.controls['phone_mobile'].setValue(data.details.phone_mobile);
      this.editUploadProfile.controls['ssnNo'].setValue(data.details.ssnNo);
     
      if (data.details.dob != null && data.details.dob != '') {
        this.editUploadProfile.controls['dob_month'].setValue(data.details.dob.split('-')[0]);
        this.editUploadProfile.controls['dob_day'].setValue(data.details.dob.split('-')[1]);
        this.editUploadProfile.controls['dob_year'].setValue(parseInt(data.details.dob.split('-')[2]));
        this.setDays(data.details.dob.split('-')[0]);
  
      }
      this.editUploadProfile.controls['phone_home'].setValue(data.details.phone_home);
      this.editUploadProfile.controls['extn_no'].setValue(data.details.extn_no);
      this.editUploadProfile.controls['phone_work'].setValue(data.details.phone_work);
      this.countryFn().then(res => {
  
        this.countryList = res;
        if (data.details.country != null && data.details.country != '') {
          this.editUploadProfile.controls['country'].setValue(data.details.country);
  
        }
        this.countryChange().then(data1 => {
          this.stateList = data1;
          if (data.details.state != null && data.details.state != '') {
            this.editUploadProfile.controls['state'].setValue(data.details.state);
          }
          this.stateChange().then(stData => {
            this.cityList = stData;
            if (data.details.city != null && data.details.city != '') {
              this.editUploadProfile.controls['city'].setValue(data.details.city);
            }
          });
        }).catch(err => {
          //console.log(err);
        });
      }).catch(err => {
        //console.log(err);
      });
      this.editUploadProfile.controls['zipCode'].setValue(data.details.zipCode);
      this.editUploadProfile.controls['streetName'].setValue(data.details.streetName);
      this.editUploadProfile.controls['profileImage'].setValue(data.details.profileImage);
      this.editUploadProfile.controls['isVisa'].setValue(data.details.isVisa);
      this.editUploadProfile.controls['visaType'].setValue(data.details.visaType);
      this.editUploadProfile.controls['WorkAuth'].setValue(data.details.visaType);
      if (this.checkWA != 'US Citizen' && this.checkWA != 'Green Card' && this.checkWA != 'H1B') {
        this.editUploadProfile.controls['WorkAuthDropDownEAD'].setValue(data.details.visaType);
        this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].setValue(data.details.visaType);
        const checkEAD = this.editUploadProfile.controls['visaType'].value;
        if (checkEAD == 'CPT EAD' || checkEAD == 'OPT EAD' || checkEAD == 'GC EAD' || checkEAD == 'L2 EAD' || checkEAD == 'H4 EAD' || checkEAD == 'TN Visa' || checkEAD == 'E3 Visa') {
  
          this.editUploadProfile.controls['WorkAuth'].setValue('EAD');
          this.isvisibleEAD = false;
        }
        if (checkEAD == 'B1/B2' || checkEAD == 'L1' || checkEAD == 'F1' || checkEAD == 'F2' || checkEAD == 'J1' || checkEAD == 'OTHER VISA') {
  
          this.editUploadProfile.controls['WorkAuth'].setValue('NeedNewH1B');
          this.isvisibleNeedNewH1B = false;
        }
      }
      else {
        this.editUploadProfile.controls['WorkAuthDropDownEAD'].reset();
        this.editUploadProfile.controls['WorkAuthDropDownNewH1B'].reset();
        this.isvisibleEAD = false;
        this.isvisibleNeedNewH1B = false;
      }
      this.editUploadProfile.controls['SecurityClearance'].setValue(data.details.SecurityClearance);
      this.editUploadProfile.controls['validTill'].setValue(data.details.validTill);
  
      this.socialForm.controls['facebookUrl'].setValue(data.details.facebookUrl);
      this.socialForm.controls['twitterUrl'].setValue(data.details.twitterUrl);
      this.socialForm.controls['linkdinUrl'].setValue(data.details.linkdinUrl);
      this.socialForm.controls['blogUrl'].setValue(data.details.blogUrl);
      this.socialForm.controls['voiceSample'].setValue(data.details.voiceSample);
      this.voiceFileName = data.details.voiceSample;
      this.docName = docsname;
      if (docsname == '' || docsname == undefined) {
        this.docName = '';
      }
      this.socialForm.controls['docIds'].setValue(data.details.docIds);
      this.socialForm.controls['educationFile'].setValue(data.details.educationFile);
  
      this.skillsForm.controls['skill'].setValue(data.details.skill);
      this.skillsForm.controls['suggested_keywords'].setValue(data.details.suggested_keywords);
      this.skillsForm.controls['resumeSummary'].setValue(data.details.resumeSummary);
      this.isUsDegree = data.details.us_master_degree;
      if (this.isUsDegree == true) {
        this.professionalForm.controls['us_master_degree'].setValue("true");
      }
      else if (this.isUsDegree == false) {
        this.professionalForm.controls['us_master_degree'].setValue("false");
      }
  
      this.professionalForm.controls['highest_degree'].setValue(data.details.highest_degree);
      this.professionalForm.controls['us_master_degree'].setValue(String(data.details.us_master_degree));
      this.professionalForm.controls['expected_salary'].setValue(data.details.expected_salary);
      this.professionalForm.controls['rate'].setValue(data.details.rate);
      this.professionalForm.controls['current_job_title'].setValue(data.details.role);
      this.professionalForm.controls['total_experience'].setValue(data.details.total_experience);
      this.professionalForm.controls['resume_title'].setValue(data.details.resume_title);
      this.professionalForm.controls['resume'].setValue(data.details.resume);    
      this.isShow = true;
      //console.log("resumename",data.details.resume);
      this.resumeName = data.details.resume;
      localStorage.setItem('resumefileName', data.details.resume);
      //check resume file exits    
      this.ResumeFileExits(data.details.resume);
  
      //  resume preview doc
      this.doc =  this.baseUrlDoc +  data.details.resume;   
      //this.doc = "https://verisourceapi-staging.veriklick.in/api/enterprise/image/resume/nikhil_kumar_undefined_121120_08_icw4zckik3sbyl.doc";
      //console.log("doc=== ",this.doc);
      //console.log("ResumeFilePAth=== ",this.baseUrlDoc +  data.details.resume);
      this.refrencedate = data.details.ref_date;
      this.professionalForm.controls['companyName'].setValue(data.details.companyName);
      this.professionalForm.controls['contact_person'].setValue(data.details.contact_person);
      this.professionalForm.controls['contact_phone'].setValue(data.details.contact_phone);
      this.professionalForm.controls['contact_email'].setValue(data.details.contact_email);
      this.professionalForm.controls['refDate'].setValue(data.details.ref_date);
      //Manu Datta: 20210331, VerisourceBugSheetAfterMerge/commonLogin 503
      if (this.professionalForm.controls['refDate'].value == "") {
        this.professionalForm.controls['refDate'].markAsTouched();
      }
      this.professionalForm.controls['refDate'].setErrors(null);
      this.professionalForm.controls['jobTitle'].setValue(data.details.jobTitle);
      this.professionalForm.controls['re_location'].setValue(data.details.re_location);
      this.professionalForm.controls['recentEmployer'].setValue(data.details.recentEmployer);
      this.editUploadProfile.controls['availabilityJoin'].setValue(data.details.availabilityJoin);
      this.editUploadProfile.controls['availabilityTime'].setValue(data.details.availabilityTime);
      this.professionalForm.controls['currency'].setValue(data.details.currency);
      this.professionalForm.controls['frequency'].setValue(data.details.frequency);
  
     // if (data.details.profileImage !== '') {
        // this.imgProfile = `${this.serverpth}/api/staffingagency/image/profileimage/${data.details.profileImage}`;
        // this.imageName = data.details.profileImage;
        // this.ImageFileExits(data.details.profileImage);
        
       // this.imgProfile =   this.userData1.data.credentials.profileImagePath;
      // } else {
      //   this.imgProfile = '';
      // }
      if (data.docs.length > 0) {
        data.docs.forEach(element => {
          this.docsList.push(element);
        });
      }
    }
  }

  nationality() {
    this.AuthenticationService.jwtTokenExpCheck();
    return new Promise((resolve, reject) => {
      this.API.nationality().subscribe((res: any) => {
        if (res.success) {
          this.nationalityList = res.data;
          this.allNationality = this.nationalityList;
          resolve(res.data);
        } else {
          reject('Error!');
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    });
  }


  //Akhilesh:20200912, file check exits on root folder
  ResumeFileExits(filename:any) { 
    this.AuthenticationService.jwtTokenExpCheck();
    this.resumeFile = localStorage.getItem("resumefileName");
    //console.log("resumeFile ==", this.resumeFile);
    //console.log("filename---",filename);
    this.API.fileExists("resume",filename).subscribe((res: any) => {
      //console.log("filecheck", res);
      if (res.data == true) {       
        this.isResumeExits = true;
      }
    });
  }

  //Akhilesh:20200912, file check exits on root folder
  ImageFileExits(imgname) {  
    this.AuthenticationService.jwtTokenExpCheck(); 
    //console.log("imageName ==", this.imageName,imgname);
    this.API.fileExists("profileimage",imgname).subscribe((res: any) => {
      //console.log("filecheckImg", res);
      if (res.data == true) {       
        this.isImgExits = true;
      }
    });
  }

  countryFn() {
    this.AuthenticationService.jwtTokenExpCheck();
    return new Promise((resolve, reject) => {
      this.API.country().subscribe((res: any) => {
        if (res.success) {
          this.countryList = res.data;
          resolve(res.data);
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
          reject('error');
        }
      });
    });
  }

  selectVissa() {
    const validTills = this.editUploadProfile.get('validTill');
    this.editUploadProfile.controls['isVisa'].value ?
      (
        this.isVisaToggle = true,
        this.nextButton = true,
        this.isRequired = true,
        validTills.setValidators(Validators.required),
        this.editUploadProfile.controls['visaType'].reset(),
        this.editUploadProfile.controls['validTill'].reset()
      ) :
      (
        this.isVisaToggle = false,
        this.isRequired = false,
        this.nextButton = true,
        validTills.clearValidators(),
        this.editUploadProfile.controls['visaType'].reset(),
        this.editUploadProfile.controls['validTill'].reset()
      );
    validTills.updateValueAndValidity();
  }

  fillCandidateForm(formName: string, fieldName: string, fieldValue: any) {
    if(this.jobseekerId == undefined){
      if (fieldValue != null) {
        this[formName].controls[fieldName].setValue(fieldValue);
      }
  
      if (formName == 'professionalForm' && fieldName == 'contact_email') {
        this.professionalForm.controls['contact_email'].setValue('');
      }
      if (formName == 'professionalForm' && fieldName == 'contact_phone') {
        this.professionalForm.controls['contact_phone'].setValue('');
      }
    }
  }
  enableResume() {
    if(this.jobseekerId == undefined){
      const firstName = this.editUploadProfile.controls['firstName'].value;
      const lastName = this.editUploadProfile.controls['lastName'].value;
      if (firstName != "" && lastName != "") {
        this.resumedisable = false;
      }
    }
  }

  //20201104:Akhilesh,Search country api
  searchCountryValue = false;
  searchCountry(e) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (e.target.value != '') {
      this.API.searchCountry(e.target.value).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.searchCountryValue = true;
        }
        if (res.success) {
          this.countryList = res.data;
          if (res.data.length != 0) {
            this.searchCountryValue = false;
          }
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.countryList = this.allCountryList;
      this.searchCountryValue = false;
    }
  }

  JobseekerResume(id) {       
    this.AuthenticationService.jwtTokenExpCheck();
    if(this.jobseekerId != undefined){
    this.preLoader = true;
    const _promisses = new Promise((resolve, reject) => {        
      //let seekerId=  this.jobseekerId.substring(6);
      this.API.jobseekerProfileResume(id).subscribe(res => {      
        this.response = res;    
        if(this.response.data.length > 0){               
        var resumename = this.resume_name = this.response.data[0].fileName;
        var resumepath = this.resume_path = this.response.data[0].filePath;
        localStorage.setItem('resumename',  resumename); 
        localStorage.setItem('resumepath',  resumepath); 
        resolve(res);       
        this.preLoader = false;
        }
      }, err => {
        reject(err);
      });
    });
      return _promisses;
  }
  }

  selectevent: any;
  result: any;
  customResumeFileName: any = '';
  UPLOAD_RESUMETOS3(customResumeFileName) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (customResumeFileName != null) {
      // const filename = this.resumeFileName.split('/');
      // const name = filename[3];

      if (customResumeFileName.length > 0) {
        const formData = new FormData();
        formData.append("FilePath", this.selectevent.target.files[0]);
        formData.append("NewFileName", customResumeFileName);

        this.subscriptions.push(this.staffAPI.POSTJOBSEEKER_DOCUMENTTOS3_VDIAL(formData).subscribe(res => {
          //console.log('************',res);
          this.result = res;
          this.resumePath= customResumeFileName;
          if (this.result.success) {
            // this.isUploadShowbtn = true;
            this.resumeLoader=false;
            // this.isShowbtn = false;
            //this.formModel.professional.resume = this.customResumeFileName;
            this.professionalForm.controls['resume'].setValue(this.customResumeFileName);

            this.toastr.successToastr("Resume Uploaded Successfully!");
            this.isResumeUploaded = true;
            this.GetCandaiteResumeFromS3();

          } else {
            this.toastr.successToastr("Resume Not Uploaded Successfully!,Something Went Wrong", 'errorClass');
            this.professionalForm.controls['resume'].setValue('');
            this.isResumeUploaded = false;
          }

        }));
      }

    }
  }

  GetCandaiteResumeFromS3() {
    if (this.resumePath != null) {
      this.customResumeFileName = this.resumePath;
      if (this.resumePath.length > 0) {
        let body = {
          "FileName": this.resumePath,
        }
        this.subscriptions.push(this.staffAPI.GETJOBSEEKER_S3DOCUMENTURL( body).subscribe((res: any) => {
          this.ProfileData = res;
          //console.log('------------',this.ProfileData);
          if (this.ProfileData.success) {
            //this.resume_path = "'" + this.ProfileData.data.fileName + "'";
             this.resume_path = this.ProfileData.data.fileName;
             //this.pageValue = true;
          }
        }));
      }
    }
  }

  
}


