import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
//import { environment } from '../../environments/environment'
import { Title } from '@angular/platform-browser';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { EnterpriseService } from '../enterprise.service';
import { environment } from '../../../environments/environment';
import {AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
import { TableService } from 'src/app/admin/service';
import { ConfirmDeleteComponent } from 'src/app/share-module/popUp/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-staffing-agency-details',
  templateUrl: './staffing-agency-details.component.html',
  styleUrls: ['./staffing-agency-details.component.css']
})
export class StaffingAgencyDetailsComponent implements OnInit {
  @ViewChild('myFileInput',{static:false}) myFileInput;
  defaultPageSize=5;
  defaultPageNumber=1;
  paginator = {
    pageSize: this.defaultPageSize,
    length: 0,
    pageSizeOptions: [5, 10, 15, 20],
    pageIndex:0
    
  }
  allCityList: any = [];
  allStateList: any = [];
  allCountryList:any =[];
  basicupdate: any;
  isEditform = false;
  islinkform  = false;
  preloader = true;
  isverifyBtn: boolean = false;
  issuccess: any;
  staffAgID: String = "";
  staffAData: any;
  ProfileData:any;
  staffADataDetails: any;
  staffAdmindata: any;
  detailPage: boolean = false;
  jobseekerId: String = "";
  synch: boolean = false;
  isCollapsedMenu: boolean = false;
  s_img_Path: string = '';
  c_img_Path: string = '';
  showData:boolean=false;
  showCandidateData:boolean=true;
  addressFrom: FormGroup;
  isAddressFrom  = false;
  Profilelist: any;
  countryList: any = [];
  allcountryList: any = [];
  stateLoader = false;
  stateList: any = [];
  allstateList: any = [];
  cityLoader = false;
  cityList: any = [];
  allcityList: any = [];
  CountryId: any = 0;
  stateId: any = 0;
  resetForm : FormGroup;
  notMatch: boolean = false;
  isAddform: boolean = false;
  cityId: any = 0;
  addFrmUpdateResp: any;
  submitted = false;
  submitted2 = false;
  staffEditForm2: FormGroup;
  staffEditFormDetails: FormGroup;
  loading:boolean=false;
  imgName: any;
  isImgExits: boolean = false;
  userData: any;
  profileLoader: boolean = false;
  resImageData: any;
  imgProfile: any;
  userData1: any;
  imageFileEvent: any;
  mobMenulist() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
  }

  constructor(private _router: Router, private _paramVal: ActivatedRoute, private tostr: ToastrManager,private _titleService:Title,
  private AuthenticationService: AuthenticationService,private _formBuilder: FormBuilder,private api: EnterpriseService,private shareservice:ShareService,
  private staffAPI: staffingAgencyServices,private tableService:TableService,public dialog: MatDialog) {

    this.AuthenticationService.jwtTokenExpCheck();
    this.userData1 = JSON.parse(window.localStorage.getItem('udata'));
    this.staffAgID = this._paramVal.snapshot.params['id'];

    this.staffEditForm2 = this._formBuilder.group({
      'twitterUrl': [''],
      'facebookUrl': [''],
      'linkdinUrl': ['']
    });

    
         
    this.staffEditFormDetails = this._formBuilder.group({
      'companyName': ['',[Validators.required]],
      'firstName': ['', [Validators.required]],
      'middleName': [''],
      'lastName': ['',[Validators.required]],
      'contactNo': ['', Validators.required],
      // 'emailId': ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
    //  'website': ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      'modifiedBy':[''],
      'emailId': ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      'website': ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      'staffingIdVD':['']
    });

    //for title set 
    var newTitle = 'Verisource | Supplier Details';
    this._titleService.setTitle( newTitle );
    // this.staffAgID = this._paramVal.snapshot.params['id'];

    this.addressFrom = this._formBuilder.group({
      'country': ['', Validators.required],
      'state': ['', Validators.required],
      'city': ['', Validators.required],
      'zipCode': ['', Validators.required],
      'street': ''
    });

    this.resetForm = this._formBuilder.group({
      
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      re_password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      createdDate:['']
    });
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
  }

  get sf() { return this.staffEditFormDetails.controls; }
  
  ngOnInit() {
    //this.staffAgID = this._paramVal.snapshot.params['id'];
    this.loadStaffingDetails();
    this.profileImage();
    this.loadAllstaffingcandidates(this.defaultPageNumber, this.defaultPageSize);
  }

  profileImage() {  
    this.AuthenticationService.jwtTokenExpCheck();
    this.preloader = true;    
      //let id = this.userData.data.credentials.userId;
      this.staffAPI.profileImageVD(this.staffAgID).subscribe(res => {        
        
        this.ProfileData = res; 
        if(this.ProfileData.data.length > 0){   
        var profileImagePath = this.s_img_Path =this.ProfileData.data[0].profileImagePath;         
        localStorage.setItem('profileImagePath', profileImagePath);      
       
        this.preloader = false;
        }
      
      });
    
  }

  loadStaffingDetails() {
    this.showData=false;
    this.preloader = true;
   // this.staffAgID = this._paramVal.snapshot.params['id'];
   this.AuthenticationService.jwtTokenExpCheck();
    this.api.staffingagencyDetailview(this.staffAgID).pipe(first()).subscribe(res => {
     
      if (res.success) {
        this.showData=true;
        this.staffAData = res.data;
        //console.log('this.staffAData', this.staffAData)
        this.preloader = false;
        if (this.staffAData.staffingdetails.profile_status == 'Verified') {
          this.isverifyBtn = true;
        }
        else if (this.staffAData.staffingdetails.profile_status == 'Non-Verified') {
          this.isverifyBtn = false;
        }
        // this.s_img_Path = `${environment.url}/api/admin/image/profileimage/${this.staffAData.staffingdetails.profileImage}`;
        // this.imgName = this.staffAData.staffingdetails.profileImage;
        // this.ImageFileExits(this.staffAData.staffingdetails.profileImage);
      }
    });
  }

  loadAllstaffingcandidates(pageno, pagesize) {
    
    this.showCandidateData=false;
    this.AuthenticationService.jwtTokenExpCheck();
    this.api.staffingcandidates(pageno, pagesize, this.staffAgID).pipe(first()).subscribe(res => {
      if (res.success) {
        this.showCandidateData=true;
        this.staffAdmindata = res.data;
        if (this.staffAdmindata.length > 0) {
          this.paginator.length = this.staffAdmindata[0].totalCount;
          
        }
      }
    });
  }

  getchildpaginatorData(e) {
    
    this.paginator.pageSize=e.pageSize;
    this.paginator.pageIndex=e.pageIndex;
    this.AuthenticationService.jwtTokenExpCheck();
    this.loadAllstaffingcandidates(e.pageIndex + 1, e.pageSize)
    //console.log(e.pageIndex);
    //console.log(e.pageSize);

  }
//this function is not use in this component 
  // viewData(id, e) {
  //   this.synch = false;
  //   e.preventDefault();
  //   this.detailPage = true;
  //   this.api.candidateDetails(id).pipe(first()).subscribe(stffingagency => {
  //     this.staffADataDetails = stffingagency;
  //     this.c_img_Path = `${this.staffADataDetails.filePath}${this.staffADataDetails.profileImage}`;
  //     this.synch = true;
  //   });
  // }

  download_doc(data, val) {
    if (val == 'resume') {
      window.location.href = `${data.resumepath}`;
    }
    if (val = 'voice') {
      window.location.href = `${data.voicesamplepath}`;
    }
  }

  jobseekerView(id) {
    this._router.navigate(['/ep/enterprise/jobseeker-view', id])
  }

  verifystaffingAgency(staff) {
    this.preloader = true;
    this.AuthenticationService.jwtTokenExpCheck();
    this.api.verifyenterpriseDetails(staff, 'SA', 0).pipe(first()).subscribe(res => {
      this.issuccess = res;
      this.preloader = false;
      if (this.issuccess.success) {
        this.tostr.successToastr(this.issuccess.message, "Success!");
        this.issuccess = false;
        this.isverifyBtn = true;
      } else {
        this.tostr.errorToastr(this.issuccess.message, 'OPPS!')
      }
    },(errorResponse: any) => {     
      this.preloader = false;
      if (errorResponse instanceof HttpErrorResponse) {
        if (errorResponse.status === 422) {
           if(errorResponse['error'].errors){
             this.tostr.errorToastr(errorResponse['error'].errors[0].msg)
           }
        }
        else{
          this.tostr.errorToastr(errorResponse['message']);
        }
      }
     });    
  }

  get addFrm() { return this.addressFrom.controls; }

  modalStaffingAddressPopup() {
    this.preloader=true;
    this.AuthenticationService.jwtTokenExpCheck();
    this.addressFrom.controls['country'].setValue(this.staffAData.staffingdetails.country);
    this.addressFrom.controls['state'].setValue(this.staffAData.staffingdetails.state);
    this.addressFrom.controls['city'].setValue(this.staffAData.staffingdetails.city);
    this.addressFrom.controls['zipCode'].setValue(this.staffAData.staffingdetails.zipCode);
    this.addressFrom.controls['street'].setValue(this.staffAData.staffingdetails.street);
    this.preloader=false;
this.api.country().subscribe((res: any) => {
  if (res.success) {
    this.countryList = res.data;
    this.allcountryList = res.data;
    this.countryChange(true);
    // this.stateChange(true);
    this.isAddressFrom = !this.isAddressFrom;
  } else {
    this.tostr.errorToastr(res.message, 'Error!');
  }
});
}

countryChange(type:Boolean) {
  this.countryList.forEach((element: { name: any; Id: any; }) => {
    if (this.addressFrom.controls['country'].value === element.name) {
      this.CountryId = element.Id;
    }
  });
  if(!type){
    this.addressFrom.controls['state'].setValue('');
    this.addressFrom.controls['city'].setValue('');
    this.addressFrom.get('state').setValidators(Validators.required);
    this.addressFrom.get('state').updateValueAndValidity();
    this.addressFrom.get('city').updateValueAndValidity();
  }
  
  this.stateLoader = true;
  this.api.state(this.CountryId).subscribe((res: any) => {
    this.stateLoader = false;
    if (res.success) {
      this.stateList = res.data;
      this.allstateList = res.data;
      this.stateList.forEach((element: { name: any; Id: any; }) => {
        if (this.addressFrom.controls['state'].value === element.name) {
          this.stateId = element.Id;
        }
      });
      this.stateChange(type);
    } else {
      this.tostr.errorToastr(res.message, 'Error!');
    }
  });
}

stateChange(formType:Boolean) {
  this.cityLoader = true;
  this.stateList.forEach((element: { name: any; Id: any; }) => {
    if (this.addressFrom.controls['state'].value === element.name) {
      this.stateId = element.Id;
    }
  });
  if(!formType){
    this.addressFrom.controls['city'].setValue('');
    this.addressFrom.get('city').setValidators(Validators.required);
    this.addressFrom.get('city').updateValueAndValidity();
  }
  
  this.api.city(this.stateId).subscribe((res: any) => {
    this.cityLoader = false;
    if (res.success) {
      this.cityList = res.data;
      this.allcityList=res.data;
      if(this.cityList.length == 0){           
        this.addressFrom.controls['city'].clearValidators();
        this.addressFrom.controls['city'].updateValueAndValidity();
        this.addressFrom.controls['city'].setErrors(null);
        this.addressFrom.controls['city'].setValue('');
        
        }
      this.cityList.forEach((element: { name: any; Id: any; }) => {
        if (this.addressFrom.controls['city'].value === element.name) {
          this.cityId = element.Id;
        }
      });
    } else {
      this.tostr.errorToastr(res.message, 'Error!');
    }
  });
}

removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
  const startPos = e.currentTarget.selectionStart;
  if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
  }
}

SaveOrUpdateAddressDetails() {
  this.AuthenticationService.jwtTokenExpCheck();
  this.isAddressFrom = true;
  if (this.addressFrom.valid) {
    this.preloader=true;
    this.isAddressFrom = false;
    this.api.UpdateStaffingAgencyAddress(this.addressFrom.value,this.staffAData.staffingdetails.empId).subscribe(res => {
      this.addFrmUpdateResp = res;
      if (this.addFrmUpdateResp.success) {
        this.loadStaffingDetails();
        this.isAddressFrom = false;
        this.preloader=false;
        this.submitted = false;
        this.tostr.successToastr(this.addFrmUpdateResp.message, '', { position: 'top-right' });
      } else {
        this.preloader=false;
        this.isAddressFrom = true;
        this.tostr.errorToastr(this.addFrmUpdateResp.message, '', { position: 'top-right' });
      }
    },(errorResponse: any) => {     
      this.preloader = false;
      this.isAddressFrom = true;
      if (errorResponse instanceof HttpErrorResponse) {
        if (errorResponse.status === 422) {
          if(errorResponse['error'].errors){
            this.tostr.errorToastr(errorResponse['error'].errors[0].msg)
          }
        }
        else{
          this.tostr.errorToastr(errorResponse['message']);
        }
      }
     });
  } else {
    return false;
  }
}

modal_staffinglink_basicdetails() {
  this.preloader=true;
  this.AuthenticationService.jwtTokenExpCheck();
   this.staffEditFormDetails.controls['companyName'].setValue(this.staffAData.staffingdetails.companyName);
   this.staffEditFormDetails.controls['firstName'].setValue(this.staffAData.staffingdetails.firstName);
   this.staffEditFormDetails.controls['middleName'].setValue(this.staffAData.staffingdetails.middleName);
   this.staffEditFormDetails.controls['lastName'].setValue(this.staffAData.staffingdetails.lastName);
   this.staffEditFormDetails.controls['emailId'].setValue(this.staffAData.staffingdetails.emailId);
   this.staffEditFormDetails.controls['contactNo'].setValue(this.staffAData.staffingdetails.contactNo);
   this.staffEditFormDetails.controls['website'].setValue(this.staffAData.staffingdetails.website);
   this.isEditform = !this.isEditform;
   this.preloader=false;
}


  modal_staffinglink_popup() {
    this.preloader=true;
    this.AuthenticationService.jwtTokenExpCheck();
    this.staffEditForm2.controls['twitterUrl'].setValue(this.staffAData.staffingdetails.twitterUrl);
    this.staffEditForm2.controls['facebookUrl'].setValue(this.staffAData.staffingdetails.facebookUrl);
    this.staffEditForm2.controls['linkdinUrl'].setValue(this.staffAData.staffingdetails.linkdinUrl);
    this.preloader=false;
    this.islinkform = !this.islinkform;
  }

  sociallinkSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    if (this.staffEditForm2.valid) {
      this.islinkform=false;
      this.preloader=true;
      this.api.profileSocialUpdate1(this.staffEditForm2.value,this.staffAData.staffingdetails.empId).subscribe(res => {
        this.basicupdate = res;
        if (this.basicupdate.success) {
          this.loadStaffingDetails();
          this.islinkform = false;
          this.preloader=false;
          this.tostr.successToastr(this.basicupdate.message, '', { position: 'top-right' });
        } else {
          this.islinkform=true;
          this.preloader=false;
          this.tostr.errorToastr(this.basicupdate.message, '', { position: 'top-right' });
        }
      });
    } else {
      return false;
    }
  }

  staffingFormdetals() {
    this.AuthenticationService.jwtTokenExpCheck();
    let userid=this.userData.data.credentials.userId
    this.staffEditFormDetails.controls['modifiedBy'].setValue(userid);
    this.submitted2 = true;
    var employerid: number=Number(this.staffAData.staffingdetails.empId)
    //console.log('employerid',employerid);
    if (this.staffEditFormDetails.valid) {             
    this.isEditform=false;
    this.preloader=true;
    this.staffEditFormDetails.controls['staffingIdVD'].setValue(this.staffAData.staffingdetails.employeeId);
    
      this.api.profileBasicUpdates('SA',this.staffEditFormDetails.value,this.staffAData.staffingdetails.stId,this.staffAData.staffingdetails.empId).subscribe(res => {     
        this.basicupdate = res;
        if (this.basicupdate.success) {
          this.api.profileBasicUpdatesVD(this.staffEditFormDetails.value,employerid,environment.AppRefTypeSupplier).
          subscribe(res => { 
            this.basicupdate = res;
            this.staffEditFormDetails.controls['staffingIdVD'].setValue(this.staffAData.staffingdetails.employeeId);
            if (this.basicupdate.success) {
              this.onSelect(this.imageFileEvent);
              //this.loadStaffingDetails();
              // this.profileImage();
                this.isEditform = false;
                this.preloader=false;
              this.islinkform = false;
              this.tostr.successToastr(this.basicupdate.message, '', { position: 'top-right' });
              window.location.reload();
            }
            else {
              this.preloader=false;
              this.isEditform=true;
              return false;
            }
          });
          
        } else {
          this.preloader=false;
          this.isEditform=true;
          this.tostr.errorToastr(this.basicupdate.message, '', { position: 'top-right' });
        }
      },(errorResponse: any) => {     
        this.preloader = false;
        this.isEditform = true;
        if (errorResponse instanceof HttpErrorResponse) {
          if (errorResponse.status === 422) {
             if(errorResponse['error'].errors){
               this.tostr.errorToastr(errorResponse['error'].errors[0].msg)
             }
          }
          else{
            this.tostr.errorToastr(errorResponse['message']);
          }
        }
       });      
      }
    else {
      this.preloader=false;
      return false;
    }
  
  }


  
  OnChangePhoneNo(field_no, e) {
    let f_val  = '';
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== ''  && e.keyCode != 8) {
    if (field_no === 1) {
      this.staffEditFormDetails.controls['contactNo'].setValue(npa  + '-' + nxx + '-' + last4);
    } else if (field_no === 2) {

    }
  }
  }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  
  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }
  
  sfsubmitted=false;
  resetPasswordform(){
    this.AuthenticationService.jwtTokenExpCheck();
    this.sfsubmitted = false;
    this.resetForm = this._formBuilder.group({
      
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      re_password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      createdDate:['']
    });

    this.resetForm.controls['password'].setErrors({ 'invalid': false });
    this.resetForm.controls['re_password'].setErrors({ 'invalid': false });
    this.notMatch = false;
    this.isAddform = !this.isAddform;
  }

  onSubmit() {
    this.isAddform=false;
    this.preloader=true;
    this.AuthenticationService.jwtTokenExpCheck();
    this.sfsubmitted = true;
    if (this.resetForm.controls['password'].value === this.resetForm.controls['re_password'].value) {
      this.notMatch = false;
      if (this.resetForm.valid) {
        this.loading = true;
        try {
          let params = {
            Email: this.staffAData['staffingdetails'].emailId,
            Password: this.resetForm.controls['password'].value,
            code: ''
          };
          this.resetForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

            this.tableService.resetPasswordVDInAdmin(params).subscribe((res: any) => {
            this.loading = false;
            this.preloader=false;
            if (res.success) {
              this.tostr.successToastr(res.message, 'Done!');
            } else {
              this.tostr.errorToastr(res.message, 'Error!');
            }
          });
        } catch (error) {
          this.preloader=false;
          throw error;
        }
        this.isAddform = false;
      } else {
        this.isAddform=true;
        this.preloader=false;
        return false;
      }
    } else {
      this.isAddform=true;
      this.preloader=false;
      this.notMatch = true;
      return false;
    }
  }

  confirm() {
    this.AuthenticationService.jwtTokenExpCheck();
     const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
       width: 'auto',
       //disableClose: true,
       hasBackdrop: false,
       data: {Email:this.staffAData['staffingdetails'].emailId}
     });
     dialogRef.afterClosed().subscribe(result => {
       //console.log('The dialog was closed');
       if (result != 'close') {
       }
     });
  }
  
  get f() {
    return this.resetForm.controls;
  }

//Akhilesh:20200912, file check exits on root folder
  ImageFileExits(imgname) {   
    this.api.fileExists("profileimage",imgname).subscribe((res: any) => {
      if (res.data == true) {       
        this.isImgExits = true;
      }
    });
  }
  // 20201010: Akhilesh, Special charcter validation, jira no 118(1)
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;   
     if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
       this.tostr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
       return false}
     }

     
  searchStateValue = false;
  searchState(countryId, e) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (e.target.value != '') {
      this.api.searchState(countryId, e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchStateValue = true;
        }
        if (res.success) {
          this.stateList = res.data;
          if (res.data.length != 0) {
            this.searchStateValue = false;
          }
        } else {
          this.tostr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {

      this.stateList = this.allstateList;
      this.searchStateValue = false;
    }

  }

  
  searchCityValue = false;
  searchCity(stateId, e) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (e.target.value != '') {
      this.api.searchCity(stateId, e.target.value).subscribe((res: any) => {

        if (res.data.length == 0) {
          this.searchCityValue = true;
        }
        if (res.success) {
          this.cityList = res.data;
          if (res.data.length != 0) {
            this.searchCityValue = false;
          }
        } else {
          this.tostr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.cityList = this.allcityList;
      this.searchCityValue = false;
    }

  }

//20201104:Akhilesh,Search country api
  searchCountryValue = false;
  searchCountry(e) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (e.target.value != '') {
      this.api.searchCountry(e.target.value).subscribe((res: any) => {
        if (res.data.length == 0) {
          this.searchCountryValue = true;
        }
        if (res.success) {
          this.countryList = res.data;
          if (res.data.length != 0) {
            this.searchCountryValue = false;
          }
        } else {
          this.tostr.errorToastr(res.message, 'Error!');
        }
      });
    }
    else {
      this.countryList = this.allcountryList;
      this.searchCountryValue = false;
    }
  }

  onSelect(event) {  
    this.AuthenticationService.jwtTokenExpCheck();
    if(event != undefined){
      if (event.target.files[0].size < (2029410 / 2)) {
        this.imageFileEvent = event;      
            const formData = new FormData();
            formData.append('profileimage', event.target.files[0]);
            if (event.target.files && event.target.files[0]) {
              const file = event.target.files[0];  
              const reader = new FileReader();
              reader.onload = e => this.s_img_Path = reader.result as string;  
              reader.readAsDataURL(file);
          }
    
            if(this.staffEditFormDetails.controls['staffingIdVD'].value != undefined && this.staffEditFormDetails.controls['staffingIdVD'].value != '' && this.staffEditFormDetails.controls['staffingIdVD'].value != null && this.staffAData.staffingdetails.employeeId != undefined){      
              this.profileLoader = true;
              const formData = new FormData();
              formData.append('profileimage', event.target.files[0]);
              if (event.target.files[0].type === 'image/png'
                || event.target.files[0].type === 'image/jpg'
                || event.target.files[0].type === 'image/jpeg') {
                this.profileLoader = true;
                    formData.append("ImageUrl", event.target.files[0]);
                    formData.append("UserId", this.staffAData.staffingdetails.employeeId);                    
                    this.staffAPI.seekerProfileImgUpdateVD(formData).subscribe(res => { 
                    this.resImageData = res;
                    if (this.resImageData.success) {
                      var profileImagePath = this.imgProfile = this.userData1.data.credentials.profileImagePath;
                      this.profileLoader = false;
                      //this.tostr.successToastr(this.resImageData.message, '', { position: 'bottom-right' });
                      this.profileLoader = false;               
                    } else {
                     // this.tostr.errorToastr(this.resImageData.message, '', { position: 'bottom-right' });
                    }
                  });
                
              }
              else {
                this.tostr.errorToastr('Profile image should be in png or jpg format.', 'Error!');
              }
            }
          }
          else {
            this.tostr.errorToastr('Profile image should be less than 1 MB.');
            this.profileLoader = false;
          }
    }
    
    }

  clearFile(event) { 
    this.myFileInput.nativeElement.value = '';
  }

  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public togglePasswordVisibility(type:Boolean): void {
    if(type){
      this.showPassword = !this.showPassword;
    }
    else{
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
}
