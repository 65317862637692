import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, EmailValidator, FormControl } from '@angular/forms';
import { EnterpriseService } from '../enterprise.service';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { StaffingAgencyPopUpComponent } from '../staffing-agency-pop-up/staffing-agency-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/admin/service/excel.service';
import { TableService } from 'src/app/admin/service/table.service';
import { AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';
import { SuuplierPopupComponent } from 'src/app/share-module/popUp/addSupplier/suuplier-popup/suuplier-popup.component';

  
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-staffing-agency-enterprise',
  templateUrl: './staffing-agency-enterprise.component.html',
  styleUrls: ['./staffing-agency-enterprise.component.css'],
  providers: [    
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class StaffingAgencyEnterpriseComponent implements OnInit {
   // Datepicker takes `Moment` objects instead of `Date` objects.
   date = new FormControl(moment([2017, 0, 1]));
 
  @ViewChild(MatPaginator,{static : false} ) paginators: MatPaginator;
  //isRequired = false;
  preloader: boolean = false;
  isAddform: boolean = false;
  staffingagencie = [];
  csv_staffingagency:any=[];
  entblocksec: any;
  staffunblk: any;
  body: FormGroup;
  isCollapsedMenu: boolean = false;
  submitted: boolean = false;
  defaultPageSize = 5;
  defaultPageNumber = 1;
  securityQuestionsList: any = [];
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];
  paginator = {
    pageSize: this.defaultPageSize,
    length: 0,
    pageSizeOptions: [5, 10, 15, 20],
    pageIndex: 0
  }
  issuccess: any;
  signupstaffingForm: FormGroup;
  userData: any;
  createdby: any;
  staffReg: any;

  typeUser='';

  mobMenulist() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
  }
  constructor(public dialog: MatDialog, private enterpriseApis: EnterpriseService, private _router: Router,
    private formBuilder: FormBuilder, private tostr: ToastrManager, private datePipe: DatePipe,
    private tableService: TableService,
    private router: Router,
    private excelService: ExcelService,private AuthenticationService: AuthenticationService, private API: AuthenticationService,
    private shareservice:ShareService,
    private _titleService:Title
    
    ) {
      var newTitle = 'Verisource | Suppliers';
      this._titleService.setTitle(newTitle);
      this.AuthenticationService.jwtTokenExpCheck();
    this.body = this.formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      totalcandidates: '0-All',
      pageSize: 5,
      PageNumber: 1,
      action: 'All',
      jobid: '',
      profile_status: 'All',
      active_status:'All',
      source:'All'
    });

    this.signupstaffingForm = this.formBuilder.group({
      companyName: ['', [Validators.required]],
      website: ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      firstName: ['',[Validators.required]],
      middleName: [''],
      lastName: ['', [Validators.required]],
      emailId: ['', [Validators.required, Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      password: ['', [Validators.required, Validators.minLength(6),
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      contactNo: ['', Validators.required],
      securityQuestions: ['', Validators.required],
      answer: ['', [Validators.required]],
      createdDate: [''],
      createdby: [''],
      vdStaffingId: ['']

    });

    this.tableService.getSecurityQuestions().subscribe((res: any) => {
      if (res.success) {
        this.securityQuestionsList = res.data;
      } else {
      }
    });
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
  }

  ngOnInit() {
    this.loadAllStaffingAgencies();

  }

  get sf() { return this.signupstaffingForm.controls; }
  Popupenterpsform() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.signupstaffingForm.reset();
    this.isAddform = !this.isAddform;
  }

  loadAllStaffingAgencies() {
    this.AuthenticationService.jwtTokenExpCheck();
    if (this.body.controls['fromdate'].value == null) {
      this.body.controls['fromdate'].setValue('')
    }
    if (this.body.controls['todate'].value == null) {
      this.body.controls['todate'].setValue('')
    }
    this.preloader = true;
    this.body.controls["fromdate"].setValue(this.shareservice.convertDateIntoUtc( this.datePipe.transform(this.body.value.fromdate, 'yyyy-MM-dd')));
    this.body.controls["todate"].setValue(this.shareservice.convertDateIntoUtc(this.datePipe.transform(this.body.value.todate, 'yyyy-MM-dd :23:59:59')))
    this.body.controls['todate'].setErrors(null);
    this.body.controls['fromdate'].setErrors(null);
    if (this.userData.data.credentials.userType != 'AD') {
      if (this.searchButton == true) {
        this.body.controls['PageNumber'].setValue(1);
      }
      this.enterpriseApis.filterforstaffing(this.body.value,false).pipe(first()).subscribe(res => {
        if (res.success) {
          
          this.preloader = false;
          if (this.searchButton == true) {
            this.paginators.pageIndex = 0;
          }
          this.searchButton = false;
          this.staffingagencie = res.data;
          this.isBlock=false;
          this.isUnBlock = false;
          //console.log(this.staffingagencie)
          if (this.staffingagencie.length > 0) {
            this.paginator.length = this.staffingagencie[0].totalCount;
          }
          else {
            this.paginator.length = 0;
         }  
         this.csv_staffingagency=[];
         this.staffingagencie.forEach(element => {
          this.csv_staffingagency.push({
              'Sr.No.':this.csv_staffingagency.length + 1,
              'Supplier ID': element.staffEmpId,
              'Supplier': element.companyName,
              'Registration Date': this.datePipe.transform(element.registerDate,'MM/dd/yyyy'),
              'Website': element.website,
              'Total Jobseekers':element.totalcandidates,
              'Source':element.source,
              'Status':element.status
            });
      
        });
        }else{
          this.preloader = false;
        }
      });
    }
    else if (this.userData.data.credentials.userType === 'AD') {
      if (this.searchButton == true) {
        this.body.controls['PageNumber'].setValue(1);
      }
      this.enterpriseApis.filterforAdmin(this.body.value).pipe(first()).subscribe(res => {
        if (res.success) {
          this.preloader = false;
          this.staffingagencie = res.data;
          if (this.searchButton == true) {
            this.paginators.pageIndex = 0;
          }
          this.searchButton = false;
          if (this.staffingagencie.length > 0) {
            this.paginator.length = this.staffingagencie[0].totalCount;
          }
          else{
            this.paginator.length =0;
          }
          this.csv_staffingagency=[];
          this.staffingagencie.forEach(element => {
              this.csv_staffingagency.push({
                  'Supplier ID': element.staffEmpId,
                  'Supplier': element.companyName,
                  'Registration Date': element.registerDate,
                  'Website': element.website,
                  'Total Jobseekers':element.totalcandidates,
                  'Source':element.source,
                  'Status':element.status
                });
          
          });

        }
        else {
          this.preloader = false;
        }
      });
    }

  }

  clearForm() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.body = this.formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      totalcandidates: '0-All',
      pageSize: 5,
      PageNumber: 1,
      action: 'All',
      jobid: '',
      profile_status:'All',
      active_status:'All',
      source:'All'
    });
    this.paginator.pageSize=5;
    this.loadAllStaffingAgencies();
    this.paginators.pageIndex = 0;
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }

  searchButton = false;
  loadData(e) {
    this.searchButton = true;
    this.selectPageNo(e);
  }

  selectPageNo(e) {
    this.loadAllStaffingAgencies();
  }

  // this method is not in use in this component 
  // addstaffingagencydetails() {
    
  //   this.AuthenticationService.jwtTokenExpCheck();
  //   this.submitted = true;
  //   if (this.signupstaffingForm.valid) {
  //     if (this.userData.data.credentials.userType == 'EP' || this.userData.data.credentials.userType == 'SA') {
  //       this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
  //       this.signupstaffingForm.controls['createdby'].setValue(this.createdby);
  //     }
  //     else {
  //       let userid=this.userData.data.credentials.userId
  //       this.signupstaffingForm.controls['createdby'].setValue(userid);

  //     }

  //     this.signupstaffingForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
  //     this.enterpriseApis.addstaffingagencyVD(this.signupstaffingForm.value).subscribe(res => {
        
  //       this.staffReg = res;
  //       //console.log('this.staffReg', this.staffReg);
  //       this.signupstaffingForm.controls['vdStaffingId'].setValue(this.staffReg.data)
        
  //       if (this.staffReg.success) {
  //         this.enterpriseApis.addstaffingagency(this.signupstaffingForm.value).pipe(first()).subscribe(res => {
            
  //           this.issuccess = res;
  //           if (this.issuccess.success) {
  //             this.submitted = false;
  //             this.loadAllStaffingAgencies();
  //             this.isAddform = false;
  //             this.tostr.successToastr(this.issuccess.message, "Success!");
  //           } else {
  //             this.tostr.errorToastr(this.issuccess.message, 'OPPS!')
  //           }
  //         });

  //       } else {
  //         this.tostr.errorToastr(this.staffReg.message, 'OPPS!');
  //       }
  //     });


  //   }


  //   else {
  //     return false;
  //   }

  // }

  addSupplierOpenDialog() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
     if(this.isLogin != 'false'){
     const dialogRef = this.dialog.open(SuuplierPopupComponent, {
       width: 'auto',
       //disableClose: true,
       hasBackdrop: false,
       data: this.typeUser = 'EP',
     });
     dialogRef.afterClosed().subscribe(result => {
       //console.log('The dialog was closed');
       if (result != 'close') {
         this.router.navigate(['/ep/enterprise/staffingagency']);
       }
       this.loadAllStaffingAgencies();
     });
  }
  } 
  
  isBlock=false;
  isUnBlock = false;
  blockentstaffing(stid,empid) {
  this.isUnBlock=false;
  this.AuthenticationService.jwtTokenExpCheck();  
    //Active Inactive from Veridial
  this.tableService.updateCompanyStatusVD(empid,'Inactive',environment.AppRefTypeSupplier).subscribe(res => {
    this.issuccess = res;
    if (this.issuccess.success) { 
    this.enterpriseApis.staffingBlock(stid).pipe(first()).subscribe(stffingagency => {
      this.entblocksec = stffingagency;
      if (this.entblocksec.success) {
        this.tostr.successToastr(this.entblocksec.message, "Success!");
        this.loadAllStaffingAgencies();
      } else {
        this.tostr.errorToastr(this.entblocksec.message, 'OPPS!')
      }
    });
  }
});
  }

  unblockentstaffing(stid,empid) {
  this.isBlock=false;
  //Active Inactive from Veridial
  this.AuthenticationService.jwtTokenExpCheck();
  this.tableService.updateCompanyStatusVD(empid,'Active',environment.AppRefTypeSupplier).subscribe(res => {
    this.issuccess = res;
    if (this.issuccess.success) { 
    this.enterpriseApis.staffingUnblock(stid).pipe(first()).subscribe(stffingagencyunblk => {
      this.staffunblk = stffingagencyunblk;
      if (this.staffunblk.success) {
        this.tostr.successToastr(this.staffunblk.message, "Success!");
        this.loadAllStaffingAgencies();
      } else {
        this.tostr.errorToastr(this.staffunblk.message, 'OPPS!')
      }

    });
  }
});
}

  staffingagencyView(id) {
    if (this.userData.data.credentials.userType == 'EP') {
      this._router.navigate(['/ep/enterprise/staffing_agency_detail/',id]);
    }
    else if (this.userData.data.credentials.userType === 'AD') {
      this._router.navigate(['/ad/admin/staffing_agency_detail/', id]);
    }
  }

  existph = false;
  validateContact: any;
  checkPhone() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.existph = false;
    this.enterpriseApis.contactExist(this.signupstaffingForm.controls['contactNo'].value).subscribe((res: any) => {
      this.validateContact = res;
      if (this.validateContact.data.length > 0 && this.validateContact.data.length != 0) {
        this.existph = true;
        this.signupstaffingForm.controls['contactNo'].setErrors({ 'invalid': true })
      }
    });
  }

  isRequired = false;
  OnChangePhoneNo(tab, e) {
    this.AuthenticationService.jwtTokenExpCheck();
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== '' && e.keyCode != 8) {
      if (tab === 'CP') {
        this.signupstaffingForm.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
      }
    } else {
      this.isRequired = false;
    }
  }

  getpaginatorData(e) {
    this.searchButton = false;
    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.body.controls['pageSize'].setValue(e.pageSize);
    this.body.controls['PageNumber'].setValue(e.pageIndex + 1);
    this.loadAllStaffingAgencies();
  }

  isLogin='true';
  openStaffingAgencyDialog(staffingagencyId: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(StaffingAgencyPopUpComponent, {
      width: '500px',
      disableClose: true,
      data: { staffingagencyId }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.csv_staffingagency, 'sample');
  }
  saweb = false;
  jsweb = false;
  epweb = false;
  websiteValidation(type, event) {
    
    //console.log('type, event', type, event);
    this.API.checkweb(type, event.target.value).subscribe((res: any) => {
      
      //console.log('res', res);
      if (res.success) {
        if (res.data.exists) {
          this.signupstaffingForm.controls['website'].setErrors({ 'errors': true });
          this.saweb = true;
        } else {
          this.saweb = false;
        }
      } else {
        //console.log('res.message', res.message);
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
  }
  saname = false;

  NameValidation(event) {
    
    let id;
    //console.log('type, event', event);
    if (this.userData.data.credentials.userType == 'EP') {
      id = 'VKEP-0' + this.userData.data.credentials.userId;
    } else {
      id = this.userData.data.credentials.userId;
    }

    this.API.checkSuplireName(id, event.target.value).subscribe((res: any) => {
      
      //console.log('res', res);
      if (res.success) {
        if (res.data.exists) {
          this.signupstaffingForm.controls['companyName'].setErrors({ 'errors': true });
          this.saname = true;
        } else {
          this.saname = false;
        }
      } else {
        //console.log('res.message', res.message);
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
  }

     // 20200929: Akhilesh, Special charcter validation, jira no 70
     omit_validate_specialchar(event: { charCode: any; }) {
      const k = event.charCode;   
       if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
         this.tostr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
         return false}
       }
      
  from_Date:String;
  dateForToDate(type:number){
    if(type == 1){
      this.from_Date = new Date(this.datePipe.transform(this.body.controls['fromdate'].value)).toISOString();
    }
  
    if(type == 2){
      this.today = new Date(this.datePipe.transform(this.body.controls['todate'].value)).toISOString();
    }
  }

  registerMissedSupplierInVD(supplier) {
    this.AuthenticationService.jwtTokenExpCheck();
    const body=
    {
        'firstName':supplier.firstName,
        'lastName':supplier.lastName,
        'emailId':supplier.emailId,
        'contactNo':supplier.contactNo,
        'profileImage':supplier.profileImage==null?'':supplier.profileImage,
        'id': 0,
        'skillId': environment.VeridialSkillId,
        'subscriberId': 0,
        'permissionId': environment.VeridialPermissionId,
        'roleManagerId': 0,
        'AppType': environment.AppType,
        // 'AppRefId': jobseeker.jobseekerId,
        'AppRefType': environment.AppRefTypeJobseeker
      }
    if (this.userData.data.credentials.userType == 'AD' || this.userData.data.credentials.userType == 'EP') {
    this.signupstaffingForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
    this.enterpriseApis.addstaffingagencyVD(body,Number(supplier.staffEmpId)).subscribe(async (resp:any)=> {
      this.staffReg = resp;
      if (this.staffReg.success) {
        this.enterpriseApis.UpdateIdFromVeridialForEnterprise(Number(supplier.staffEmpId),Number(this.staffReg.data),'SA').subscribe(async (response: any) => {
          this.tostr.successToastr(this.issuccess.message, "Success!");
          this.loadAllStaffingAgencies();
        });
      }
      else {
        this.tostr.errorToastr('User is already mapped.');
      }
    });
              
    }
  }
}


