import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { TableService } from 'src/app/admin/service';
import { AuthenticationService } from 'src/app/app.service';
import { ShareService } from 'src/app/share-module/share.service';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';

@Component({
  selector: 'app-client-popup',
  templateUrl: './client-popup.component.html',
  styleUrls: ['./client-popup.component.css']
})
export class ClientPopupComponent implements OnInit {
  @ViewChild('myFileInput',{static:false}) myFileInput;
  signupEnterpriseForm:FormGroup;
  currentUser: any;
  issuccess: any;
  submited = false;
  isAddform: boolean = false;
  phoneNumber: string;
  epPhone = false;
  isAddEnterpriseform=true;
  securityQuestionsList: any;
  issuccess1: any;
  imageFileEvent: any;
  profileLoader: boolean = false;
  user_id: any;
  resImageData: any;
  imgProfile: any;
  userData1:any;
  loader = false;
  constructor(
     private formBuilder: FormBuilder,
     private api: TableService,
     private AuthenticationService:AuthenticationService,
     private _titleService: Title,
     private tostr: ToastrManager,
     private AdminApi: TableService,
     private router: Router,
     private shareservice:ShareService,
     private dialogRef: MatDialogRef<ClientPopupComponent>,
     private staffAPI: staffingAgencyServices,
     @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
      const newTitle = 'Verisource | Admin Dashboard';
      this.userData1 = JSON.parse(window.localStorage.getItem('udata'));
      this._titleService.setTitle(newTitle);
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.AuthenticationService.jwtTokenExpCheck();

    this.signupEnterpriseForm = this.formBuilder.group({
      'companyName': ['', [Validators.required]],
      'website': ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
      'firstName': ['',  [Validators.required]],
      'middleName': [''],
      'lastName': ['',  [Validators.required]],
      'emailId': ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      'password': [''],
      'contactNo': ['', Validators.required],
      'securityQuestions':['',Validators.required],
      'answer' : ['',Validators.required],
      'createdDate':[''],
      'vdEntpId': ['']
      //  'isAdmin':true
    });

    this.AdminApi.getSecurityQuestions().subscribe((res: any) => {
      if (res.success) {
        this.securityQuestionsList = res.data;
      } else {   
      } 
    });
  }

  ngOnInit() {
  }

  epIds:Number;
  enterpriseformdetail() {
    var email = this.signupEnterpriseForm.controls["emailId"].value;
    this.signupEnterpriseForm.controls["emailId"].setValue(email.toLowerCase());
     this.AuthenticationService.jwtTokenExpCheck();
    this.submited = true;
    if (this.signupEnterpriseForm.valid) {  
      this.loader = true;
          this.api.enterpriseForm(this.signupEnterpriseForm.value).subscribe(async res => {          
            this.issuccess1 = res;
            if (this.issuccess1.success  && Number(res.data.empId) > 0) {
              this.epIds = res.data.empId;
              if(Number(res.data.empId) > 0){
                this.api.enterpriseFormVD(this.signupEnterpriseForm.value,Number(res.data.empId)).subscribe(async (resp:any) => {                
                  this.issuccess = resp;                  
                  if (this.issuccess.success) {
                    this.signupEnterpriseForm.controls['vdEntpId'].setValue(this.issuccess.data);
                    this.api.updateEmployeeIdByVeridialUserId(resp.data,res.data.empId,'EP').subscribe(async response => {
                    });
                  if(this.imageFileEvent != undefined){
                     await this.onSelect(this.imageFileEvent);               
                  }
                  this.loader=false;
                  this.submited = false;
                  this.tostr.successToastr(this.issuccess1.message, "Success!");
                  this.isAddform = false;
                  this.dialogRef.close('close');
                  this.router.navigate(['/ad/admin/enterprise']);
                  this.isAddform = false;
                } else {
                  this.loader=false;
                  this.submited = false;
                  this.tostr.errorToastr(this.issuccess1.message, 'OPPS!')
                }
                });
              }
              else{
                this.loader=false;
              }       
          }
          else {
            this.loader=false;
            this.tostr.errorToastr(this.issuccess1.message, 'OPPS!')
          } 
        },(errorResponse: any) => {     
          this.loader = false;
          this.isAddform = true;
          if (errorResponse instanceof HttpErrorResponse) {
            if (errorResponse.status === 422) {
               if(errorResponse['error'].errors){
                 this.tostr.errorToastr(errorResponse['error'].errors[0].msg)
               }
            }
            else{
              this.tostr.errorToastr(errorResponse['message']);
            }
          }
         });
    }
    else {
      this.tostr.errorToastr('Invalid Input Please try again!', 'Alert!', {
        position: 'top-right'
      });
      return false;
    }
  }


  // enterpriseformdetail() {
  //   this.phoneNumber = this.signupEnterpriseForm.controls['contactNo'].value;
  //   this.phoneValidation('EP', this.phoneNumber);
  //   this.submited = true;
  //   if (this.signupEnterpriseForm.valid) {
  //     this.signupEnterpriseForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
  //     this.AdminApi.enterpriseForm(this.signupEnterpriseForm.value).pipe(first()).subscribe(res => {
  //       this.issuccess = res;
  //       if (this.issuccess.success) {
  //         this.tostr.successToastr(this.issuccess.message, "Success!");
  //         this.submited = false;
  //         this.isAddform = false;
  //         this.dialogRef.close('close');
  //         this.router.navigate(['/ad/admin/enterprise']);
  //       } else {
  //         this.tostr.errorToastr(this.issuccess.message, 'OPPS!')
  //       }
  //     });
  //   }
  //   else {
  //     this.tostr.errorToastr('Fill all required fields !', 'Alert!', {
  //       position: 'top-right'
  //     });
  //     return false;
  //   }
  // }

  clearData() {
    this.signupEnterpriseForm.reset();
    this.dialogRef.close('close');
  }

  get f() { return this.signupEnterpriseForm.controls; }

  phoneValidation(type, event) {
    this.epPhone = false;
      this.phoneNumber = this.signupEnterpriseForm.controls['contactNo'].value;
    this.AdminApi.checkphone('EP', this.phoneNumber).subscribe((res: any) => {
      if (res.success) {
        if (res.data.exists) {      
          this.epPhone = true;
          this.signupEnterpriseForm.controls['contactNo'].setErrors({ 'invalid': true });     
        } 
        else {
          this.epPhone = false;
        }
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
  }

// 20201128,Akhilesh, Issue no 65
  isRequired = false;
  submitted = false;
  //submited = false;
  OnChangePhoneNo(tab, e) {
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== '' && e.keyCode != 8) {
      if (tab === 'EP') {
        this.signupEnterpriseForm.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
      }      
    } else {
      this.isRequired = false;
    }
  }


  onSelect(event) { 
    if(event != undefined){
      if(event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/jpeg'){
        if (event.target.files[0].size < (2029410 / 2)) {
          this.imageFileEvent = event;      
            const formData = new FormData();
            formData.append('profileimage', event.target.files[0]);
            if (event.target.files && event.target.files[0]) {
              const file = event.target.files[0];  
              const reader = new FileReader();
              reader.onload = e => this.imgProfile = reader.result as string;  
              reader.readAsDataURL(file);
            }
            if(this.signupEnterpriseForm.controls['vdEntpId'].value != undefined && this.signupEnterpriseForm.controls['vdEntpId'].value != ''){      
              this.profileLoader = true;
              const formData = new FormData();
              formData.append('profileimage', event.target.files[0]);
              this.profileLoader = true;         
              //this.API.staffJobseekerProfileImg(formData).subscribe(res => {
                formData.append("ImageUrl", event.target.files[0]);
                formData.append("UserId", this.signupEnterpriseForm.controls['vdEntpId'].value);                 
                this.staffAPI.seekerProfileImgUpdateVD(formData).subscribe(res => { 
                this.resImageData = res;
                if (this.resImageData.success) {
                  // this.profileupdateImage();
                  // var profileImagePath = this.imgProfile = this.userData1.data.credentials.profileImagePath;
                  // localStorage.setItem('profileImagePath', profileImagePath);
                  // this.myFileInput.nativeElement.value = '';
                  // this.tostr.successToastr(this.resImageData.message, '', { position: 'bottom-right' });
                  //this.editUploadProfile.controls['profileImage'].setValue(this.resImageData.data.fileName);
                  this.profileLoader = false;               
                  // this.profileImage =this.resImageData.data.fileName;
                } else {
                  this.profileLoader = false;
                  // this.tostr.errorToastr(this.resImageData.message, '', { position: 'bottom-right' });
                }
              });
              
            }
            
        }
        else {
          this.tostr.errorToastr('Profile image should be less than 1 MB.');
          this.profileLoader = false;
        }
      }
      else {
        this.tostr.errorToastr('Profile image should be in png or jpg format.', 'Error!');
      }
    }  
    
    
}

removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
  const startPos = e.currentTarget.selectionStart;
  if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
    e.preventDefault();
  }
}

omit_validate_specialchar(event: { charCode: any; }) {
  const k = event.charCode;   
    if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
     this.tostr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
    return false}
}

omit_special_char(event: { charCode: any; }) {
  const k = event.charCode;
  return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
}

omit_special_char_number(event: { charCode: any; }) {
  const k = event.charCode;
  return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
}

clearFile(event) { 
  this.myFileInput.nativeElement.value = '';
}
}
