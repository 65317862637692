import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { StaffingAgencyServices } from '../../admin/service/staffing-agency.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/app.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  Feedbacklist = [
    { id: '1', name: 'I no longer want to receive these emails', selected: false },
    { id: '2', name: 'I never signed up for this mailing list', selected: false },
    { id: '3', name: 'The emails are inappropriate', selected: false },
    { id: '4', name: 'The emails are spam and should be reported', selected: false },
    { id: '5', name: 'Other (fill in reason below)', selected: false }];
  isother = false;
  SunsForm: FormGroup;
  emailpara:any;
  //textareaValue:any
  optionValue: any;
  submitform: FormGroup;

  constructor(private AuthenticationService:AuthenticationService,private layoutService:LayoutService,private paramval: ActivatedRoute,private _formBuilder: FormBuilder, private tostr: ToastrManager, private stafingagencyService: StaffingAgencyServices ) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.fillform();
    this.emailpara = this.paramval.snapshot.params.emailid;
  }

  ngOnInit() {
    this.layoutService.setShowSidebar(false);
  }
  fillform() {
    this.SunsForm = this._formBuilder.group({
      textareaValue: [''],
    });
  }
  checkIsother(value: any) {
    this.optionValue = value;
    if (value === 'Other (fill in reason below)') {
      this.isother = true;
    } else {
      this.isother = false;
    }
  }
  feedbacksend() {
    if (this.optionValue !== undefined) {
      const test = this.SunsForm.controls.textareaValue.value;
      //console.log('SunsForm', test);
      //console.log('SunsForm', this.SunsForm.controls.textareaValue.value);
      this.submitform = this._formBuilder.group({
        comment: test,
        feedback: this.optionValue,
        mailid: this.emailpara,
      });
      //console.log('this.submitform.value',this.submitform.value)
      this.AuthenticationService.jwtTokenExpCheck();
      this.stafingagencyService.sendfeedbackmail(this.submitform.value).subscribe(res => {
          location.href = "https://www.veriklick.com/";
      })

    }
    else {
      this.tostr.errorToastr('Please Select Option');
    }



  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }


}
