import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  apipath = '';
  constructor(private http: HttpClient,) {
    this.apipath = environment.apipath;
   }


   RefreshToken(data){
    const localurl = this.apipath + 'Authentication/VSRefreshToken';
    const httpOptions = {     
        headers :{
          Authorization:"Bearer " + localStorage.getItem("token") // token
      }
      };
    return this.http.get<any>(localurl, httpOptions);
  }
}
