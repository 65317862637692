import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, EmailValidator, FormControl } from '@angular/forms';
import { EnterpriseService } from '../../enterprise/enterprise.service';
import { first, startWith, map } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/admin/service/excel.service';
import {AuthenticationService } from '../../app.service';
import { MatPaginator } from '@angular/material/paginator';
import { ShareService } from '../share.service';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { TableService } from 'src/app/admin/service';
export interface User {
  name: string;
  email: string;
}
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  providers: [    
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class ProjectsComponent implements OnInit {
   // Datepicker takes `Moment` objects instead of `Date` objects.
   date = new FormControl(moment([2017, 0, 1]));

  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  isRequired = false;etpago
  preloader: boolean = false;
  isAddform: boolean = false;
  searchForm: FormGroup;
  projectForm: FormGroup;
  isCollapsedMenu: boolean = false;
  submitted: boolean = false;
  projectsDetails = [];
  csv_projectsDetails:any=[];
  clients = [];
  allclients=[];
  options: User[] = [];
  localdata : any={};
  createdByName ='';
  userData:any={};
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];
  /*options: User[] = [
    { Id: 1, name: 'Dev', emailId: 'dev@gmail.com' },
    { Id: 2, name: 'erica', emailId: 'erica@gmail.com' },
    { Id: 3, name: 'Poll', emailId: 'Poll@gmail.com' },
    { Id: 4, name: 'monti', emailId: 'monti@gmail.com' }
  ];*/
  paginator = {
    pageSize: 5,
    length: 0,
    pageSizeOptions: [5, 10, 15, 20],
    pageIndex: 0
  }
  constructor(private enterpriseService: EnterpriseService, private excelService:ExcelService,
    private _router: Router, private formBuilder: FormBuilder, private toastr: ToastrManager,
    private datePipe: DatePipe  ,private activeRoute: ActivatedRoute, private shareservice:ShareService,
 private AuthenticationService: AuthenticationService,
 ) {
    this.searchForm = this.formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      totalProjects: '0-All',
      pageSize: 5,
      PageNumber: 1,
      client:'',
      type:''
    });
 this.userData =  JSON.parse(window.localStorage.getItem('udata'));
    this.projectForm = this.formBuilder.group({
      projectName: ['', [Validators.required]],
      projInitBudgetAmt: ['', Validators.required],
      projStartDate: ['', [Validators.required]],
      projToDate: ['', [Validators.required]],
      clients:['', [Validators.required]],
      mspFees: [],
      vmsFees: [],
      otherFees: [],
      saMarkup: [],
      projAdminEmail:  ['', [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      projAdminName: [],
      projManagerEmail:  ['', [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      projManagerName: [],
      projDescription: [],
      projDocumentFile: [],
      createdDate:['']
    });
  }
  projManagerFilteredOptions: Observable<User[]>;
  projAdminFilteredOptions: Observable<User[]>;
  ngOnInit() {


    this.localdata=JSON.parse(localStorage.getItem('udata'));
    if(this.userData.data.credentials.userType === 'AD'){
      this.createdByName=(this.userData.data['credentials'].firstName);
    }
    if(this.userData.data.credentials.userType === 'EP'){
      this.createdByName=(this.userData.data['credentials'].firstName + this.localdata.data['credentials'].lastName);
    }
    this.loadAllProjects();
    this.loadClients();
    this.projManagerFilteredOptions = this.projectForm.controls.projManagerEmail.valueChanges
      .pipe(
        startWith(''),
        map(name => name ? this._filter(name.toLowerCase()) : this.options.slice())
      );

    this.projAdminFilteredOptions = this.projectForm.controls.projAdminEmail.valueChanges
      .pipe(
        startWith(''),
        map(name => name ? this._filter(name) : this.options.slice())
      );
  }
  displayFn(user?: User): string | undefined {
    return user ? user.email : undefined;
  }
  getUserName(eleName: string, user: User) {
    this.projectForm.controls[eleName].setValue(user.name);
  }
  get pf() { return this.projectForm.controls; }

  //get all clients
 loadClients(){
 
  this.AuthenticationService.jwtTokenExpCheck();
   this.enterpriseService.getClients().subscribe((res: any) => {
      this.preloader = false;
      if (res.success) {
        //console.log('res.data',res.data);
        this.clients = res.data;
        this.allclients = res.data;
      } else {
        //console.log(res);
      }
    });
 }
 
 loadAllProjects() {

  this.AuthenticationService.jwtTokenExpCheck();
    this.preloader = true;
    
    if(this.userData.data.credentials.userType === 'EP'){
      this.searchForm.controls['client'].setValue(this.userData.data.credentials.AppRefId)
    }
    if(this.searchButton == true){
      this.searchForm.controls['PageNumber'].setValue(1);
    }
    if(this.userData.data.credentials.userType === 'AD'){
      this.searchForm.controls['type'].setValue('Admin')
    }

    this.enterpriseService.getProjects(this.searchForm.value).subscribe((res: any) => {

      this.preloader = false;
      if (res.success) {
        this.projectsDetails = res.data;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;  
        if (this.projectsDetails.length > 0) {
          this.paginator.length = this.projectsDetails[0].totalCount;
        }
        this.csv_projectsDetails=[];
        if(this.userData.data.credentials.userType === 'AD'){
          this.projectsDetails.forEach(element => {
            this.csv_projectsDetails.push({
                'Project Name': element.projectName,
                'Date': element.fromDate,
                'Not To Exceed': element.totalAllocatedBudgetAmt,
                'MSP (%)': element.mspFees,
                'VMS (%)':element.vmsFees,
                'Other (%)':element.otherFees,
                'Supplier Markup (%)':element.saMarkupFees,
  
                'Jobs': element.noOfJobs,
                'Hiring Manager':element.manager,
                'Initiator':element.manager,
                'Client':element.clients
              });
        });
        }

        else{
          this.projectsDetails.forEach(element => {
            this.csv_projectsDetails.push({
                'Project Name': element.projectName,
                'Date': element.fromDate,
                'Not To Exceed': element.totalAllocatedBudgetAmt,
                'MSP (%)': element.mspFees,
                'VMS (%)':element.vmsFees,
                'Other (%)':element.otherFees,
                'Supplier Markup (%)':element.saMarkupFees,
                'Jobs': element.noOfJobs,
                'Hiring Manager':element.manager,
                'Initiator':element.manager
              });
        });
        }
        
      } else {
        //console.log(res);
      }
    });
  }

  addProjectPopUp() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.projectForm = this.formBuilder.group({
      projectName: ['', [Validators.required]],
      projInitBudgetAmt: ['', Validators.required],
      projStartDate: ['', [Validators.required]],
      projToDate: ['', [Validators.required]],
      clients:['', [Validators.required]],
      mspFees: [],
      vmsFees: [],
      otherFees: [],
      saMarkup: [],
      projAdminEmail: ['', [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      projAdminName: [],
      projManagerEmail: ['', [Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
      projManagerName: [],
      projDescription: [],
      projDocumentFile: [],
      createdDate:['']
    });
    this.isAddform = !this.isAddform;
  }

  viewProjectDetails(projectNo: string,project:any) {
    
    if(this.userData.data.credentials.userType === 'AD'){
      let id = project.clients == null?'1':project.clients;
      this._router.navigate(['/ad/admin/project/', id,projectNo]);
    }
    if(this.userData.data.credentials.userType === 'EP'){
      this._router.navigate(['/ep/enterprise/project/', projectNo]);
    }
  }

  searchButton = false;
  loadData(){
    this.searchButton= true;
    this.loadAllProjects();
  }


  getpaginatorData(e) {
 
    this.searchButton = false;
    // this.searchForm.controls['pageSize'].setValue(e.pageSize);
    // this.searchForm.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.searchForm.controls['pageSize'].setValue(e.pageSize);
    this.searchForm.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.loadAllProjects();
  }

  onProjectFrmSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.submitted = true;
    var aa=this.projectForm.controls['projStartDate'].value;
    var bb=this.projectForm.controls['projToDate'].value;
    if(this.projectForm.controls['projStartDate'].value == ""){
      this.projectForm.controls['projStartDate'].markAsTouched();
    }
    if(this.projectForm.controls['projToDate'].value == ""){
      this.projectForm.controls['projToDate'].markAsTouched();
    }  
    if(this.userData.data.credentials.userType === 'EP'){
      this.projectForm.controls['clients'].setValue(this.userData.data.credentials.AppRefId)
    } 
      if (this.projectForm.valid) {
        if(this.projectForm.controls['projStartDate'].value <= this.projectForm.controls['projToDate'].value && this.projectForm.controls['projStartDate'].value != '' && this.projectForm.controls['projToDate'].value != '' ){
        try {
          var createdDate=new Date();
          // this.projectForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(createdDate));
          // var a1=this.projectForm.controls['createdDate'].value;
          var createdDate1=new Date().toISOString().slice(0,10)+' 18:30:00.000';
          this.projectForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(this.datePipe.transform(new Date(),'yyyy-MM-dd')));
          var a2=this.projectForm.controls['createdDate'].value;

        // this.projectForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(createdDate));
        // this.projectForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(createdDate));
        this.projectForm.controls['projStartDate'].setValue(this.shareservice.convertDateIntoUtc(this.projectForm.controls["projStartDate"].value));
        this.projectForm.controls['projToDate'].setValue(this.shareservice.convertDateIntoUtc(this.projectForm.controls["projToDate"].value));
        this.projectForm.controls['projStartDate'].setErrors(null);
        this.projectForm.controls['projToDate'].setErrors(null);
        var aa1=this.projectForm.controls['projStartDate'].value;
        var bb1=this.projectForm.controls['projToDate'].value;
        var bb1=this.projectForm.controls['createdDate'].value;
          this.enterpriseService.addProject(this.projectForm.value, this.createdByName,this.userData.data.credentials.userType).subscribe((res: any) => {
            if (res.success) {
              this.toastr.successToastr('Project Added successfully');
              this.projectForm.reset();
              this.isAddform = !this.isAddform;
              this.loadAllProjects();
            } else {
              this.toastr.errorToastr(res.message);
            }
          });
        } catch (error) {
          this.toastr.errorToastr(error);
        }
      }
      else{
        this.toastr.errorToastr('Start Date should not be greater than End Date..');
      }
      } else {
        this.toastr.errorToastr('Fill all required fields');
      }
  }

  private _filter(item: string): User[] {
    return this.options.filter((option) => {
      return option.email.includes(item) || option.name.includes(item);
    })
  }
  findMatchUsers(searchText: string) {
    if (searchText.length > 2) {
      this.enterpriseService.searchUser(searchText).subscribe((res: any) => {
        this.options = res.data;
      });
    }
  }
  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return charCode===46;
    }
    return true;
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.csv_projectsDetails, 'sample');
  }

  clearForm(){
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.from_Date = null;
    this.searchForm = this.formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      totalProjects: '0-All',
      pageSize: 5,
      PageNumber: 1,
      client:'',
      type:''
    });
    this.paginator.pageSize=5;
    this.loadAllProjects();
    this.paginators.pageIndex = 0;
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }




//20201211, Akhilesh, Searchin gin client dropdown
  
  // searchStateValue = false;
  // searchState(countryId, e) {
  //   if (e.target.value != '') {
  //     this.API.searchState(countryId, e.target.value).subscribe((res: any) => {

  //       if (res.data.length == 0) {
  //         this.searchStateValue = true;
  //       }
  //       if (res.success) {
  //         this.stateList = res.data;
  //         if (res.data.length != 0) {
  //           this.searchStateValue = false;
  //         }
  //       } else {
  //         this.toastr.errorToastr(res.message, 'Error!');
  //       }
  //     });
  //   }
  //   else {

  //     this.stateList = this.allStateList;
  //     this.searchStateValue = false;
  //   }

  // }

    // 20200929: Akhilesh, Special charcter validation, jira no 70
    omit_validate_specialchar(event: { charCode: any; }) {
      const k = event.charCode;   
       if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
         this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
         return false}
       }

  from_Date:String;
  dateForToDate(type:number){
    if(type == 1){
      this.from_Date = new Date(this.datePipe.transform(this.searchForm.controls['fromdate'].value)).toISOString();
    }
  
    if(type == 2){
      this.today = new Date(this.datePipe.transform(this.searchForm.controls['todate'].value)).toISOString();
    }
  }

  searchClientValue = false;
  //Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
  searchEnterprise(e) {
    let mspId: any;
    if (this.userData.data.credentials.CreatedBy){
      mspId = this.userData.data.credentials.CreatedBy;
    }else{
      mspId = this.userData.data.credentials.userId;
    }
    if (e.target.value != '') {
      this.enterpriseService.get_client_search(e.target.value,mspId).subscribe((res: any) => {
       
        if (res.data.length == 0) {
          this.searchClientValue = true;
        }
        if (res.success) {
          this.clients = res.data;
          if (res.data.length != 0) {
            this.searchClientValue = false;
          }
        } 
      });
    }
    else {

      this.clients = this.allclients;
      this.searchClientValue = false;
    }

  }
}
