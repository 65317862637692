import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  routerClass: string;
  isLogin: boolean;
  year: number = new Date().getFullYear();
  // isLoginF: string = 'false';
  constructor(private router: Router) {
    this.isLogin = localStorage.getItem('islogin') == 'true' ? true : false;
    // this.router.events.subscribe(val => {
    //   let a = JSON.parse(JSON.stringify(val))
    //   this.routerClass = a.url.replace('/', '');
    //   // this.routerClass= val.NavigationEnd.url;
    //   this.isLogin = localStorage.getItem('islogin') == 'true' ? true : false;

    // })

  }

  ngOnInit() {

  }

  logData(e) {
    // this.isLoginF = e;
    if (e === 'false') {
      this.isLogin = false;
    } else { this.isLogin = true }
  }

  openPageInNewTab(url: string) {
    const routeMapping: { [key: string]: string } = {
      'about': '/aboutus',
      'contact': '/contactus',
      'terms': '/auth/terms',
      'privacy': '/auth/terms',
      // Add more mappings as needed
    };
  
    const targetRoute = routeMapping[url];
  
    if (targetRoute) {
      // this.router.navigate([targetRoute]);
      window.open(targetRoute, '_blank');
    }
  }

}
