import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder } from '@angular/forms';
// import { StaffingAgencyServices } from '../../../services/staffing-agency.service';
import { ShareService } from '../share.service';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { TableService } from 'src/app/admin/service';
import { StaffingAgencyServices } from 'src/app/admin/service/staffing-agency.service';
//import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../app.service';
import * as moment from 'moment';

export interface DialogData {
  id: string;
  jobId: string;
  title: string;
  jobDescription: string;
  skills: string;
  requestId: string;
  displayName: string;
  allowedPaymentModels: string;
  buyerId: string;
  city: string;
  countryCode: string;
  companyName: string;
  endDate: Date;
  maxAmount: number;
  name: string;
  postalCode: string;
  postionsAvailable: number;
  pushedProfile: number;
  //jobId: string;
  startDate: Date;
  state: string;
  status: string;
  street1: string;
  street2: string;
  timestamp: Date;
  totalCount: number;
  type: string;
  adjustedPayRates: string;
}




@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css']
})
export class InvitationComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  counter: number = 0;
  jobIdSelected: any = [];
  jobseekerlist: any;
  preloader = false;
  sourceId: string;
  listOfAllRequest: any;
  noOfRecord = 0;
  filterForm: FormGroup;
  paginator = {
    pageSize: 10,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [10, 20, 50, 100]
  };

  isAddform: boolean = true;
  joblistData: any;
  Invitationtbl: any;
  preLoader: boolean;
  listView: boolean;
  jobFilter: FormGroup;
  invitationForm: FormGroup;
  createdBy: any;
  // tslint:disable-next-line: variable-name


  JobTypeList = [
    { name: 'Contract', value: 'Contract' },
    { name: 'Contract to Hire', value: 'Contract to Hire' },
    { name: 'Hire', value: 'Hire' },

  ];
  PayrateDropList = [
    { name: 'Pay Rate', value: 'Pay Rate' },
    { name: 'Annual Salary', value: 'Annual Salary' },
  ];
  userData: any;
  typePopup = '';
  jobseekerId = '';

  constructor(private dialogRef: MatDialogRef<InvitationComponent>, private toastr: ToastrManager, private Service: ShareService,
    private _formBuilder: FormBuilder, private enterpriseApis: EnterpriseService, @Inject(MAT_DIALOG_DATA) public data: any,
    private tableService: TableService, private stafingagencyService: StaffingAgencyServices, private AuthenticationService: AuthenticationService, private shareservice: ShareService) {

      this.userData = JSON.parse(window.localStorage.getItem('udata'));

    this.createdBy = this.userData.data.credentials.userId

    this.AuthenticationService.jwtTokenExpCheck();
    this.typePopup = data.type;
    this.jobseekerId = data.data.hasOwnProperty('jobseekerId') ? data.data.jobseekerId : '';
    this.filterInit();
    this.JoblistDatafc();
    this.LoadBeelineData();
  }

  ngOnInit() {
  }
  clearData() {
    this.isAddform = false;
    this.ngOnInit();
  }
  filterInit() {
    this.filterForm = this._formBuilder.group({
      PageSize: 10,
      PageNumber: 1,
      JobStatus: '',
      fromdate: '',
      todate: '',
      search: '',
      totalapplied: '0-All',
      pool_Id: this.data['data'].pk_pool_Id,
      Clientsearch: '',
      Citysearch: '',
      Titlesearch: '',
      vkJobStatusAD: 'All',
      VMSJobStatus: 'All'
      //createdBy:this.createdBy
    });


    this.jobFilter = this._formBuilder.group({
      PageSize: 10,
      PageNumber: 1,
      postedDate: '0',
      jobType: 'All',
      country: '',
      minExperience: 0,
      maxExperience: 0,
      minAnnualPay: 0,
      maxAnnualPay: 0,
      search: '',
      //location: '',
      jobLocation: '',
      // jobResponse: '',
      // jobRequirement: 'All',
      orderBy: 'Date',
      state: '',
      zipCode: '',
      jobTypeBase: '',
      Payrate: '',
      PayrateDrop: '',
      user: '',
      pool_Id: this.data['data'].pk_pool_Id
    });
  }

  JoblistDatafc() {
    // this.preLoader = true;
    this.Service.jobsListAPI(this.jobFilter.value).subscribe(res => {
      this.joblistData = res;
      this.preLoader = false;
      if (this.joblistData.success) {
        //console.log(this.joblistData.data);
        this.listView = true;
        if (this.joblistData.data.length > 0) {
          this.paginator.length = this.joblistData.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
      }
    });
  }


  LoadBeelineData() { // load beelin job data

    this.preloader = true;
    if (this.userData.data.credentials.userType === 'EP') {
      this.searchType = 'EP';
      this.user = 'EP';
      this.jobFilter.controls['user'].setValue('EP');
      this.LoadBeelineDataEP();
    }
    if (this.userData.data.credentials.userType === 'AD') {
      this.searchType = 'VMS';
      this.user = 'VMS';
      this.LoadBeelineDataAD();
    }

  }

  searchButton = false;
  loadData() {
    this.searchButton = true;
    this.searchJob();
  }

  searchJob() {
    this.AuthenticationService.jwtTokenExpCheck();
    //console.log('check', this.searchType);
    if (this.searchType == 'VMS') {
      this.user = 'VMS';
      if (this.userData.data.credentials.userType === 'EP') {
        this.jobFilter.controls['user'].setValue('VMS');
        this.LoadBeelineDataEP();
      }
      if (this.userData.data.credentials.userType === 'AD') {
        this.LoadBeelineDataAD();
      }

    }
    if (this.searchType == 'EP') {
      this.user = 'EP';
      if (this.userData.data.credentials.userType === 'EP') {
        this.jobFilter.controls['user'].setValue('EP');
        this.LoadBeelineDataEP();
      }
      if (this.userData.data.credentials.userType === 'AD') {
        this.LoadBeelineDataAD();
      }
    }
  }

  clearSearchData() {

    this.filterForm = this._formBuilder.group({
      PageSize: 10,
      PageNumber: 1,
      JobStatus: '',
      fromdate: '',
      todate: '',
      search: '',
      totalapplied: '0-All',
      pool_Id: this.data['data'].pk_pool_Id,
      Clientsearch: '',
      Citysearch: '',
      Titlesearch: '',
      vkJobStatusAD: 'All',
      VMSJobStatus: 'All'
    });


    this.jobFilter = this._formBuilder.group({
      PageSize: 10,
      PageNumber: 1,
      postedDate: '0',
      jobType: 'All',
      country: '',
      minExperience: 0,
      maxExperience: 0,
      minAnnualPay: 0,
      maxAnnualPay: 0,
      search: '',
      //location: '',
      jobLocation: '',
      // jobResponse: '',
      // jobRequirement: 'All',
      orderBy: 'Date',
      state: '',
      zipCode: '',
      jobTypeBase: '',
      Payrate: '',
      PayrateDrop: '',
      user: '',
      pool_Id: this.data['data'].pk_pool_Id
    });
    this.paginator.pageSize = 10;
    this.changeLoadType(this.user);
    this.paginators.pageIndex = 0;
  }
  typeLoad = false;
  searchType = '';
  changeLoadType(type: string) {

    this.AuthenticationService.jwtTokenExpCheck();
    this.searchType = type;
    if (type == 'VMS') {
      this.user = 'VMS';
      if (this.userData.data.credentials.userType === 'EP') {
        this.jobFilter = this._formBuilder.group({
          PageSize: 10,
          PageNumber: 1,
          postedDate: '0',
          jobType: 'All',
          country: '',
          minExperience: 0,
          maxExperience: 0,
          minAnnualPay: 0,
          maxAnnualPay: 0,
          search: '',
          //location: '',
          jobLocation: '',
          // jobResponse: '',
          // jobRequirement: 'All',
          orderBy: 'Date',
          state: '',
          zipCode: '',
          jobTypeBase: '',
          Payrate: '',
          PayrateDrop: '',
          user: '',
          pool_Id: this.data['data'].pk_pool_Id
        });
        this.jobFilter.controls['user'].setValue('VMS');
        this.LoadBeelineDataEP();
      }
      if (this.userData.data.credentials.userType === 'AD') {
        this.filterForm = this._formBuilder.group({
          PageSize: 10,
          PageNumber: 1,
          JobStatus: '',
          fromdate: '',
          todate: '',
          search: '',
          totalapplied: '0-All',
          pool_Id: this.data['data'].pk_pool_Id,
          Clientsearch: '',
          Citysearch: '',
          Titlesearch: '',
          vkJobStatusAD: 'All',
          VMSJobStatus: 'All'
        });
        this.LoadBeelineDataAD();
      }

    }
    if (type == 'EP') {
      this.user = 'EP';
      if (this.userData.data.credentials.userType === 'EP') {
        this.jobFilter = this._formBuilder.group({
          PageSize: 10,
          PageNumber: 1,
          postedDate: '0',
          jobType: 'All',
          country: '',
          minExperience: 0,
          maxExperience: 0,
          minAnnualPay: 0,
          maxAnnualPay: 0,
          search: '',
          //location: '',
          jobLocation: '',
          // jobResponse: '',
          // jobRequirement: 'All',
          orderBy: 'Date',
          state: '',
          zipCode: '',
          jobTypeBase: '',
          Payrate: '',
          PayrateDrop: '',
          user: '',
          pool_Id: this.data['data'].pk_pool_Id
        });
        this.jobFilter.controls['user'].setValue('EP');
        this.LoadBeelineDataEP();
      }
      if (this.userData.data.credentials.userType === 'AD') {
        this.filterForm = this._formBuilder.group({
          PageSize: 10,
          PageNumber: 1,
          JobStatus: '',
          fromdate: '',
          todate: '',
          search: '',
          totalapplied: '0-All',
          pool_Id: this.data['data'].pk_pool_Id,
          createdBy: this.createdBy,
          Clientsearch: '',
          Citysearch: '',
          Titlesearch: '',
          vkJobStatusAD: 'All',
          VMSJobStatus: 'All'
        });
        this.LoadBeelineDataAD();
      }
    }
  }

  user = '';
  LoadBeelineDataEP() { // load beelin job data

    //console.log(this.userData.data.credentials.userType, 'this.userData.data.credentials.userType');
    this.preloader = true;
    if (this.searchButton == true) {
      this.jobFilter.controls['PageNumber'].setValue(1);
    }
    // this.Service.LoadBeelineData(this.filterForm.value).pipe(first()).subscribe(res => {
    this.enterpriseApis.EnterpriseViewListJobs(this.jobFilter.value).subscribe((res: any) => {
      const tempjobIdSelected = this.jobIdSelected;
      if (res.success) {

        this.preloader = false;
        this.listOfAllRequest = res;

        this.listView = true;
        if (this.listOfAllRequest.data.length > 0) {
          this.paginator.length = this.listOfAllRequest.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
        if (this.searchButton == true) {
          this.paginators.pageIndex = 0;
        }
        this.listOfAllRequest.data.forEach(function (candidate: { selected: boolean; jobId: string }) {
          // here we check condition and select previous data
          if (tempjobIdSelected.length > 0) {
            tempjobIdSelected.forEach(function (Value) {
              if (candidate.jobId === Value.jobId) {
                candidate.selected = true;
              }
            });
          } else {
            candidate.selected = false;
          }
        });
        //console.log('data', this.listOfAllRequest.data);
      }
    });


  }
  LoadBeelineDataAD() { // load beelin job data

    //console.log(this.userData.data.credentials.userType, 'this.userData.data.credentials.userType');
    this.preloader = true;
    if (this.searchButton == true) {
      this.filterForm.controls['PageNumber'].setValue(1);
    }
    // this.Service.LoadBeelineData(this.filterForm.value).pipe(first()).subscribe(res => {
    this.tableService.LoadBeelineData(this.user, this.filterForm.value, this.typePopup, this.jobseekerId).pipe(first()).subscribe(res => {
      const tempjobIdSelected = this.jobIdSelected;
      if (res.success) {
        this.preloader = false;
        this.listOfAllRequest = res;
        this.listView = true;
        if (this.listOfAllRequest.data.length > 0) {
          this.paginator.length = this.listOfAllRequest.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
        if (this.searchButton == true) {
          this.paginators.pageIndex = 0;
        }
        this.listOfAllRequest.data.forEach(function (candidate: { selected: boolean; sourceId: string }) {
          // here we check condition and select previous data
          if (tempjobIdSelected.length > 0) {
            tempjobIdSelected.forEach(function (Value) {
              if (candidate.sourceId === Value.sourceId) {
                candidate.selected = true;
              }
            });
          } else {
            candidate.selected = false;
          }
        });
        //console.log('data', this.listOfAllRequest.data);
      }
    });


  }


  getPaginatorData(e: { pageSize: number; pageIndex: number; }) {
    this.searchButton = false;
    this.paginator.pageIndex = e.pageIndex;
    this.paginator.pageSize = e.pageSize;

    if (this.user == 'VMS') {
      if (this.userData.data.credentials.userType === 'EP') {
        this.jobFilter.controls['user'].setValue('VMS');
        this.jobFilter.controls.PageSize.setValue(e.pageSize);
        this.jobFilter.controls.PageNumber.setValue(e.pageIndex + 1);
        this.LoadBeelineDataEP();
      }
      if (this.userData.data.credentials.userType === 'AD') {
        this.filterForm.controls.PageSize.setValue(e.pageSize);
        this.filterForm.controls.PageNumber.setValue(e.pageIndex + 1);
        this.LoadBeelineDataAD();
      }

    }
    if (this.user == 'EP') {
      if (this.userData.data.credentials.userType === 'EP') {
        this.jobFilter.controls['user'].setValue('EP');
        this.jobFilter.controls.PageSize.setValue(e.pageSize);
        this.jobFilter.controls.PageNumber.setValue(e.pageIndex + 1);
        this.LoadBeelineDataEP();
      }
      if (this.userData.data.credentials.userType === 'AD') {
        this.filterForm.controls.PageSize.setValue(e.pageSize);
        this.filterForm.controls.PageNumber.setValue(e.pageIndex + 1);
        this.LoadBeelineDataAD();
      }
    }
  }

  // select job and push id in array
  selectJob(data, event) {
    //console.log('in selected job', this.jobIdSelected);
    if (this.userData.data.credentials.userType === 'AD') {
      this.selectJobAD(data, event);
    }
    if (this.userData.data.credentials.userType === 'EP') {
      this.selectJobEP(data, event);
    }
  }
  selectJobAD(data, event) {
    //console.log('in selected job', this.jobIdSelected);
    if (event.checked) {
      this.jobIdSelected.push({
        sourceId: data.sourceId,
      });
    } else {
      for (let i = 0; i < this.jobIdSelected.length; i++) {
        //console.log(this.jobIdSelected[i].sourceId);
        if (this.jobIdSelected[i].sourceId === data.sourceId) {
          this.jobIdSelected.splice(i, 1);
        }
      }
      //console.log("After Unselected job", this.jobIdSelected);
    }

  }
  selectJobEP(data, event) {
    //console.log('in selected job', this.jobIdSelected);

    if (event.checked) {
      this.jobIdSelected.push({
        jobId: data.jobId,
      });
    } else {
      for (let i = 0; i < this.jobIdSelected.length; i++) {
        //console.log(this.jobIdSelected[i].jobId);
        if (this.jobIdSelected[i].jobId === data.jobId) {
          this.jobIdSelected.splice(i, 1);
        }
      }
      //console.log('After Unselected job', this.jobIdSelected);
    }
  }

  checkedUnChecked(candidate: { selected: any; }, event: { checked: any; }) {

    candidate.selected = event.checked;
  }



  makeRandom(lengthOfCode: number, possible: string) {
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  // not tasted yet
  SendMail(record: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (this.userData.data.credentials.userType === 'EP') {
      this.SendMailEP(record);
    }
    if (this.userData.data.credentials.userType === 'AD') {
      this.SendMailAd(record);
    }
  }


  SendMailAd(record: any) {
    
    //console.log('SendMail', record);
    const PayrateDrop = this.jobFilter.controls.PayrateDrop.value;
    let tempPayRate: null;
    let tempAnnualSalary: null;
    if (PayrateDrop === 'Pay Rate') {
      tempPayRate = this.jobFilter.controls.Payrate.value;
    } else {
      tempAnnualSalary = this.jobFilter.controls.Payrate.value;
    }
    if (this.jobFilter.controls.PayrateDrop.value !== '') {
      if (this.jobFilter.controls.Payrate.value > 0) {
        if (this.jobFilter.controls.jobTypeBase.value !== '') {
          if (this.jobIdSelected.length !== 0) {
            record.forEach((element) => { // apply loop for multiple jobs
              if (element.selected) {
                // I will write code for check unsubscribe email in job functionality here
                this.stafingagencyService.GetSubscribeUnsubscribeData(this.data.data.emailId, 1).pipe(first()).subscribe(res => {
                  if (res.success) {
                    //console.log('res.data.length', res.data);
                    if (res.data !== undefined) {
                      if (res.data.IsUnsubscribe === false) {
                        this.joblistData = res;
                        this.dialogRef.close();
                        const toastr = this.toastr;
                        const possible = '4334fcs2';
                        const lengthOfCode = 40;
                        // call makeRandom to get verification code for jobseeker;
                        const verifycode = this.makeRandom(lengthOfCode, possible);
                        // call makeRandom to get rejectcode for jobseeker
                        const rejectcode = this.makeRandom(lengthOfCode, possible);
                        this.invitationForm = this._formBuilder.group({  // here we fill the form for save and send mail
                          rejectcode,
                          verifycode,
                          jobId: this.user == 'EP' ? element.sourceId : element.sourceId, // beelineRequestId
                          city: element.city,
                          state: element.state,
                          pay_rate: tempPayRate,
                          annualSalary: tempAnnualSalary,
                          jobseekerId: this.data.data.jobseekerId,
                          jobType: this.jobFilter.controls.jobTypeBase.value,
                          poolId: null,
                          description: element.description,
                          jobTitle: element.title,
                          conpanyName: element.displayName,
                          userEmail: this.data.data.emailId,
                          username: this.data.data.fullName,
                          JobStatus: element.status,
                          postalCode: element.postalCode,
                          createdDate: ['']
                        });
                        //console.log('this.invitationForm.value', this.invitationForm.value);
                        this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

                        // call api for  mail send and save data
                        this.stafingagencyService.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                          this.joblistData = res;
                        });
                      }
                    } else {
                      this.joblistData = res;
                      this.dialogRef.close();
                      const toastr = this.toastr;
                      const possible = '4334fcs2';
                      const lengthOfCode = 40;
                      // call makeRandom to get verification code for jobseeker
                      const verifycode = this.makeRandom(lengthOfCode, possible);
                      // call makeRandom to get rejectcode for jobseeker
                      const rejectcode = this.makeRandom(lengthOfCode, possible);
                      // here we fill the form for save and send mail
                      this.invitationForm = this._formBuilder.group({
                        rejectcode,
                        verifycode,
                        jobId: this.user == 'EP' ? element.sourceId : element.sourceId, // beelineRequestId
                        city: element.city,
                        state: element.state,
                        pay_rate: tempPayRate,
                        annualSalary: tempAnnualSalary,
                        jobseekerId: this.data.data.jobseekerId,
                        jobType: this.jobFilter.controls.jobTypeBase.value,
                        poolId: null,
                        description: element.description,
                        jobTitle: element.title,
                        conpanyName: element.displayName,
                        userEmail: this.data.data.emailId,
                        username: this.data.data.fullName,
                        JobStatus: element.status,
                        postalCode: element.postalCode,
                        createdDate: ['']
                      });
                      //console.log('this.invitationForm.value', this.invitationForm.value);
                      this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
                      // call api for  mail send and save data
                      this.stafingagencyService.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                        this.joblistData = res;
                      });

                    }
                  }
                });

              }
            });
            this.toastr.successToastr('Job sent successfully to job seeker');
            this.dialogRef.close();
            //  remove old code from here
          } else {
            this.toastr.errorToastr('Select atleast one Job.');
            return false;
          }
        } else {
          this.toastr.errorToastr('Please select Job Type.');
          return false;
        }
      } else {
        this.toastr.errorToastr('Please Enter valid Pay rate.');
        return false;
      }
    } else {
      this.toastr.errorToastr('Please select Pay rate or Annual Salary.');
      return false;
    }
  }
  SendMailEP(record: any) {
    //console.log('SendMail', record);
    const PayrateDrop = this.jobFilter.controls.PayrateDrop.value;
    let tempPayRate: null;
    let tempAnnualSalary: null;
    if (PayrateDrop === 'Pay Rate') {
      tempPayRate = this.jobFilter.controls.Payrate.value;
    } else {
      tempAnnualSalary = this.jobFilter.controls.Payrate.value;
    }
    if (this.jobFilter.controls.PayrateDrop.value !== '') {
      if (this.jobFilter.controls.Payrate.value > 0) {
        if (this.jobFilter.controls.jobTypeBase.value !== '') {
          if (this.jobIdSelected.length !== 0) {
            record.forEach((element) => { // apply loop for multiple jobs
              if (element.selected) {
                if (this.user = 'VMS') {
                  element.jobId = element.requestId;
                }
                // I will write code for check unsubscribe email in job functionality here
                this.Service.GetSubscribeUnsubscribeData(this.data.data.emailId, 1).pipe(first()).subscribe(res => {
                  if (res.success) {
                    //console.log('res.data.length', res.data);
                    if (res.data !== undefined) {
                      if (res.data.IsUnsubscribe === false) {
                        this.joblistData = res;
                        this.dialogRef.close();
                        const toastr = this.toastr;
                        const possible = '4334fcs2';
                        const lengthOfCode = 40;
                        // call makeRandom to get verification code for jobseeker;
                        const verifycode = this.makeRandom(lengthOfCode, possible);
                        // call makeRandom to get rejectcode for jobseeker
                        const rejectcode = this.makeRandom(lengthOfCode, possible);
                        this.invitationForm = this._formBuilder.group({  // here we fill the form for save and send mail
                          rejectcode,
                          verifycode,
                          jobId: element.jobId, // beelinejobId
                          city: element.city,
                          state: element.state,
                          pay_rate: tempPayRate,
                          annualSalary: tempAnnualSalary,
                          jobseekerId: this.data.data.jobseekerId,
                          jobType: this.jobFilter.controls.jobTypeBase.value,
                          poolId: null,
                          description: element.description,
                          jobTitle: element.title,
                          conpanyName: element.companyName,
                          userEmail: this.data.data.emailId,
                          username: this.data.data.fullName,
                          JobStatus: element.status,
                          postalCode: element.postalCode,
                          createdDate: ['']
                        });
                        //console.log('this.invitationForm.value', this.invitationForm.value);
                        this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
                        // call api for  mail send and save data
                        this.Service.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                          this.joblistData = res;
                        });
                      }
                    } else {
                      this.joblistData = res;
                      this.dialogRef.close();
                      const toastr = this.toastr;
                      const possible = '4334fcs2';
                      const lengthOfCode = 40;
                      // call makeRandom to get verification code for jobseeker
                      const verifycode = this.makeRandom(lengthOfCode, possible);
                      // call makeRandom to get rejectcode for jobseeker
                      const rejectcode = this.makeRandom(lengthOfCode, possible);
                      // here we fill the form for save and send mail
                      this.invitationForm = this._formBuilder.group({
                        rejectcode,
                        verifycode,
                        jobId: element.jobId, // beelinejobId
                        city: element.city,
                        state: element.state,
                        pay_rate: tempPayRate,
                        annualSalary: tempAnnualSalary,
                        jobseekerId: this.data.data.jobseekerId,
                        jobType: this.jobFilter.controls.jobTypeBase.value,
                        poolId: null,
                        description: element.description,
                        jobTitle: element.title,
                        conpanyName: element.companyName,
                        userEmail: this.data.data.emailId,
                        username: this.data.data.fullName,
                        JobStatus: element.status,
                        postalCode: element.postalCode,
                        createdDate: ['']
                      });
                      //console.log('this.invitationForm.value', this.invitationForm.value);
                      this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
                      // call api for  mail send and save data
                      this.Service.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                        this.joblistData = res;
                      });

                    }
                  }
                });
              }
            });
            this.toastr.successToastr('Job sent successfully to job seeker');
            this.dialogRef.close();
            //  remove old code from here
          } else {
            this.toastr.errorToastr('Select atleast one Job.');
            return false;
          }
        } else {
          this.toastr.errorToastr('Please select Job Type.');
          return false;
        }
      } else {
        this.toastr.errorToastr('Please Enter valid Pay rate.');
        return false;
      }
    } else {
      this.toastr.errorToastr('Please select Pay rate or Annual Salary.');
      return false;
    }
  }






  // this function cal for sending mail to pool mamber to multiple jobs
  SendPoolMail(record: any, poolId: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (this.userData.data.credentials.userType === 'EP') {
      this.SendPoolMailEP(record, poolId);
    }
    if (this.userData.data.credentials.userType === 'AD') {
      this.SendPoolMailAD(record, poolId);
    }

  }
  SendPoolMailAD(record: any, poolId: any) {
    const PayrateDrop = this.jobFilter.controls.PayrateDrop.value;
    let tempPayRate: null;
    let tempAnnualSalary: null;
    if (PayrateDrop === 'Pay Rate') {
      tempPayRate = this.jobFilter.controls.Payrate.value;
    } else {
      tempAnnualSalary = this.jobFilter.controls.Payrate.value;
    }
    if (this.jobFilter.controls.PayrateDrop.value !== '') {
      if (this.jobFilter.controls.Payrate.value > 0) {
        if (this.jobFilter.controls.jobTypeBase.value !== '') {
          // here we check payrate and value is selected or not
          if (this.jobIdSelected.length > 0) { // use to check atleast one job selected
            // select jobseekers from database by pool id

            this.tableService.getSavedJobseekerIdInpool(poolId).pipe(first()).subscribe(res => {
              if (res.success) {

                this.jobseekerlist = res.data;
                this.preloader = false;
                if (this.jobseekerlist.length > 0) {
                  this.paginator.length = this.jobseekerlist[0].totalCount;
                }
                const tempjobseekerlist = this.jobseekerlist;
                //console.log('tempjobseekerlist', tempjobseekerlist);

                record.forEach((element) => { // apply loop for multiple jobs
                  if (element.selected) {
                    //I will write code for check unsubscribe email in job functionality here 
                    tempjobseekerlist.forEach(jobseekr => { // apply loop for to send mail to jobseekers
                      //console.log('jobseekr.emailId', jobseekr.emailId);
                      this.stafingagencyService.GetSubscribeUnsubscribeData(jobseekr.emailId, 1).pipe(first()).subscribe(res => {
                        if (res.success) {
                          //console.log('res.data.length', res.data);
                          if (res.data !== undefined) {
                            if (res.data.IsUnsubscribe == false) {
                              // //console.log('Run In Success2', res.data.emailId);
                              // //console.log('Run In Success2', res.data.IsUnsubscribe);
                              this.joblistData = res;
                              this.dialogRef.close();
                              const toastr = this.toastr;
                              const possible = '4334fcs2';
                              const lengthOfCode = 40;
                              // call makeRandom to get verification code for jobseeker;
                              const verifycode = this.makeRandom(lengthOfCode, possible);
                              // call makeRandom to get rejectcode for jobseeker
                              const rejectcode = this.makeRandom(lengthOfCode, possible);
                              //console.log('jobid ki value ,==== ', record.sourceId)
                              this.invitationForm = this._formBuilder.group({  // here we fill the form for save and send mail
                                rejectcode,
                                verifycode,
                                jobId: this.user == 'EP' ? element.sourceId : element.sourceId, // beelineRequestId
                                city: element.city,
                                state: element.state,
                                pay_rate: tempPayRate,
                                annualSalary: tempAnnualSalary,
                                jobseekerId: jobseekr.jobseeker_id,
                                jobType: this.jobFilter.controls.jobTypeBase.value,
                                poolId,
                                description: element.description,
                                jobTitle: element.title,
                                conpanyName: element.displayName,
                                userEmail: jobseekr.emailId,
                                username: jobseekr.fullName,
                                JobStatus: element.status,
                                postalCode: element.postalCode,
                                createdDate: ['']
                              });
                              //console.log('this.invitationForm.value', this.invitationForm.value);
                              this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
                              
                              // call api for  mail send and save data
                              this.stafingagencyService.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                                this.joblistData = res;
                              });
                            }
                          } else {
                            
                            // this api for  get jobseeker list for invitation_request
                            this.stafingagencyService.getjobseekerlistformail(element.sourceId, jobseekr.jobseeker_id).subscribe(res => {
                              this.joblistData = res;

                              if (this.joblistData.data.length > 0) {
                                for (var i = 0; i < this.joblistData.data.length; i++) {
                                  if (this.joblistData.data[i].jobseekerStatus == true) {
                                    this.counter++
                                  }
                                }

                                  if (this.counter > 0) {
                                    this.counter=0;
                                    return false
                                  }
                                  else {
                                    this.joblistData = res;
                            this.dialogRef.close();
                            const toastr = this.toastr;
                            const possible = '4334fcs2';
                            const lengthOfCode = 40;
                            // call makeRandom to get verification code for jobseeker
                            let verifycode = this.makeRandom(lengthOfCode, possible);
                            // call makeRandom to get rejectcode for jobseeker
                            let rejectcode = this.makeRandom(lengthOfCode, possible);
                            this.invitationForm = this._formBuilder.group({  // here we fill the form for save and send mail
                              rejectcode,
                              verifycode,
                              jobId: this.user == 'EP' ? element.sourceId : element.sourceId, // beelineRequestId
                              city: element.city,
                              state: element.state,
                              pay_rate: tempPayRate,
                              annualSalary: tempAnnualSalary,
                              jobseekerId: jobseekr.jobseeker_id,
                              jobType: this.jobFilter.controls.jobTypeBase.value,
                              poolId,
                              description: element.description,
                              jobTitle: element.title,
                              conpanyName: element.displayName,
                              userEmail: jobseekr.emailId,
                              username: jobseekr.fullName,
                              JobStatus: element.status,
                              postalCode: element.postalCode,
                              createdBy: this.userData.data.credentials.userId,
                              createdDate: ['']
                            });
                            //console.log('this.invitationForm.value', this.invitationForm.value);
                            this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

                                    // call api for  mail send and save data
                                    this.stafingagencyService.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                                      this.joblistData = res;
                                    });
                                  }

                               // }
                              }
                              else {
                                this.joblistData = res;
                            this.dialogRef.close();
                            const toastr = this.toastr;
                            const possible = '4334fcs2';
                            const lengthOfCode = 40;
                            // call makeRandom to get verification code for jobseeker
                            let verifycode = this.makeRandom(lengthOfCode, possible);
                            // call makeRandom to get rejectcode for jobseeker
                            let rejectcode = this.makeRandom(lengthOfCode, possible);
                            this.invitationForm = this._formBuilder.group({  // here we fill the form for save and send mail
                              rejectcode,
                              verifycode,
                              jobId: this.user == 'EP' ? element.sourceId : element.sourceId, // beelineRequestId
                              city: element.city,
                              state: element.state,
                              pay_rate: tempPayRate,
                              annualSalary: tempAnnualSalary,
                              jobseekerId: jobseekr.jobseeker_id,
                              jobType: this.jobFilter.controls.jobTypeBase.value,
                              poolId,
                              description: element.description,
                              jobTitle: element.title,
                              conpanyName: element.displayName,
                              userEmail: jobseekr.emailId,
                              username: jobseekr.fullName,
                              JobStatus: element.status,
                              postalCode: element.postalCode,
                              createdBy: this.userData.data.credentials.userId,
                              createdDate: ['']
                            });
                            //console.log('this.invitationForm.value', this.invitationForm.value);
                            this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

                                // call api for  mail send and save data
                                this.stafingagencyService.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                                  this.joblistData = res;
                                });
                              }


                            });
                          }
                        }
                      });
                    });

                  }
                });
                this.toastr.successToastr('Job invitation sent to group successfully.');
                this.dialogRef.close();
              }
            });


          } else {
            this.toastr.errorToastr('Select atleast one Job.');
            return false;
          }
        } else {
          this.toastr.errorToastr('Please select Job Type.');
          return false;
        }

      } else {
        this.toastr.errorToastr('Please Enter valid Pay rate.');
        return false;
      }
    } else {
      this.toastr.errorToastr('Please select Pay rate or Annual Salary.');
      return false;
    }
  }
  SendPoolMailEP(record: any, poolId: any) {
 
    const PayrateDrop = this.jobFilter.controls.PayrateDrop.value;
    let tempPayRate: null;
    let tempAnnualSalary: null;
    if (PayrateDrop === 'Pay Rate') {
      tempPayRate = this.jobFilter.controls.Payrate.value;
    } else {
      tempAnnualSalary = this.jobFilter.controls.Payrate.value;
    }
    if (this.jobFilter.controls.PayrateDrop.value !== '') {
      if (this.jobFilter.controls.Payrate.value > 0) {
        if (this.jobFilter.controls.jobTypeBase.value !== '') {
          // here we check payrate and value is selected or not
          if (this.jobIdSelected.length > 0) { // use to check atleast one job selected
            // select jobseekers from database by pool id
            this.Service.getSavedJobseekerIdInpool(poolId).pipe(first()).subscribe(res => {
              if (res.success) {

                this.jobseekerlist = res.data;
                this.preloader = false;
                if (this.jobseekerlist.length > 0) {
                  this.paginator.length = this.jobseekerlist[0].totalCount;
                }
                const tempjobseekerlist = this.jobseekerlist;
                //console.log('tempjobseekerlist', tempjobseekerlist);

                record.forEach((element) => { // apply loop for multiple jobs
                  if (element.selected) {
                    //I will write code for check unsubscribe email in job functionality here 
                    tempjobseekerlist.forEach(jobseekr => { // apply loop for to send mail to jobseekers
                      //console.log('jobseekr.emailId', jobseekr.emailId);
                      this.Service.GetSubscribeUnsubscribeData(jobseekr.emailId, 1).pipe(first()).subscribe(res => {
                        if (res.success) {
                          //console.log('res.data.length', res.data);
                          if (res.data !== undefined) {
                            if (res.data.IsUnsubscribe == false) {
                              // //console.log('Run In Success2', res.data.emailId);
                              // //console.log('Run In Success2', res.data.IsUnsubscribe);
                              this.joblistData = res;
                              this.dialogRef.close();
                              const toastr = this.toastr;
                              const possible = '4334fcs2';
                              const lengthOfCode = 40;
                              // call makeRandom to get verification code for jobseeker;
                              const verifycode = this.makeRandom(lengthOfCode, possible);
                              // call makeRandom to get rejectcode for jobseeker
                              const rejectcode = this.makeRandom(lengthOfCode, possible);
                              this.invitationForm = this._formBuilder.group({  // here we fill the form for save and send mail
                                rejectcode,
                                verifycode,
                                jobId: this.user == 'EP' ? element.sourceId : element.requestId, // beelinejobId
                                city: element.city,
                                state: element.state,
                                pay_rate: tempPayRate,
                                annualSalary: tempAnnualSalary,
                                jobseekerId: jobseekr.jobseeker_id,
                                jobType: this.jobFilter.controls.jobTypeBase.value,
                                poolId,
                                description: element.description,
                                jobTitle: element.jobTitle,
                                conpanyName: element.companyName,
                                userEmail: jobseekr.emailId,
                                username: jobseekr.fullName,
                                JobStatus: element.status,
                                postalCode: element.postalCode,
                                createdDate: ['']
                              });
                              //console.log('this.invitationForm.value', this.invitationForm.value);
                              this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

                              // call api for  mail send and save data
                              this.Service.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                                this.joblistData = res;
                              });
                            }
                          } else {
                           

                            // this.joblistData = res;
                            // this.dialogRef.close();
                            // const toastr = this.toastr;
                            // const possible = '4334fcs2';
                            // const lengthOfCode = 40;
                            // // call makeRandom to get verification code for jobseeker
                            // let verifycode = this.makeRandom(lengthOfCode, possible);
                            // // call makeRandom to get rejectcode for jobseeker
                            // let rejectcode = this.makeRandom(lengthOfCode, possible);
                            // this.invitationForm = this._formBuilder.group({  // here we fill the form for save and send mail
                            //   rejectcode,
                            //   verifycode,
                            //   jobId: this.user == 'EP' ? element.sourceId : element.requestId, // beelinejobId
                            //   city: element.city,
                            //   state: element.state,
                            //   pay_rate: tempPayRate,
                            //   annualSalary: tempAnnualSalary,
                            //   jobseekerId: jobseekr.jobseeker_id,
                            //   jobType: this.jobFilter.controls.jobTypeBase.value,
                            //   poolId,
                            //   description: element.description,
                            //   jobTitle: element.jobTitle,
                            //   conpanyName: element.companyName,
                            //   userEmail: jobseekr.emailId,
                            //   username: jobseekr.fullName,
                            //   JobStatus: element.status,
                            //   postalCode: element.postalCode,
                            //   createdBy: this.userData.data.credentials.CreatedBy,
                            //   createdDate: ['']
                            //   //verifyUrl: `${this.baseUrl}/invitation_result/${verifycode}`,
                            //   //rejectUrl: `${this.baseUrl}/invitation_result/${rejectcode}`,
                            //   //SubscribeUnsubscribe: `${this.baseUrl}/sus`
                            //   // verifyUrl: `http://localhost:4200/invitation_result/${verifycode}`,
                            //   // rejectUrl: `http://localhost:4200/invitation_result/${rejectcode}`,
                            //   // SubscribeUnsubscribe: `http://localhost:4200/sus`

                            // });
                            // //console.log('this.invitationForm.value', this.invitationForm.value);
                            // this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
                            // // call api for  mail send and save data
                            // this.Service.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                            //   this.joblistData = res;
                            // });

                            // this api for  get jobseeker list for invitation_request
                            this.stafingagencyService.getjobseekerlistformail(element.jobId, jobseekr.jobseeker_id).subscribe(res => {
                              this.joblistData = res;

                              if (this.joblistData.data.length > 0) {
                                for (var i = 0; i < this.joblistData.data.length; i++) {
                                  if (this.joblistData.data[i].jobseekerStatus == true) {
                                    this.counter++
                                  }
                                }

                                  if (this.counter > 0) {
                                    this.counter=0;
                                    return false
                                  }
                                  else {
                                    this.joblistData = res;
                            this.dialogRef.close();
                            const toastr = this.toastr;
                            const possible = '4334fcs2';
                            const lengthOfCode = 40;
                            // call makeRandom to get verification code for jobseeker
                            let verifycode = this.makeRandom(lengthOfCode, possible);
                            // call makeRandom to get rejectcode for jobseeker
                            let rejectcode = this.makeRandom(lengthOfCode, possible);
                            this.invitationForm = this._formBuilder.group({  // here we fill the form for save and send mail
                              rejectcode,
                              verifycode,
                              jobId: this.user == 'EP' ? element.sourceId : element.jobId, // beelinejobId
                              city: element.city,
                              state: element.state,
                              pay_rate: tempPayRate,
                              annualSalary: tempAnnualSalary,
                              jobseekerId: jobseekr.jobseeker_id,
                              jobType: this.jobFilter.controls.jobTypeBase.value,
                              poolId,
                              description: element.description,
                              jobTitle: element.jobTitle,
                              conpanyName: element.companyName,
                              userEmail: jobseekr.emailId,
                              username: jobseekr.fullName,
                              JobStatus: element.status,
                              postalCode: element.postalCode,
                              createdBy: this.userData.data.credentials.CreatedBy,
                              createdDate: ['']
                              //verifyUrl: `${this.baseUrl}/invitation_result/${verifycode}`,
                              //rejectUrl: `${this.baseUrl}/invitation_result/${rejectcode}`,
                              //SubscribeUnsubscribe: `${this.baseUrl}/sus`
                              // verifyUrl: `http://localhost:4200/invitation_result/${verifycode}`,
                              // rejectUrl: `http://localhost:4200/invitation_result/${rejectcode}`,
                              // SubscribeUnsubscribe: `http://localhost:4200/sus`

                            });
                            //console.log('this.invitationForm.value', this.invitationForm.value);
                            this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
                            // call api for  mail send and save data
                            this.Service.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                              this.joblistData = res;
                            });
                          }
                              }
                              else {
                                this.joblistData = res;
                                this.dialogRef.close();
                                const toastr = this.toastr;
                                const possible = '4334fcs2';
                                const lengthOfCode = 40;
                                // call makeRandom to get verification code for jobseeker
                                let verifycode = this.makeRandom(lengthOfCode, possible);
                                // call makeRandom to get rejectcode for jobseeker
                                let rejectcode = this.makeRandom(lengthOfCode, possible);
                                this.invitationForm = this._formBuilder.group({  // here we fill the form for save and send mail
                                  rejectcode,
                                  verifycode,
                                  jobId: this.user == 'EP' ? element.sourceId : element.jobId, // beelinejobId
                                  city: element.city,
                                  state: element.state,
                                  pay_rate: tempPayRate,
                                  annualSalary: tempAnnualSalary,
                                  jobseekerId: jobseekr.jobseeker_id,
                                  jobType: this.jobFilter.controls.jobTypeBase.value,
                                  poolId,
                                  description: element.description,
                                  jobTitle: element.jobTitle,
                                  conpanyName: element.companyName,
                                  userEmail: jobseekr.emailId,
                                  username: jobseekr.fullName,
                                  JobStatus: element.status,
                                  postalCode: element.postalCode,
                                  createdBy: this.userData.data.credentials.CreatedBy,
                                  createdDate: ['']
                                  //verifyUrl: `${this.baseUrl}/invitation_result/${verifycode}`,
                                  //rejectUrl: `${this.baseUrl}/invitation_result/${rejectcode}`,
                                  //SubscribeUnsubscribe: `${this.baseUrl}/sus`
                                  // verifyUrl: `http://localhost:4200/invitation_result/${verifycode}`,
                                  // rejectUrl: `http://localhost:4200/invitation_result/${rejectcode}`,
                                  // SubscribeUnsubscribe: `http://localhost:4200/sus`
    
                                });
                                //console.log('this.invitationForm.value', this.invitationForm.value);
                                this.invitationForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
                                // call api for  mail send and save data
                                this.Service.invitationSendAPI(this.invitationForm.value).subscribe(res => {
                                  this.joblistData = res;
                                });
                              }

                            });             
                          }
                        }
                      });
                    });

                  }
                });
                this.toastr.successToastr('Job sent successfully to job seeker');
                this.dialogRef.close();
              }
            });

          } else {
            this.toastr.errorToastr('Select atleast one Job.');
            return false;
          }
        } else {
          this.toastr.errorToastr('Please select Job Type.');
          return false;
        }

      } else {
        this.toastr.errorToastr('Please Enter valid Pay rate.');
        return false;
      }
    } else {
      this.toastr.errorToastr('Please select Pay rate or Annual Salary.');
      return false;
    }
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

}




