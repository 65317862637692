import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JwtServiceService } from './jwt-service.service';
import { ToastrManager  } from 'ng6-toastr-notifications';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
// import { environment } from '';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  @Output() isLogin: EventEmitter<any> = new EventEmitter();
  currentUser = new BehaviorSubject(null);
  //refreshToken: refreshToken = new refreshToken();
  constructor(private jwtService:JwtServiceService, private toastr:ToastrManager, private userService:UserService) { 
    // if (localStorage.getItem(this.key)) {
    //   const data = JSON.parse(localStorage.getItem(this.key));
    //   this.currentUser.next(data);
    // }
  }

  isTokenExpired(token?: string): boolean {  
    if (!token) { token = this.getToken(); }
    if (!token) { return true; }
    return this.getTokeStatus();
  }

  getToken() {
    const localToken = window.localStorage['token'];
    return localToken;
  }

  getTokeStatus() { 
    const now = Date.now().valueOf() / 1000;
    //console.log('now', now);
    const token = this.jwtService.getToken();
    const decoded = this.jwtService.getDecodedAccessToken(token);

    const diffValue = (decoded.exp - now);
    if (diffValue > 0) {
      const diffmint = diffValue / 60;
      if (diffmint <= environment.refreshTokenMaxTime && diffmint >= environment.refreshTokenMinTime) {
        const tokenValue = window.localStorage['token'];

        this.userService.RefreshToken(tokenValue).subscribe(response => {
          try {
            if (response.success == true) {
              localStorage.setItem('islogin', 'true');
              this.jwtService.saveToken(response.data, true);
              //const userid = this.jwtService.getCurrentUserId();
            }
            else {
              if (response.Message) {
                this.toastr.successToastr(response.Message);
              } else {
                this.toastr.errorToastr('some error occured');
              }
            }
          }
          catch (e) {
            this.toastr.errorToastr('some error occured');
          }
        });
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    }
    return false;
  }

  token = 'token';
  udata = 'udata';
  Logout() {
    localStorage.removeItem(this.token);
    localStorage.removeItem(this.udata);

  }
}
