import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EnterpriseService } from '../enterprise.service';
import { first } from 'rxjs/operators';
import {AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';
import { ViewChild } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';



@Component({
  selector: 'app-add-agency-in-group',
  templateUrl: './add-agency-in-group.component.html',
  styleUrls: ['./add-agency-in-group.component.css']
})
export class AddAgencyInGroupComponent implements OnInit {
  @ViewChild(MatPaginator,{static : false} ) paginators: MatPaginator;
  preloader: boolean;
  groupRequest: any;
  AgencyIdSelected: any = [];
  loadForm: FormGroup;
  paginator = {
    pageSize: 5,
    length: 0,
    pageSizeOptions: [10, 20, 50, 100],
    pageIndex: 0
  };
  listOfAllRequest: any;
  listView: boolean;
  areAllSelected = false;

  constructor(private dialogRef: MatDialogRef<AddAgencyInGroupComponent>,
    private toastr: ToastrManager, private api: EnterpriseService, public dialog: MatDialog, private _router: Router,
    private _formBuilder: FormBuilder,
      private AuthenticationService: AuthenticationService,private shareservice:ShareService,
      @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.AuthenticationService.jwtTokenExpCheck();
    this.groupRequest = JSON.parse(localStorage.getItem('CurrentGroupRequest'));
    ////console.log('this.data',this.data);
  }

  ngOnInit() {
 
    this.loadInit();
    if(this.data.update==undefined){
      this.data.update=false
    }
    //console.log('this.data', this.data.update);
    if (this.data.update) {
      this.loadAllStaffingAgenciesForUpdate();

    } else {
      this.loadAllStaffingAgencies();
    }
  }
  loadInit() {
    this.loadForm = this._formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      totalcandidates: '0-All',
      pageSize: 5,
      PageNumber: 1,
      actiontype: 'Insert',
      groupId: this.groupRequest.staffingAgencyGroup_Id
    });
  }

  resetstaffingFilter() {

    this.loadForm = this._formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      totalcandidates: '0-All',
      pageSize: 5,
      PageNumber: 1,
      actiontype: 'Insert',
      groupId: this.groupRequest.staffingAgencyGroup_Id
    });
    this.paginator.pageSize=5;
    this.loadAllStaffingAgencies();
    this.paginators.pageIndex = 0;
  }

  resetstaffingFilter1() {

        this.loadForm = this._formBuilder.group({
          fromdate: '',
          todate: '',
          search: '',
          totalcandidates: '0-All',
          pageSize: 5,
          PageNumber: 1,
          actiontype: 'Update',
          groupId: this.groupRequest.staffingAgencyGroup_Id
        });
        this.paginator.pageSize=5;
        this.loadAllStaffingAgencies();
        this.paginators.pageIndex = 0;
      }

  getpaginatorData(e) {

    //this.searchButton = false;
    // this.body.controls['PageSize'].setValue(e.pageSize);
    // this.body.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.loadForm.controls['pageSize'].setValue(e.pageSize);
    this.loadForm.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.loadAllStaffingAgencies();
  }

  loadAllStaffingAgencies() { // load beelin job data

    this.preloader = true;
    this.api.GetSAForAgencyGroup(this.loadForm.value).pipe(first()).subscribe(res => {
      if (res.success) {
        this.preloader = false;
        this.listOfAllRequest = res.data;
        this.areAllSelected = false;
        this.listView = true;
        if (this.listOfAllRequest.length > 0) {
          this.paginator.length = this.listOfAllRequest[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
      }
    });
  }
  loadAllStaffingAgenciesForUpdate() { // load beelin job data
    this.loadForm.controls.actiontype.setValue('Update');
    this.loadAllStaffingAgencies();
  }
  selectJob(data, event) {
    if (event.checked) {
      this.AgencyIdSelected.push({
        'staffingagencyId': data.staffingagencyId,
      });
    } else {
      for (let i = 0; i < this.AgencyIdSelected.length; i++) {
        //console.log(this.AgencyIdSelected[i].staffingagencyId);
        if (this.AgencyIdSelected[i].staffingagencyId === data.staffingagencyId) {
          this.AgencyIdSelected.splice(i, 1);
        }
      }
      //console.log("After Unselected job", this.AgencyIdSelected);
    }
  }

  checkedUnChecked(candidate: { staffingagencyId: any; }, event: { checked: any; }) {
    let notChecked = 0;
    this.listOfAllRequest.forEach((item: { staffingagencyId: any; selected: any; }) => {
      if (item.staffingagencyId === candidate.staffingagencyId) {
        item.selected = event.checked;
      }
      if (!item.selected) {
        notChecked++;
      }
    });
    this.areAllSelected = (notChecked === 0);
  }
  toggleAllSelection() {
    this.areAllSelected = !this.areAllSelected;
    const isAllSelected = this.areAllSelected;
    this.listOfAllRequest.forEach((item: { selected: boolean; }) => {
      item.selected = isAllSelected;
    });
  }
  // AddAgencyIngroup(record: any) {
  //   let selectedCount1 = 0;
  //   const recordlenth = record.length;
  //   record.forEach((count) => {
  //     if (count.selected === false) {
  //       selectedCount1 = selectedCount1 + 1;
  //     }
  //   });
  //   if (recordlenth !== selectedCount1) {
  //     record.forEach((element) => {
  //       if (element.selected) {
  //         element.enterpriseId = this.data.staffingagencyId; //enterpriseId;
  //         element.isActive = false;
  //         this.api.insertUpdateAssignJobseeker(element).pipe(first()).subscribe(res => {
  //           if (res.success) {
  //             // this.tostr.successToastr(res.message, 'Success!');
  //           } else {
  //             this.toastr.errorToastr(res.message, 'OPPS!');
  //           }
  //         });
  //       }
  //     });
  //     this.toastr.successToastr('Data Save Successfully', 'Success!');
  //     //this._router.navigate(['/assignSeeker']);
  //   } else {
  //     this.toastr.errorToastr('Please Select At least One Candidate');
  //     return false;

  //   }

  // }
  AddAgencyIngroup(record: any) {
    let selectedCount1 = 0;
    const recordlenth = record.length;
    //console.log('recordlenth', recordlenth);
    record.forEach((count) => {
      if (count.selected === false) {
        selectedCount1 = selectedCount1 + 1;
      }

    });
    if (recordlenth !== selectedCount1) {
      ////console.log(this.Savedjobseekerlist);
      record.forEach((element) => {
        if (element.selected) {
          element.staffingAgencyGroup_Id = this.groupRequest.staffingAgencyGroup_Id;
          if (this.data.update) {
          element.isAdded = false;
          } else {
            element.isAdded = true;
          }
          this.api.SaveAgencyInGroupdetails(element).pipe(first()).subscribe(res => {
            if (res.success) {
            } else {
              this.toastr.errorToastr(res.message, 'OPPS!');
            }
          });
        }
      });
      this.toastr.successToastr('Data Save Successfully', 'Success!');
      this.clearData();
      //window.location.reload();
      //this._router.navigate(['/ep/enterprise/staffingagencygroupdashboard']);
      //this._router.navigate(['/ep/enterprise/staffingagencygroup']);
    } else {
      this.toastr.errorToastr('Please Select At least One Agency');
      return false;
    }
  }
  clearData() {
   // this._router.navigate(['/ep/enterprise/staffingagencygroup']);
    //this._router.navigate(['/ep/enterprise/staffingagencygroupdashboard']);
    this.dialogRef.close();
  }

  getPaginatorData(e) {
    this.loadForm.controls['pageSize'].setValue(e.pageSize);
    this.loadForm.controls['PageNumber'].setValue(e.pageIndex + 1);
    this.loadAllStaffingAgencies();
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

}
