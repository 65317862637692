import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './auth/home/home.component';
import { ErrorpageComponent } from './exta/errorpage/errorpage.component';
import { GuestBrowseJobComponent } from './exta/guest-browse-job/guest-browse-job.component';
import { GuestContactUsComponent } from './exta/guest-contact-us/guest-contact-us.component';

import { AboutUsComponent } from './exta/about-us/about-us.component';
import { InvitaionLinkResultComponent } from './share-module/invitaion-link-result/invitaion-link-result.component';
import { StaffingInvitationLinkComponent } from './share-module/staffing-invitation-link/staffing-invitation-link.component';
import { SubscribeUnsubscribeComponent } from './share-module/subscribe-unsubscribe/subscribe-unsubscribe.component';
import { ProjectbudgetapprovalComponent } from './share-module/projectbudgetapproval/projectbudgetapproval.component';
import { ProjectapprovalComponent } from './share-module/projectapproval/projectapproval.component';
import { FeedbackComponent } from './share-module/feedback/feedback.component';
import { MyJobListEnterpriseComponent } from './enterprise/my-job-list-enterprise/my-job-list-enterprise.component';
import { EnterpriseDashboardComponent } from './enterprise/enterprise-dashboard/enterprise-dashboard.component';
import { StaffingAgencyEnterpriseComponent } from './enterprise/staffing-agency-enterprise/staffing-agency-enterprise.component';
import { StaffingAgencyDetailsComponent } from './enterprise/staffing-agency-details/staffing-agency-details.component';
import { JobseekerEnterpriseComponent } from './enterprise/jobseeker-enterprise/jobseeker-enterprise.component';
import {AuthGuard} from 'src/app/auth/auth.guard'
// Lazy loading ------------------

export const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: 'st',loadChildren: () => import('./staffing-agency/staffing-agency.module').then(m => m.StaffingAgencyModule), canActivate: [AuthGuard]},
  { path: 'auth', 
  loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
},
  {
    path: 'ep',
    loadChildren: () => import('./enterprise/enterprise.module').then(m => m.EnterpriseModule),canActivate: [AuthGuard] 
  },
  {
    path: 'js',
    loadChildren: () => import('./JobSeeker/job-seeker.module').then(m => m.JobSeekerModule),canActivate: [AuthGuard]
  },
  {
    path: 'ad',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),canActivate: [AuthGuard]
  },
  { path: 'jobs', component: GuestBrowseJobComponent },
  { path: 'contactus', component: GuestContactUsComponent },
  { path: 'invitation_result/:id/:type/:createdby', component: InvitaionLinkResultComponent},
  { path: 'invitation_result/:id/:type', component: InvitaionLinkResultComponent},
  { path: 'invitation_result/:id', component: InvitaionLinkResultComponent},
  { path: 'Staffinginvitation_result/:id/:type',component: StaffingInvitationLinkComponent},
  { path: 'Staffinginvitation_result/:id',component: StaffingInvitationLinkComponent},
  { path: 'sus',  pathMatch: 'full', component: SubscribeUnsubscribeComponent },
  { path: 'approveprojectbudget/:id', component: ProjectbudgetapprovalComponent },
  { path: 'approveproject/:id', component: ProjectapprovalComponent },
  { path:'feed_back/:emailid',component:FeedbackComponent},
  
  // New Vms changes 
  //{ path: 'dashboard', pathMatch: 'full', component: EnterpriseDashboardComponent },
  // { path: 'jobRequest/detail/id/:id', component: AddNewJobComponent },
  // { path: 'jobRequest/Preview/id/:id', component: PreviewJobComponent },
  // { path: 'supplier/id/:id', pathMatch: 'full', component: StaffingAgencyDetailsComponent },

  // { path: '**', component: ErrorpageComponent },
  // {
  //   path: 'ep',
  //   loadChildren: () => import('./enterprise/enterprise.module').then(m => m.EnterpriseModule),
    
  // },
  // {
  //   path: 'js',
  //   // loadChildren: './JobSeeker/job-seeker.module#JobSeekerModule'
  //   loadChildren: () => import('./JobSeeker/job-seeker.module').then(m => m.JobSeekerModule),

  // },
  // {
  //   path: 'ad',
  //   // loadChildren: './admin/admin.module#AdminModule'
  //   loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),

  // },
  { path: 'aboutus', pathMatch: 'full', component: AboutUsComponent },
  { path: '**', component: ErrorpageComponent }


];
export const appRouteModule = RouterModule.forRoot(routes); 
