import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnterpriseService } from '../enterprise.service';
import {AuthenticationService } from '../../app.service';


export interface DialogData {
  staffingagencyId: string;
  firstName: string;
  lastName: string;
  contactNo: string;
  emailId: string;
  address: string;
  companyName: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

@Component({
  selector: 'app-staffing-agency-pop-up',
  templateUrl: './staffing-agency-pop-up.component.html',
  styleUrls: ['./staffing-agency-pop-up.component.css']
})

export class StaffingAgencyPopUpComponent implements OnInit {
  baseUrl = '';
  isDataready = false;
  constructor(
    private tableService: EnterpriseService,
    private dialogRef: MatDialogRef<StaffingAgencyPopUpComponent>,
    private AuthenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
  
 this.AuthenticationService.jwtTokenExpCheck();
  }

  ngOnInit() {
    
    this.tableService.staffingagencyDetailview(this.data.staffingagencyId).subscribe(res => {
      if (res.success) {
        this.data = res.data.staffingdetails;
      }
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
