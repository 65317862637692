import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
// import { LoginComponent } from './component/login/login.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Title } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LayoutService } from './share-module/layout.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
   sidebarStatus: boolean;
   isLogin = 'false';   
   sidebarVisible = false;
  constructor(private dialog: MatDialog, private toastr: ToastrManager, vcr: ViewContainerRef,private _titleService : Title,private layoutService:LayoutService) {
    var newTitle = 'Verisource';
    this._titleService.setTitle( newTitle );
  }
 
  ngOnInit() {
    this.layoutService.sideBar$.subscribe((show: boolean) => {
      this.sidebarVisible = show;
    });

    // let userToken = localStorage.getItem("token");
    // if(userToken){
    //   this.sidebarStatus = true;
      
    // }else{
    //   this.sidebarStatus = false;
    // }
    
    // if (localStorage.getItem('islogin') === null) {
    //   localStorage.setItem('islogin', 'false')    
    // }

  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = "some data";
  }
  myEmitter(e) {
  }
}
