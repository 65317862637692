import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TableService } from 'src/app/admin/service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { ShareService } from '../share.service';
import { ContentManagementComponent } from 'src/app/admin/content-management/content-management.component';
import {AuthenticationService } from '../../app.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';



@Component({
  selector: 'app-add-contact-us',
  templateUrl: './add-contact-us.component.html',
  styleUrls: ['./add-contact-us.component.css']
})
export class AddContactUsComponent implements OnInit {
  User = [
    {name: 'Supplier', value: 'SA'},
    {name: 'Client', value: 'EP'},
    {name: 'Job Seeker', value: 'JS'}      
   ];
  paginator = {
    pageSize: 5,
    length: 0,
    pageSizeOptions: [5, 10, 15, 20]

  }
  userData: any;
  @Input() createdBy: string = "";
  loginStatus:string;
  @Input() userType: string = "";
  issuccess: any;
  AllUsers: any;
  personLoad: boolean = false;
  issubmitaddfrom : boolean = false; 
  contactusForm: FormGroup; 
  submitted: boolean = false;
  usersData: any;
  user_type_name: any = "Person";
  emailvalidator = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,3}(\\.[a-zA-Z]{2,2})?(\\.[a-zA-Z]{2,2})?$'
  alluserList: any =[];
  constructor(
    private tableService: TableService,
    private shareApies: ShareService, 
    private toastr: ToastrManager,
    private addJobAPI: EnterpriseService,
    private _router: Router,
    private tostr: ToastrManager,
    private _paramVal: ActivatedRoute,
    private api: TableService,  private AuthenticationService: AuthenticationService,private shareservice:ShareService,
    private _formBuilder: FormBuilder,private dialogRef: MatDialogRef<AddContactUsComponent>) { 
    this.AuthenticationService.jwtTokenExpCheck();

    this.contactusForm = this._formBuilder.group({
      user_type:['',Validators.required],
      user_name:['',Validators.required],
      name: ['', [Validators.required]],
      email: ['',[Validators.required, Validators.pattern(this.emailvalidator)]],
      subject: ['', [Validators.required]],
      phone: ['', Validators.required],
      message: ['',[Validators.required]],
      createdDate:['']
    });
    this.userData = JSON.parse(window.localStorage.getItem('udata'));//Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
    //this.loginStatus =  localStorage.getItem('islogin')
  }

  ngOnInit() {
  }
  get fcu() { return this.contactusForm.controls; }

  

  loadAllUserTypewise() {
    this.personLoad = true;
    try {
      this.api.loadAllUserTypeWise(this.contactusForm.controls.user_type.value).pipe(first()).subscribe((res: any) => {
        this.personLoad = false;
        if(this.contactusForm.controls.user_type.value=="EP"){
          this.user_type_name = "Client";
        }else if(this.contactusForm.controls.user_type.value=="SA"){
          this.user_type_name = "Supplier";
        }else{
          this.user_type_name = "Person";
        }
        if (res.success) {
        
        this.AllUsers = res.data;
          this.alluserList = this.AllUsers;
          this.contactusForm.controls['email'].setValue(this.AllUsers.emailId);
          this.contactusForm.controls['phone'].setValue(this.AllUsers.contactNo);
         
          if (this.AllUsers.length > 0) {
            this.paginator.length = this.AllUsers[0].totalCount;
          }
        } else {
          this.tostr.errorToastr(res.message, 'Error!');
        }
      });
    } catch (error) {
    }
  }

  loadUser(){
    this.personLoad = true;
    try {
      this.api.getUserDetails(this.contactusForm.value).subscribe(res => {
        this.personLoad = false;
        this.issuccess = res;
        if (this.issuccess.success) {
          this.usersData = res.data;
          this.contactusForm.controls['email'].setValue(this.usersData[0].emailId);
          this.contactusForm.controls['phone'].setValue(this.usersData[0].contactNo);
          this.contactusForm.controls['name'].setValue(this.usersData[0].fullName);
          
          if (this.AllUsers.length > 0) {
            this.paginator.length = this.AllUsers[0].totalCount;
          }
        } else {
          //this.tostr.errorToastr(res, 'Error!');
        }
      });
    } catch (error) {
    }
  }

  clearData() {
    this.contactusForm.reset();
    this.dialogRef.close();
   // this._router.navigate(['/ep/enterprise/staffingagencygroup']);

  }



  AddContactUs() {  
   
    this.AuthenticationService.jwtTokenExpCheck();
    this.submitted = true;
    if (this.contactusForm.valid) {
      this.contactusForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
      //Manu Datta: 20210309, VerisourceBugSheetAfterMerge/commonLogin 435
      if(localStorage.getItem("CreatedBy") != "null" && localStorage.getItem("CreatedBy")){
        this.createdBy = localStorage.getItem("CreatedBy")
      }else{
        this.createdBy = this.userData.data.credentials.userId;
      }
      this.shareApies.addcontactus(this.contactusForm.value,this.createdBy).subscribe(res => {
        this.issuccess = res;
        if (this.issuccess.success) {
          this.issubmitaddfrom = false;
          this.toastr.successToastr(this.issuccess.message, '', { position: 'bottom-right' });
          this.contactusForm.reset();
          this.dialogRef.close();
          this.submitted = false;
        } else {
          this.toastr.errorToastr(this.issuccess.message, '', { position: 'bottom-right' });
        }
      });
    } else {
      // this.submitted = false;
    }
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
        e.preventDefault();
    }
  }

  OnChangePhoneNo(e) {
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if(e.target.value != '' && e.keyCode != 8){
      this.contactusForm.controls['phone'].setValue(npa + '-'+ nxx + '-' + last4);
    }
  }

  // 20200929: Akhilesh, Special charcter validation, jira no 70
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;   
     if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
       this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
       return false}
     }

     //20201106:Akhilesh,Search user api
     //Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
searchuserValue = false;
searchuser(e) { 
  if (e.target.value != '') {
    let mspId: any;
    if (this.userData.data.credentials.CreatedBy){
      mspId = this.userData.data.credentials.CreatedBy;
    }else{
      mspId = this.userData.data.credentials.userId;
    }
    this.addJobAPI.get_searchnotifyuser(this.contactusForm.controls["user_type"].value,mspId,e.target.value).subscribe((res: any) => {
      if (res.data.length == 0) {
        this.searchuserValue = true;
      }
      
      if (res.success) {
        this.AllUsers = res.data;
        if (res.data.length != 0) {
          this.searchuserValue = false;
        }
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }
    });
  }
  else {
    this.AllUsers = this.alluserList;
    this.searchuserValue = false;
  }
}
}
