import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
import { ToastrManager } from 'ng6-toastr-notifications';
import {AuthenticationService } from '../../app.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { Router } from '@angular/router';
import { ShareService } from '../share.service';
import { Title } from '@angular/platform-browser';
import { LayoutService } from '../layout.service';


@Component({
  selector: 'app-reset-password-after-expire',
  templateUrl: './reset-password-after-expire.component.html',
  styleUrls: ['./reset-password-after-expire.component.css']
})
export class ResetPasswordAfterExpireComponent implements OnInit {
  changePaswordForm :FormGroup;
  notMatch: boolean;
  userDataCheck: any = {};
  staffingagencyId: any;
  emailId: any;
  submited3: boolean=false;
  constructor(  private _formBuilder: FormBuilder,
    private EnterpriserApi: EnterpriseService,
	 private AuthenticationService: AuthenticationService,
   private staffApi: staffingAgencyServices,  private router: Router,
    private toastr: ToastrManager,private shareservice:ShareService,
    private _titleService:Title,private layoutService:LayoutService) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.changePaswordForm = this._formBuilder.group({
      oldPassword: ['',  [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      password: ['',  [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      confirmPassword: ['',  [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      updatedDate:['']
    });

    var newTitle = 'Verisource | ResetPassword';
    this._titleService.setTitle(newTitle);
    this.userDataCheck = JSON.parse(localStorage.getItem('udata'));
    //console.log('udata', JSON.stringify(this.userDataCheck));
    //console.log('this.userDataCheck.data.credentials', this.userDataCheck.data.credentials.emailId);
    // this.staffingagencyId=this.userDataCheck.data.credentials.staffingagencyId;
    // this.emailId=this.userDataCheck.data.credentials.emailId;


   }

  ngOnInit() {
  }

  get cp(){return this.changePaswordForm.controls}


  onSubmit() {
 
    this.AuthenticationService.jwtTokenExpCheck();
    //console.log('this.userDataCheck.data.credentialsuserType',this.userDataCheck.data.credentials.userType)

    this.submited3 = true;
    if (this.changePaswordForm.controls['password'].value === this.changePaswordForm.controls['confirmPassword'].value) {
      this.notMatch = false;
      //console.log('this.changePaswordForm.valid',this.changePaswordForm);
      if (this.changePaswordForm.valid) {
        this.changePaswordForm.controls['updatedDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        this.changePaswordForm.controls['updatedDate'].setErrors(null);
        try {
          if( this.userDataCheck.data.credentials.userType=='EP') {
            //this.EnterpriserApi.changePassword(this.changePaswordForm.value, this.userDataCheck.data.credentials.enterpriseId,'EP',this.userDataCheck.data.credentials.emailId).subscribe((res: any) => {
              this.EnterpriserApi.resetPasswordVD(this.changePaswordForm.value, this.userDataCheck.data.credentials.emailId).subscribe((res: any) => {
              if (res.success) {
                this.toastr.successToastr('Password changed successfully');
                localStorage.setItem('islogin', 'false');
                localStorage.removeItem('token');
                localStorage.removeItem('udata');
                window.localStorage.setItem('isPasswordReset', 'false');
                localStorage.clear();
                debugger
                this.router.navigate(['/auth/home'])
                .then(() => {
                    this.layoutService.setShowSidebar(false);
                    this.layoutService.setShowHeader(false);
                  });
              } else {
                this.toastr.errorToastr('Old password is not correct');
              }
            });
          }
          if( this.userDataCheck.data.credentials.userType=='SA') {
            this.EnterpriserApi.resetPasswordVD(this.changePaswordForm.value, this.userDataCheck.data.credentials.emailId).subscribe((res: any) => {
              if (res.success) {
                window.localStorage.setItem('isPasswordReset', 'false');
                this.toastr.successToastr('Password changed successfully');
                localStorage.setItem('islogin', 'false');
                localStorage.removeItem('token');
                localStorage.removeItem('udata');
                localStorage.clear();
                debugger
                this.router.navigate(['/auth/home'])
                .then(() => {
                    this.layoutService.setShowSidebar(false);
                    this.layoutService.setShowHeader(false);
                  });
              } else {
                this.toastr.errorToastr('Old password is not correct');
              }
            });

          }
          if( this.userDataCheck.data.credentials.userType=='JS') {
            //this.EnterpriserApi.changePassword(this.changePaswordForm.value, this.userDataCheck.data.credentials.enterpriseId,'EP',this.userDataCheck.data.credentials.emailId).subscribe((res: any) => {
              this.EnterpriserApi.resetPasswordVD(this.changePaswordForm.value, this.userDataCheck.data.credentials.emailId).subscribe((res: any) => {
              if (res.success) {
                this.toastr.successToastr('Password changed successfully');
                localStorage.setItem('islogin', 'false');
                localStorage.removeItem('token');
                localStorage.removeItem('udata');
                window.localStorage.setItem('isPasswordReset', 'false');
                localStorage.clear();
                debugger
                this.router.navigate(['/auth/home'])
                .then(() => {
                    this.layoutService.setShowSidebar(false);
                    this.layoutService.setShowHeader(false);
                  });
              } else {
                this.toastr.errorToastr('Old password is not correct');
              }
            });
          } 
        } catch (error) {
          //console.log('error',error)

          throw error;
        }
      } else {
        return false;
      }
    } else {
      this.notMatch = true;
      return false;
    }
  }


//   onSubmit() {
//     this.AuthenticationService.jwtTokenExpCheck();
//     this.submited3 = true;
//     if (this.changePaswordForm.controls['password'].value === this.changePaswordForm.controls['confirmPassword'].value) {
//       this.notMatch = false;
//       if (this.changePaswordForm.valid) {

//         try {
//           this.changePaswordForm.controls['updatedDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));

//           this.EnterpriserApi.resetPasswordVD(this.changePaswordForm.value, 'client0219@yopmail.com').subscribe((res: any) => {

//             if (res.success) {
//               this.toastr.successToastr(res.message, 'Done!');
//             }
//             else {
//               this.toastr.errorToastr('Old password is not correct', 'Error!');
//             }
//           });
//         } catch (error) {
//           throw error;
//         }
//         //this.isChangePasswordform = false;
//       } else {
//         return false;
//       }
//     } else {
//       this.notMatch = true;
//       return false;
//     }
//   }
  

}
