import { Routes, RouterModule } from '@angular/router';
import { NgModule } from "@angular/core";
import { EnterpriseDashboardComponent } from './enterprise-dashboard/enterprise-dashboard.component';
import { MyJobListEnterpriseComponent } from './my-job-list-enterprise/my-job-list-enterprise.component';
import { EnterpriseViewListCandidatesComponent } from './enterprise-view-list-candidates/enterprise-view-list-candidates.component'
import { EnterpriseComponent } from './enterprise.component'
import { ProfileForEnterpriseComponent } from './profile-for-enterprise/profile-for-enterprise.component';
import { MyInvoiceInterprisePdingComponent } from './my-invoice-interprise-pding/my-invoice-interprise-pding.component';
import { MyInvoiceInterprisePaidComponent } from './my-invoice-interprise-paid/my-invoice-interprise-paid.component';
import { InvoiceViewDetailsComponent } from './invoice-view-details/invoice-view-details.component';
import { EnterpriseSubadminComponent } from './enterprise-subadmin/enterprise-subadmin.component';
import { SearchProfileComponent } from './search-profile/search-profile.component';
import { InterviewComponent } from './interview/interview.component';
import { InvoicePendingDetailsComponent } from './invoice-pending-details/invoice-pending-details.component';
import { EnterpriseContactUsComponent } from './enterprise-contact-us/enterprise-contact-us.component';
import { NotificationComponent } from './notification/notification.component';
import { from } from 'rxjs';
import { CandidatesComponent } from './candidates/candidates.component';
import { AllEntannouncementsComponent } from './all-entannouncements/all-entannouncements.component';
import { StaffingAgencyEnterpriseComponent } from './staffing-agency-enterprise/staffing-agency-enterprise.component';
import { StaffingAgencyDetailsComponent } from './staffing-agency-details/staffing-agency-details.component';
import { JobseekerEnterpriseComponent } from './jobseeker-enterprise/jobseeker-enterprise.component';
import { CandidateProfileCreateComponent } from './candidate-profile-create/candidate-profile-create.component';
import { JobSeekerViewDetailsComponent } from './job-seeker-view-details/job-seeker-view-details.component';
//import { ProjectsComponent } from './projects/projects.component';
import { StaffingAgencyGroupComponent } from './staffing-agency-group/staffing-agency-group.component';
import { AssignJobToAgencyComponent } from './assign-job-to-agency/assign-job-to-agency.component';
import { AssignJobToAgencyGroupComponent } from './assign-job-to-agency-group/assign-job-to-agency-group.component';
import { GroupJobseekerComponent } from '../share-module/group-jobseeker/group-jobseeker.component';
import { AddpoolSeekerComponent } from '../share-module/addpool-seeker/addpool-seeker.component';
import { JobseekerStaffingStatusComponent } from '../share-module/jobseeker-staffing-status/jobseeker-staffing-status.component';
import { ProjectsComponent } from '../share-module/projects/projects.component';
import { ProjectComponent } from '../share-module/project/project.component';
import { AddNewJobEnterpriseComponent } from '../share-module/add-new-job-enterprise/add-new-job-enterprise.component';
import { EditJobListComponent } from '../share-module/edit-job-list/edit-job-list.component';
import { InvitaionLinkResultComponent } from '../share-module/invitaion-link-result/invitaion-link-result.component';
import { StaffingInvitationLinkComponent } from '../share-module/staffing-invitation-link/staffing-invitation-link.component';
import { SubscribeUnsubscribeComponent } from '../share-module/subscribe-unsubscribe/subscribe-unsubscribe.component';
import { FeedbackComponent } from '../share-module/feedback/feedback.component';
import { TimesheetsupplierComponent } from '../share-module/timesheetsupplier/timesheetsupplier.component'
// import { ClienttimesheetjobseekerComponent } from './clienttimesheetjobseeker/clienttimesheetjobseeker.component';
import { TimesheetjobseekerComponent } from '../share-module/timesheetjobseeker/timesheetjobseeker.component';
import { ClienttimesheetdetailsComponent } from '../share-module/clienttimesheetdetails/clienttimesheetdetails.component';
import { ResetPasswordAfterExpireComponent } from '../share-module/reset-password-after-expire/reset-password-after-expire.component';
import { TimesheetinvoiceComponent } from '../share-module/timesheetinvoice/timesheetinvoice.component';
//import { ClienttimesheetdetailsComponent } from './clienttimesheetdetails/clienttimesheetdetails.component';


export const routes: Routes = [
  {
    path: 'enterprise',
    component: EnterpriseComponent,
    children: [
      { path: 'staffingagency', pathMatch: 'full', component: StaffingAgencyEnterpriseComponent },
      { path: 'dashboard', pathMatch: 'full', component: EnterpriseDashboardComponent },
      { path: 'updateprofile', pathMatch: 'full', component: ProfileForEnterpriseComponent },
      { path: 'addnewjob', pathMatch: 'full', component: AddNewJobEnterpriseComponent },
      // { path: 'candidates', pathMatch: 'full', component: CandidatesComponent },

      { path: 'myjoblist', pathMatch: 'full', component: MyJobListEnterpriseComponent },
      { path: 'viewlistcandidates/:jobId/:jobTitle', pathMatch: 'full', component: EnterpriseViewListCandidatesComponent },
      { path: 'EditJobList/:jobId', pathMatch: 'full', component: AddNewJobEnterpriseComponent },
     
      // { path: 'EditJobList/:id/:jobId', pathMatch: 'full', component: AddNewJobEnterpriseComponent },
      // { path: 'MyInvoiceInterprisePaid', pathMatch: 'full', component: MyInvoiceInterprisePdingComponent },
      // { path: 'MyInvoiceInterprisePding', pathMatch: 'full', component: MyInvoiceInterprisePaidComponent },
      // { path: 'InvoiceViewDetails/:id', pathMatch: 'full', component: InvoiceViewDetailsComponent },
      { path: 'subadmin/:id', pathMatch: 'full', component: EnterpriseSubadminComponent },
      // { path: 'search_profile', pathMatch: 'full', component: SearchProfileComponent },
      // { path: 'Interview', pathMatch: 'full', component: InterviewComponent },
      //{ path: 'ContactUs', pathMatch: 'full', component: EnterpriseContactUsComponent },
      // { path: 'InvoicePendingDetails/:id', pathMatch: 'full', component: InvoicePendingDetailsComponent },
      // { path: 'notification', pathMatch: 'full', component: NotificationComponent },
      // { path: 'announcements', pathMatch: 'full', component: AllEntannouncementsComponent },

      { path: 'staffing_agency_detail/:id', pathMatch: 'full', component: StaffingAgencyDetailsComponent },
      { path: 'Jobseeker', pathMatch: 'full', component: JobseekerEnterpriseComponent },
      { path: 'add-jobseeker', pathMatch: 'full', component: CandidateProfileCreateComponent },
      { path: 'update-jobseeker/:id', pathMatch: 'full', component: CandidateProfileCreateComponent },
      { path: 'jobseeker-view/:id', pathMatch: 'full', component: JobSeekerViewDetailsComponent },
      // { path: 'projects', pathMatch: 'full', component: ProjectsComponent },
      // { path: 'project/:id', pathMatch: 'full', component: ProjectComponent },
      // { path: 'project/:id/job', pathMatch: 'full', component: AddNewJobEnterpriseComponent },
      { path: 'staffingagencygroup', pathMatch: 'full', component: StaffingAgencyGroupComponent },

      { path: 'assignJobToAgency', pathMatch: 'full', component: AssignJobToAgencyComponent },
      { path: 'assignJobToAgencyGroup', pathMatch: 'full', component: AssignJobToAgencyGroupComponent },

      // { path: 'Group', pathMatch: 'full', component: GroupJobseekerComponent },
      // { path: 'addseeker/:id', pathMatch: 'full', component: AddpoolSeekerComponent },
      // { path: 'updateseeker/:updatePoolseeker/:id', pathMatch: 'full', component: AddpoolSeekerComponent },
      // { path: 'JobseekerStaffingStatus/:jobid/:poolid/:jobtype', pathMatch: 'full', component: JobseekerStaffingStatusComponent },
      // { path: 'invitation_result/:id', pathMatch: 'full', component: InvitaionLinkResultComponent },
      // { path: 'Staffinginvitation_result/:id', pathMatch: 'full', component: StaffingInvitationLinkComponent },
      // { path: 'timesheetsupplier', pathMatch: 'full', component: TimesheetsupplierComponent },
      // { path: 'clientjobseeker/:id', pathMatch: 'full', component: TimesheetjobseekerComponent },
      // { path: 'timesheetdetails/:period/:jobid/:id', pathMatch: 'full', component:  ClienttimesheetdetailsComponent},
      // { path: 'timesheetdetails/:period/:invoiceid', pathMatch: 'full', component:  ClienttimesheetdetailsComponent},
      // { path: 'invoicerecords/:period/:invoiceid/:inv', pathMatch: 'full', component:  ClienttimesheetdetailsComponent},
       { path: 'feed_back/:emailid', component: FeedbackComponent },
       { path: 'resetpassword', pathMatch: 'full', component: ResetPasswordAfterExpireComponent },
      // { path: 'invoice', pathMatch: 'full', component:  TimesheetinvoiceComponent}
    ]
  }

];
export const enterpriseRouteModule = RouterModule.forChild(routes);


