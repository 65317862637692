import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../app.service'
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class ShareService {
  url: string = '';
  token: string = '';
  userData:any={};
  constructor(private mainAPI: AuthenticationService,
     private http: HttpClient,
     private paramval: ActivatedRoute,
    private AuthenticationService: AuthenticationService,
    private datePipe:DatePipe
    ) {
    this.url = this.mainAPI.url;
    this.token = localStorage.getItem('token');
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
  }

  jobsListAPI = (body) => {
    // let Option = new Http()
    let localurl = this.url + '/api/staffingagency/browsejobs';
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }

  convertDateIntoUtc(value){
    if (value)  {
      return  moment.utc(value).format('YYYY-MM-DD HH:mm:ss');
     } 
    else {
          return '';
    }
  }

  convertDateIntoUtcForSearch(value){
    if (value)  {
      return  moment.utc(value).format('YYYY-MM-DD 23:59:59');
     } 
    else {
          return '';
    }
  }

  guestJobsListAPI = (body) => {
    // let Option = new Http()
    let localurl = this.url + '/api/staffingagency/browsejobs'
    // console.log(localurl, body)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };

    return this.http.post(localurl, body, httpOptions);
  }




  loadallcontacts_replymessage(userType, tokenId) {
    let url = `${this.url}/api/admin/getsupports_replymessage/` + userType + '/' + tokenId
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get<any>(url, httpOptions).pipe(map(res => {
      return res;
    }));
  }

  loadallcontactslist = (data) => {

    let url = `${this.url}/api/staffingagency/getsupportslist`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    //this.contactuslistForm.value.fromdate=(this.shareservice.convertDateIntoUtc( this.contactuslistForm.controls["fromdate"].value))
    // this.contactuslistForm.value.todate=(this.shareservice.convertDateIntoUtc( this.contactuslistForm.controls["todate"].value))

    data.fromdate=(this.datePipe.transform(data.fromdate, "yyyy-MM-dd")) 
    data.todate=(this.datePipe.transform(data.todate, "yyyy-MM-dd 23:59:59")) 

    let body = {
      'PageSize': data.PageSize,
      'PageNumber': data.PageNumber,
      'user_type': data.user_type,
      'search': data.search,
      'fromdate':(this.convertDateIntoUtc(data.fromdate)),
      'todate': (this.convertDateIntoUtc(data.todate )),
      'status':data.status
    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }


  replytoquery = (data, tokenId) => {
    
    let url = `${this.url}/api/staffingagency/replysupports/` + tokenId
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      'reply': data.reply,
      'support_status': data.support_status,
      'createdDate':data.createdDate
    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }

//Manu Datta: 20201212, Added registerBy CommonLogin 144
  contactus = (userType,registerBy, data) => {
          let localurl = this.url + '/api/staffingagency/generatecontactus';
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'authenticate': `${this.token}`,
            // 'Authorization' : 'Bearer ' + `${this.token}`
            })
           
          };
          let body = {
            'yourName': data.name,
            'useremailId': data.email,
            'subject': data.subject,
            'message': data.message,
            'contactNo': data.phone,
            'user_type': userType,
            'createdDate':data.createdDate,
            'registerBy':registerBy
          }    
          return this.http.post(localurl, body, httpOptions);
        }


         addcontactus = (data,registerBy) => {
          let localurl = this.url + '/api/admin/generatecontactus';
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'authenticate': `${this.token}`
            })
          };
          let body = {
            'yourName': data.name,
            'useremailId': data.email,
            'subject': data.subject,
            'message': data.message,
            'contactNo': data.phone,
            'user_type': data.user_type,
            'userId': data.user_name,
            'createdDate':data.createdDate,
            'registerBy':registerBy
          }    
          return this.http.post(localurl, body, httpOptions);
        }
//#region 
//   contactus = (userType, data) => {
//     if (userType == 'EP') {
//       let localurl = this.url + '/api/enterprise/generatecontactus';
//       const httpOptions = {
//         headers: new HttpHeaders({
//           'Content-Type': 'application/json',
//           'authenticate': `${this.token}`
//         })
//       };
//       let body = {
//         'yourName': data.name,
//         'useremailId': data.email,
//         'subject': data.subject,
//         'message': data.message,
//         'contactNo': data.phone,
//       }
//       // console.log(localurl, body)

//       return this.http.post(localurl, body, httpOptions);
//     }
//     else if (userType == 'SA') {
//       let localurl = this.url + '/api/staffingagency/generatecontactus'
//       const httpOptions = {
//         headers: new HttpHeaders({
//           'Content-Type': 'application/json',
//           'authenticate': `${this.token}`
//         })
//       };
//       let body = {
//         'yourName': data.name,
//         'useremailId': data.email,
//         'subject': data.subject,
//         'message': data.message,
//         'contactNo': data.phone,
//       }
//       // console.log(localurl, body);
//       return this.http.post(localurl, body, httpOptions);
//     }
//     else if (userType == 'JS') {
//       let localurl = this.url + '/api/staffingagency/generatecontactus'
//       const httpOptions = {
//         headers: new HttpHeaders({
//           'Content-Type': 'application/json',
//           'authenticate': `${this.token}`
//         })
//       };
//       let body = {
//         'yourName': data.name,
//         'useremailId': data.email,
//         'subject': data.subject,
//         'message': data.message,
//         'contactNo': data.phone,
//       }
//       // console.log(localurl, body);
//       return this.http.post(localurl, body, httpOptions);
//     }
//     else if (userType == 'GN') {
//       let localurl = this.url + '/api/guestuser/contactus'
//       const httpOptions = {
//         headers: new HttpHeaders({
//           'Content-Type': 'application/json',
//           // 'authenticate': `${this.token}`
//         })
//       };
//       let body = {
//         'yourName': data.name,
//         'useremail': data.email,
//         'subject': data.subject,
//         'message': data.message,
//         'contactNo': data.phone,
//       };
//       // console.log(localurl, body);
//       return this.http.post(localurl, body, httpOptions);
//     }
//   }
//#endregion
  notification(type, size, pageNumber) {
		this.AuthenticationService.jwtTokenExpCheck();
		
    let localurl: string = '';
    if (type == 'SA') {
      localurl = `${this.url}/api/staffingagency/getnotifications`
    }
    if (type == 'EP') {
      localurl = `${this.url}/api/enterprise/getnotifications`
    }
    if (type == 'JS') {
      localurl = `${this.url}/api/enterprise/getnotifications`
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': `${this.token}`
      })
    };
    let body = {
      PageSize: size,
      PageNumber: pageNumber
    }
    return this.http.post(localurl, body, httpOptions);


  }

  getfiltersBrowsejobs() {
    this.token = localStorage.getItem('token');
    let localurl = `${this.url}/api/staffingagency/getfilters/SA/browsejobs`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  country_staff() {
    let localurl = `${this.url}/api/staffingagency/countries`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }

  state_staff(search) {
    const localURL = `${this.url}/api/staffingagency/statefilter/${search}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localURL, httpOptions);
  }

  jobLocation_staff(search) {
    let localurl = `${this.url}/api/staffingagency/statefilter/${search}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  getfiltersMyCandidates() {
    const localurl = `${this.url}/api/staffingagency/getfilters/AD/mycandidatesAD`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get(localurl, httpOptions);
  }
  mycandidateList(body: any) {
    if(this.userData.data.credentials.userType === 'AD'){
    var localurl = `${this.url}/api/admin/getJobseekerListForGroup`;
    }
    if(this.userData.data.credentials.userType === 'EP'){
      var localurl = `${this.url}/api/enterprise/GetEnterpriseGroupSeeker`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    const data = {
      'resumeStatus': body.resumeStatus,
      'profileStatus': body.profileStatus,
      'highestDegree': body.highestDegree,
      'minExperience': body.minExperience,
      'usMasterDegree': body.usMasterDegree,
      'country': body.country,
      'citizenship': body.citizenship,
      'usaExperience': body.usaExperience,
      'minUSAExperience': body.minUSAExperience,
      'maxUSAExperience': body.maxUSAExperience,
      'skills': body.skills,
      'maxExperience': body.maxExperience,
      'favourite_status': body.favourite_status,
      'search': body.search.trim(),
      'PageSize': body.PageSize,
      'PageNumber': body.PageNumber,
      'zipCode': body.zipCode,
      'state': body.state,
      'enterpriseId': body.enterpriseId,
      'PoolID': body.PoolID,
      'actiontype': body.actiontype,
      'fromdate':body.fromdate,
      'todate':body.todate,
      'source':body.source,
      'usertype': this.userData.data.credentials.userType
    };
    return this.http.post<any>(localurl, data, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  country() {
    const localurl = `${this.url}/api/jobseeker/countries`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        authenticate: this.token + ''
      })
    };

    return this.http.get(localurl, httpOptions);

  }

  saveJobseekerPooldetails(body) {
    const url = `${this.url}/api/admin/savejobseekerPool/${this.userData.data.credentials.userType}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  GetSubscribeUnsubscribeData(emailid: any, topicid: any) {
    const localurl = `${this.url}/api/staffingagency/getSubscribeDetails/${emailid}/${topicid}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    // return this.http.get(localurl, httpOptions);
    return this.http.get<any>(localurl, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  invitationSendAPI = (body) => {

    const localurl = `${this.url}/api/staffingagency/invitationSendFromEnterprise`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.post(localurl, body, httpOptions);
  }
  getSavedJobseekerIdInpool(poolid) {
    const localurl = `${this.url}/api/admin/getsavedjobseekerInPool/ ` + poolid;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.get<any>(localurl, httpOptions).pipe(map(res => {
      return res;
    }));
  }
  SavedPoolSeekerListByPoolId(data) {
    let url = `${this.url}/api/admin/savedpoolseekerList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      'PageSize': data.PageSize,
      'PageNumber': data.PageNumber,
      'pk_pool_Id': data.pk_pool_Id,
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }

  HistoryofSavedPoolSeekerListByPoolId(data) {

    let url = `${this.url}/api/admin/historysavedpoolseekerList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    let body = {
      'PageSize': data.PageSize,
      'PageNumber': data.PageNumber,
      'pk_pool_Id': data.pk_pool_Id,
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  poolJobDetailspoolIdList(data) {
    if(this.userData.data.credentials.userType === 'EP'){
      var url = `${this.url}/api/admin/poolEnterpriseJobDetailspoolIdList`;
      if(data.typejob == 'VMS'){
        var url = `${this.url}/api/admin/poolJobDetailspoolIdList`;
      }
    }
    if(this.userData.data.credentials.userType === 'AD'){
      var url = `${this.url}/api/admin/poolJobDetailspoolIdList`;
      if(data.typejob == 'EP'){
        var url = `${this.url}/api/admin/poolEnterpriseJobDetailspoolIdList`;
      }
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    const body = {
      'PageSize': data.PageSize,
      'PageNumber': data.PageNumber,
      'pk_pool_Id': data.pk_pool_Id,
      'action': data.action,
      'jobseekerId': data.jobseekerId,
      'typejob': this.userData.data.credentials.userType,
      'createdBy': this.userData.data.credentials.userId
    };

    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }

  loadInvoiceFor(data:any,sourcetype:number) {
        let url = `${this.url}/api/admin/loadInvoiceFor`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
 
    const body ={
      body:data,
      type:sourcetype
    }
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }
  jobseekerstatuslist(type:string,data) {
    // this.isLogin = localStorage.getItem('isLogin'); if (this.isLogin == 'true') {
       let url = `${this.url}/api/admin/jobseekerstatuslist`;
       const httpOptions = {
         headers: new HttpHeaders({
           'Content-Type': 'application/json',
           'authenticate': this.token + ""
         })
       };
       if( data.checkId != 'Job' ){
        type = 'Admin';
       }
       let body = {
         "PageSize": data.PageSize,
         "PageNumber": data.PageNumber,
         "jobtype": data.jobtype,
         "jobid": data.jobid,
         "poolid": data.poolid,
         "type":type,
         "fromdate": data.fromdate,
         "todate": data.todate,
         "status": data.status,
         "jobseekerStatus":data.jobseekerStatus,
         "search": data.search
       };
 
       return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
         return res;
       }));
    // } else { this._router.navigate(['/']); }
   }


   AssigncandidateList(body: any) {
    const localurl = `${this.url}/api/admin/getAssigncandidateList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(localurl, body, httpOptions).pipe(map(res => {
      return res;
    }));
  }


   // Search state API
   //Manu Datta: 20210224, VerisourceBugSheetAfterMerge/commonLogin 404
   searchClient(data: string,mspId: string) {
    this.AuthenticationService.jwtTokenExpCheck();
    const localurl = `${this.url}/api/admin/searchClient/${data}/${mspId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token
      })
    };
    return this.http.get(localurl, httpOptions);
  }


  filterforenterprise = (data) => {
    //this.isLogin = localStorage.getItem('isLogin');
   // if (this.isLogin == 'true') {

      // let Option = new Http()
      let url = `${this.url}/api/admin/enterprisesview`
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'authenticate': this.token + ""
        })
      };
      let body = {
        "PageSize": data.PageSize,
        "PageNumber": data.PageNumber,
        "status": data.status,
        "fromdate": data.fromdate,
        "todate": data.todate,
        "search": data.search,
        "assignseeker":true
      }
      return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
        return res;
      }));
   // }
   // else {
      //this._router.navigate(['/']);
    // }
  }

  insertUpdateAssignJobseeker(body) {
    let url = `${this.url}/api/admin/InsertUpdateAssignjobseeker`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'authenticate': this.token + ''
      })
    };
    return this.http.post<any>(url, body, httpOptions).pipe(map(res => {
      return res;
    }));

  }

 

 // Chek file exits
 fileExists(filename) {
  let localurl = `${this.url}/api/enterprise/file_exits/${filename}`;
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'authenticate': this.token
    })
  };
  return this.http.get(localurl, httpOptions);
}

//Manu Datta: 20210407, Encryption
  //The set method is use for encrypt the value.
  encrypt(keys, value){
    // var key = CryptoJS.enc.Utf8.parse(keys);
    // var iv = CryptoJS.enc.Utf8.parse(keys);
    // var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    // {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    // });
    // return encrypted.toString();
    var keySize = 256;
    var salt = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.PBKDF2(keys, salt, {
        keySize: keySize / 32,
        iterations: 100
    });
    
    var iv = CryptoJS.lib.WordArray.random(128 / 8);
    
    var encrypted = CryptoJS.AES.encrypt(value, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });
    
    var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
    
    return result;
  }

   //The get method is use for decrypt the value.
   decrypt(keys, value){
    // var key = CryptoJS.enc.Utf8.parse(keys);
    // var iv = CryptoJS.enc.Utf8.parse(keys);
    // var decrypted = CryptoJS.AES.decrypt(value, key, {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    // });
    // return decrypted.toString(CryptoJS.enc.Utf8);
    var key = CryptoJS.enc.Utf8.parse(keys);                             
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);  
    var decrypted = CryptoJS.AES.decrypt(value, key, {iv: iv}); 
  return decrypted.toString(CryptoJS.enc.Utf8);   
  }

}
