import { Injectable } from '@angular/core';
import * as jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'  // <- ADD THIS
})
export class JwtService {
  token_name = 'access_token';
  user_Role = 'Role';
  user_id = 'UserId';
  user_email = 'Email';
  user_name = 'Username';
  constructor(private jw:JwtService) {
    
  }

  // private isTokenExpired(): boolean {
  //   const token = localStorage.getItem('token');

  //   if (token != null) {
  //     const decoded = this.getDecodedAccessToken(token);
  //     const now = Date.now() / 1000;

  //     return decoded.exp <= now;
  //   }

  //   return true; // No token or invalid token
  // }

  // public checkTokenExpire(){
  //   return this.isTokenExpired();
  // }

  getToken(): string {
    const localToken = window.localStorage[this.token_name];

    
    //const sessionToken = window.localStorage[this.token_name];
    const sessionToken = null;
    if (localToken && localToken != '' && localToken != null && localToken != undefined) {
      return localToken;
    }
    else if (sessionToken && sessionToken != '' && sessionToken != null && sessionToken != undefined && sessionToken != 'undefined') {
      return sessionToken;
    }
    else {
      return '';
    }
  }

  saveToken(data: any, rememberMe: boolean) {
    if (rememberMe) {
      window.localStorage[this.token_name] = data;
    }
    else {
      window.localStorage[this.token_name] = data;
    }
  }

  // deleteToken() {
  //   localStorage.clear();
  //   sessionStorage.clear();
  // }

  // getUserRole(): string {
  //   var decode = this.getDecodedAccessToken(this.getToken())
  //   return decode[this.user_Role];
  // }

  // getCurrentUserId(): string {
  //   var decode = this.getDecodedAccessToken(this.getToken())
  //   return decode[this.user_id];
  // }

  getUserEmail(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.user_email];
  }

  getUserName(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.user_name];
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
}
