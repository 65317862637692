import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
//import { AuthenticationService } from '../../../app.service';
import { environment } from 'src/environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TableService } from '../../service/table.service';
import {AuthenticationService } from '../../../app.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { ShareService } from 'src/app/share-module/share.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';



@Component({
  selector: 'app-jobseeker-details-popup',
  templateUrl: './jobseeker-details-popup.component.html',
  styleUrls: ['./jobseeker-details-popup.component.css']
})
export class JobseekerDetailsPopupComponent implements OnInit {
  userData: any;
  jobseekerDetails:any={};
  localStorageData : any = {};
  showResumeUploader = false;
  baseUrl="";
  userType = '';
  isDataready = false;
  isSuccess: any;
  deleteId = '';
  iswithdraw  = false;
  months = {
    '01' : 'Jan' ,
    '02' : 'Feb' ,
    '03' : 'Mar' ,
    '04' : 'Apr' ,
    '05' : 'May' ,
    '06' : 'Jun' ,
    '07' : 'Jul' ,
    '08' : 'Aug' ,
    '09' : 'Sept' ,
    '10' : 'Oct' ,
    '11' : 'Nov' ,
    '12' : 'Dec'
  };
  resumeName: any;
  isResumeExits: boolean =false;
  register_Date:any;
  constructor(
    private api: TableService,
    public dialogRef:MatDialogRef<JobseekerDetailsPopupComponent>,
private AuthenticationService: AuthenticationService,
private enterpriserApi: EnterpriseService,
private toastr:ToastrManager, 
private shareservice:ShareService,
private router: Router,
private datepipe:DatePipe,
    //private mainApi: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {

 data;
    this.AuthenticationService.jwtTokenExpCheck();
    this.jobseekerDetails=data;
    this.register_Date = this.datepipe.transform(this.jobseekerDetails.registerDate);
    this.resumeName = this.jobseekerDetails.resume;
    localStorage.setItem('resumefileName', this.jobseekerDetails.resume);
   // this.ResumeFileExits(data.resume);
    this.baseUrl = `${environment.url}/api/admin/image/resume/`;
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
   }

  ngOnInit() {
    this.initData();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteColor() {
    if(this.jobseekerDetails.resume == '') {return 'gray'}
    if(this.jobseekerDetails.resume != '') {return 'red'}
}

uploadColor(){
  if(this.jobseekerDetails.resume == '') {return 'green'}
  if(this.jobseekerDetails.resume != '') {return 'gray'}
}

downloadColor(){
  if(this.jobseekerDetails.resume == '') {return 'gray'}
  if(this.jobseekerDetails.resume != '') {return 'blue'}
}
resumeUploader(){
  this.showResumeUploader = true;
}

jobSeekerResume='';
popUpDelete(jobSeekerId,resume) {
  this.deleteId = jobSeekerId;
  this.jobSeekerResume = resume;
  this.iswithdraw = true;
}

onSelectUpdateResume(jobSeekerId,event) {
  var fullname=this.jobseekerDetails.fullName.split(' '); 
  let mydata = new FormData();
  let date:string=this.shareservice.convertDateIntoUtc(new Date());
  mydata.append('candidateName', fullname[0] + "_" + fullname[1]);
  mydata.append('resume', event.target.files[0]);
  mydata.append('date',date );
  if (event.target.files[0].size < (2029410 / 2)){
    this.api.JobseekerUploadResume(jobSeekerId,mydata,this.shareservice.convertDateIntoUtc(new Date())).subscribe(res => {
      this.isSuccess = res; 
      if (this.isSuccess.success) {
        this.initData();
        this.showResumeUploader = false;
        this.toastr.successToastr(this.isSuccess.message, '', { position: 'top-right' });     
//this.onNoClick();
      } else {

        this.toastr.errorToastr(this.isSuccess.message, '', { position: 'top-right' });
      }
    });
  }

else {
  this.toastr.errorToastr('Resume file should be less than 1 MB.');
}
}

DeleteResume() {
  this.AuthenticationService.jwtTokenExpCheck();
  this.showResumeUploader = false;
  this.api.JobseekerDeleteResume(this.deleteId,this.jobSeekerResume).subscribe(res => {
    this.isSuccess = res;
    //console.log(this.isSuccess);
    if (this.isSuccess.success) {
      this.initData();
      this.toastr.successToastr(this.isSuccess.message, "", { position: "top-right" });  
     // this.showResumeUploader = true;      
    } else {
      this.toastr.errorToastr(this.isSuccess.message, "", { position: "top-right" });
    }
  });
  this.iswithdraw = false;
}

initData() {

  try {
    this.isDataready = true;
    this.api.resumepreview(this.data.jobseekerId).subscribe((res: any) => {
      this.isDataready = false;
      if (res.success) { 
         
        //this.localStorageData = JSON.parse(localStorage.getItem('udata'));
        //this.userType = this.localStorageData.data.credentials.userType;
        this.jobseekerDetails = res.data.resumedetails;
        if(this.jobseekerDetails.dob !== null){
          this.jobseekerDetails.dob_month = this.months[this.jobseekerDetails.dob.split('-')[0]];
          this.jobseekerDetails.dob_day = this.jobseekerDetails.dob.split('-')[1];
        }        
        this.ResumeFileExits(res.data.resumedetails.resume);
      } else {
        this.jobseekerDetails = [];
      }
    });
  } catch (error) {
    //console.log(error);
  }
}

//Akhilesh:20200912, file check exits on root folder
ResumeFileExits(resumeName) {   
 
  this.resumeName = localStorage.getItem('resumefileName');
  //console.log("filecheckresume ==", this.resumeName);
  this.enterpriserApi.fileExists("resume",resumeName).subscribe((res: any) => {
 
    //console.log("filecheckresume", res);
    if (res.data == true) {       
      this.isResumeExits = true;
    }
  });
}

updateResume(jobSeekerId) {
  
  this.AuthenticationService.jwtTokenExpCheck();
  let isOnreset: boolean;
  this.onNoClick();
  if (this.userData.data.credentials.userType == 'AD') {     
    this.router.navigate(['/ad/admin/update-jobseeker', jobSeekerId]);
    //window.location.reload();
  }
  if (this.userData.data.credentials.userType == 'SA') {
    this.router.navigate(['/st/staff/vms/updateCandidate', jobSeekerId]);
  }
  if (this.userData.data.credentials.userType == 'SA') {
    this.router.navigate(['/st/staff/vms/updateCandidate', jobSeekerId]);
  }
}
}
