import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShareService } from 'src/app/share-module/share.service';
import { EnterpriseService } from '../enterprise.service';
import {AuthenticationService } from '../../app.service';
import { first } from 'rxjs/operators';
import { StaffingAgencyPopUpComponent } from '../staffing-agency-pop-up/staffing-agency-pop-up.component';
@Component({
  selector: 'app-supplier-group-details',
  templateUrl: './supplier-group-details.component.html',
  styleUrls: ['./supplier-group-details.component.css']
})
export class SupplierGroupDetailsComponent implements OnInit {
 
  preloader: boolean;
  groupRequest: any;
  AgencyIdSelected: any = [];
  loadForm: FormGroup;
  paginator = {
    pageSize: 20,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [10, 20, 50, 100]
  };
  listOfAllRequest: any;
  listView: boolean;
  areAllSelected = false;
  constructor(private dialogRef: MatDialogRef<SupplierGroupDetailsComponent>,
    private toastr: ToastrManager, private api: EnterpriseService, public dialog: MatDialog, private _router: Router,
    private _formBuilder: FormBuilder,
      private AuthenticationService: AuthenticationService,private shareservice:ShareService,
      @Inject(MAT_DIALOG_DATA) public data: any,) {
       
    this.groupRequest = JSON.parse(localStorage.getItem('CurrentGroupRequest'));
   }

  ngOnInit() {
 
    this.loadInit();
    //console.log('this.data', this.data.update);
    if (this.data.update == true) {
      this.loadAllStaffingAgenciesForUpdate();
    }   
   
  }
  loadAllStaffingAgenciesForUpdate() { // load beelin job data
    this.loadForm.controls.actiontype.setValue('Update');
    this.loadAllStaffingAgencies();
  }
  loadInit() {
    this.loadForm = this._formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      totalcandidates: '0-All',
      pageSize: 10,
      PageNumber: 1,
      actiontype: 'Insert',
      groupId: this.groupRequest.staffingAgencyGroup_Id
    });
  }
  loadAllStaffingAgencies() { // load beelin job data
 
    //console.log('LoadAssignedSeekedData', this.groupRequest.GroupName);
    this.preloader = true;
    this.api.GetSAForAgencyGroup(this.loadForm.value).pipe(first()).subscribe(res => {
      const tempjobIdSelected = this.AgencyIdSelected;
      if (res.success) {
        this.preloader = false;
        this.listOfAllRequest = res.data;
        //console.log('LoadAssignedSeekedData', this.listOfAllRequest);

        this.listView = true;
        if (this.listOfAllRequest.length > 0) {
          this.paginator.length = this.listOfAllRequest[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
        this.listOfAllRequest.forEach(function (candidate: { selected: boolean; staffingagencyId: string }) {       
          if (tempjobIdSelected.length > 0) {
            tempjobIdSelected.forEach(function (Value) {
              if (candidate.staffingagencyId === Value.staffingagencyId) {
                candidate.selected = true;
              }
            });
          } else {
            candidate.selected = false;
          }
        });
        //console.log('data', this.listOfAllRequest);
      }
    });
  }

  isLogin = 'true';
  openStaffingAgencyDialog(staffingagencyId: any) {
     
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(StaffingAgencyPopUpComponent, {
      width: '500px',
      disableClose: true,
      data: { staffingagencyId  }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
    }
  }
}
