import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, EmailValidator, FormControl } from '@angular/forms';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { first } from 'rxjs/operators';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { StaffingAgencyPopUpComponent } from 'src/app/enterprise/staffing-agency-pop-up/staffing-agency-pop-up.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/admin/service/excel.service';
import { TableService } from 'src/app/admin/service/table.service';
import {AuthenticationService } from '../../app.service';
import { ShareService } from '../share.service';
import moment, { Moment } from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../timesheetjobseeker/timesheetjobseeker.component';

	 

@Component({
  selector: 'app-timesheetsupplier',
  templateUrl: './timesheetsupplier.component.html',
  styleUrls: ['./timesheetsupplier.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class TimesheetsupplierComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  date = new FormControl(moment());
 //isRequired = false;
 userType:any;
 id:any;
 preloader: boolean = false;
 isAddform: boolean = false;
 staffingagencie = [];
 entblocksec: any;
 staffunblk: any;
 body: FormGroup;
 isCollapsedMenu: boolean = false;
 submitted: boolean = false;
 defaultPageSize = 5;
 defaultPageNumber = 1;
 securityQuestionsList: any = [];
 paginator = {
   pageSize: this.defaultPageSize,
   length: 0,
   pageSizeOptions: [5, 10, 15, 20],
   pageIndex: 0
 }
 today: String = new Date().toISOString().split('T')[0];
 todayDate: String = new Date().toISOString().split('T')[0];
 issuccess: any;
 signupstaffingForm: FormGroup;
 userData: any;
csv_timesheet=[];

 mobMenulist() {
   this.isCollapsedMenu = !this.isCollapsedMenu;
 }
 constructor(public dialog: MatDialog, private enterpriseApis: EnterpriseService, private _router: Router,
   private formBuilder: FormBuilder, private tostr: ToastrManager,private datePipe: DatePipe,
   private tableService: TableService,private AuthenticationService: AuthenticationService,
   private excelService: ExcelService,
   private _activated: ActivatedRoute ,private shareservice:ShareService
   ) {

    this.id = this._activated.snapshot.params['id'];
   this.body = this.formBuilder.group({
     fromdate: '',
     todate: '',
     search: '',
     totalcandidates: '0-All',
     pageSize: 5,
     PageNumber: 1,
     action: 'All',
     jobid: '',
     employeeId:'',
      manager:'',
      empName:'',
      projName:'',
      sheet_status:'',
      supname:'',
      projectid:''
   });

   this.signupstaffingForm = this.formBuilder.group({
     companyName: ['', Validators.required],
     website: ['', [Validators.required, Validators.pattern("(https?://)?([\\da-zA-Z.-]+)\\.([a-zA-Z.]{2,6})[/\\w .-]*/?")]],
     firstName: ['', Validators.required],
     middleName: [''],
     lastName: ['', Validators.required],
     emailId: ['', [Validators.required, Validators.email,
     Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
     password: ['', [Validators.required, Validators.minLength(6),
     Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{5,}')]],
     contactNo: ['', Validators.required],
     securityQuestions:['',Validators.required],
     answer : ['',Validators.required],
     createdDate:['']
   });

   this.tableService.getSecurityQuestions().subscribe((res: any) => {
     if (res.success) {
       this.securityQuestionsList = res.data;
     } else {   
     } 
   });
   this.userData = JSON.parse(window.localStorage.getItem('udata'));
   this.userType=this.userData.data.credentials.userType;
 }

 ngOnInit() {
   
   this.loadAllStaffingAgencies();
   
 }

 get sf() { return this.signupstaffingForm.controls; }
 Popupenterpsform() {

   this.signupstaffingForm .reset();
    this.isAddform = !this.isAddform; 
   }


 loadAllStaffingAgencies() {
  this.AuthenticationService.jwtTokenExpCheck();
   if (this.body.value.todate != "" && this.body.value.fromdate != null) {
     const d = new Date(this.body.value.todate);
     const f = new Date(this.body.value.fromdate);
     let finalDate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
     let formDate = f.getFullYear() + "-" + (f.getMonth() + 1) + "-" + f.getDate();
     this.body.value.todate = finalDate;
     this.body.value.fromdate = formDate
     this.body.value.todate = (this.datePipe.transform(this.body.value.todate,'yyyy-M'))
     this.body.value.fromdate=(this.datePipe.transform( this.body.value.fromdate,'yyyy-M' ))
   }
   if(this.body.controls['fromdate'].value == null){
     this.body.controls['fromdate'].setValue('')
   }
   if(this.body.controls['todate'].value == null){
     this.body.controls['todate'].setValue('')
   }
   this.preloader = true;
   if( this.body.value.todate >=  this.body.value.fromdate){
   
    if(this.userData.data.credentials.userType != 'AD'){
      if(this.searchButton == true){
        this.body.controls['PageNumber'].setValue(1);
      }   
     this.enterpriseApis.gettimesheetstaffing(this.body.value).pipe(first()).subscribe(res => {
       if (res.success) {
         this.preloader = false;
         this.staffingagencie = res.data;  
         if (this.staffingagencie.length > 0) {
           this.paginator.length = this.staffingagencie[0].totalCount;
         }
         else{
          this.paginator.length=0;
         }
         if(this.searchButton == true)
         {
           this.paginators.pageIndex = 0;
         }
         this.searchButton = false;

         this.csv_timesheet=[];

         this.staffingagencie.forEach(element => {
          this.csv_timesheet.push({
              'VK Employee ID': element.employeeId,
              'Employee Name': element.employeeName,
              'Manager': element.managerName,
              'Supplier Name':element.suppliercompany,
              'Job Id':element.jobId,
              'Project No.':element.projectid,
              'Project Name':element.projname,
              'Month':this.datePipe.transform(element.period_Date,'MMM y'),
              'Reg Hrs':element.TotalHours,
              'Rate':element.job_rate,
              'OT Hrs':element.ot_totalHour,
              'OT Rate':element.overtime_payrate,
              'Cur.':element.currency,
              'Cost':element.totalamount,
              'Status':'UnApproved'
            });
      
      });

       }
     });
   }
   else if(this.userData.data.credentials.userType === 'AD')
   {
    if(this.searchButton == true){
      this.body.controls['PageNumber'].setValue(1);
    }
     this.enterpriseApis.adminfilterforstaffing(this.body.value,this.id).pipe(first()).subscribe(res => {
       if (res.success) {
         this.preloader = false;
         this.staffingagencie = res.data;
         if(this.searchButton == true)
            {
              this.paginators.pageIndex = 0;
            }
            this.searchButton = false;
         if (this.staffingagencie.length > 0) {
           this.paginator.length = this.staffingagencie[0].totalCount;
         }
       }
     });
   }
     
  }
  else{
    this.tostr.errorToastr('From date should be less than To date', "error!");
  }
 }
 
 clearForm(){
  this.today = new Date().toISOString().split('T')[0];
  this.from_Date = null;
   this.body = this.formBuilder.group({
     fromdate: '',
     todate: '',
     search: '',
     totalcandidates: '0-All',
     pageSize: 5,
     PageNumber: 1,
     action: 'All',
     jobid: '',
     employeeId:'',
     manager:'',
     empName:'',
     projName:'',
     sheet_status:'',
     supname:'',
     projectid:''
   });
   this.paginator.pageSize = 5;
   this.loadAllStaffingAgencies();
   this.paginators.pageIndex=0;
   this.paginators.pageSize=5;
 }
 removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
   const startPos = e.currentTarget.selectionStart;
   if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
     e.preventDefault();
   }
 }

 omit_special_char(event: { charCode: any; }) {
   const k = event.charCode;
   return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
 }

 omit_special_char_number(event: { charCode: any; }) {
   const k = event.charCode;
   return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
 }


 searchButton = false;
 loadData(){
   this.searchButton= true;
   this.loadAllStaffingAgencies();
 }
 addstaffingagencydetails() {
   this.submitted = true;
   if(this.signupstaffingForm.valid){
    this.signupstaffingForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
     this.enterpriseApis.addstaffingagency(this.signupstaffingForm.value).pipe(first()).subscribe(res => {
       this.issuccess = res;
       if (this.issuccess.success) {
         this.submitted = false;
         this.loadAllStaffingAgencies();
         this.isAddform = false;
         this.tostr.successToastr(this.issuccess.message, "Success!");
       } else {
         this.tostr.errorToastr(this.issuccess.message, 'OPPS!')
       }
     });
   }
   else{
     return false;
   }
   
 }

 blockentstaffing(id) {
   this.enterpriseApis.staffingBlock(id).pipe(first()).subscribe(stffingagency => {
     this.entblocksec = stffingagency;
     if (this.entblocksec.success) {
       this.tostr.successToastr(this.entblocksec.message, "Success!");
       this.loadAllStaffingAgencies();
     } else {
       this.tostr.errorToastr(this.entblocksec.message, 'OPPS!')
     }
   });
 }

 unblockentstaffing(id) {
   this.enterpriseApis.staffingUnblock(id).pipe(first()).subscribe(stffingagencyunblk => {
     this.staffunblk = stffingagencyunblk;
     if (this.staffunblk.success) {
       this.tostr.successToastr(this.staffunblk.message, "Success!");
       this.loadAllStaffingAgencies();
     } else {
       this.tostr.errorToastr(this.staffunblk.message, 'OPPS!')
     }

   });
 }

 staffingagencyView(id) {
   //ad/admin
   if(this.userType=="EP"){
   this._router.navigate(['/ep/enterprise/clientjobseeker', id]);
   }else if(this.userType=="AD")
   {
    this._router.navigate(['/ad/admin/adminjobseeker/',id]);
   }
   // if(this.userData.data.credentials.userType != 'AD'){
   //   this._router.navigate(['/ep/enterprise/staffing_agency_detail/', id]);
   // }
   // else if(this.userData.data.credentials.userType === 'AD'){
   //   this._router.navigate(['/ad/admin/staffing_agency_detail/', id]);
   // }
  
   //this._router.navigate(['/ep/enterprise/update-jobseeker',jobSeekerId]);

 }

 existph = false;
 validateContact:any;
 checkPhone() {
   this.existph = false;
   this.enterpriseApis.contactExist(this.signupstaffingForm.controls['contactNo'].value).subscribe((res: any) => {   
     this.validateContact = res;
      if(this.validateContact.data.length > 0 && this.validateContact.data.length != 0){
       this.existph = true;
       this.signupstaffingForm.controls['contactNo'].setErrors({ 'invalid': true })
     }  
   });
 }

 isRequired = false;
 OnChangePhoneNo(tab, e) {
   var r = /(\D+)/g,
     npa = '',
     nxx = '',
     last4 = '';
   e.value = e.target.value.replace(r, '');
   npa = e.value.substr(0, 3);
   nxx = e.value.substr(3, 3);
   last4 = e.value.substr(6, 4);
   if (e.target.value !== '' && e.keyCode != 8) {
     if (tab === 'CP') {
       this.signupstaffingForm.controls['contactNo'].setValue(npa + '-' + nxx + '-' + last4);
     }
   } else {
     this.isRequired = false;
   }
 }
 
 getpaginatorData(e) {
   this.paginator.pageSize = e.pageSize;
   this.paginator.pageIndex = e.pageIndex;
   this.body.controls['pageSize'].setValue(e.pageSize);
   this.body.controls['PageNumber'].setValue(e.pageIndex + 1);
   this.searchButton = false;
   this.loadAllStaffingAgencies();
 }

 isLogin='true';
 openStaffingAgencyDialog(staffingagencyId: any) {
  this.AuthenticationService.jwtTokenExpCheck();
  this.isLogin = localStorage.getItem('islogin');
  if(this.isLogin != 'false'){
   const dialogRef = this.dialog.open(StaffingAgencyPopUpComponent, {
     width: '500px',
     disableClose: true,
     data: { staffingagencyId }
   });
   dialogRef.afterClosed().subscribe(result => {
     //console.log('The dialog was closed');
   });
  }
 }     

 exportAsXLSX(): void {
   this.excelService.exportAsExcelFile(this.csv_timesheet, 'sample');
 }

 from_Date:String;
dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datePipe.transform(this.body.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datePipe.transform(this.body.controls['todate'].value)).toISOString();
  }
}

timesheetDetails(details){
  let jobseekerId=details.employeeId;
  if(this.userType=="SA"){
  this._router.navigate(['/st/staff/timesheetdetails/',details.period_Date,details.jobId,jobseekerId]);
  }else if(this.userType=="EP"){
   this._router.navigate(['/ep/enterprise/timesheetdetails/',details.period_Date,details.jobId,jobseekerId]);
  }else if(this.userType=="AD"){
   this._router.navigate(['/ad/admin/timesheetdetails/',details.period_Date,details.jobId,jobseekerId]);
  }
   }

   chosenYearHandler(normalizedYear: Moment,value:string) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    if(value=='fromdate'){
      this.body.controls['fromdate'].setValue(ctrlValue);
    }
    if(value=='todate'){
      this.body.controls['todate'].setValue(ctrlValue);
    }
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>,value:string) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
      if(value=='fromdate'){
        this.body.controls['fromdate'].setValue(this.datePipe.transform(ctrlValue['_d'],'yyyy-MM'));
      }
      if(value=='todate'){
        this.body.controls['todate'].setValue(this.datePipe.transform(ctrlValue['_d'],'yyyy-MM'));
      }
    datepicker.close();
  }
}
