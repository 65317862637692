import { Component, OnInit, Inject } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import {AuthenticationService } from '../../app.service';

import * as moment from 'moment';
import { ShareService } from '../share.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})

export class AddGroupComponent implements OnInit {
  AddPoolForm: FormGroup;
  issuccess: any;

  submitted = false;
  isAddform: boolean;
  userData: any;
  constructor(private enterPriseAPI: EnterpriseService, private _router: Router, private formBuilder: FormBuilder, public dialog: MatDialog,
              private shareservice:ShareService,private dialogRef: MatDialogRef<AddGroupComponent>, 
 private AuthenticationService: AuthenticationService,
 private tostr: ToastrManager, @Inject(MAT_DIALOG_DATA) public data: any, 
              ) {

    this.AuthenticationService.jwtTokenExpCheck();
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.AddPoolForm = this.formBuilder.group({
      PoolName: ['', [Validators.required,Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      PoolDescription: ['', [Validators.required, Validators.pattern('^(?!\\s)(?![^]*\\s$)[a-zA-Z0-9\\s()-]+$')]],
      pk_pool_Id: [''],
      createdDate:['']
    });
    if (this.data === true) {
      this.isAddform = true;
    } else {
      this.isAddform = false;
      this.PoolDataSetForUpdate(this.data);
    }
  }

  get pl() { return this.AddPoolForm.controls; }
  ngOnInit() {
  }
  clearData() {
 
    this.AddPoolForm.reset();
    this.dialogRef.close('close');
  }
  PoolDataSetForUpdate(id: any) {

    this.enterPriseAPI.GetPoolDetails(id).pipe(first()).subscribe(res => {
      if (res.success) {
        res.data.forEach(element => {
          this.AddPoolForm.controls['PoolName'].setValue(element.poolName);
          this.AddPoolForm.controls['pk_pool_Id'].setValue(element.pk_pool_Id);
          this.AddPoolForm.controls['PoolDescription'].setValue(element.description);
        });
      }
    });
  }
 

  addPooldetails() {
  
    this.AuthenticationService.jwtTokenExpCheck();
    this.submitted = true;
   // this.AddPoolForm.controls['PoolName'].value.replace(/\s/g, ""); 
  //  this.AddPoolForm.controls['PoolDescription'].value.replace(/^\s+|\s+$/gm,'');
 
    if (this.AddPoolForm.valid) {
      if (this.AddPoolForm.controls['pk_pool_Id'].value) {
        this.AddPoolForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        this.enterPriseAPI.UpdatetPool(this.AddPoolForm.value).pipe(first()).subscribe(res => {
          this.issuccess = res;
          if (this.issuccess.success) {
            this.submitted = false;
            this.clearData();
           
            this.tostr.successToastr(this.issuccess.message, 'Success!');
            //this._router.navigate(['/ep/enterprise/Group/']);
            this.dialogRef.close(this.issuccess);
           // window.location.reload();
          } else {
            this.tostr.errorToastr(this.issuccess.message, 'OPPS!');
          }
        });
      } else {      
        this.AddPoolForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));      
        this.enterPriseAPI.insertPool(this.AddPoolForm.value).pipe(first()).subscribe(res => {
          this.issuccess = res;
          if (this.issuccess.success) {
            this.submitted = false;
            this.clearData();
            this.tostr.successToastr(this.issuccess.message, 'Success!');
            this.redirect();
            
          } else {
            this.tostr.errorToastr(this.issuccess.message, 'OPPS!');
          }
        });
      }

    }
  }
  redirect() {    
    if(this.userData.data.credentials.userType == 'AD'){
      this._router.navigate(['/ad/admin/group']);
    }else if(this.userData.data.credentials.userType == 'EP'){
      this._router.navigate(['/ep/enterprise/Group']);
    } 

  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
 // 20200929: Akhilesh, Special charcter validation, jira no 70
 omit_validate_specialchar(event: { charCode: any; }) {
  const k = event.charCode;   
   if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
     this.tostr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
     return false}
   }

}
