import { Component, OnInit, ɵConsole } from '@angular/core';
import { FormGroup, FormBuilder, Form, Validators } from '@angular/forms';
import { StaffingAgencyServices } from '../../admin/service/staffing-agency.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShareService } from '../share.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-subscribe-unsubscribe',
  templateUrl: './subscribe-unsubscribe.component.html',
  styleUrls: ['./subscribe-unsubscribe.component.css']
})
export class SubscribeUnsubscribeComponent implements OnInit {
  MailingTopic: any = [];
  TopicIdSelected: any = [];
  topicid: any;
  submited = false;
  SunsForm: FormGroup;
  createdDate:string;
  // [
  //   { id: '1', name: 'Job-Invitation', selected: false },
  //   { id: '2', name: 'Notification And Announcment', selected: false },
  //   { id: '3 ', name: 'test3', selected: false },
  // ];

  constructor(private tostr: ToastrManager, private _activated: ActivatedRoute,private _router: Router,
     private stafingagencyService: StaffingAgencyServices,private layoutService:LayoutService, private _formBuilder: FormBuilder, private shareservice:ShareService ) {
    // this.topicid = this._activated.snapshot.params['topicid'];
    this.fillform();
    this.fillTopic();

  }

  ngOnInit() {
    this.layoutService.setShowSidebar(false);
  }
  fillform() {
    this.SunsForm = this._formBuilder.group({
      contact_email: ['', [Validators.required, Validators.email, Validators.pattern(
        '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,3}(\\.[a-zA-Z]{2,2})?(\\.[a-zA-Z]{2,2})?$')]],
    });
  }

  get f() { return this.SunsForm.controls; }
  fillTopic() {
    this.stafingagencyService.GetUnsubscribeTopics().subscribe(res => {
      if (res.success) {
        this.MailingTopic = res.data.recordset;
        //console.log('Mailing Topic', this.MailingTopic);
        //console.log(res.data);
      }
    });
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  UpdateTopicdetails() {
    if (this.SunsForm.valid) {
      const emailid = this.SunsForm.controls.contact_email.value;
      this.MailingTopic.forEach((element) => {
        if (element.selected) {
          element.email = emailid;
          element.IsUnsubscribe = true;
          element.createdDate = this.shareservice.convertDateIntoUtc(new Date());
          //console.log('element', element);
          this.createdDate = this.shareservice.convertDateIntoUtc(new Date());
          this.stafingagencyService.insertUpdateSubsUnSubs(element).pipe(first()).subscribe(res => {
            if (res.success) {
             // location.href = "https://www.veriklick.com/";
              this._router.navigate(['/feed_back/',emailid]);
            } else {

            }
          });
        }
      });
    } else {
      this.tostr.errorToastr('Email should be a valid email id.', 'Error!');

    }
  }
  selectJob(data: { topicid: any; }, event: { checked: any; }) {

    if (event.checked) {
      this.TopicIdSelected.push({
        topicid: data.topicid,
      });
    } else {
      for (let i = 0; i < this.TopicIdSelected.length; i++) {
        //console.log(this.TopicIdSelected[i].topicid);
        if (this.TopicIdSelected[i].topicid === data.topicid) {
          this.TopicIdSelected.splice(i, 1);
        }
      }
    }
  }

  checkedUnChecked(item: { selected: any; }, event: { checked: any; }) {
    item.selected = event.checked;
    // //console.log("item.selected", item.selected);
    // //console.log("this.MailingTopic", this.MailingTopic);


  }


}
