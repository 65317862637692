import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';   
import { EnterpriseService } from '../enterprise.service';
import { Time } from '@angular/common';
import { ToastrManager } from 'ng6-toastr-notifications';
import { JobseekerService } from '../../JobSeeker/jobseeker.service';
// import { DatePipe } from '@angular/common';
import { PreviewResumePopupComponent } from '../preview-resume-popup/preview-resume-popup.component';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Router, RouterLink, RouterModule, ActivatedRoute } from '@angular/router';
import { ExcelService } from 'src/app/admin/service/excel.service';
import {AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';

export interface DialogData {
  jobseekerId: string;
  date: Date;
  time: Time;
}



// export class DefaultIntl extends OwlDateTimeIntl = {

//   /** A label for the cancel button */
//   cancelBtnLabel= 'Cancel',
  
//   /** A label for the set button */
//   setBtnLabel= 'Set',
  
//   /** A label for the hour12 button (AM) */
//   hour12AMLabel= 'AM',
  
//   /** A label for the hour12 button (PM) */
//   hour12PMLabel= 'PM',
//   };



@Component({
  selector: 'app-search-profile',
  templateUrl: './search-profile.component.html',
  styleUrls: ['./search-profile.component.css'],
})
export class SearchProfileComponent implements OnInit {
  @ViewChild(MatPaginator,{static : true} ) paginators: MatPaginator;
  listShow: boolean = false;
  isVisibleFilterBtn = true;
  dataLoad  = false;
  preLoader: boolean = false;
  searchFilter: FormGroup;
  ddlFilterList: any = [];
  filterList: any;
  paginator = {
    pageSize: 5,
    length: 0,
    pageSizeOptions: [5, 10, 20, 100],
    pageIndex: 0
  };

  panelOpenState: boolean = false;
  constructor(
    private _router: Router,
    private _fromBuilder: FormBuilder,
    private enterPriseAPI: EnterpriseService,
    private JSApi: JobseekerService,
    private toastr: ToastrManager,
    private datepipe:DatePipe,
    private _formBuilder: FormBuilder,
    private excelService: ExcelService,
    public dialog: MatDialog,private _titleService : Title,
private AuthenticationService: AuthenticationService,
) {

    // for title set 
    var newTitle = 'Verisource | Search Job Seeker';
    this._titleService.setTitle( newTitle );
    this.formInit();
    this.enterPriseAPI.getfilterSearchProfile().subscribe((res: any) => {
      if (res.success) {
        this.filterList = res.data;
        if(res.data.length == 0){
          this.isVisibleFilterBtn = false;
        }  
      } else {
        this.toastr.errorToastr(res.message, "Error!");
      }
    });

  }

  ngOnInit() {
    
    this.setFilter();
  }
 
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
   const startPos = e.currentTarget.selectionStart;
   if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
     e.preventDefault();
   }
 }
  islstopen: boolean = false;
  OpenlfMenu() { this.islstopen = !this.islstopen }
  formInit() {
    this.searchFilter = this._fromBuilder.group({
      PageSize: 5,
      PageNumber: 1,
      resume_status: "All",
      secialization: '',
      search: "",
      resumeStatus: "All",
      profileStatus: 'All',
      highestDegree: 'Any',
      minExperience: 0,
      maxExperience: 0,
      usMasterDegree: 'All',
      citizenship: '',
      skills: '',
      minUsaExperience: 0,
      maxUsaExperience: 0,
      country: '',
      state:'',
      zipCode:'',
      profile_status:'All'
    });
  }             

  filterListData: any=[];
  csv_filterListData:any=[];

  searchButton = false;
  loadData(){
    this.searchButton= true;
    this.setFilter();
  }
  clearForm() {
   
    this.searchFilter = this._fromBuilder.group({
      PageSize: 5,
      PageNumber: 1,
      resume_status: "All",
      secialization: '',
      search: "",
      resumeStatus: "All",
      profileStatus: 'All',
      highestDegree: 'Any',
      minExperience: 0,
      maxExperience: 0,
      usMasterDegree: 'All',
      citizenship: '',
      skills: '',
      minUsaExperience: 0,
      maxUsaExperience: 0,
      country: '',
      state:'',
      zipCode:'',
      profile_status:'All'
    });
    this.paginator.pageSize=5;
    this.setFilter();
    this.paginators.pageIndex = 0;
  }
  setFilter() {

    this.AuthenticationService.jwtTokenExpCheck();
    this.preLoader = true;
    if(this.searchButton == true){
      this.searchFilter.controls['PageNumber'].setValue(1);
    }
    this.enterPriseAPI.searchprofile(this.searchFilter.value).subscribe((res: any) => {
      this.preLoader = false;
      this.listShow = true;
      this.dataLoad  = true;
      if (res.success) {
        this.filterListData = res;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;
        // //console.log(this.filterListData);
        if (this.filterListData.data.length > 0) {
          this.paginator.length = this.filterListData.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }
        this.filterListData.data.forEach(element => {
          this.csv_filterListData.push({
              'Name': element.fullName,
              'Job Seeker last Update': this.datepipe.transform(element.resumeuploaded_date,'MM/dd/yyyy','locale'),
              'Status':  element.noOfAppliedjobs==0?'No job applied':'Applied for '+ element.noOfAppliedjobs+' job' ,
              'Skills': element.role,
              'Company':element.companyName,
              'Interviews': 'Interviews',      
            });
      });
        ////console.log("Filter Response =>", this.csv_filterListData);
      }
    });
  }

  isLogin='true';
  openDialog(id): void {
   
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){

    const dialogRef = this.dialog.open(shceduleInterviewPopup, {
      width: '800px',
      data: { jobseekerId: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.setFilter();
      // this.animal = result;
    });
  }
  }

  dateFormat(date){
    return this.datepipe.transform(date,'dd/MM/yyyy');
  }

  previewDialog(details): void {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRefDetail = this.dialog.open(PreviewResumePopupComponent, {
      width: 'auto',
      data: details
    });

    dialogRefDetail.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  }

  jsonArrayCnvrtVl(val) {
    // //console.log('val :---->>', Object.keys(val));
    return Object.keys(val);
  }
  jsonArrayCnvrt(val) {
    return Object.values(val);
  }

  countryData(ddlType, values) {
    this.ddlFilterList = [];
    // //console.log("==============?>", ddlType);
    switch (ddlType) {
      case 'country': {
        if (this.searchFilter.controls['country'].value.length >= 1) {
          this.enterPriseAPI.country_enterprise().subscribe((res: any) => {
            if (res.success) {
              //console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }
          });
        }
        break;
      }
      case 'jobLocation': {
        if (this.searchFilter.controls['jobLocation'].value.length >= 3) {
          //console.log("Response ------------>");
          this.JSApi.joblocation(this.searchFilter.controls['jobLocation'].value).subscribe((res: any) => {
            if (res.success) {
              //console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }

          });
        } else {
          //console.log("dssadsa");
        }
        break;
      }
      case 'citizenship': {
        if (this.searchFilter.controls['citizenship'].value.length >= 1) {
          this.enterPriseAPI.nationality_enterprise().subscribe((res: any) => {
            if (res.success) {
              //console.log(res)
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }
          });
        }
        break;
      }

    }
  }

  clearData() {
    this.ddlFilterList = [];
  }

  setpaggination(e) {

    this.searchButton = false;
    // this.searchFilter.controls['PageSize'].setValue(e.pageSize);
    // this.searchFilter.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.searchFilter.controls['PageSize'].setValue(e.pageSize);
    this.searchFilter.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.setFilter();
  }
  reset() {
    this.formInit();
    this.paginator.pageSize=20;
    this.setFilter();

  }

  exportAsXLSX(): void {
   
    this.excelService.exportAsExcelFile(this.csv_filterListData, 'sample');
  }

}




@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
    <section class="interviewSchedule" *ngIf="!preloader">  
      <div class="modal-header">
        <h2>Interview Schedule</h2>
        <button aria-label="Close" class="close"  (click)="onNoClick()" type="button"><span aria-hidden="true"><i class="material-icons"> close </i></span></button>
      </div>
      <form [formGroup]="scheduleForm">
        <div class="row">
          <div class="col-md-6 col-lg-7">
            <ul class="jobList">    
              <li class="jobItems" *ngFor="let items of JobList.data">
                <div class="custom-radio">
                  <input type="radio" name="jobId" formControlName="jobId" value="{{items.jobId}}" id="{{items.jobId}}" (change)="jobValue(items)"/>
                  <label for="{{items.jobId}}"> 
                    <div class="d-flex flex-wrap">
                      <h1><span class="badge badge-success">{{items.jobId}}</span> {{items.jobTitle}}</h1>
                      <div class="ml-auto joblocation d-flex"><strong>Location:</strong> <span class="text-truncate ml-2 locationLimit">{{items.jobLocation | slice:0:50 }}{{ items.jobLocation.length > 50 ? '...' : ''}}</span></div>
                    </div>
                    <p><strong>Skills:</strong> {{items.keywords}}</p>
                  </label>
                </div>
              </li>    
              <li *ngIf="JobList.data.length == 0">
                <p>Jobs not found or applied for all jobs.</p>
              </li>         
            </ul>
          </div>
          <div class="col-md-6 col-lg-5 text-center">
            <div class="date_container">
              <div class="date-time">
                <owl-date-time-inline formControlName="scheduleDate" hour12Timer  min="{{today}}"></owl-date-time-inline>
              </div>
              <div class="btn-wrapper mt-3">
                <button class="btn btn-primary mr-1" (click)="GetTime(data.jobseekerId)">Schedule</button>
               
              </div>
            </div>
          </div>
        </div> 
        </form>       
    </section>
    <mat-spinner *ngIf="preloader"></mat-spinner>

    <div class="customModal" *ngIf="issendml">
    <div class="modal">
        <div class="modal-backdrop show" (click)="issendml = false"></div>   
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Send Email</h5>
                    <button type="button" class="close" data-dismiss="modal" (click)="closeSendMailPopUp()" aria-label="Close"><i class="material-icons">close</i></button>
                </div>

        <form [formGroup]="sendEmailToCandidate">
            <div class="modal-body">
                <div class="row">

                    <div class="col-sm-6">
                      <div class="form-group">
                          <label for="" class="form-label">Job-Id : {{jobData.jobId}} </label>
                      </div>
                    </div>

                    <div class="col-sm-6">
                      <div class="form-group">
                          <label for="" class="form-label">Interview Time : {{scheduleForm.controls['scheduleDate'].value}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12">
                    <div class="form-group">
                        <label for="" class="form-label"> Job-Description </label>
                         {{jobData.jobDescription}}
                    </div>
                  </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="" class="form-label">To <small class="text-danger fill">*</small></label>
                            <input type="text" formControlName="to" name="to" disabled="disabled" class="form-control" (keydown.space)="$event.preventDefault()" maxlength="100" placeholder=""  [ngClass]="{ 'is-invalid': submitted && a.to.errors || mailError}" />
                            <div *ngIf="submitted && a.to.errors" class="invalid-feedback">
                                <div *ngIf="a.to.errors.required">To email is required</div>
                                <div *ngIf="a.to.errors.pattren">Email must be a valid email address</div>
                            </div> 
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="" class="form-label">Subject <small class="req">*</small></label>
                            <input type="text" formControlName="subject" name="subject" (keypress)="removeSpaces($event)" class="form-control" maxlength="200" placeholder=""  [ngClass]="{ 'is-invalid': submitted && a.subject.errors }" />
                            <div *ngIf="submitted && a.subject.errors" class="invalid-feedback">
                                <div *ngIf="a.subject.errors.required">Subject  is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="" class="form-label">Message <small class="text-danger fill">*</small></label>
                            <textarea name="content" id="content" formControlName="content" (keypress)="removeSpaces($event)" class="form-control" maxlength="500" [ngClass]="{ 'is-invalid': submitted && a.content.errors }"></textarea>
                            <div *ngIf="submitted && a.content.errors" class="invalid-feedback">
                                    <div *ngIf="a.content.errors.required">Message is required</div>
                                </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
                
            </div>
            <div class="modal-footer">
                <a class="btn btn-primary" (click)="closeSendMailPopUp()">Cancel</a>
                <a class="btn btn-secondary" (click)="sendemail()">Submit</a>
                
            </div>
        </form>
        <div class="loaderFile" *ngIf="profileLoader">
            <img src="/assets/images/preloader.gif" />
        </div>
    </div>
</div>
</div>
</div>
    
  `,
  styleUrls: ['./search-profile.component.css']

})
export class shceduleInterviewPopup {
  emailsuccess: any;
  submitted = false;
  Candidate:any;
  issendml: boolean = false;
  sendEmailToCandidate: FormGroup;
  today: String = new Date().toISOString().split('T')[0];
  JobList: any;
  selectedMoment: Date = new Date();
  preloader: boolean = false;
  scheduleForm: FormGroup;
  MyjobFilter = {
    PageNumber: 1,
    PageSize: 500,
    country: "",
    jobLocation: "",
    jobType: "All",
    maxAnnualPay: 0,
    maxExperience: 0,
    minAnnualPay: 0,
    minExperience: 0,
    orderBy: "Date",
    postedDate: 0,
    search: ""
  }

  constructor(
    public dialogRef: MatDialogRef<shceduleInterviewPopup>,
    private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private AuthenticationService: AuthenticationService,
    private enterpriseApis: EnterpriseService,
    private toastr: ToastrManager,
    private shareservice:ShareService
  ) {

    this.scheduleForm = this._formBuilder.group({
      jobId: '',
      jobseekerId: '',
      resume_status: 'Interview Schedule',
      scheduleDate: [''],
      createdDate:['']
    });
    this.sendEmailToCandidate = this._formBuilder.group({
      "jobseekerId": [''],
      "to": ['',[Validators.required, Validators.email]],
      "subject": ['',[Validators.required]],
      "content": ['',[Validators.required]],
      "jobId":[''],
      "jobDesc":[''],
     "interviewTime":['']
    });
    this.preloader = true;
    // picker.open()
    try {
      this.enterpriseApis.singleInterview(data.jobseekerId).subscribe((res: any) => {
        // this.listShow = true;
        this.preloader = false;
        if (res.success) {
          this.JobList = res;
        } else {
          this.toastr.errorToastr(res.message, 'Error!');
        }
      });

    } catch (error) {
      this.toastr.errorToastr(error, 'Error!');
    }



  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  popUpsend(id) {
this.enterpriseApis.candidatedetails(id).subscribe((res: any) => {
  if (res.success) {
    //this.setValueCandidate(res.data);
    this.Candidate=res.data.details;
    this.issendml = !this.issendml;
    this.sendEmailToCandidate.controls['jobseekerId'].setValue(this.Candidate.jsId);
    this.sendEmailToCandidate.controls['to'].setValue(this.Candidate.emailId);
  } else {
    this.toastr.errorToastr(res.message, 'Error!');
  }
});
  }

  closeSendMailPopUp(){
    this.issendml = false;
    this.scheduleForm = this._formBuilder.group({
      jobId: '',
      jobseekerId: '',
      resume_status: 'Interview Schedule',
      scheduleDate: [''],
      createdDate:['']
    });
    this.dialogRef.close();
  }
  sendemail() {
    this.submitted = true;
    //console.log(this.sendEmailToCandidate);
    this.sendEmailToCandidate.controls['jobId'].setValue(this.jobData.jobId);
    this.sendEmailToCandidate.controls['jobDesc'].setValue(this.jobData.jobDescription);
    this.sendEmailToCandidate.controls['interviewTime'].setValue(this.scheduleForm.controls['scheduleDate'].value);

    if (this.sendEmailToCandidate.valid){   
    // //console.log('send email >>>>>>>', this.sendEmailToCandidate.value);
    this.enterpriseApis.sendemailtocandidate(this.sendEmailToCandidate.value).subscribe(res => {
      // //console.log('LoadJobList >>>>>>>>>>>>>   :', res);
      this.emailsuccess = res;
      // //console.log('emailsuccess >>>>>>>>>>>>..', this.emailsuccess);
      if (this.emailsuccess.success) {
        // //console.log('emailsuccess >>>>>>>>>>>>..', this.emailsuccess);
        this.toastr.successToastr(this.emailsuccess.message, '', { position: 'bottom-right' });
        // this.profileLoader = false;
        this.sendEmailToCandidate.reset();
        this.scheduleForm.reset();
        this.issendml = false;
        this.dialogRef.close();
        // window.location.reload();
      } else {
        // //console.log('emailsuccess >>>>>>>>>>>>..', this.emailsuccess);
        this.toastr.errorToastr(this.emailsuccess.message, '', { position: 'bottom-right' });
      }
    });
  }
  else{
     return false;
  }
}
  onNoClick(): void {
    this.dialogRef.close();
  }

  get a() { return this.sendEmailToCandidate.controls; }

  jobData :any={};
  checkJob = true;
  jobValue(value:any){
    this.jobData = value;
    this.checkJob = false;
  }
  GetTime(id) { 
    this.AuthenticationService.jwtTokenExpCheck();      
    //console.log('this.scheduleForm.value',this.scheduleForm.value);
    // this.scheduleForm.controls['scheduleDate'].setValue(this.selectedMoment);
    try {
      if (this.scheduleForm.value.jobId !== '' && this.scheduleForm.value.scheduleDate !== '') {
         let mycase: Date = this.scheduleForm.value.scheduleDate;
        // //console.log('mycase :---->', mycase)
        // let mycas = `${mycase}`;
        // let mycas = this.datePipe.transform(mycase, 'yyyy-MM-dd') + ' ' + this.datePipe.transform(mycase, 'hh:mm');
      //  let mycas = this.datePipe.transform(mycase, 'yyyy-MM-dd') + ' ' + mycase.getHours() + ':' + mycase.getMinutes();
        this.scheduleForm.controls['jobseekerId'].setValue(this.data.jobseekerId);
         this.scheduleForm.controls['scheduleDate'].setValue(mycase);

        const d = new Date(this.scheduleForm.value.scheduleDate);
        let scheduleDate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        this.scheduleForm.value.scheduleDate = scheduleDate;

        //console.log('After Change Calender :', this.scheduleForm.value);
        // this.body.jobId = ;
        this.scheduleForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        this.enterpriseApis.scheduleinterview(this.scheduleForm.value).subscribe((res: any) => {
          if (res.success) {
            //console.log(res);
            this.toastr.successToastr(res.message, 'Success!');
          
            this.popUpsend(id);
            //window.location.reload();
          } else {
            this.toastr.errorToastr(res.message, 'Error!');
          }
        });
      } else {
        this.toastr.warningToastr('Please Select Job & Scheduling time.', 'Warning!')
        return false;
      }

    } catch (error) {
      this.toastr.errorToastr(error, 'Error!');
    }



  }




}