import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableService } from 'src/app/admin/service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-projectbudgetapproval',
  templateUrl: './projectbudgetapproval.component.html',
  styleUrls: ['./projectbudgetapproval.component.css']
})
export class ProjectbudgetapprovalComponent implements OnInit {
  id = '';
  baseUrl = '';
  projectData: any;
  IsapprovePopUp: boolean = false;
  IsrejectPopUp: boolean = false;
  IsNotrejectPopUp: boolean = false;
  IsYesrejectPopUp: boolean = false;
  IsExpiredPopUp: boolean = false;
  constructor
    (
      private _activated: ActivatedRoute,
      private layoutService:LayoutService,
      private tableService: TableService
    ) {
    this.id = this._activated.snapshot.params['id'];
    this.baseUrl = "/";
    this.verifyinvitation(this.id);
  }

  ngOnInit() {
    this.layoutService.setShowSidebar(false);
  }

  verifyinvitation(code: any) {
    this.tableService.approvBudgetProject(code).subscribe(res => {
      this.projectData = res
      if(this.projectData.data[0].approval_status != 'Approved' && this.projectData.data[0].approval_status != 'Rejected'){
      if (this.projectData.success) {
        if (this.projectData.data[0].approval_url_token.trim() === code) {
          // let body = {
          //   "Id": this.projectData.data.Id,
          //   "ProjectId": this.projectData.data.projectid,
          //   "status": "Approved"
          // }
          this.IsapprovePopUp = true;
          let Id = this.projectData.data[0].Id;
          let budgetId = this.projectData.data[0].budgetId;
          let status = "Approved";
          this.tableService.getProjectBudgetApprovalByEmailId(Id, budgetId, status).subscribe(res => {
            let data = res;
          })

        }
        else {
          this.IsrejectPopUp = true;
        }

      }
    }
      else{
        this.IsExpiredPopUp=true;
            }
    })

  }

  Yesreject(){
    this.IsrejectPopUp = false;
    this.IsNotrejectPopUp = true;
    let Id = this.projectData.data[0].Id;
    let budgetId = this.projectData.data[0].budgetId;
    let status = "Rejected";
    this.tableService.getProjectBudgetApprovalByEmailId(Id, budgetId, status).subscribe(res => {
      let data = res;
    })
  }
  
  Noreject(){
    this.IsrejectPopUp = false;
    this.IsNotrejectPopUp = false;
    this.IsYesrejectPopUp=true;
    }

}
