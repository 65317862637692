import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Inject, Component } from '@angular/core';
import { AppComponent } from './app.component';
import { StickyModule } from 'ng2-sticky-kit';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastrModule } from 'ng6-toastr-notifications';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { appRouteModule } from './app-routing.module';
import { ExtaModule } from './exta/exta.module';
import { ComponentModule } from './component/component.module';
import { AuthenticationService } from './app.service';
import { AuthModule } from './auth/auth.module';
import { from } from 'rxjs';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { JobseekerDetailsPopupComponent } from './admin/popup/jobseeker-details-popup/jobseeker-details-popup.component';
import { AuthGuard } from './auth/auth.guard';
import { LoaderInterceptor} from 'src/app/share-module/service/loader-interceptor.service'


// import { AdmindashboardComponent } from './admin/admindashboard/admindashboard.component';
// import { AdminComponent } from './admin/admin.component';

@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        JobseekerDetailsPopupComponent,
        // AdminComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule,
        appRouteModule,
        MatAutocompleteModule,
        ToastrModule.forRoot(),
        MatNativeDateModule,
        MatInputModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatDialogModule,
        MatTooltipModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        StickyModule,
        MatFormFieldModule,
        NgbModule.forRoot(),
        // StaffingAgencyModule,
        // EnterpriseModule,
        ComponentModule,
        // ContactUsComponent,
        AuthModule,
        ExtaModule,
        MatIconModule,
        MatTabsModule,
        MatCheckboxModule,
        RichTextEditorAllModule
    ],
    providers: [AuthenticationService, NativeDateAdapter, MatDatepickerModule, Title, AuthGuard,
        // {
        // provide: HTTP_INTERCEPTORS,
        // useClass: LoaderInterceptor,
        // multi: true,
        // }
    ],
    bootstrap: [AppComponent],
    exports: [MatInputModule]
})
export class AppModule { }
