import { Injectable } from '@angular/core';
import * as jwt_decode from "jwt-decode";
@Injectable({
  providedIn: 'root'
})
export class JwtServiceService {
  token = 'token';
  userRole = 'Role';
  userId = 'UserId';
  userEmail = 'Email';
  userName = 'Username';
  user_full_name = 'Name'; 
  subscriber_id = 'SubscriberId'; 
  profileImage ='ProfileImage';
  ProfileImagePath = 'ProfileImagePath';
  subscribeId = "SubscriberId";
  createdBy = 'CreatedBy';
  appRefId = 'AppRefId';
  appRefTypeCode = 'AppRefTypeCode';
  constructor() { 

  }

  getToken(): string {
    const localToken = window.localStorage[this.token];

    //console.log('localToken',localToken);
    //const sessionToken = window.localStorage[this.token_name];
    //const sessionToken = null;
    if (localToken && localToken != '' && localToken != null && localToken != undefined) {
      return localToken;
    }
    else{
      return '';
    }
    // else (sessionToken && sessionToken != '' && sessionToken != null && sessionToken != undefined && sessionToken != 'undefined') {
    //   //console.log(sessionToken);
    //   return sessionToken;
    // }
  }

  saveToken(data: any, rememberMe: boolean) {
    //console.log(data);
    if (rememberMe) {
      window.localStorage[this.token] = data.token;
    }
    else {
      window.localStorage[this.token] = data;
    }
  }

  deleteToken() {
    localStorage.clear();
    sessionStorage.clear();
  }

  getUserRole(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    // console.log('decode',decode);
    return decode[this.userRole];
  }

  getCurrentUserId(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.userId];
  }

  getUserEmail(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.userEmail];
  }

  getUserName(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.userName];
  }



  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  getSubscriberId(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.subscriber_id];

  }

  getRecruiterProfileImgPath(): string {

    var decode = this.getDecodedAccessToken(this.getToken())
    if (decode != null) {
      var data = decode[this.ProfileImagePath];
      return data;
    }
    return '';
  }

}
